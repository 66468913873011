import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import { Defect, getDefectFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import {
  useDefectDeleteMutation,
  useDefectForm,
  useDefectUpdateMutation,
} from "../../hooks";
import { DefectFormFields } from "../defect-form-fields";
import { Delete } from "@mui/icons-material";
import { ModifiedEntity } from "../modified-entity";

const EditDefectDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  data: Defect;
}> = ({ open, onClose, data }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, isSubmitting },
    watch,
  } = useDefectForm(getDefectFormValues(data));

  const { mutateAsync: updateMutation } = useDefectUpdateMutation(
    setError,
    data,
    () => {
      reset(getDefectFormValues(data));
      onClose();
    }
  );

  const { mutateAsync: deleteMutation, isLoading: deleteLoading } =
    useDefectDeleteMutation(data, () => {
      reset(getDefectFormValues(data));
      onClose();
    });

  const onSubmit = async (values: any) => {
    await updateMutation(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    reset(getDefectFormValues(data));
  }, [open, reset, data]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {t("Mangel bearbeiten")}
          <Box textAlign="right">
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              color="secondary"
              onClick={() => deleteMutation()}
            >
              <Delete /> {t("Löschen")}
            </LoadingButton>
            <ModifiedEntity entity={data} />
          </Box>
        </Box>
      </DialogTitle>{" "}
      <DialogContent>
        <Card>
          <DefectFormFields
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default EditDefectDialog;
