import { useMemo } from "react";
import * as yup from "yup";
import { usePersonQualificationValidation } from "./use-person-qualification-validation";

export const usePersonQualificationValidationSchema = () => {
  const validation = usePersonQualificationValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
