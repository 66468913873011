import React, { VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../hooks/use-title";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { routes } from "../../lib/routes";
import {
  ApiError,
  CheckStep,
  CheckStepFormValues,
  CheckStepType,
  getCheckStepFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/header";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { StyledCardTitle } from "../../components/globals";
import LoadingButton from "../../components/loading-button";
import { useCheckStepValidationSchema } from "../../hooks/validations/check-processes/use-check-step-validation-schema";

const CreateCheckStep: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neuer Prüfschritt"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCheckStepValidationSchema();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prüfschritte"), link: routes.checkSteps },
    { label: t("Neu") },
  ];

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<CheckStepFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCheckStepFormValues(),
  });

  const onSubmit = async (
    values: CheckStepFormValues,
    setError: UseFormSetError<CheckStepFormValues>
  ) => {
    await request<CheckStep>(apiRoutes.checkStepCreate, "post", values)
      .then(() => {
        enqueueSnackbar(t("Prüfschritt erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.checkSteps);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Prüfschritte")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prüfschritt")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Controller
                  control={control}
                  name={"title"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Titel")}
                      fullWidth
                      required
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  control={control}
                  name={"type"}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{t("Typ")}</InputLabel>
                      <Select
                        variant="standard"
                        labelId="type"
                        id="type"
                        fullWidth
                        {...field}
                        value={field.value}
                      >
                        {Object.values(CheckStepType).map((type) => (
                          <MenuItem value={type} key={type}>
                            {t(`checkStep.type.${type}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  control={control}
                  name={"securityRelated"}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value || false}
                          onChange={field.onChange}
                          color="primary"
                        />
                      }
                      label={t("Sicherheitsrelevant")}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Beschreibung")}
                      fullWidth
                      multiline
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateCheckStep;
