import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useCompanyValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      customer: yup.boolean(),
      supplier: yup.boolean(),
      manufacturer: yup.boolean(),
      partner: yup.boolean(),
      importer: yup.boolean(),
      trainingCenter: yup.boolean(),
      email: yup.string().email(t("Keine gültige E-Mail Adresse.")),
      website: yup
        .string()
        .nullable()
        .matches(
          /^(((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)?$/,
          t("Keine gültige Website.")
        ),
      name: yup.string().required(t("Name darf nicht leer sein.")),
      term: yup.string().required(t("Suchbegriff darf nicht leer sein.")),
      street: yup.mixed().when("customer", {
        is: (customer: boolean) => customer,
        then: yup.string().required(t("Straße darf nicht leer sein.")),
      }),
      zipCode: yup.mixed().when("customer", {
        is: (customer: boolean) => customer,
        then: yup.string().required(t("PLZ darf nicht leer sein.")),
      }),
      country: yup.mixed().when("customer", {
        is: (customer: boolean) => customer,
        then: yup.string().required(t("Land darf nicht leer sein.")),
      }),
      city: yup.mixed().when("customer", {
        is: (customer: boolean) => customer,
        then: yup.string().required(t("Stadt darf nicht leer sein.")),
      }),
      customerNumber: yup.mixed().when("customer", {
        is: (customer: boolean) => customer,
        then: yup
          .string()
          .typeError(t("Kundennummer darf nicht leer sein."))
          .required(t("Kundennummer darf nicht leer sein.")),
      }),
      supplierNumber: yup.mixed().when("supplier", {
        is: (supplier: boolean) => supplier,
        then: yup
          .string()
          .typeError(t("Lieferrantennummer darf nicht leer sein."))
          .required(t("Lieferrantennummer darf nicht leer sein.")),
      }),
    }),
    [t]
  );
};
