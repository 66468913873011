import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  ApiError,
  Company,
  Document,
  DocumentFormValues,
  getLocationFormValues,
  Location,
  LocationFormValues,
  ManualType,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { StyledHighlightedGrid } from "../globals";
import DocumentUpload from "../document-upload";
import { Add, Clear } from "@mui/icons-material";
import LoadingButton from "../loading-button";
import { useLocationValidationSchema } from "../../hooks/validations/locations/use-location-validation-schema";
import { useMutation, useQueryClient } from "react-query";
import { isOnlyClient, isOnlyTester } from "lib/security";
import { useCurrentUser } from "hooks/use-current-user";
import PaperDialog from "../paper-dialog";

const LocationCreateDialog: FunctionComponent<{
  open: boolean;
  onClose: () => void;
  company?: Company;
  onLocationUpdate: (location: Location) => void;
  redirectRoute?: string;
  name?: string;
}> = ({
  open,
  onClose,
  company,
  onLocationUpdate,
  redirectRoute,
  name = "",
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState<Location[]>([]);

  const {
    data: companies,
    isLoading: companyLoading,
    error: companyError,
  } = useUnlimitedPaginationApi<{}, Company>(apiRoutes.companies, undefined, {
    companyOption: true,
  });

  const validationSchema = useLocationValidationSchema();

  const isTester = isOnlyTester(user);
  const isClient = isOnlyClient(user);

  const {
    mutateAsync: locationMutation,
    isLoading: locationsLoading,
    error: locationsError,
  } = useMutation(
    async () => {
      if (!customer || isTester) {
        return;
      }
      return await request<Location[]>(
        apiRoutes.companyLocations(+customer),
        "get"
      );
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        setLocations(res.data);
      },
    }
  );

  const {
    data: documents,
    isLoading: documentsLoading,
    error: documentsError,
  } = useUnlimitedPaginationApi<DocumentFormValues, Document>(
    apiRoutes.documents,
    { type: ManualType.LocationPlan },
    undefined,
    { enabled: !isClient && !isTester }
  );

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, isSubmitting },
    reset,
    watch,
  } = useForm<LocationFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getLocationFormValues(undefined, company),
  });

  const customer = watch("company");

  useEffect(() => {
    if (!customer) {
      setValue("parent", null);
      setLocations([]);
      return;
    }
    locationMutation();
  }, [customer, setValue, locationMutation]);

  useEffect(() => {
    reset(getLocationFormValues(undefined, company));
  }, [open, company, reset]);

  const showUpload = watch("showUpload", false);
  const queryClient = useQueryClient();

  const onSubmit = async (
    values: LocationFormValues,
    setError: UseFormSetError<LocationFormValues>
  ) => {
    if (showUpload && !values.documentUpload.documentUpload) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        name: values.name,
        parent: values.parent,
        comment: values.comment,
        companies: [values.company],
        document: values.document,
        ...(values.showUpload
          ? {
              documentUpload: {
                name: values.documentUpload.name,
                type: values.documentUpload.type,
                comment: values.documentUpload.comment,
              },
            }
          : {}),
      })
    );

    await request<Location>(apiRoutes.locationCreate, "post", formData)
      .then(async (res) => {
        enqueueSnackbar(t("Ort wurde erfolgreich erstellt."), {
          variant: "success",
        });
        onLocationUpdate(res.data);
        if (redirectRoute) {
          history.push(redirectRoute);
        }

        await queryClient.invalidateQueries([apiRoutes.locations]);
        if (company) {
          await queryClient.invalidateQueries([
            apiRoutes.companyLocations(company.id),
          ]);
        }

        reset(getLocationFormValues(undefined, company));
        onClose();
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => {
    setValue("name", name);
  }, [name, setValue]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <DialogTitle>Neuer Ort/Abteilung</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {locationsError && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {t("Übergeordnete Orte konnten nicht geladen werden.")}
                    </Alert>
                  </Grid>
                )}
                {documentsError && (!isClient || !isTester) && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {t("Dokumente konnten nicht geladen werden.")}
                    </Alert>
                  </Grid>
                )}
                {companyError && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {t("Kunden konnten nicht geladen werden.")}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {company ? (
                    <TextField
                      variant="standard"
                      label={t("Organisation")}
                      fullWidth
                      disabled={true}
                      value={company?.name}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={"company"}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          id="company"
                          options={companies}
                          loading={companyLoading}
                          loadingText={t("Lädt Kunden...")}
                          getOptionKey={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          value={
                            companies.find(
                              (company) => company.id === field.value
                            ) || null
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label={t("Kunde")}
                              required
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"name"}
                    render={({ field, fieldState }) => (
                      <TextField
                        variant="standard"
                        label={t("Name")}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                {!isTester && (
                  <Grid item xs={12}>
                    {locations && (
                      <Controller
                        control={control}
                        name="parent"
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            id="parent"
                            loadingText="Lädt..."
                            noOptionsText="Es wurden keine Orte/Abteilungen für diesen Kunden gefunden"
                            loading={locationsLoading}
                            options={locations}
                            getOptionKey={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, value) =>
                              field.onChange(value?.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label={t("Übergeordneter Ort")}
                                disabled={!!locationsError}
                                error={
                                  fieldState.isTouched && fieldState.invalid
                                }
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"comment"}
                    render={({ field, fieldState }) => (
                      <TextField
                        variant="standard"
                        label={t("Kommentar")}
                        fullWidth
                        multiline={true}
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                {!isClient && !isTester && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={"document"}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            id="document"
                            options={documents || []}
                            loading={documentsLoading}
                            getOptionKey={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, value) =>
                              field.onChange(value?.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label={t("Lagerplan")}
                                disabled={!!locationsError}
                                error={
                                  fieldState.isTouched && fieldState.invalid
                                }
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={"showUpload"}
                        render={({ field }) => (
                          <>
                            <Button
                              type="button"
                              onClick={() => {
                                field.onChange(!showUpload);
                              }}
                            >
                              {showUpload ? (
                                <>
                                  {" "}
                                  <Clear /> {t("Abbrechen")}
                                </>
                              ) : (
                                <>
                                  <Add /> {t("Neuen Lagerplan hochladen")}
                                </>
                              )}
                            </Button>
                            <Checkbox
                              aria-hidden={true}
                              style={{ display: "none" }}
                              checked={showUpload}
                              onChange={field.onChange}
                              color="primary"
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {showUpload && (
                <Grid container spacing={0}>
                  <StyledHighlightedGrid item xs={12}>
                    <Typography variant={"h6"}>
                      {t("Dokument hinzufügen")}
                    </Typography>
                    <DocumentUpload
                      control={control}
                      formPrefix="documentUpload."
                      allowedTypes={[ManualType.LocationPlan]}
                    />
                  </StyledHighlightedGrid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="secondary">
            {t("Abbrechen")}
          </Button>
          <LoadingButton
            disabled={!isValid}
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
        </DialogActions>
      </form>
    </PaperDialog>
  );
};

export default LocationCreateDialog;
