import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useUserValidation = (passwordRequired = true) => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      username: yup.string().required(t("Benutzername ist notwendig.")),
      fullName: yup.string().nullable(),
      email: yup
        .string()
        .email(t("Keine gültige E-Mail Adresse."))
        .required(t("E-Mail ist notwendig.")),
      currentPassword: yup
        .string()
        .required(t("Derzeitiges Passwort ist notwendig.")),
      password: yup.string().when("$passwordRequired", {
        is: () => passwordRequired,
        then: yup.string().required(t("Passwort ist notwendig.")),
      }),
      passwordRepeat: (ref = "password") =>
        yup
          .string()
          .oneOf([yup.ref(ref), null], t("Passwörter müssen übereinstimmen."))
          .when("$passwordRequired", {
            is: () => passwordRequired,
            then: yup.string().required(t("Passwort ist notwendig.")),
          }),
    }),
    [t, passwordRequired]
  );
};
