import { useTitle } from "../../hooks/use-title";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
} from "@mui/material";
import React from "react";
import Header from "../../components/header";
import {
  ApiError,
  DocumentFormValues,
  getDocumentFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { StyledCardTitle } from "../../components/globals";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormSetError } from "react-hook-form";
import DocumentUpload from "../../components/document-upload";
import { useDocumentValidationSchema } from "../../hooks/validations/documents/use-document-validation-schema";
import { ValidationMode } from "../../hooks/validations";
import LoadingButton from "../../components/loading-button";

const CreateDocument = () => {
  const { t } = useTranslation();
  useTitle(t("Neues Dokument"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Dokumente"), link: routes.documents },
    { label: t("Neu") },
  ];

  const validationSchema = useDocumentValidationSchema(ValidationMode.Create);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<DocumentFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getDocumentFormValues(),
  });

  const onSubmit = async (
    values: DocumentFormValues,
    setError: UseFormSetError<DocumentFormValues>
  ) => {
    if (!values.documentUpload) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }
    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        name: values.name,
        type: values.type,
        comment: values.comment,
      })
    );
    await request<Document>(apiRoutes.documentCreate, "post", formData)
      .then(() => {
        enqueueSnackbar(t("Dokument wurde erfolgreich hochgeladen."), {
          variant: "success",
        });
        history.push(routes.documents);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Dokument")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Dokument")}
            </StyledCardTitle>
            <DocumentUpload control={control} />
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={isSubmitting || !isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Dokument anlegen")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateDocument;
