import { CompanyGrant, Role } from "../../model";
import { useTitle } from "../../hooks/use-title";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Delete, Edit, PictureAsPdf } from "@mui/icons-material";
import Header from "../../components/header";
import {
  apiRoutes,
  getAbsoluteApiUrl,
  request,
  useCompanyDetailsApi,
} from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Definition,
  StyledCardTitle,
  StyledContainer,
  StyledFooter,
  StyledLogo,
  TabPanel,
} from "../../components/globals";
import Guarded from "../../components/guarded";
import { ModifiedEntity } from "../../components/modified-entity";
import styled from "styled-components";
import ItemListFilterable from "../../components/item-list-filterable";
import { useGuarded } from "../../hooks/use-guarded";
import Locations from "./locations";
import CompanyList from "../../components/company-list";
import Persons from "./persons";
import Billing from "./billing";
import Orders from "./orders";

const StyledSpan = styled.span`
  display: block;
`;

enum TabPanels {
  Default,
  Billing,
  Locations,
  Persons,
  Orders,
  Items,
}

const CompanyDetails = () => {
  const { isGranted } = useGuarded();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useCompanyDetailsApi(
    companyId as unknown as number
  );
  const history = useHistory();
  const [value, setValue] = useState(0);
  useTitle(data?.name || t("Organisation Details"));
  const { search } = useLocation();
  const tab = new URLSearchParams(search).get("tab") || "";

  useEffect(() => {
    switch (tab) {
      case "orders":
        setValue(TabPanels.Orders);
        break;
      case "items":
        setValue(TabPanels.Items);
        break;
      case "billing":
        setValue(TabPanels.Billing);
        break;
      case "locations":
        setValue(TabPanels.Locations);
        break;
      case "persons":
        setValue(TabPanels.Persons);
        break;
      case "default":
      default:
        setValue(TabPanels.Default);
        break;
    }
  }, [tab]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={`/company`}>
            {t("Zurück zu Organisationen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Organisationen"), link: routes.companies },
    { label: data.name },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie die Organisation {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.company(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Organisation wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/companies");
      })
      .catch(() =>
        enqueueSnackbar(t("Organisation konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const changeTab = (tab: TabPanels) => {
    if (tab === TabPanels.Default) {
      history.replace({
        search: ``,
      });
      return;
    }
    history.replace({
      search: `?tab=${TabPanels[tab].toLowerCase()}`,
    });
  };

  return (
    <StyledContainer>
      <Header
        title={t("Organisationen")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            {value === 0 && (
              <>
                <Guarded requiredRole={Role.Admin}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={routes.companyEdit(data.id)}
                  >
                    <Edit /> {t("Bearbeiten")}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                  >
                    <Delete /> {t("Löschen")}
                  </Button>
                </Guarded>

                <ModifiedEntity entity={data} />
              </>
            )}
          </>
        }
      />
      <Card>
        <Paper square={true}>
          <Tabs
            value={value}
            onChange={(event, newTab) => changeTab(newTab)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Organisation" value={TabPanels.Default} />
            {isGranted(CompanyGrant.VIEW_BILLING, data) && (
              <Tab label="Verrechnung" value={TabPanels.Billing} />
            )}
            <Tab label="Orte/Abteilungen" value={TabPanels.Locations} />
            <Tab label="Personen" value={TabPanels.Persons} />
            <Tab label="Aufträge" value={TabPanels.Orders} />
            <Tab label="Items" value={TabPanels.Items} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={TabPanels.Default}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Typ")}
                </StyledCardTitle>
                <Grid container>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.customer}
                          readOnly={true}
                          name="customer"
                          color="primary"
                        />
                      }
                      label={t("Kunde")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.supplier}
                          readOnly={true}
                          name="supplier"
                          color="primary"
                        />
                      }
                      label={t("Lieferant")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.manufacturer}
                          readOnly={true}
                          name="manufacturer"
                          color="primary"
                        />
                      }
                      label={t("Hersteller")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.partner}
                          readOnly={true}
                          name="partner"
                          color="primary"
                        />
                      }
                      label={t("Vertriebspartner")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.distributor}
                          readOnly={true}
                          name="distributor"
                          color="primary"
                        />
                      }
                      label={t("Distributeur")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.testPartner}
                          readOnly={true}
                          name="testPartner"
                          color="primary"
                        />
                      }
                      label={t("Prüfpartner")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.opinionLeader}
                          readOnly={true}
                          name="opinionLeader"
                          color="primary"
                        />
                      }
                      label={t("Meinungsbildner")}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.trainingCenter}
                          readOnly={true}
                          name="trainingCenter"
                          color="primary"
                        />
                      }
                      label={t("Schulungszentrum")}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Stammdaten")}
                </StyledCardTitle>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Definition title={t("Name")} value={data.name} />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition title={t("Suchbegriff")} value={data.term} />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition
                      title={t("Übergeordnete Organisation")}
                      value={
                        data.parent ? (
                          <Link
                            component={RouterLink}
                            to={routes.company(data.parent.id)}
                            key={data.parent.id}
                          >
                            <StyledSpan>{data.parent.name}</StyledSpan>
                          </Link>
                        ) : (
                          <StyledSpan>
                            {t("Keine übergeordnete Organisation")}
                          </StyledSpan>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition
                      title={t("Suborganistaion")}
                      value={
                        <>
                          {data.companies.map((company) => (
                            <Link
                              component={RouterLink}
                              to={routes.company(company.id)}
                              key={company.id}
                            >
                              <StyledSpan>{company.name}</StyledSpan>
                            </Link>
                          ))}
                          {!data.companies.length && (
                            <StyledSpan>
                              {t("Keine Organisationen zugeordnet")}
                            </StyledSpan>
                          )}
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition
                      title={t("Firmenbuchnummer")}
                      value={data.registerNumber}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition
                      title={t("UID-Nummer")}
                      value={data.uidNumber}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition title={t("Telefonnummer")} value={data.phone} />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition title={t("E-Mail")} value={data.email} />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition title={t("Webseite")} value={data.website} />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Sonstiges")}
                </StyledCardTitle>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Definition
                      title={t("Kommentar")}
                      value={
                        data.comment ||
                        t("Es wurde noch kein Kommentar hinzugefügt.")
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
            <Divider
              orientation={"vertical"}
              flexItem
              style={{ marginRight: "-2px" }}
            />
            <Grid item xs={12} md={6}>
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Adresse")}
                </StyledCardTitle>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Definition title={t("Straße")} value={data.street} />
                  </Grid>
                  <Grid item xs={3}>
                    <Definition title={t("PLZ")} value={data.zipCode} />
                  </Grid>
                  <Grid item xs={3}>
                    <Definition title={t("Ort")} value={data.city} />
                  </Grid>
                  <Grid item xs={6}>
                    <Definition title={t("Land")} value={data.country} />
                  </Grid>
                  <Grid item xs={12}>
                    <Definition
                      title={t("Adresszusatz")}
                      value={data.additionalAddressInformation}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {data.customer && (
                <>
                  <CardContent>
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("Kunden Daten")}
                    </StyledCardTitle>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Definition
                          title={t("Kundennummer")}
                          value={data.customerNumber}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Definition
                          title={t("Kundengruppe")}
                          value={data.customerGroup}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                </>
              )}
              <Guarded requiredRole={Role.Admin}>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Prüfpartner")}
                  </StyledCardTitle>
                  {!!data.testPartners?.length ? (
                    <Grid container spacing={1}>
                      {data.testPartners?.map((partner) => (
                        <Grid key={partner.id} item>
                          <Link
                            component={RouterLink}
                            to={routes.company(partner.id)}
                          >
                            <Chip label={partner.name} clickable />
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="body1">
                      {t("Es wurde noch kein Prüfpartner hinterlegt")}
                    </Typography>
                  )}
                </CardContent>
                <Divider />
              </Guarded>
              <Guarded grant={CompanyGrant.VIEW_DESIGN} subject={data}>
                <CardContent>
                  <StyledCardTitle
                    variant="h6"
                    color="secondary"
                    gutterBottom
                    style={{ justifyContent: "space-between" }}
                  >
                    {t("Logos und Design")}
                    <Button
                      variant="contained"
                      color="secondary"
                      href={getAbsoluteApiUrl(
                        apiRoutes.companyReportPreview(data.id)
                      )}
                      target="_blank"
                      startIcon={<PictureAsPdf />}
                    >
                      {t("Vorschau")}
                    </Button>
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Logo")}
                        value={
                          data.logo ? (
                            <StyledLogo
                              alt={t("Logo")}
                              src={
                                data.logo + "&date=" + new Date().toISOString()
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Stempel")}
                        value={
                          data.seal ? (
                            <StyledLogo
                              alt={t("Stempel")}
                              src={
                                data.seal + "&date=" + new Date().toISOString()
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Definition
                        title={t("Footer")}
                        value={
                          data.footer ? (
                            <StyledFooter
                              alt={t("Footer")}
                              src={
                                data.footer +
                                "&date=" +
                                new Date().toISOString()
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
              </Guarded>
              <Guarded requiredRole={Role.Admin}>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Eigentümer")}
                  </StyledCardTitle>
                  {!!data.owner ? (
                    <Grid container spacing={1}>
                      <Grid key={data.owner.id} item>
                        <Link
                          component={RouterLink}
                          to={routes.company(data.owner.id)}
                        >
                          <Chip label={data.owner.name} clickable />
                        </Link>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body1">
                      {t("Es wurde noch kein Eigentümer hinterlegt")}
                    </Typography>
                  )}
                </CardContent>
                <Divider />
              </Guarded>
              {data.supplier && (
                <>
                  <CardContent>
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("Lieferanten Daten")}
                    </StyledCardTitle>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Definition
                          title={t("Lieferantennummer")}
                          value={data.supplierNumber}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.importer}
                              readOnly={true}
                              name="importer"
                              color="primary"
                            />
                          }
                          label={t("Importeur")}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              )}
              {data.portfolio && (data.importer || data.supplier) && (
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Portfolio")}
                  </StyledCardTitle>
                  {data.portfolio.length ? (
                    <CompanyList companies={data.portfolio} />
                  ) : (
                    <CardContent>
                      <Typography variant="body1">
                        {t("Es wurde noch keine Organisation hinzugefügt")}
                      </Typography>
                    </CardContent>
                  )}
                </CardContent>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        {isGranted(CompanyGrant.VIEW_BILLING, data) && (
          <TabPanel value={value} index={TabPanels.Billing}>
            <Billing company={data} />
          </TabPanel>
        )}
        <TabPanel value={value} index={TabPanels.Locations}>
          <Locations company={data} />
        </TabPanel>
        <TabPanel value={value} index={TabPanels.Persons}>
          <Persons company={data} />
        </TabPanel>
        <TabPanel value={value} index={TabPanels.Orders}>
          <Orders company={data} />
        </TabPanel>
        <TabPanel value={value} index={TabPanels.Items}>
          <ItemListFilterable filters={{ company: data.id }} company={data} />
        </TabPanel>
      </Card>
    </StyledContainer>
  );
};

export default CompanyDetails;
