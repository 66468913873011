import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import { Document, DocumentFilters, ManualType, Role } from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { Link } from "react-router-dom";
import DocumentList from "../../components/document-list";
import { config } from "../../config";

const DocumentsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Dokumente"));
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Dokumente") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [documentType, setFilterType] = useQueryState(
    "",
    "documentType",
    queryStateOptions
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<DocumentFilters, Document>(
      { search, type: documentType },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    DocumentFilters,
    Document
  >(apiRoutes.documents, params);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterType("");
  };

  const hasFilters = search !== "" || documentType !== "";

  return (
    <StyledContainer data-test="documents-content">
      <Header
        title={`Dokumente`}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.Admin}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.documentCreate}
            >
              <Add /> {t("Neues Dokument")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("zB: Dokumenten Name")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="action">{t("Typ")}</InputLabel>
              <Select
                labelId="action"
                id="action"
                value={documentType}
                onChange={(e) => setFilterType(e.target.value as string)}
                label={t("Typ")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                {Object.values(ManualType).map((document) => (
                  <MenuItem
                    sx={capitalizeStyle}
                    value={document}
                    key={document}
                  >
                    <em>{t(`document.type.${document}`)}</em>
                  </MenuItem>
                ))}
              </Select>
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <DocumentList
          documents={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default DocumentsOverview;
