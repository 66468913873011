import {
  Autocomplete,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { FindInPage } from "@mui/icons-material";
import DocumentUpload from "components/document-upload";
import { StyledCardTitle } from "components/globals";
import { apiRoutes, getAbsoluteApiUrl } from "lib/api";
import { DateFormat, ManualType } from "model";
import {
  AccessStatus,
  Billing,
  BillingFormValues,
  TariffModel,
} from "model/billing";
import React, { FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BillingFormFields: FunctionComponent<{
  control: Control<BillingFormValues>;
  billing?: Billing;
}> = ({ control, billing }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Organisatorische Kontaktdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"organizerName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Name")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"organizerEmail"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"organizerPhone"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Telefonnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Buchhaltung Kontaktdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"accountantName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Name")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"accountantEmail"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"accountantPhone"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Telefonnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Rechnungsinformationen")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"invoiceEmail"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"invoiceRemark"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Rechnungsvermerk")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name={"orderNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Bestellnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Tarif")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name="tariffModel"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="tariffModel"
                  options={Object.values(TariffModel)}
                  getOptionLabel={(option) =>
                    t(`billing.tariffModel.${option}`)
                  }
                  onChange={(_, value) => field.onChange(value)}
                  value={field.value || null}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Tarifmodell")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Controller
              control={control}
              name="accessStatus"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="accessType"
                  options={Object.values(AccessStatus)}
                  getOptionLabel={(option) =>
                    t(`billing.accessStatus.${option}`)
                  }
                  onChange={(_, value) => field.onChange(value)}
                  value={field.value || null}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Zugriffstatus")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Vertragsdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6} md={8}>
            <DocumentUpload
              control={control}
              allowedTypes={[ManualType.Contract]}
              formPrefix="newContract"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Controller
                  control={control}
                  name={"dueDate"}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      format={DateFormat.Default}
                      label={t("Stichtag")}
                      onChange={field.onChange}
                      value={field.value ? new Date(field.value) : null}
                      slotProps={{
                        textField: {
                          error: fieldState.isTouched && fieldState.invalid,
                          helperText: fieldState.error?.message,
                          fullWidth: true,
                          variant: "standard",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              {billing?.contract?.fileName && (
                <Grid item>
                  <Controller
                    control={control}
                    name={"deleteContract"}
                    render={({ field }) => (
                      <FormControlLabel
                        label={
                          <>
                            {t("Vertrag löschen")}
                            <a
                              href={getAbsoluteApiUrl(
                                apiRoutes.documentFile(billing.contract!.id)
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton
                                aria-label={t("Ansehen")}
                                size="small"
                              >
                                <FindInPage />
                              </IconButton>
                            </a>
                          </>
                        }
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="deleteContract"
                            color="primary"
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default BillingFormFields;
