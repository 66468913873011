import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { FormValues } from "./index";
import { Check, Item, Nullable } from "../../model";
import { Alert, Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { useFilters } from "../../hooks/use-filters";
import { config } from "../../config";
import { useDebounceState } from "../../hooks/use-debounce-state";
import CreateItemDialog from "../create-item-dialog";
import { Cancel, CheckCircle, Error } from "@mui/icons-material";
import { amber, green, red } from "@mui/material/colors";

interface ItemOption {
  value: string | null;
  label: string;
  defects: number;
  checkResult?: number;
}

const filter = createFilterOptions<ItemOption>();
const createItemOptions = (items: Item[], check: Check): ItemOption[] => {
  return items.map((item) => ({
    value: item.id.toString(),
    label: item.serialNumber,
    checkResult:
      item.itemChecks?.find((ic) => ic.order && ic.order.id === check.order.id)
        ?.checkResult || 0,
    defects: item.openDefects?.length || 0,
  }));
};

export const orderProcessingItemsQueryKey = "order-processing-items";

const OptionIcon: VoidFunctionComponent<{ option: ItemOption }> = ({
  option,
}) => {
  const style = getIconColor(option);
  return (
    <Box
      style={{
        display: "flex",
        borderRadius: "50%",
        width: "1.1em",
        height: "1.1em",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {getIcon(option)}
    </Box>
  );
};

const ItemSelector: VoidFunctionComponent<{
  check: Check;
  control: Control<FormValues>;
  watch: UseFormWatch<FormValues>;
  onItemSelect: (item: Nullable<Item>) => void;
}> = ({ check, control, onItemSelect, watch }) => {
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useDebounceState(search, setSearch);
  const [currentItemSerialNumber, setCurrentItemSerialNumber] = useState("");

  const { params } = useFilters<{}, Item>(
    {
      serialNumber: search,
      company: check.order.customer.id,
    },
    "id",
    "desc",
    config.pageSize
  );
  const { data, isLoading, error } = usePaginationApi<{}, Item>(
    apiRoutes.items,
    params,
    undefined,
    [orderProcessingItemsQueryKey, params]
  );
  const { t } = useTranslation();
  const itemOptions = createItemOptions(data?.results || [], check);
  const formItem = watch("item");

  useEffect(() => {
    onItemSelect(
      data?.results.find((i) => formItem && i.id === +formItem) || null
    );
  }, [formItem, data, onItemSelect]);

  if (error) {
    return <Alert severity="error">{t("Fehler beim Laden der Items!")}</Alert>;
  }

  return (
    <>
      <Controller
        control={control}
        name="item"
        render={({ field, fieldState }) => (
          <Autocomplete
            id="item"
            loading={isLoading}
            options={itemOptions}
            disabled={!!check.currentItem}
            inputValue={inputSearch}
            value={
              itemOptions.find(
                (item) =>
                  item.value && field.value && +item.value === +field.value
              ) || null
            }
            onInputChange={(event, value) => {
              if (!value) {
                onItemSelect(null);
                field.onChange(null);
              }
              setInputSearch(value || "");
            }}
            getOptionKey={(option) => option.value || ""}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  value: `new${params.inputValue}`,
                  label: t(`Item "{{serialNumber}}" hinzufügen`, {
                    serialNumber: params.inputValue,
                  }),
                  defects: 0,
                });
              }
              return filtered;
            }}
            onChange={(event, value) => {
              if (!value || !value.value) {
                field.onChange(null);
                return;
              }
              if (value.value.substr(0, 3) === "new") {
                setCurrentItemSerialNumber(value.value.substr(3));
                return;
              }
              if (!data) {
                return;
              }
              const item = data.results.find(
                (o) => value.value && +o.id === +value.value
              );
              if (!item) {
                return;
              }
              field.onChange(value.value);
              onItemSelect(item);
              setInputSearch(item.serialNumber);
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>{option.label}</Grid>
                  <Grid item>
                    <OptionIcon option={option} />
                  </Grid>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Item-Seriennummer")}
                required
                error={fieldState.isTouched && fieldState.invalid}
                variant="outlined"
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <CreateItemDialog
        open={!!currentItemSerialNumber}
        onClose={() => setCurrentItemSerialNumber("")}
        company={check.order.customer}
        serialNumber={currentItemSerialNumber}
        onCreated={(item) => {
          setSearch(item.serialNumber);
          onItemSelect(item);
        }}
      />
    </>
  );
};

export default ItemSelector;

function getIconColor(option: ItemOption) {
  if (option.defects > 0) {
    return {
      color: amber["500"],
      backgroundColor: "black",
    };
  }
  switch (option.checkResult) {
    case 1:
      return { color: green["500"] };
    case 5:
      return { color: red["500"] };
    default:
      return { color: "grey" };
  }
}

function getIcon(option: ItemOption) {
  if (option.defects > 0) {
    return <Error style={{ fontSize: "1.6em" }} />;
  }
  switch (option.checkResult) {
    case 1:
      return <CheckCircle style={{ fontSize: "1.6em" }} />;
    case 5:
      return <Cancel style={{ fontSize: "1.6em" }} />;
    default:
      return <></>;
  }
}
