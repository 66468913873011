import { Switch, useRouteMatch } from "react-router-dom";
import PersonsOverview from "./overview";
import GuardedRoute from "../../components/guarded-route";
import { routes } from "../../lib/routes";
import { Role } from "../../model";
import CreatePerson from "./create";
import EditPerson from "./edit";
import PersonDetails from "./details";
import PersonQualificationCreate from "./person-qualification-create";
import PersonQualificationEdit from "./person-qualification-edit";

const Persons = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <GuardedRoute exact path={path} requiredRole={[Role.Admin, Role.Client]}>
        <PersonsOverview />
      </GuardedRoute>
      <GuardedRoute
        path={routes.personCreate}
        requiredRole={[Role.Admin, Role.Client]}
      >
        <CreatePerson />
      </GuardedRoute>
      <GuardedRoute
        path={routes.personEdit(":personId")}
        requiredRole={[Role.Admin, Role.Client]}
      >
        <EditPerson />
      </GuardedRoute>
      <GuardedRoute
        path={routes.personQualificationEdit(
          ":personId",
          ":personQualificationId"
        )}
        requiredRole={Role.QA}
      >
        <PersonQualificationEdit />
      </GuardedRoute>
      <GuardedRoute
        path={routes.personQualificationCreate(":personId")}
        requiredRole={Role.QA}
      >
        <PersonQualificationCreate />
      </GuardedRoute>
      <GuardedRoute
        path={routes.person(":personId")}
        requiredRole={[Role.Admin, Role.Client]}
      >
        <PersonDetails />
      </GuardedRoute>
    </Switch>
  );
};

export default Persons;
