import React, {
  Dispatch,
  SetStateAction,
  useState,
  VoidFunctionComponent,
} from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import {
  Action,
  Defect,
  FilePreview,
  Item,
  ItemDocumentationType,
  ItemDocumentationValues,
  Role,
} from "../../model";
import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { StyledHeadline } from "../globals";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { apiRoutes } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../hooks/use-current-user";
import { Add } from "@mui/icons-material";
import { Thumbnail } from "../thumbnail";
import { Option } from "../order-processing/helper";
import CreateDefectDialog from "../create-defect-dialog";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import CreateActionDialog from "../create-action-dialog";
import { hasRole } from "lib/security";

export const bypassResultConfirmationText =
  "Sind Sie sich sicher, dass Sie das Item trotz des angegebenen Mangels nicht sperren möchten?";

export const filterOptions = createFilterOptions<Option>();
export const createDefectOptions = (defects: Defect[]): Option[] =>
  defects.map((defect) => ({
    value: defect.id.toString(),
    label: defect.title,
  }));
export const createActionOptions = (actions: Action[]): Option[] =>
  actions.map((action) => ({
    value: action.id.toString(),
    label: action.title,
  }));

export const ItemDocumentationFormFields: VoidFunctionComponent<{
  control: Control<ItemDocumentationValues>;
  type: string;
  item: Item;
  files: FilePreview[];
  setFiles: Dispatch<SetStateAction<FilePreview[]>>;
  setValue: UseFormSetValue<ItemDocumentationValues>;
  locationId?: number;
}> = ({ type, control, item, files, setFiles, setValue, locationId }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [defectInput, setDefectInput] = useState<string>("");
  const [actionInput, setActionInput] = useState<string>("");
  const [createDefectOpen, setCreateDefectOpen] = useState(false);
  const [createActionOpen, setCreateActionOpen] = useState(false);

  const { data: defectsData, isLoading: defectsLoading } =
    useUnlimitedPaginationApi<
      { companyId: string; locationId: string },
      Defect
    >(apiRoutes.defects, {
      companyId: item.customer?.id.toString() || "",
      locationId: item.location?.id.toString() || "",
    });

  const defectOptions = createDefectOptions(defectsData);

  const { data: actionsData, isLoading: actionsLoading } =
    useUnlimitedPaginationApi<
      { companyId: string; locationId: string },
      Defect
    >(apiRoutes.actions, {
      companyId: item.customer?.id.toString() || "",
      locationId: item.location?.id.toString() || "",
    });

  const actionOptions = createActionOptions(actionsData);

  const selectImages = (fileList: FileList) => {
    Array.from(fileList).forEach((file) => {
      if (files.length > 4) {
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFiles((files) => {
          if (files.length > 4) {
            return files;
          }
          return [...files, { file, preview: reader.result as string }];
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (file: File) => {
    setFiles((files) => files.filter((f) => f.file !== file));
  };

  return (
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledHeadline>{t("Bilder")}</StyledHeadline>
              {files.length <= 0 && (
                <Typography style={{ marginBottom: "1em" }}>
                  {t("Keine Bilder vorhanden.")}
                </Typography>
              )}
              <Grid container spacing={2}>
                {files.map((file, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Thumbnail
                      preview={file}
                      onDelete={() => {
                        if (!file) {
                          console.warn("Cannot remove file, file is missing");
                          return;
                        }
                        removeFile(file.file);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <label>
                <Button
                  style={{ marginTop: "1em" }}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  startIcon={<Add />}
                  component="span"
                  disabled={files.length > 4}
                >
                  {t("Bilder hinzufügen")}
                  <input
                    accept="image/*"
                    id="attachment.fileUpload"
                    hidden
                    type="file"
                    onChange={(e) => {
                      if (!e.currentTarget.files) {
                        return;
                      }
                      selectImages(e.currentTarget.files);
                    }}
                    multiple
                  />
                </Button>
              </label>
              <Typography variant="caption">
                {t("1-5 Bilder auswählen")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel id="item-documentation-type-label">
                      {t("Typ")}
                    </InputLabel>
                    <Select
                      variant="standard"
                      fullWidth
                      {...field}
                      value={field.value}
                      labelId="item-documentation-type-label"
                    >
                      <MenuItem
                        key="defect"
                        value={ItemDocumentationType.Defect}
                      >
                        {t("Mangel")}
                      </MenuItem>
                      <MenuItem
                        key="documentation"
                        value={ItemDocumentationType.Documentation}
                      >
                        {t("Dokumentation")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            {type === ItemDocumentationType.Documentation && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Beschreibung")}
                      fullWidth
                      {...field}
                      required
                      disabled={
                        !(
                          hasRole(currentUser, Role.Admin) ||
                          hasRole(currentUser, Role.Tester)
                        )
                      }
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            )}
            {type === ItemDocumentationType.Defect && (
              <>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="defect"
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="defect"
                        loading={defectsLoading}
                        loadingText={t("Lädt Mängel...")}
                        noOptionsText={t("Es wurden keine Mängel gefunden")}
                        options={defectOptions}
                        getOptionKey={(option) => option.value || ""}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(_, value) => {
                          if (
                            value &&
                            value.value &&
                            value.value.substr(0, 3) === "new"
                          ) {
                            setDefectInput(value.value.substr(3));
                            return setCreateDefectOpen(true);
                          }
                          field.onChange(value?.value || null);
                        }}
                        value={
                          defectOptions.find((defect) =>
                            field.value
                              ? defect.value === field.value.toString()
                              : false
                          ) || null
                        }
                        filterOptions={(options, params) => {
                          const filtered = filterOptions(options, params);
                          if (params.inputValue !== "") {
                            filtered.push({
                              value: `new${params.inputValue}`,
                              label: t(`Mangel "{{name}}" hinzufügen`, {
                                name: params.inputValue,
                              }),
                            });
                          }
                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={t("Mangel")}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            required={true}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="action"
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="action"
                        loading={actionsLoading}
                        loadingText={t("Lädt Maßnahmen...")}
                        noOptionsText={t("Es wurden keine Maßnahmen gefunden")}
                        options={actionOptions}
                        getOptionKey={(option) => option.value || ""}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(_, value) => {
                          if (
                            value &&
                            value.value &&
                            value.value.substr(0, 3) === "new"
                          ) {
                            setActionInput(value.value.substr(3));
                            return setCreateActionOpen(true);
                          }
                          field.onChange(value?.value || null);
                        }}
                        value={
                          actionOptions.find((action) =>
                            field.value
                              ? action.value === field.value.toString()
                              : false
                          ) || null
                        }
                        filterOptions={(options, params) => {
                          const filtered = filterOptions(options, params);
                          if (params.inputValue !== "") {
                            filtered.push({
                              value: `new${params.inputValue}`,
                              label: t(`Maßnahme "{{name}}" hinzufügen`, {
                                name: params.inputValue,
                              }),
                            });
                          }
                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={t("Vorgeschlagene Maßnahme")}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            required={true}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="bypassResultLock"
                    render={({ field }) => (
                      <FormControlLabel
                        label={t("Prüfurteil gemäß Prüfung ausstellen.")}
                        control={
                          <Checkbox
                            color="primary"
                            checked={field.value || false}
                            onChange={field.onChange}
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="comment"
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Kommentar")}
                    fullWidth
                    multiline
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {createDefectOpen && (
        <CreateDefectDialog
          open={createDefectOpen}
          onClose={() => setCreateDefectOpen(false)}
          company={item?.customer || undefined}
          locationId={locationId}
          title={defectInput}
          onCreate={(defect) => {
            setDefectInput(defect.title);
            setValue("defect", defect.id);
          }}
        />
      )}
      {createActionOpen && (
        <CreateActionDialog
          open={createActionOpen}
          onClose={() => setCreateActionOpen(false)}
          company={item?.customer || undefined}
          locationId={locationId}
          title={actionInput}
          onCreate={(action) => {
            setActionInput(action.title);
            setValue("action", action.id);
          }}
        />
      )}
    </CardContent>
  );
};
