import React, { useState, VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { CompanyFilters, CompanyOption } from "../../model";
import { apiRoutes } from "../../lib/api";
import Category from "./category";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import { useCheckQuery } from "../../hooks/queries/use-check-query";
import CreateCompanyDialog from "../create-company-dialog";
import { useSelectCompanyMutation } from "../../hooks/mutation/use-select-company-mutation";

const filter = createFilterOptions<CompanyOption>();

const CompanySelect: VoidFunctionComponent = () => {
  const company = useSelectedCompany();
  // Handle autocomplete value separately to be able to reset it after selection
  const [autocompleteCompany, setAutocompleteCompany] =
    useState<CompanyOption | null>(null);
  const { data: companies } = useUnlimitedPaginationApi<
    CompanyFilters,
    CompanyOption
  >(apiRoutes.companies, undefined, {
    companyOption: true,
  });
  const { t } = useTranslation();
  const { data } = useCheckQuery();
  const checkRunning = !!data;
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [currentName, setCurrentName] = useState<string>("");
  const selectCompanyMutation = useSelectCompanyMutation();

  return (
    <>
      <Category label={t(`Aktive Organisation`)} />
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        px="16px"
        mt={-0.5}
        mb={2}
      >
        <Autocomplete
          options={companies}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.term || option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={autocompleteCompany}
          onChange={(_, value) => {
            if (value?.id === company?.id) {
              setAutocompleteCompany(null);
              return;
            }
            if (value?.create) {
              setCurrentName(value?.name);
              return setCreateDialogOpen(true);
            }
            setAutocompleteCompany(value);
            selectCompanyMutation.mutate(value?.id || null, {
              onSuccess: () => setAutocompleteCompany(null),
            });
          }}
          disabled={checkRunning || selectCompanyMutation.isLoading}
          renderInput={(params) =>
            checkRunning ? (
              <Tooltip
                title={t<string>(
                  "Während einer laufenden Prüfung kann die aktive Organisation nicht gewechselt werden. Bitte Prüfung zuvor beenden."
                )}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={t("Prüfung aktiv")}
                />
              </Tooltip>
            ) : (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label={t("Suchbegriff")}
              />
            )
          }
          filterOptions={(options, state) => {
            const filtered = filter(options, state);
            if (state.inputValue !== "") {
              filtered.push({
                id: -1,
                name: state.inputValue,
                term: t(`Organisation "{{name}}" hinzufügen`, {
                  name: state.inputValue,
                }),
                create: true,
              });
            }
            return filtered;
          }}
        />
        {company && (
          <Box>
            <Typography variant="subtitle2">{company.name}</Typography>
            <Typography variant="caption">
              {company.street}, {company.zipCode} {company.city}
            </Typography>
          </Box>
        )}
      </Box>
      {createDialogOpen && (
        <CreateCompanyDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onUpdate={(data) => selectCompanyMutation.mutate(data.id)}
          name={currentName}
        />
      )}
    </>
  );
};

export default CompanySelect;
