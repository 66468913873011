import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller, useFieldArray } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import styled from "styled-components";
import { DateFormat, DeliveryDatesValues, OrderFormValues } from "../../model";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StyledGrid = styled(Grid)`
  margin-left: auto;
`;

const OrderDeliveryDate: FunctionComponent<{
  control: Control<OrderFormValues>;
  deliveryDates?: DeliveryDatesValues[];
}> = ({ control, deliveryDates }) => {
  const { t } = useTranslation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "deliveryDates",
  });

  return (
    <Grid container spacing={3}>
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} key={field.id}>
            <Card>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>{index + 1}</Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`deliveryDates.${index}.isRange` as const}
                          defaultValue={field.isRange as any}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={field.value || false}
                                  onChange={field.onChange}
                                  color="primary"
                                />
                              }
                              label={t("Liefer-Zeitspanne")}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`deliveryDates.${index}.badWeather` as const}
                          defaultValue={field.badWeather as any}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={field.value || false}
                                  onChange={field.onChange}
                                  color="primary"
                                />
                              }
                              label={t("Schlechtwetter Auftrag")}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`deliveryDates.${index}.date` as const}
                          defaultValue={field.date as any}
                          render={({ field, fieldState }) => (
                            <DatePicker
                              name={field.name}
                              value={field.value ? new Date(field.value) : null}
                              format={DateFormat.Default}
                              onChange={field.onChange}
                              label={t("Datum")}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  error:
                                    fieldState.isTouched && fieldState.invalid,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  onBlur: () =>
                                    !field.value && field.onChange(null),
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {deliveryDates && deliveryDates[index]?.isRange && (
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={`deliveryDates.${index}.dateTo` as const}
                            defaultValue={field.dateTo as any}
                            render={({ field, fieldState }) => (
                              <DatePicker
                                name={field.name}
                                value={
                                  field.value ? new Date(field.value) : null
                                }
                                format={DateFormat.Default}
                                onChange={field.onChange}
                                label={t("Datum bis")}
                                slotProps={{
                                  textField: {
                                    variant: "standard",
                                    error:
                                      fieldState.isTouched &&
                                      fieldState.invalid,
                                    helperText: fieldState.error?.message,
                                    required: true,
                                    fullWidth: true,
                                    onBlur: () =>
                                      !field.value && field.onChange(null),
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      style={{ height: "100%" }}
                    >
                      <Grid item>
                        <Tooltip title={t<string>("Termin entfernen")}>
                          <IconButton
                            type="button"
                            onClick={() => remove(index)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
      <StyledGrid item>
        <Button
          type="button"
          onClick={() =>
            append({
              date: null,
              dateTo: null,
              isRange: false,
              badWeather: false,
            })
          }
          color="primary"
        >
          <Add />
          {t("Termin hinzufügen")}
        </Button>
      </StyledGrid>
    </Grid>
  );
};

export default OrderDeliveryDate;
