import { FunctionComponent } from "react";
import { Role, User as UserModel } from "../../model";
import { Chip, Grid } from "@mui/material";
import UserAvatar from "../user-avatar";
import { useCurrentUser } from "../../hooks/use-current-user";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)`
  margin-left: 0.25em;
`;

const UserBadge: FunctionComponent<{ user: UserModel }> = ({ user }) => {
  const currentUser = useCurrentUser();
  const isAdmin =
    user.roles.includes(Role.Admin) || user.roles.includes(Role.SuperAdmin);
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <UserAvatar user={user} />
      </Grid>
      <Grid item>
        {user.username}
        {` `}
        {isAdmin && (
          <StyledChip
            label={t("Administrator")}
            color={`primary`}
            size={`small`}
          />
        )}
        {!user.enabled && (
          <StyledChip
            label={t("Deaktiviert")}
            color={`secondary`}
            size={`small`}
          />
        )}
        {currentUser.id === user.id && (
          <StyledChip label={t("Du!")} color={`default`} size={`small`} />
        )}
      </Grid>
    </Grid>
  );
};

export default UserBadge;
