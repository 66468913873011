import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useBillingValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      organizerName: yup.string(),
      organizerEmail: yup.string().email(t("Keine gültige E-Mail Adresse.")),
      organizerPhone: yup.string().nullable(),
      accountantName: yup.string().nullable(),
      accountantEmail: yup.string().email(t("Keine gültige E-Mail Adresse.")),
      accountantPhone: yup.string().nullable(),
      invoiceEmail: yup.string().email(t("Keine gültige E-Mail Adresse.")),
      invoiceRemark: yup.string().nullable(),
      orderNumber: yup.string().nullable(),
      tariffModel: yup.string().nullable(),
      accessStatus: yup.string().nullable(),
    }),
    [t]
  );
};
