import React, { FunctionComponent, useCallback, useEffect } from "react";
import {
  Autocomplete,
  CardContent,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
} from "../globals";
import {
  Category,
  Company,
  Dict,
  Item,
  ItemCheckStatus,
  ItemFilters,
  LocationOption,
  Order,
} from "../../model";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useDebounceState } from "../../hooks/use-debounce-state";
import { FilterParams, useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { useSnackbar } from "notistack";
import { Clear } from "@mui/icons-material";
import ItemList from "../item-list";
import { config } from "../../config";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import styled from "styled-components";

export const listItemsQueryKey = "list-items";

export const StyledFilterFormControl = styled(FilterFormControl)`
  width: 14%;
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const ItemListFilterable: FunctionComponent<{
  filters?: ItemFilters;
  company?: Company;
  order?: Order;
  onFilterParamsChange?: (params: FilterParams<ItemFilters, Dict>) => void;
}> = ({ filters, company, order, onFilterParamsChange }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const selectedCompany = useSelectedCompany();

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [category, setFilterCategory] = useQueryState(
    "",
    "category",
    queryStateOptions
  );
  const [inputCategory, setInputCategory] = useDebounceState(
    category,
    setFilterCategory
  );
  const [contact, setFilterContact] = useQueryState(
    "",
    "contact",
    queryStateOptions
  );
  const [inputContact, setInputContact] = useDebounceState(
    contact,
    setFilterContact
  );
  const [checkResult, setFilterCheckResult] = useQueryState(
    "",
    "checkResult",
    queryStateOptions
  );
  const [inputCheckResult, setInputCheckResult] = useDebounceState(
    checkResult,
    setFilterCheckResult
  );
  const [location, setFilterLocation] = useQueryState(
    "",
    "location",
    queryStateOptions
  );
  const [inputLocation, setInputLocation] = useDebounceState(
    location,
    setFilterLocation
  );
  const [orderId, setFilterOrderId] = useQueryState(
    "",
    "orderId",
    queryStateOptions
  );
  const [inputOrderId, setInputOrderId] = useDebounceState(
    orderId,
    setFilterOrderId
  );
  const [contactId, setContactId] = useQueryState(
    "",
    "contactId",
    queryStateOptions
  );
  const filterStatusDefault = "active";
  const [status, setFilterStatus] = useQueryState(
    filterStatusDefault,
    "status",
    queryStateOptions
  );
  const [inputFilterStatus, setInputFilterStatus] = useDebounceState(
    status,
    setFilterStatus
  );
  const [checkStatus, setFilterCheckStatus] = useQueryState<
    ItemCheckStatus | ""
  >("", "checkStatus", queryStateOptions);
  const [inputCheckStatus, setInputCheckStatus] = useDebounceState(
    checkStatus,
    setFilterCheckStatus
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<ItemFilters, Item>(
      {
        search,
        category,
        contact,
        checkResult,
        order: filters?.order ? filters.order : orderId ? orderId : "",
        company: filters?.company ? filters.company : "",
        contactId,
        location,
        status,
        checkStatus,
      },
      "id",
      "desc",
      config.pageSize
    );

  const showOrderFilter = !company && !order;

  const { data, isLoading, error } = usePaginationApi<ItemFilters, Item>(
    apiRoutes.items,
    params,
    undefined,
    [listItemsQueryKey, params]
  );

  const paramsDep = JSON.stringify(params);
  useEffect(() => {
    onFilterParamsChange && onFilterParamsChange(params);
  }, [paramsDep, onFilterParamsChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: locations,
    isLoading: locationsLoading,
    error: locationsError,
  } = useUnlimitedPaginationApi<{}, LocationOption>(
    apiRoutes.locations,
    company ? { company: company.id } : {},
    { locationOption: true }
  );
  const {
    data: orders,
    isLoading: ordersLoading,
    error: ordersError,
  } = useUnlimitedPaginationApi<{}, Order>(
    apiRoutes.orders,
    {},
    {
      orderOption: true,
    },
    {
      enabled: showOrderFilter,
    }
  );
  const [items, setItems] = React.useState<Item[]>([]);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    setItems(data ? data.results : []);
  }, [data]);

  const resetFilter = useCallback(() => {
    setFilterSearch("");
    setFilterCategory("");
    setFilterContact("");
    setFilterCheckResult("");
    setFilterLocation("");
    setFilterOrderId("");
    setContactId("");
    setFilterStatus(filterStatusDefault);
    setFilterCheckStatus("");
  }, [
    setFilterSearch,
    setFilterCategory,
    setFilterContact,
    setFilterCheckResult,
    setFilterLocation,
    setFilterOrderId,
    setContactId,
    setFilterStatus,
    setFilterCheckStatus,
  ]);

  // Reset all filters when selected company changes
  const selectedCompanyId = selectedCompany?.id;
  useEffect(() => {
    if (selectedCompanyId) {
      resetFilter();
    }
  }, [selectedCompanyId, resetFilter]);

  const hasFilters =
    search !== "" ||
    category !== "" ||
    contact !== "" ||
    checkResult !== "" ||
    location !== "" ||
    contactId !== "" ||
    orderId !== "" ||
    status !== filterStatusDefault ||
    checkStatus !== "";

  return (
    <>
      <CardContent>
        <FilterFormGroup row>
          <FilterFormControl variant="outlined" size="small">
            <InputLabel id="status">{t("Status")}</InputLabel>
            <Select
              labelId="status"
              id="status"
              value={inputFilterStatus}
              onChange={(e) => setInputFilterStatus(e.target.value as string)}
              label={t("Status")}
            >
              <MenuItem sx={capitalizeStyle} value="all">
                <em>{t("Alle")}</em>
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value="active">
                {t("Aktiv")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value="archived">
                {t("Archiviert")}
              </MenuItem>
            </Select>
          </FilterFormControl>
          {showOrderFilter && (
            <StyledFilterFormControl>
              <Autocomplete
                id="orderId"
                fullWidth
                onChange={(event, value) =>
                  setInputOrderId(value ? "" + value.id : "")
                }
                options={orders}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.orderNumber}
                isOptionEqualToValue={(option) =>
                  "" + option.id === inputOrderId
                }
                loading={ordersLoading}
                disabled={!!ordersError}
                value={
                  orders.find((order) => "" + order.id === inputOrderId) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Auftrag")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
          )}
          <StyledFilterFormControl>
            <Autocomplete
              id="category"
              fullWidth
              onChange={(event, value) =>
                setInputCategory(value ? value.name : "")
              }
              options={categories}
              loading={categoriesLoading}
              getOptionKey={(option) => option.name}
              getOptionLabel={(option) => option.name}
              disabled={!!categoriesError}
              isOptionEqualToValue={(option) => option.name === inputCategory}
              value={
                categories.find(
                  (category) => category.name === inputCategory
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Kategorie")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </StyledFilterFormControl>
          <FilterFormControl>
            <TextField
              label={t("Suche")}
              variant="outlined"
              placeholder={t("zB: Modell, Seriennummer,...")}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              size="small"
            />
          </FilterFormControl>
          <FilterFormControl>
            <TextField
              label={t("Verwender")}
              variant="outlined"
              placeholder={t("Nachname")}
              value={inputContact}
              onChange={(e) => setInputContact(e.target.value)}
              size="small"
            />
          </FilterFormControl>
          <StyledFilterFormControl>
            <Autocomplete
              id="location"
              fullWidth
              onChange={(event, value) =>
                setInputLocation(value ? "" + value.id : "")
              }
              options={locations}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option) =>
                "" + option.id === inputLocation
              }
              loading={locationsLoading}
              disabled={!!locationsError}
              value={
                locations.find(
                  (location) => "" + location.id === inputLocation
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Ort/Abteilung")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </StyledFilterFormControl>
          <FilterFormControl variant="outlined" size="small">
            <InputLabel id="result">{t("Prüfurteil")}</InputLabel>
            <Select
              labelId="review"
              id="review"
              value={inputCheckResult}
              onChange={(e) => setInputCheckResult(e.target.value as string)}
              label={t("Prüfurteil")}
            >
              <MenuItem sx={capitalizeStyle} value="">
                <em>{t("Alle")}</em>
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={1}>
                {t("Freigegeben")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={5}>
                {t("Ausgeschieden")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={3}>
                {t("Gesperrt")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={"0"}>
                {t("Ausständig")}
              </MenuItem>
            </Select>
          </FilterFormControl>
          <FilterFormControl variant="outlined" size="small">
            <InputLabel id="result">{t("Itemstatus")}</InputLabel>
            <Select
              labelId="checkStatus"
              id="checkStatus"
              value={inputCheckStatus || ""}
              onChange={(e) =>
                setInputCheckStatus(e.target.value as ItemCheckStatus)
              }
              label={t("Itemstatus")}
            >
              <MenuItem sx={capitalizeStyle} value="">
                <em>{t("Alle")}</em>
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ItemCheckStatus.Critical}>
                {t("item.checkStatus." + ItemCheckStatus.Critical)}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ItemCheckStatus.Pending}>
                {t("item.checkStatus." + ItemCheckStatus.Pending)}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ItemCheckStatus.Planning}>
                {t("item.checkStatus." + ItemCheckStatus.Planning)}
              </MenuItem>
            </Select>
          </FilterFormControl>
          {hasFilters && (
            <IconButton size="small" onClick={resetFilter}>
              <Clear />
            </IconButton>
          )}
        </FilterFormGroup>
      </CardContent>
      <ItemList
        items={items ?? []}
        loading={isLoading}
        params={params}
        createSortHandler={createSortHandler}
        company={company}
        order={order ? order : orders?.find((order) => order.id === +orderId)}
        showForOrder={!!orderId}
      />
      <TablePagination
        rowsPerPageOptions={config.pageSizes}
        component="div"
        count={data?.filtered || 0}
        rowsPerPage={params.pageSize}
        page={params.page}
        onPageChange={(e, page) => handleChangePage(page)}
        onRowsPerPageChange={(e) =>
          handleChangePageSize(parseInt(e.target.value, 10))
        }
      />
    </>
  );
};

export default ItemListFilterable;
