import { ReactChild, VoidFunctionComponent } from "react";
import Error from "../error";
import { useTranslation } from "react-i18next";

const NotFound: VoidFunctionComponent<{ actions?: ReactChild }> = ({
  actions,
}) => {
  const { t } = useTranslation();
  return (
    <Error
      title={`404`}
      description={t("Seite wurde nicht gefunden.")}
      actions={actions}
    />
  );
};

export default NotFound;
