import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useCheckStepValidationSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return yup.object({
      title: yup.string().required(t("Titel darf nicht leer sein")),
    });
  }, [t]);
};
