import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Divider, Grid } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { getItemFormValues, ItemFormValues, Role } from "../../model";
import { useItemDetailsApi } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { StyledContainer } from "../../components/globals";
import ItemImportInfo from "../../components/item-import-info";
import LoadingButton from "../../components/loading-button";
import ItemFormFields from "../../components/item-form-fields";
import { useItemForm, useItemUpdateMutation } from "../../hooks";
import Guarded from "../../components/guarded";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyTester } from "../../lib/security";
import { useHistory } from "react-router";

const EditItem: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { itemId } = useParams<{ itemId: string | undefined }>();
  const { isLoading, error, data } = useItemDetailsApi(
    itemId as unknown as number
  );
  useTitle(data?.serialNumber || t("Item bearbeiten"));
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
  } = useItemForm(getItemFormValues(data));

  const updateMutation = useItemUpdateMutation(setError, data);

  const onSubmit = async (values: ItemFormValues) => {
    if (!data) {
      return;
    }
    await updateMutation.mutateAsync(values);
  };

  useEffect(() => reset(getItemFormValues(data)), [reset, data]);

  // Only allow testers to edit items if they are the creator
  useEffect(() => {
    if (onlyTester && data && data.createdByUserId !== user.id) {
      history.push(routes.item(data.id));
    }
  }, [user, data, history, onlyTester]);

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.items}>
            {t("Zurück zu Items")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Items"), link: routes.items },
    { label: data.serialNumber, link: routes.item(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <StyledContainer>
      <Header title={t("Item")} breadcrumbs={breadcrumbs} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <ItemFormFields
              control={control}
              setValue={setValue}
              watch={watch}
              item={data}
            />
            <Divider />
            <CardActions>
              <LoadingButton
                type="submit"
                size="medium"
                color="primary"
                disabled={!isValid}
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit((values) => onSubmit(values))}
              >
                {t("Speichern")}
              </LoadingButton>
            </CardActions>
          </Card>
        </Grid>
        <Guarded requiredRole={Role.Admin}>
          <Grid item xs={12} md={6}>
            <ItemImportInfo item={data} />
          </Grid>
        </Guarded>
      </Grid>
    </StyledContainer>
  );
};

export default EditItem;
