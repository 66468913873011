import {
  Blameable,
  Commentable,
  Company,
  CompanyPreview,
  Document,
  DocumentFormValues,
  ID,
  ManualType,
  Timestampable,
} from "./";

// Sync with LocationVoter in backend
export enum LocationGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  UPDATE_DOCUMENT = "update-document",
  UPDATE_COMPANIES = "update-companies",
}

export interface Location
  extends LocationPreview,
    Timestampable,
    Blameable,
    Commentable {
  parent: Location | null;
  locations: Location[];
  companies: Company[];
  document: Document | null;
  itemsCount: number;
  personCount: number;
  itemCheckStatusCriticalCount: number;
  itemCheckStatusPendingCount: number;
  itemCheckStatusPlanningCount: number;
}

export interface LocationFilters {
  search: string;
  company: string;
}

export interface LocationOption {
  id: ID;
  name: string;
}

export interface LocationPreview {
  id: ID;
  name: string;
  companies: CompanyPreview[];
  parent: LocationPreview | null;
}

export interface LocationFormValues {
  name: string;
  parent: ID | null;
  comment: string;
  companies: ID[];
  document: ID | null;
  showUpload: boolean;
  documentUpload: DocumentFormValues;
  company?: ID;
}

export const getLocationFormValues = (
  location?: Location,
  company?: Company
): LocationFormValues => ({
  name: location?.name || "",
  parent: location?.parent?.id || null,
  comment: location?.comment || "",
  company: company?.id,
  companies: company
    ? [company.id]
    : location?.companies
    ? location.companies.map((company) => company.id)
    : [],
  document: location?.document ? location?.document.id : null,
  showUpload: false,
  documentUpload: {
    name: "",
    type: ManualType.LocationPlan,
    comment: "",
    documentUpload: "",
  },
});
