import { useMemo } from "react";
import * as yup from "yup";
import { useDefectValidation } from "./use-defect-validation";

export const useDefectValidationSchema = () => {
  const validation = useDefectValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
