import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./i18n/de.json";
import en from "./i18n/en.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  lng: process.env.REACT_APP_DEFAULT_LOCALE || "en",

  interpolation: {
    escapeValue: false,
  },
  nsSeparator: ".",
});

export default i18n;
