import React, { FunctionComponent } from "react";
import { HeadCell, Qualification, Role } from "../../model";
import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { TableNoResult } from "../table-no-result";
import { QualificationFilters } from "../../views/qualifications/overview";
import { Link as RouterLink } from "react-router-dom";

const QualificationList: FunctionComponent<{
  qualifications: Qualification[];
  loading?: boolean;
  params?: FilterParams<QualificationFilters, Qualification>;
  createSortHandler?: SortHandler<Qualification>;
}> = ({ qualifications, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<Qualification>[] = [
    { id: "name", label: t("Name") },
    { id: "qualificationTags", label: t("Tag"), sortable: false },
    { id: "type", label: t("Typ") },
    { id: "conditions", label: t("Voraussetzungen"), sortable: false },
    {
      id: "refreshInterval",
      label: t("Auffrischungsintervall"),
      align: "center",
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {qualifications.map((qualification) => (
                <TableRow key={qualification.id} hover={true}>
                  <TableCell>{qualification.name}</TableCell>
                  <TableCell>
                    {qualification.qualificationTags &&
                      qualification.qualificationTags.map((tag) => (
                        <Link
                          component={RouterLink}
                          to={routes.qualificationTag(tag.id)}
                          display={"block"}
                          key={tag.id}
                        >
                          {tag.name}
                        </Link>
                      ))}
                  </TableCell>
                  <TableCell>
                    {t(`qualification.type.${qualification.type}`)}
                  </TableCell>
                  <TableCell>
                    {qualification.conditions.map((condition) => (
                      <Link
                        component={RouterLink}
                        to={routes.qualification(condition.id)}
                        display={"block"}
                        key={condition.id}
                      >
                        {condition.name}
                      </Link>
                    ))}
                  </TableCell>
                  <TableCell align="center">
                    {qualification.refreshInterval} {t("Jahr/e")}
                  </TableCell>
                  <CompactTableCell align="right">
                    <Guarded requiredRole={Role.QA}>
                      <IconLink
                        to={routes.qualification(qualification.id)}
                        tooltip={t("Ansehen")}
                        icon={<Visibility />}
                      />
                    </Guarded>
                    <Guarded requiredRole={Role.QA}>
                      <IconLink
                        to={routes.qualificationEdit(qualification.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!qualifications.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default QualificationList;
