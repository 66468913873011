import { useContext } from "react";
import AppContext from "../context/app";
import { CurrentUser } from "../model";
import { useQuery } from "react-query";
import { apiRoutes, request } from "../lib/api";

export const userQueryKey = "user";

/**
 * Refetches the user data on window focus. This hook should only be used once. To get the user data use useCurrentUser instead.
 */
export const useQueryUser = (user: CurrentUser) => {
  const { setUser } = useContext(AppContext);

  useQuery(
    userQueryKey,
    async () => {
      const { data } = await request<CurrentUser>(apiRoutes.profile.me);
      setUser(data);
      return data;
    },
    {
      initialData: user,
      refetchOnWindowFocus: true,
      staleTime: 5000,
    }
  );
};
