import React, { useRef, VoidFunctionComponent } from "react";
import { Check, Nullable } from "../../model";
import { Button, DialogActions, DialogContent, Paper } from "@mui/material";
import SignaturePad from "react-signature-pad-wrapper";
import { useTranslation } from "react-i18next";
import LoadingButton from "../loading-button";

const SignatureView: VoidFunctionComponent<{
  check: Check;
  onCancel: () => void;
  onContinue: (signature: string) => void;
  loading: boolean;
}> = ({ onCancel, onContinue, loading }) => {
  const { t } = useTranslation();
  const ref = useRef<Nullable<SignaturePad>>(null);

  return (
    <>
      <DialogContent>
        <Paper elevation={0} variant="outlined">
          <SignaturePad
            options={{
              minWidth: 2,
              maxWidth: 3,
            }}
            height={500}
            ref={ref}
          />
          <Button
            variant="text"
            onClick={() => {
              if (!ref.current) {
                return;
              }
              ref.current.clear();
            }}
          >
            {t("Zurücksetzen")}
          </Button>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          size="medium"
          color="secondary"
          disabled={loading}
          onClick={onCancel}
        >
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={() => {
            if (!ref.current) {
              return;
            }
            onContinue(ref.current.toDataURL());
          }}
        >
          {t("Prüfung abschließen")}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default SignatureView;
