import { BillingFormValues } from "model/billing";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBillingValidationSchema } from "../validations/billing/use-billing-validation-schema";

export const useBillingForm = (defaultValues: BillingFormValues) => {
  const validationSchema = useBillingValidationSchema();
  return useForm<BillingFormValues>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
};
