import React, { FunctionComponent, useState } from "react";
import { Defect, HeadCell, Role } from "../../model";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { TableNoResult } from "../table-no-result";
import { DefectFilters } from "../../views/defects/overview";
import EditDefectDialog from "../edit-defect-dialog";
import { IconButton } from "../icon-button";

const DefectList: FunctionComponent<{
  data: Defect[];
  loading?: boolean;
  params?: FilterParams<DefectFilters, Defect>;
  createSortHandler?: SortHandler<Defect>;
}> = ({ data, loading, params, createSortHandler }) => {
  const { t } = useTranslation();
  const [selectedDefect, setSelectedDefect] = useState<Defect | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const headCells: HeadCell<Defect>[] = [
    { id: "title", label: t("Titel") },
    { id: "company", label: t("Organisation") },
    { id: "location", label: t("Ort/Abteilung") },
  ];

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={
                    params && params.orderBy === headCell.id
                      ? params.order
                      : false
                  }
                >
                  {createSortHandler && headCell.sortable !== false ? (
                    <TableSortLabel
                      active={params && params.orderBy === headCell.id}
                      direction={
                        params && params.orderBy === headCell.id
                          ? params.order
                          : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          {!loading ? (
            <>
              <TableBody>
                {data.map((entry) => (
                  <TableRow key={entry.id} hover={true}>
                    <CompactTableCell>{entry.title}</CompactTableCell>
                    <CompactTableCell>{entry.company.name}</CompactTableCell>
                    <CompactTableCell>
                      {entry.location ? (
                        <>
                          {entry.location.name}
                          {entry.location.parent ? (
                            <>{entry.location.parent.name}</>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </CompactTableCell>
                    <CompactTableCell align={"right"}>
                      <Guarded requiredRole={Role.Admin}>
                        <IconButton
                          onClick={() => {
                            setSelectedDefect(entry);
                            setEditDialogOpen(true);
                          }}
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </Guarded>
                    </CompactTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {!data.length && <TableNoResult />}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={100}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {selectedDefect && (
        <EditDefectDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          data={selectedDefect}
        />
      )}
    </>
  );
};

export default DefectList;
