import React, { FunctionComponent } from "react";
import { CheckProcess, HeadCell, Role } from "../../model";
import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { TableNoResult } from "../table-no-result";
import { CheckProcessFilters } from "../../views/check-processes/overview";
import { Link as RouterLink } from "react-router-dom";
import CheckProcessStatus from "../check-process-status";

const CheckProcessList: FunctionComponent<{
  checkProcesses: CheckProcess[];
  loading?: boolean;
  params?: FilterParams<CheckProcessFilters, CheckProcess>;
  createSortHandler?: SortHandler<CheckProcess>;
}> = ({ checkProcesses, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<CheckProcess>[] = [
    { id: "name", label: t("Name") },
    { id: "description", label: t("Beschreibung") },
    { id: "categories", label: t("Kategorien") },
    { id: "products", label: t("Produkte") },
    { id: "status", label: t("Status") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {checkProcesses.map((checkProcess) => (
                <TableRow key={checkProcess.id} hover={true}>
                  <CompactTableCell>{checkProcess.name}</CompactTableCell>
                  <CompactTableCell>
                    {checkProcess.description}
                  </CompactTableCell>
                  <TableCell>
                    {checkProcess.categories.map((category) => (
                      <Link
                        component={RouterLink}
                        to={routes.category(category.id)}
                        display={"block"}
                        key={category.id}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </TableCell>
                  <TableCell>
                    {checkProcess.products.map((product) => (
                      <Link
                        component={RouterLink}
                        to={routes.product(product.id)}
                        display={"block"}
                        key={product.id}
                      >
                        {product.displayName}
                      </Link>
                    ))}
                  </TableCell>
                  <CompactTableCell align="center">
                    <CheckProcessStatus status={checkProcess.status} />
                  </CompactTableCell>
                  <CompactTableCell>
                    <IconLink
                      to={routes.checkProcess(checkProcess.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <Guarded requiredRole={Role.QA}>
                      <IconLink
                        to={routes.checkProcessEdit(checkProcess.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!checkProcesses.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CheckProcessList;
