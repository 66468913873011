import { Route, Switch, useRouteMatch } from "react-router-dom";
import ItemsOverview from "./overview";
import GuardedRoute from "../../components/guarded-route";
import { routes } from "../../lib/routes";
import { AppGrant, Role } from "../../model";
import EditItem from "./edit";
import ItemDetails from "./details";
import CreateItem from "./create";
import ImportItem from "./importer";

const Items = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ItemsOverview />
      </Route>
      <GuardedRoute
        path={routes.itemEdit(":itemId")}
        requiredRole={[Role.Admin, Role.Client, Role.Tester]}
      >
        <EditItem />
      </GuardedRoute>
      <GuardedRoute path={routes.itemCreate} grant={AppGrant.ITEM_CREATE}>
        <CreateItem />
      </GuardedRoute>
      <GuardedRoute path={routes.itemImport} grant={AppGrant.ITEM_IMPORT}>
        <ImportItem />
      </GuardedRoute>
      <Route path={routes.item(":itemId")}>
        <ItemDetails />
      </Route>
    </Switch>
  );
};

export default Items;
