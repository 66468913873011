import { Route, Switch, useRouteMatch } from "react-router-dom";
import GuardedRoute from "../../components/guarded-route";
import { routes } from "../../lib/routes";
import { Role } from "../../model";
import CreateQualificationTag from "./create";
import EditQualificationTag from "./edit";
import QualificationTagOverview from "./overview";

const QualificationTags = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <QualificationTagOverview />
      </Route>
      <GuardedRoute path={routes.qualificationTagCreate} requiredRole={Role.QA}>
        <CreateQualificationTag />
      </GuardedRoute>
      <GuardedRoute
        path={routes.qualificationTag(":qualificationTagId")}
        requiredRole={Role.QA}
      >
        <EditQualificationTag />
      </GuardedRoute>
    </Switch>
  );
};

export default QualificationTags;
