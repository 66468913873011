import React, { ReactChild, VoidFunctionComponent } from "react";
import { AxiosError } from "axios";
import { HttpStatus } from "../../model";
import NotFound from "../not-found";
import Error from "../error";
import { useTranslation } from "react-i18next";

const HttpError: VoidFunctionComponent<{
  error: AxiosError | Array<AxiosError | undefined | null>;
  actions?: ReactChild;
}> = ({ error, actions }) => {
  const { t } = useTranslation();

  const errors = (Array.isArray(error) ? error : [error]).filter(
    (item) => !!item
  ) as AxiosError[];
  if (!errors.length) {
    return null;
  }

  const firstError = errors[0];
  switch (firstError.response?.status) {
    case HttpStatus.NotFound:
      return <NotFound actions={actions} />;
  }
  return (
    <Error
      title={t("Fehler")}
      description={t("Leider ist etwas schief gelaufen.")}
      actions={actions}
    />
  );
};

export default HttpError;
