import { Grant, Grantable, Role } from "../model";
import { useCurrentUser } from "./use-current-user";
import {
  hasRole as hasUserRole,
  isGranted as isUserGranted,
} from "../lib/security";

export const useGuarded = () => {
  const user = useCurrentUser();

  return {
    isGranted: <TGrant extends Grant>(
      grant: TGrant,
      subject?: Grantable<TGrant>
    ) => isUserGranted(user, grant, subject),
    hasRole: (requiredRole: Role | Role[]) => hasUserRole(user, requiredRole),
  };
};
