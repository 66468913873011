import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useQualificationValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t("Name darf nicht leer sein.")),
      interval: yup.number().nullable(),
    }),
    [t]
  );
};
