import { useEffect, useState } from "react";
import { useQueryState } from "./use-query-state";

export type Order = "asc" | "desc";

export interface FilterParams<
  TFilters,
  TData,
  TContext = { [key: string]: any }
> extends Params<TData, TContext> {
  filters: Partial<TFilters>;
}

export interface Params<TData, TContext = { [key: string]: any }> {
  page: number;
  pageSize: number;
  orderBy: keyof TData;
  order: Order;
  context?: TContext;
}

export type SortHandler<TData> = (
  property: keyof TData,
  initSortDir?: "asc" | "desc"
) => () => void;

export const useFilters = <
  TFilters,
  TData extends { [key: string]: any },
  TContext = { [key: string]: any }
>(
  filterDeps: Partial<TFilters> = {},
  initialOrderBy: keyof TData,
  initialOrder: Order = "asc",
  initialPageSize = 10,
  context?: TContext
): {
  params: FilterParams<TFilters, TData, TContext>;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  createSortHandler: SortHandler<TData>;
} => {
  const [filters, setFilters] = useState<Partial<TFilters>>({ ...filterDeps });

  const [page, setPage] = useQueryState(0, "page");
  const [pageSize, setPageSize] = useQueryState(initialPageSize, "pageSize");

  const [orderBy, setOrderBy] = useQueryState<keyof TData>(
    initialOrderBy,
    "order"
  );
  const [order, setOrder] = useQueryState<Order>(initialOrder, "orderBy");

  useEffect(() => {
    setFilters({ ...filters, ...filterDeps });
    setPage(0);
  }, Object.values(filterDeps)); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const createSortHandler =
    (property: keyof TData, initSortDir?: "asc" | "desc") => () => {
      const isAsc =
        orderBy === property
          ? order === "asc"
          : (initSortDir || "asc") !== "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

  return {
    params: {
      filters,
      page,
      pageSize,
      order,
      orderBy,
      context,
    },
    handleChangePage,
    handleChangePageSize,
    createSortHandler,
  };
};
