import React, { VoidFunctionComponent } from "react";
import { Link } from "@mui/material";
import { Category } from "../../model";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../lib/routes";
import styled from "styled-components";

const StyledSpan = styled.span`
  display: block;
`;

const CategoryTree: VoidFunctionComponent<{ category: Category }> = ({
  category,
}) => (
  <StyledSpan>
    {category.parents.map((parent) => (
      <span key={parent.id}>
        <Link component={RouterLink} to={routes.category(parent.id)}>
          {parent.name}
        </Link>
        {" > "}
      </span>
    ))}
    <Link component={RouterLink} to={routes.category(category.id)}>
      {category.name}
    </Link>
  </StyledSpan>
);

export default CategoryTree;
