import React, { FunctionComponent } from "react";
import { DateFormat, HeadCell, ItemCheck } from "../../model";
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { CategoryFilters } from "../../views/categories/overview";
import ItemCheckResult from "../item-check-result";
import { TableNoResult } from "../table-no-result";
import { Link as RouterLink } from "react-router-dom";
import FormattedDate from "../formatted-date";
import { IconLink } from "../icon-link";
import { PictureAsPdf, Visibility } from "@mui/icons-material";
import { amber } from "@mui/material/colors";
import { isOnlyClient, isOnlyTester } from "lib/security";
import { useCurrentUser } from "hooks/use-current-user";
import GuardedLink from "../guarded-link";

const ItemCheckList: FunctionComponent<{
  itemChecks: ItemCheck[];
  loading?: boolean;
  params?: FilterParams<CategoryFilters, ItemCheck>;
  createSortHandler?: SortHandler<ItemCheck>;
}> = ({ itemChecks, loading, params, createSortHandler }) => {
  const user = useCurrentUser();
  const { t } = useTranslation();

  const headCells: HeadCell<ItemCheck>[] = [
    { id: "order", label: t("Auftrag") },
    { id: "checkDate", label: t("Prüfdatum") },
    { id: "checkResult", label: t("Prüfurteil") },
    { id: "hasDefect", label: t("Prüfinformation") },
    { id: "checkedBy", label: t("Überprüfer") },
    { id: "person", label: t("Verwender") },
    { id: "location", label: t("Ort") },
    { id: "comment", label: t("Kommentar") },
  ];

  const onlyClient = isOnlyClient(user);
  const onlyTester = isOnlyTester(user);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {itemChecks.map((check) => (
                <TableRow key={check.id} hover={true}>
                  <CompactTableCell>
                    {check.order ? (
                      <GuardedLink
                        disabled={onlyTester}
                        component={RouterLink}
                        to={routes.order(check.order.id)}
                      >
                        {check.order.orderNumber}
                      </GuardedLink>
                    ) : (
                      check.orderNumber
                    )}
                  </CompactTableCell>
                  <TableCell>
                    <FormattedDate
                      date={check.checkDate}
                      format={DateFormat.Default}
                    />
                  </TableCell>
                  <CompactTableCell>
                    <ItemCheckResult result={check.checkResult} />
                  </CompactTableCell>
                  <CompactTableCell>
                    {check.hasDefect && (
                      <Chip
                        label={t("Mangelhaft")}
                        style={{ backgroundColor: amber[500], color: "black" }}
                        size={`small`}
                      />
                    )}
                  </CompactTableCell>
                  <TableCell>
                    {check.checkedBy && (
                      <GuardedLink
                        disabled={onlyClient || onlyTester}
                        to={routes.person(check.checkedBy.id)}
                      >
                        {check.checkedBy.firstName} {check.checkedBy.lastName}
                      </GuardedLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {check.person ? (
                      <GuardedLink
                        disabled={onlyTester}
                        component={RouterLink}
                        to={routes.person(check.person.id)}
                      >
                        {check.person.firstName} {check.person.lastName}
                      </GuardedLink>
                    ) : (
                      check.personName
                    )}
                  </TableCell>
                  <TableCell>
                    {check.location && (
                      <GuardedLink
                        disabled={onlyTester}
                        component={RouterLink}
                        to={`${routes.items}?location=${check.location.id}`}
                      >
                        {check.location.name}
                        {check.location.parent
                          ? ` (${check.location.parent.name})`
                          : ""}
                      </GuardedLink>
                    )}
                  </TableCell>
                  <CompactTableCell>{check.comment}</CompactTableCell>
                  <CompactTableCell align="right">
                    {check.checkReport && (
                      <>
                        <a
                          href={check.checkReport.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton size="large">
                            <PictureAsPdf />
                          </IconButton>
                        </a>
                      </>
                    )}
                    <IconLink
                      to={routes.itemCheck(check.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!itemChecks.length && (
              <TableNoResult text={t("Es wurden keine Prüfungen gefunden.")} />
            )}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ItemCheckList;
