import {
  BlameableUser,
  Company,
  getPersonFormValues,
  ID,
  Person,
  PersonFormValues,
  Timestampable,
} from "./";

// Sync with UserVoter in backend
export enum UserGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  SEND_SUPPORT_EMAIL = "sendSupportEmail",
}

export enum Role {
  User = "ROLE_USER",
  Client = "ROLE_CLIENT",
  Tester = "ROLE_TESTER",
  QA = "ROLE_QA",
  CEO = "ROLE_CEO",
  Admin = "ROLE_ADMIN",
  SuperAdmin = "ROLE_SUPER_ADMIN",
  AllowedToSwitch = "ROLE_ALLOWED_TO_SWITCH",
}

// Sync with AppVoter in backend
export enum AppGrant {
  USERS = "users",
  LOGS = "logs",
  COMPANIES = "companies",
  COMPANY_CREATE = "company-create",
  COMPANY_CREATE_DESIGN = "company-create-design",
  COMPANY_CREATE_FULL = "company-create-full",
  COMPANY_COUNT = "company-count",
  ORDERS = "orders",
  ORDER_CREATE = "order-create",
  ORDER_MANAGE_DELIVERY_DATES = "order-manage-delivery-dates",
  CATEGORIES = "categories",
  PRODUCTS = "products",
  PRODUCT_IMPORT = "product-import",
  DOCUMENTS = "documents",
  STANDARDS = "standards",
  PERSONS = "persons",
  PERSON_CREATE = "person-create",
  ITEMS = "items",
  ITEM_CREATE = "item-create",
  ITEM_IMPORT = "item-import",
  LOCATIONS = "locations",
  LOCATION_COMPANY_FILTER = "location-company-filter",
  CHECK_PROCESS = "check-process",
  CHECK_GROUP = "check-group",
  CHECK_STEPS = "check-steps",
  CHECKS = "checks",
  CHECK_REPORTS = "check-reports",
  REPORT_DEBUG = "report-debug",
  QUALIFICATIONS = "qualifications",
  QUALIFICATION_TAGS = "qualification-tags",
  PERSON_QUALIFICATION = "person-qualification",
  DEFECT = "defect",
  ACTION = "action",
  SEARCH = "search",
  VERIFY_DEFECT = "verify-defect",
  QUEUE_TASKS = "queue-tasks",
}

export interface User extends Timestampable, BlameableUser {
  id: ID;
  username: string;
  fullName: string;
  email: string;
  avatar: string | null;
  roles: Role[];
  inheritedRoles: Role[];
  enabled: boolean;
  person?: Person;
  selectedCompany?: Company | null;
}

export interface CurrentUser extends User {
  appGrants: AppGrant[];
  impersonator?: User;
}

export interface UserFormValues {
  username: string;
  fullName: string;
  email: string;
  roles: Role[];
  plainPassword: string;
  passwordRepeat: string;
  enabled: boolean;
  person: PersonFormValues;
}

export const getUserFormValues = (data?: User): UserFormValues => ({
  username: data?.username || "",
  fullName: data?.fullName || "",
  email: data?.email || "",
  roles: data?.roles || [Role.User],
  plainPassword: "",
  passwordRepeat: "",
  enabled: data ? data.enabled : true,
  person: getPersonFormValues(data?.person, data),
});
