import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Company,
  CompanyOption,
  DateFormat,
  Location,
  Person,
  PersonFormValues,
  User,
} from "../../model";
import { StyledCardTitle } from "../globals";
import {
  createLocationOptions,
  locationsFilter,
} from "../../views/persons/edit";
import { Option } from "../order-processing/helper";
import { apiRoutes, request } from "../../lib/api";
import LocationCreateDialog from "../location-create-dialog";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { useMutation } from "react-query";
import { isOnlyClient } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useApi } from "../../hooks/use-api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const PersonFormFields: VoidFunctionComponent<{
  control: Control<PersonFormValues>;
  setValue: UseFormSetValue<PersonFormValues>;
  getValues: UseFormGetValues<PersonFormValues>;
  watch: UseFormWatch<PersonFormValues>;
  person?: Person;
  company?: Company;
  reduced?: boolean;
}> = ({
  control,
  setValue,
  getValues,
  watch,
  person,
  company,
  reduced = false,
}) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState<Option[]>([]);
  const [createLocationOpen, setCreateLocationOpen] = useState(false);
  const [currentLocationName, setCurrentLocationName] = useState<string>("");
  const user = useCurrentUser();
  const primaryCompany = watch("primaryCompany");
  const onlyClient = isOnlyClient(user);

  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<{}, Company>(
    apiRoutes.companies,
    "get",
    { companyOption: true },
    { enabled: !onlyClient }
  );

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
  } = useApi<User[]>(apiRoutes.users, "get", undefined, undefined, undefined, {
    enabled: !onlyClient && !reduced,
  });

  const { mutateAsync: locationMutate, isLoading: locationLoading } =
    useMutation(
      async () => {
        if (!primaryCompany || reduced) {
          return;
        }
        return await request<Location[]>(
          apiRoutes.companyLocations(+primaryCompany),
          "get"
        );
      },
      {
        onSuccess: (res) => {
          if (!res) {
            return;
          }
          setLocations(createLocationOptions(res.data));
          const locationValues = getValues("locations");
          const filteredLocations = locationValues.filter((location) =>
            res.data.find((l) => l.id === location)
          );
          setValue("locations", filteredLocations);
        },
      }
    );

  useEffect(() => {
    if (!primaryCompany) {
      setValue("locations", []);
      return setLocations([]);
    }
    locationMutate();
  }, [primaryCompany, locationMutate, setValue]);

  if (companiesError || usersError) {
    return (
      <HttpError
        error={[companiesError, usersError]}
        actions={
          <Button component={Link} to={routes.persons}>
            {t("Zurück zu Personen")}
          </Button>
        }
      />
    );
  }

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Kontaktdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"firstName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Vorname")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"lastName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Nachname")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          {!reduced && (
            <>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"titleBefore"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Voranstehender Titel")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"titleAfter"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Nachstehender Titel")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"email"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("E-Mail Adresse")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"phone"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Telefonnummer")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"mobilePhone"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Mobiltelefon")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"externalCode"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Zuordnung")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {!onlyClient && (
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name={"birthdate"}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        value={field.value ? new Date(field.value) : null}
                        format={DateFormat.Default}
                        onChange={field.onChange}
                        label={t("Geburtsdatum")}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            error: fieldState.isTouched && fieldState.invalid,
                            helperText: fieldState.error?.message,
                            fullWidth: true,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {!onlyClient && (
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name={"user"}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="user"
                        options={users || []}
                        loading={usersLoading}
                        loadingText={t("Lädt Benutzer...")}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) =>
                          option.fullName || option.username
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        value={
                          users?.find((user) => user.id === field.value) || null
                        }
                        onChange={(event, value) => field.onChange(value?.id)}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={t("Benutzer (System)")}
                            error={fieldState.isTouched && fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Kommentar")}
                      fullWidth
                      multiline
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {!onlyClient && (
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="archived"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value || false}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t("Archiviert")}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Zuordnung zu Organisationen")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          {!onlyClient && (
            <>
              {!reduced && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"companies"}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="companies"
                        multiple={true}
                        loading={companiesLoading}
                        loadingText={t("Lädt Organisationen...")}
                        options={companies}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        defaultValue={person?.companies}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, value) =>
                          field.onChange(
                            value.map((company: CompanyOption) => company.id)
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={t("Organistationen")}
                            error={fieldState.isTouched && fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"primaryCompany"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="primaryCompany"
                      loading={companiesLoading}
                      loadingText={t("Lädt Organisationen...")}
                      multiple={false}
                      options={companies}
                      disabled={!!company}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => field.onChange(value?.id)}
                      value={
                        companies.find(
                          (company) => company.id === field.value
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Hauptorganisation")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {!reduced && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"locations"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="locations"
                    noOptionsText={t(
                      "Es wurden keine Orte/Abteilungen zur Hauptorganisation gefunden"
                    )}
                    loading={locationLoading}
                    loadingText={t(
                      "Lädt Orte/Abteilungen für Hauptorganisation..."
                    )}
                    multiple={true}
                    options={locations}
                    getOptionKey={(option) => option.value || ""}
                    getOptionLabel={(option) => option.label}
                    value={locations.filter((location) => {
                      return location.value
                        ? field.value.includes(+location.value)
                        : [];
                    })}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onBlur={() => !field.value?.length && field.onChange([])}
                    onChange={(_, values) => {
                      const createValue = values.find((v) =>
                        v.value ? v.value.substr(0, 3) === "new" : false
                      );
                      if (createValue) {
                        setCurrentLocationName(
                          createValue.value ? createValue.value.substr(3) : ""
                        );
                        return setCreateLocationOpen(true);
                      }
                      const selectMultiple = values.find(
                        (option) =>
                          option.label.startsWith("Alle") &&
                          option.label.endsWith("freischalten")
                      );
                      if (selectMultiple) {
                        const multipleOptions = selectMultiple.value
                          ? locations.filter((location) =>
                              location.label
                                .toLowerCase()
                                .includes(selectMultiple.value!.toLowerCase())
                            )
                          : locations;

                        const mergedValues = [...values, ...multipleOptions];
                        values = mergedValues.filter(
                          (option, i) =>
                            mergedValues.indexOf(option) === i &&
                            !(
                              option.label.startsWith("Alle") &&
                              option.label.endsWith("freischalten")
                            )
                        );
                      }
                      field.onChange(
                        values.map((option) =>
                          typeof option.value == "string"
                            ? +option.value
                            : option.value
                        )
                      );
                    }}
                    filterOptions={(options, params) => {
                      const filtered = locationsFilter(options, params);
                      if (filtered.length > 1) {
                        filtered.unshift({
                          value: params.inputValue,
                          label: t(
                            `Alle ${
                              params.inputValue
                                ? `"${params.inputValue}"`
                                : "Orte/Abteilungen"
                            } freischalten`
                          ),
                        });
                      }
                      if (params.inputValue !== "") {
                        filtered.push({
                          value: `new${params.inputValue}`,
                          label: t(`Ort "{{name}}" hinzufügen`, {
                            name: params.inputValue,
                          }),
                        });
                      }
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Freigeschaltene Orte/Abteilungen")}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        required={
                          onlyClient &&
                          (user.person?.locations || []).length > 0
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      {!reduced && primaryCompany && (
        <LocationCreateDialog
          open={createLocationOpen}
          onClose={() => setCreateLocationOpen(false)}
          company={companies.find((c) => c.id === primaryCompany)}
          onLocationUpdate={(data) => {
            locationMutate();
            const locations = getValues("locations");
            setValue("locations", [...locations, data.id]);
          }}
          name={currentLocationName}
        />
      )}
    </>
  );
};
