import React, { FunctionComponent } from "react";
import { Category, HeadCell, Role } from "../../model";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { CategoryFilters } from "../../views/categories/overview";
import { TableNoResult } from "../table-no-result";

const CategoryList: FunctionComponent<{
  categories: Category[];
  loading?: boolean;
  params?: FilterParams<CategoryFilters, Category>;
  createSortHandler?: SortHandler<Category>;
}> = ({ categories, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<Category>[] = [
    { id: "name", label: t("Name") },
    { id: "parents", label: t("Übergeordnete Kategorie") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {categories.map((category) => (
                <TableRow key={category.id} hover={true}>
                  <CompactTableCell>{category.name}</CompactTableCell>
                  <CompactTableCell>
                    {category.parents.length > 0 &&
                      category.parents[category.parents.length - 1].name}
                  </CompactTableCell>
                  <CompactTableCell align={"right"}>
                    <IconLink
                      to={routes.category(category.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <Guarded requiredRole={Role.Admin}>
                      <IconLink
                        to={routes.categoryEdit(category.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!categories.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CategoryList;
