import { useQuery } from "react-query";
import { Check, Nullable } from "../../model";
import { apiRoutes, request } from "../../lib/api";

export const CheckQueryKey = "checkQueryKey";

export const useCheckQuery = () => {
  return useQuery<Nullable<Check> | null>(
    CheckQueryKey,
    async () => {
      const { data } = await request<Nullable<Check>>(apiRoutes.checks);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
