import { useTitle } from "../../hooks/use-title";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getQualificationFormValues,
  Qualification,
  QualificationFormValues,
  QualificationTag,
  QualificationType,
  Role,
} from "../../model";
import { apiRoutes, request, useQualificationDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleHookFormErrors } from "../../helpers";
import LoadingButton from "../../components/loading-button";
import Guarded from "../../components/guarded";
import { Delete } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import { useQualificationValidationSchema } from "../../hooks/validations/qualifications/use-qualification-validation-schema";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { useApi } from "../../hooks/use-api";

const EditQualification = () => {
  const { t } = useTranslation();
  const { qualificationId } = useParams<{
    qualificationId: string | undefined;
  }>();
  const { isLoading, error, data } = useQualificationDetailsApi(
    qualificationId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.name || t("Qualifikation bearbeiten"));

  const validationSchema = useQualificationValidationSchema();

  const {
    data: qualifications,
    isLoading: qualificationsLoading,
    error: qualificationsError,
  } = useApi<Qualification[]>(
    apiRoutes.qualificationConditions(qualificationId as unknown as number)
  );
  const {
    data: tags,
    isLoading: tagsLoading,
    error: tagsError,
  } = useUnlimitedPaginationApi<{}, QualificationTag>(
    apiRoutes.qualificationTags
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<QualificationFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getQualificationFormValues(data),
  });

  const onSubmit = async (
    values: QualificationFormValues,
    setError: UseFormSetError<QualificationFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<Qualification>(
      apiRoutes.qualification(data.id),
      "put",
      values
    )
      .then(() => {
        enqueueSnackbar(t("Qualifikation wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.qualification(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getQualificationFormValues(data)), [reset, data]);

  if (error || qualificationsError || tagsError) {
    return (
      <HttpError
        error={[error, qualificationsError, tagsError]}
        actions={
          <Button component={Link} to={routes.qualifications}>
            {t("Zurück zu Qualifikationen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data || qualificationsLoading || tagsLoading) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikationen"), link: routes.qualifications },
    { label: data.name, link: routes.qualification(data.id) },
    { label: t("Bearbeiten") },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Qualifikation {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.qualification(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Qualifikation wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.qualifications);
      })
      .catch(() =>
        enqueueSnackbar(t("Qualifikation konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={t("Qualifikation")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>

            <ModifiedEntity entity={data} />
          </>
        }
      />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Qualifikation")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"name"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Titel")}
                          fullWidth
                          {...field}
                          required
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"refreshInterval"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Auffrischungsintervall")}
                          fullWidth
                          {...field}
                          type="number"
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={
                            fieldState.error?.message || t("in Jahren")
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"type"}
                  render={({ field }) => (
                    <>
                      <FormLabel component="legend">{t("Typ")}</FormLabel>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.ManufacturingTraining}
                          label={t(
                            `qualification.type.${QualificationType.ManufacturingTraining}`
                          )}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.EmployeeTraining}
                          label={t(
                            `qualification.type.${QualificationType.EmployeeTraining}`
                          )}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.Instructions}
                          label={t(
                            `qualification.type.${QualificationType.Instructions}`
                          )}
                        />
                      </RadioGroup>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"conditions"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="conditions"
                      multiple={true}
                      options={qualifications || []}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      defaultValue={data.conditions}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Voraussetzungen")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"qualificationTags"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="qualificationTags"
                      multiple={true}
                      options={tags}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      defaultValue={data.qualificationTags}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Tags")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Notiz")}
                      fullWidth
                      multiline
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditQualification;
