import React, {
  useEffect,
  useMemo,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  CheckGroupStep,
  CheckProcess as CheckProcessModel,
  CheckProcessGroup,
  CheckStepCondition,
  CheckStepType,
  FilePreview,
  Item,
} from "../../model";
import CheckGroup from "./check-group";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import LoadingButton from "../loading-button";
import ItemDocumentationCard from "./item-documentation-card";
import { Control, UseFormGetValues } from "react-hook-form";
import { FormValues } from "./index";
import DefectVerifiedLabel from "../defect-verified-label";
import { StyledColoredCardContent, StyledHeadline } from "../globals";
import { amber } from "@mui/material/colors";

const CheckProcess: VoidFunctionComponent<{
  checkProcess: CheckProcessModel;
  onCancel: () => void;
  onSubmit: (
    value: CheckProcessGroup[],
    attachments: Array<FilePreview[]>
  ) => void;
  loading: boolean;
  control: Control<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  item: Item;
  openDefectReview: () => void;
}> = ({
  checkProcess,
  onCancel,
  onSubmit,
  loading,
  getValues,
  control,
  item,
  openDefectReview,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<CheckProcessGroup[]>([]);
  const [attachments, setAttachments] = useState<Array<FilePreview[]>>([]);

  const valid = useMemo(() => {
    let validity = false;
    value.forEach((group) => {
      group.checkGroup.checkSteps.forEach((step) => {
        const checkStep = step.checkStep;
        switch (checkStep.type) {
          case CheckStepType.Bool:
            if (checkStep.value !== null) {
              validity = true;
            }
            break;
          case CheckStepType.Text:
            if (checkStep.value !== null && checkStep.value !== "") {
              validity = true;
            }
            break;
        }
      });
    });
    return validity;
  }, [value]);

  useEffect(() => {
    setValue(
      checkProcess.checkGroups.map((group) => {
        group.checkGroup.checkSteps.map((s) => (s.checkStep.value = null));
        return group;
      })
    );
  }, [checkProcess, setValue]);

  const onUpdate = (
    checkProcessGroup: CheckProcessGroup,
    checkGroupStep: CheckGroupStep,
    newValue: unknown,
    condition?: CheckStepCondition
  ) => {
    setValue(
      [...value].map((processGroup) => {
        if (processGroup.id === checkProcessGroup.id) {
          const step = processGroup.checkGroup.checkSteps.find(
            (s) => s.id === checkGroupStep.id
          );
          if (!step) {
            return processGroup;
          }
          if (!condition) {
            step.checkStep.value = newValue;
            return processGroup;
          }
          const stepCondition = step.checkStep.conditions?.find(
            (c) => c.id === condition.id
          );
          if (!stepCondition) {
            return processGroup;
          }
          stepCondition.checkStep.value = newValue;
          return processGroup;
        }
        return processGroup;
      })
    );
  };

  const defectReview = getValues("defectReview");
  const defectReviewOpen = defectReview?.filter(
    (review) => review.verified === null
  );
  const defectReviewVerified = defectReview?.filter(
    (review) => review.verified === true
  );
  const defectReviewNotVerified = defectReview?.filter(
    (review) => review.verified === false
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="overline">{checkProcess.name}</Typography>
        {checkProcess.checkGroups.map((group, index) => (
          <Accordion
            style={{ width: "100%" }}
            square={true}
            key={index}
            defaultExpanded={true}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>
                {index + 1}. {group.checkGroup.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CheckGroup checkProcessGroup={group} onUpdate={onUpdate} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid item xs={12}>
        <ItemDocumentationCard
          control={control}
          item={item}
          getValues={getValues}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </Grid>
      {defectReview.length > 0 && (
        <Grid item xs={12}>
          <Card>
            <StyledColoredCardContent
              sx={{
                backgroundColor: amber[100],
              }}
            >
              <StyledHeadline>{t("Offene Mängel")}</StyledHeadline>
              <Box
                display="flex"
                style={{
                  gap: "0.5em",
                  alignContent: "center",
                  marginBottom: "0.5em",
                }}
              >
                <DefectVerifiedLabel verified={null} iconOnly={true} />{" "}
                {defectReviewOpen?.length}
                <DefectVerifiedLabel verified={true} iconOnly={true} />{" "}
                {defectReviewVerified?.length}
                <DefectVerifiedLabel verified={false} iconOnly={true} />{" "}
                {defectReviewNotVerified?.length}
              </Box>
              <Button
                onClick={openDefectReview}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Mängel verifizieren
              </Button>
            </StyledColoredCardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <LoadingButton color="inherit" onClick={onCancel} loading={loading}>
              {t("Verwerfen")}
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={() => {
                onSubmit(value, attachments);
              }}
              disabled={
                !valid || (defectReviewOpen && defectReviewOpen.length > 0)
              }
              fullWidth={true}
              loading={loading}
            >
              {t("Nächstes Item prüfen")}
            </LoadingButton>
            {!valid && (
              <FormHelperText>
                Es muss mindestens ein Prüfschritt ausgefüllt werden
              </FormHelperText>
            )}
            {defectReviewOpen && defectReviewOpen.length > 0 && (
              <FormHelperText>
                {t("Es gibt noch nicht verifizierte Mängel!")}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckProcess;
