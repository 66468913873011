import React, { FunctionComponent, MutableRefObject, useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import {
  Category,
  Company,
  CompanyFilters,
  CompanyType,
  Document,
  Product,
  ProductFormValues,
} from "../../model";
import { useTranslation } from "react-i18next";
import { useUnlimitedPaginationApi } from "hooks/use-unlimited-pagination-api";
import { apiRoutes } from "lib/api";
import { useSnackbar } from "notistack";
import HttpError from "components/http-error";
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { routes } from "lib/routes";
import { Link } from "react-router-dom";
import { StyledCardTitle, StyledHighlightedGrid } from "components/globals";
import { Add, Clear, Description } from "@mui/icons-material";
import DocumentUploadsArray from "components/document-upload/array";

const ProductFormFields: FunctionComponent<{
  control: Control<ProductFormValues>;
  watch: UseFormWatch<ProductFormValues>;
  product?: Product;
  imageRef?: MutableRefObject<HTMLInputElement | null>;
}> = ({ control, watch, product, imageRef }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<CompanyFilters, Company>(apiRoutes.companies, {
    types: [CompanyType.Manufacturer],
  });
  const {
    data: allDocuments,
    isLoading: documentsLoading,
    error: documentsError,
  } = useUnlimitedPaginationApi<[], Document>(apiRoutes.documents);

  useEffect(() => {
    companiesError?.response?.data.message &&
      enqueueSnackbar(companiesError?.response?.data.message, {
        variant: "error",
      });
    categoriesError?.response?.data.message &&
      enqueueSnackbar(categoriesError?.response?.data.message, {
        variant: "error",
      });
  }, [companiesError, categoriesError, enqueueSnackbar]);

  if (documentsError) {
    return (
      <HttpError
        error={documentsError}
        actions={
          <Button component={Link} to={routes.products()}>
            {t("Zurück zu Produkten")}
          </Button>
        }
      />
    );
  }

  const showUpload = watch("showUpload", true);

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Produktdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"model"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Modell")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"manufacturer"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="manufacturer"
                  options={companies || []}
                  loading={companiesLoading}
                  loadingText={t("Lädt Hersteller...")}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={
                    companies.find(
                      (manufacturer) => manufacturer.id === field.value
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Hersteller")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"category"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="category"
                  loading={categoriesLoading}
                  loadingText={t("Lädt Kategorien...")}
                  options={categories || []}
                  noOptionsText={t(
                    "Die Kategorie ist leider nicht vorhanden. Bitte wenden Sie sich an den Support."
                  )}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={
                    categories.find(
                      (category) => category.id === field.value
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Kategorie")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"manufacturingYear"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Herstellungsjahr")}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Details")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"articleNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Artikelnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"reviewInterval"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Prüfintervall")}
                  fullWidth
                  type="number"
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message || t("in Monaten")}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"lifetime"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Lebensdauer")}
                  fullWidth
                  type="number"
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message || t("in Monaten")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"shortDescription"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Kurzbeschreibung")}
                  fullWidth
                  multiline
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"description"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Beschreibung")}
                  fullWidth
                  multiline
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Zugeordnete Dokumente")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"documents"}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="documents"
                        multiple={true}
                        options={allDocuments}
                        defaultValue={product?.documents}
                        loading={documentsLoading}
                        loadingText={t("Lädt Dokumente...")}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) =>
                          option.name +
                          (option.type
                            ? " (" + t(`document.type.${option.type}`) + ")"
                            : "")
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, value) =>
                          field.onChange(value.map((standard) => standard.id))
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={t("Dokumente")}
                            error={fieldState.isTouched && fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"showUpload"}
                    render={({ field }) => (
                      <>
                        <Button
                          type="button"
                          onClick={() => {
                            field.onChange(!showUpload);
                          }}
                        >
                          {showUpload ? (
                            <>
                              {" "}
                              <Clear /> {t("Abbrechen")}
                            </>
                          ) : (
                            <>
                              <Add /> {t("Neue Dokumente hinzufügen")}
                            </>
                          )}
                        </Button>
                        <Checkbox
                          aria-hidden={true}
                          style={{ display: "none" }}
                          checked={showUpload}
                          onChange={field.onChange}
                          color="primary"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Divider />
                {showUpload && (
                  <StyledHighlightedGrid item xs={12}>
                    <DocumentUploadsArray control={control} />
                  </StyledHighlightedGrid>
                )}
              </Grid>
            </CardContent>
          </Grid>
          <Grid item xs={4}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Produktbild")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {product && (
                    <CardContent>
                      {product.image ? (
                        <img
                          alt={product.model}
                          src={product.image}
                          style={{
                            width: "40%",
                            height: "auto",
                            padding: "5px",
                          }}
                        />
                      ) : (
                        t("Kein Produktbild zugeordnet")
                      )}
                    </CardContent>
                  )}
                  <CardContent>
                    <Controller
                      control={control}
                      name={"imageUpload"}
                      render={({ field, fieldState }) => (
                        <>
                          <input
                            {...field}
                            value=""
                            accept="image/*"
                            id={"imageUpload"}
                            hidden
                            type="file"
                            ref={imageRef}
                            onChange={(e) => {
                              field.onChange(e.currentTarget.files![0]);
                            }}
                          />
                          <label htmlFor={"imageUpload"}>
                            <Button
                              variant="contained"
                              component="span"
                              color="primary"
                            >
                              <Description /> {t("Datei auswählen")}
                            </Button>
                          </label>
                          <Box m={1} display="inline">
                            <Typography display={"block"} variant="caption">
                              {field.value?.name}
                            </Typography>
                          </Box>
                          {fieldState.error && (
                            <FormHelperText error={true}>
                              {fieldState.error?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </CardContent>
                  {product && (
                    <Grid item xs={12}>
                      {product.image && (
                        <Controller
                          control={control}
                          name={"deleteImage"}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={field.onChange}
                                  name="deleteImage"
                                  color="primary"
                                />
                              }
                              label={t("Produktbild Löschen")}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default ProductFormFields;
