import React, { FunctionComponent, useState } from "react";
import { DateFormat, HeadCell } from "../../model";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { ErrorOutline, GetApp } from "@mui/icons-material";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { TableNoResult } from "../table-no-result";
import {
  ItemQueueTask,
  QueueTaskFilter,
  QueueTaskGrant,
  QueueTaskStatus,
} from "../../model/queue-task";
import { apiRoutes, getAbsoluteApiUrl } from "../../lib/api";
import QueueTaskStatusChip from "../queue-task-status-chip";
import { routes } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import FormattedDate from "../formatted-date";
import QueueTaskProgressIndicator from "../queue-task-progress-indicator";
import { useQueueTaskStatusQuery } from "../../hooks/queries/use-queue-task-status-query";
import PaperDialog from "../paper-dialog";
import { IconButton } from "../icon-button";

const QueueTaskList: FunctionComponent<{
  data: ItemQueueTask[];
  loading?: boolean;
  params?: FilterParams<QueueTaskFilter, ItemQueueTask>;
  createSortHandler?: SortHandler<ItemQueueTask>;
}> = ({ data, loading, params, createSortHandler }) => {
  const { t } = useTranslation();
  const { data: queueStatus } = useQueueTaskStatusQuery();
  const [errors, setErrors] = useState<string[]>([]);

  const headCells: HeadCell<ItemQueueTask>[] = [
    { id: "start", label: t("Start"), sortable: false },
    { id: "finish", label: t("Ende"), sortable: false },
    { id: "workload", label: t("Filter"), sortable: false },
    { id: "status", label: t("Status"), sortable: false, align: "center" },
    { id: "result", label: t("Ergebnis"), sortable: false, align: "right" },
  ];

  const getLink = (queueTask: ItemQueueTask): string => {
    const link = new URLSearchParams();
    if (queueTask.workload.parameters.context.orderFilter) {
      link.append("orderId", queueTask.workload.parameters.context.orderFilter);
    }
    Object.entries(queueTask.workload.parameters.filters).forEach(
      ([key, value]) => {
        link.append(key, value);
      }
    );
    return link.toString()
      ? `${routes.items}?${link.toString()}`
      : routes.items;
  };

  const getCurrentProgress = (queueTask: ItemQueueTask): number => {
    const currentStatus = queueStatus?.data?.find(
      (statusTask) => statusTask.id === queueTask.id
    );
    if (!currentStatus) {
      return -1;
    }
    return currentStatus.progress;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {data.map((queueTask) => (
                <TableRow key={queueTask.id} hover={true}>
                  <CompactTableCell>
                    <FormattedDate
                      date={queueTask.start || queueTask.createdAt}
                      format={DateFormat.DefaultDateTime}
                    />{" "}
                  </CompactTableCell>
                  <CompactTableCell>
                    {queueTask.finish && (
                      <FormattedDate
                        date={queueTask.finish}
                        format={DateFormat.DefaultDateTime}
                      />
                    )}
                  </CompactTableCell>
                  <CompactTableCell>
                    <Link
                      to={getLink(queueTask)}
                      component={RouterLink}
                      target="blank"
                    >
                      {t("Exportierte Items anzeigen")}
                    </Link>
                  </CompactTableCell>
                  <CompactTableCell align="center">
                    {queueTask.status === QueueTaskStatus.DONE &&
                    !queueTask.result ? (
                      <Tooltip
                        title={t<string>(
                          "Exports stehen nur 72h lang als Download zur Verfügung."
                        )}
                      >
                        <Chip label={t("Abgelaufen")} size="small" />
                      </Tooltip>
                    ) : (
                      <QueueTaskStatusChip status={queueTask.status} />
                    )}
                  </CompactTableCell>
                  <CompactTableCell align="right">
                    {(queueTask.error?.messages || []).length > 0 && (
                      <Tooltip title={t<string>("Fehler Details anzeigen")}>
                        <Box>
                          <IconButton
                            onClick={() =>
                              setErrors(
                                (queueTask.error?.messages || []).map(
                                  (error) => error.message
                                )
                              )
                            }
                            size="small"
                          >
                            <ErrorOutline />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    )}
                    {(queueTask.error?.messages || []).length <= 0 &&
                      (queueTask.error?.exceptions || []).length > 0 && (
                        <Tooltip
                          title={t<string>(
                            "Bitte erneut versuchen oder an Ihren Ansprechpartner wenden."
                          )}
                        >
                          <Box>
                            <IconButton disabled={true} size="small">
                              <ErrorOutline />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      )}
                    {queueTask.status === QueueTaskStatus.DONE &&
                      queueTask.result?.filename && (
                        <Guarded
                          grant={QueueTaskGrant.DOWNLOAD}
                          subject={queueTask}
                        >
                          <Tooltip title={t<string>("Download")}>
                            <a
                              href={getAbsoluteApiUrl(
                                apiRoutes.queueTasksDownload(queueTask.id)
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton
                                aria-label={t("Download")}
                                size="small"
                              >
                                <GetApp />
                              </IconButton>
                            </a>
                          </Tooltip>
                        </Guarded>
                      )}
                    {getCurrentProgress(queueTask) >= 0 && (
                      <QueueTaskProgressIndicator
                        progress={getCurrentProgress(queueTask)}
                      />
                    )}
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!data.length && <TableNoResult />}
            {errors.length > 0 && (
              <PaperDialog
                open={errors.length > 0}
                onClose={() => setErrors([])}
              >
                <DialogTitle>
                  {errors.length} {t("Fehler")}
                </DialogTitle>
                <DialogContent>
                  <List dense={true}>
                    {errors.map((error, index) => (
                      <ListItem key={error}>
                        <ListItemIcon>
                          <ErrorOutline />
                        </ListItemIcon>
                        {index + 1}. {error}
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setErrors([])} color="primary">
                    {t("Schließen")}
                  </Button>
                </DialogActions>
              </PaperDialog>
            )}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default QueueTaskList;
