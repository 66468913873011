import { Card, TablePagination } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import { StyledContainer } from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import { ItemQueueTask, QueueTaskFilter } from "../../model/queue-task";
import QueueTaskList from "../../components/queue-task-list";

export const QueueTasksQueryKey = "queueTasks";

const QueueTaskOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Exports"));
  const { enqueueSnackbar } = useSnackbar();

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search] = useQueryState("", "search", queryStateOptions);

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Exports"), link: search ? routes.exports : undefined },
  ];

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<QueueTaskFilter, ItemQueueTask>(
      { search },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    QueueTaskFilter,
    ItemQueueTask
  >(apiRoutes.queueTasks, params, undefined, [QueueTasksQueryKey]);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  if (search !== "") {
    breadcrumbs.push({ label: search, link: undefined });
  }

  return (
    <StyledContainer data-test="documents-content">
      <Header title={t("Exports")} breadcrumbs={breadcrumbs} />
      <Card>
        <QueueTaskList
          data={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default QueueTaskOverview;
