import { Document, DocumentFormValues, ManualType } from "./document";
import { DateTimeString, ID } from "./types";

export enum AccessStatus {
  None = "none",
  Basis = "basis",
  Client = "client",
  ClientReadOnly = "clientReadOnly",
  Enterprise = "enterprise",
  InHouse = "inHouse",
}

export enum TariffModel {
  None = "none",
  Basis = "basis",
  BasisPercent = "basisPercent",
  Client = "client",
  EnterpriseSmall = "enterpriseSmall",
  EnterpriseMedium = "enterpriseMedium",
  EnterpriseLarge = "enterpriseLarge",
  InHouse = "inHouse",
  InHousePercent = "inHousePercent",
}

export interface BillingPreview {
  id: ID;
  dueDate: DateTimeString | null;
  tariffModel: string | null;
  accessStatus: string | null;
}

export interface Billing extends BillingPreview {
  id: ID;
  organizerName: string;
  organizerEmail: string;
  organizerPhone: string;
  accountantName: string;
  accountantEmail: string;
  accountantPhone: string;
  invoiceEmail: string;
  invoiceRemark: string;
  orderNumber: string;
  contract: Document | null;
  contractUploadAt: DateTimeString | null;
}

export interface BillingFilters {
  accessStatus: string;
  tariffModel: string;
  dueDateMonth: number;
  dueDateYear: string;
}

export interface BillingFormValues {
  organizerName: string;
  organizerEmail: string;
  organizerPhone: string;
  accountantName: string;
  accountantEmail: string;
  accountantPhone: string;
  invoiceEmail: string;
  invoiceRemark: string;
  orderNumber: string;
  contract: ID | null;
  newContract: DocumentFormValues;
  dueDate: DateTimeString;
  tariffModel: string;
  accessStatus: string;
  deleteContract: boolean;
}

export const getBillingFormValues = (data?: Billing): BillingFormValues => ({
  organizerName: data?.organizerName || "",
  organizerEmail: data?.organizerEmail || "",
  organizerPhone: data?.organizerPhone || "",
  accountantName: data?.accountantName || "",
  accountantEmail: data?.accountantEmail || "",
  accountantPhone: data?.accountantPhone || "",
  invoiceEmail: data?.invoiceEmail || "",
  invoiceRemark: data?.invoiceRemark || "",
  orderNumber: data?.orderNumber || "",
  contract: data?.contract ? data?.contract.id : null,
  newContract: {
    name: data?.contract?.name || "",
    type: ManualType.Contract,
    comment: data?.contract?.comment || "",
    documentUpload: "",
  },
  dueDate: data?.dueDate || "",
  tariffModel: data?.tariffModel || "",
  accessStatus: data?.accessStatus || "",
  deleteContract: false,
});
