import { useTitle } from "../../hooks/use-title";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  Category,
  CategoryFormValues,
  getCategoryFormValues,
  Standard,
} from "../../model";
import { apiRoutes, request, useCategoryDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApi } from "../../hooks/use-api";
import { handleHookFormErrors } from "../../helpers";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { useCategoryValidationSchema } from "../../hooks/validations/categories/use-category-validation-schema";
import LoadingButton from "../../components/loading-button";

const EditCategory = () => {
  const { t } = useTranslation();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();
  const { isLoading, error, data } = useCategoryDetailsApi(
    categoryId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: parents,
    isLoading: parentsLoading,
    error: parentsError,
  } = useApi<Category[]>(
    apiRoutes.categoryParents(categoryId as unknown as number)
  );

  const {
    data: allStandards,
    isLoading: standardsLoading,
    error: standardsError,
  } = useUnlimitedPaginationApi<{}, Standard>(apiRoutes.standards);

  useTitle(data?.name || t("Kategorie bearbeiten"));

  const validationSchema = useCategoryValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<CategoryFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCategoryFormValues(data),
  });

  const onSubmit = async (
    values: CategoryFormValues,
    setError: UseFormSetError<CategoryFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<Category>(apiRoutes.category(data.id), "put", values)
      .then(() => {
        enqueueSnackbar(t("Kategorie wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.category(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getCategoryFormValues(data)), [reset, data]);

  if (error || standardsError || parentsError) {
    return (
      <HttpError
        error={[error, standardsError, parentsError]}
        actions={
          <Button component={Link} to={routes.categories}>
            {t("Zurück zu Kategorien")}
          </Button>
        }
      />
    );
  }

  if (
    isLoading ||
    standardsLoading ||
    parentsLoading ||
    !data ||
    !allStandards ||
    !parents
  ) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Kategorien"), link: routes.categories },
    { label: data.name, link: routes.category(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Kategorie")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Kategorie")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"name"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Name")}
                      fullWidth
                      {...field}
                      required
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={"parent"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="parent"
                      options={parents || []}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      value={
                        parents?.find((parent) => parent.id === field.value) ||
                        null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Übergeordnete Kategorie")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"standards"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="standards"
                      multiple={true}
                      options={allStandards}
                      defaultValue={data.standards}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.number +
                        (option.type
                          ? " (" + t(`standard.type.${option.type}`) + ")"
                          : "")
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(value.map((standard) => standard.id))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Normen")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditCategory;
