import React, { useState, VoidFunctionComponent } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AppGrant,
  DateFormat,
  HeadCell,
  Item,
  ItemDocumentation,
  ItemDocumentationPreview,
  ItemListPreview,
} from "../../model";
import PaperDialog from "../paper-dialog";
import { Close, Image } from "@mui/icons-material";
import { TableNoResult } from "../table-no-result";
import FormattedDate from "../formatted-date";
import { Thumbnail } from "../thumbnail";
import { ClickableThumbnailContainer, CompactTableCell } from "../globals";
import ItemDocumentationDetailDialog from "../item-documentation-detail-dialog";
import DefectStatusChip from "../defect-status-chip";
import ItemCheckResult from "../item-check-result";
import DefectVerifiedLabel from "../defect-verified-label";
import { StyledTypography } from "../date-entry";
import { Control, Controller } from "react-hook-form";
import { FormValues } from "../order-processing";
import { green, red } from "@mui/material/colors";
import { ChoiceButton } from "../order-processing/check-group";
import { isGranted } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";

const DefectListDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  data: ItemListPreview | Item;
  defects?: ItemDocumentation[];
  control?: Control<FormValues>;
}> = ({ open, onClose, data, defects, control }) => {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] =
    useState<ItemDocumentationPreview | null>(null);
  const user = useCurrentUser();
  const listDefects = defects || data.defects;

  const headCells: HeadCell<ItemDocumentation>[] = [
    { id: "attachments", label: t("Bilddokumentation"), sortable: false },
    { id: "createdAt", label: t("Aufnahmedatum"), sortable: false },
    { id: "createdBy", label: t("Aufgenommen von"), sortable: false },
    { id: "defect", label: t("Mangel"), sortable: false },
    { id: "action", label: t("Vorgeschlagene Maßnahme"), sortable: false },
    { id: "comment", label: t("Kommentar"), sortable: false },
    {
      id: "defectCorrected",
      label: t("Status"),
      sortable: false,
      align: "center",
    },
    { id: "defectCorrectionProofs", label: t("Nachweis"), sortable: false },
    {
      id: "verified",
      label: control ? t("Mangel behoben?") : t("Verifizierung"),
      sortable: false,
    },
  ];

  const editMode = control && isGranted(user, AppGrant.VERIFY_DEFECT);

  const closeDialog = () => {
    const confirm = t(
      "Sind Sie sicher, dass Sie die Verifikationen so speichern möchten? Nach dem Speichern der Prüfung, kann das Ergebnis nicht mehr bearbeitet werden."
    );
    if (editMode && !window.confirm(confirm)) {
      return;
    }
    onClose();
  };
  return (
    <>
      <PaperDialog open={open} onClose={closeDialog}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3">{t("Mängelliste")}</Typography>
              <Typography variant="body2">
                {data.serialNumber}
                {" / "}
                {data.product?.displayName}
              </Typography>
              <Typography variant="body2">
                {data.location ? data.location?.name : t("Kein Ort/Abteilung")}
                {data.location?.parent && (
                  <>
                    {" ("}
                    {data.location?.parent?.name})
                  </>
                )}
                {" / "}
                {data.person ? data.person?.displayName : t("Kein Verwender")}
              </Typography>
              <Typography variant="body2">
                {data.customer ? data.customer.name : ""}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => closeDialog()} size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        width={headCell.width}
                        align={headCell.align}
                        padding={headCell.padding || "normal"}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listDefects?.map((itemDocumentation, index) => (
                    <TableRow key={index} hover={true}>
                      <CompactTableCell size="small">
                        <ClickableThumbnailContainer
                          onClick={() => setDetailsOpen(itemDocumentation)}
                        >
                          {itemDocumentation.attachments.length > 0 &&
                          itemDocumentation.attachments[0].image ? (
                            <Thumbnail
                              preview={itemDocumentation.attachments[0]}
                              collection={
                                itemDocumentation.attachments.length > 1
                              }
                            />
                          ) : (
                            <Image />
                          )}
                        </ClickableThumbnailContainer>
                      </CompactTableCell>
                      <TableCell>
                        <FormattedDate
                          date={itemDocumentation.createdAt}
                          format={DateFormat.Default}
                        />
                      </TableCell>
                      <TableCell>
                        {itemDocumentation.createdPerson
                          ? itemDocumentation.createdPerson.displayName +
                            (itemDocumentation.createdPerson.primaryCompany
                              ? " (" +
                                itemDocumentation.createdPerson.primaryCompany
                                  .name +
                                ")"
                              : "")
                          : itemDocumentation.createdBy}
                      </TableCell>
                      <TableCell>{itemDocumentation.defect?.title}</TableCell>
                      <TableCell>{itemDocumentation.action?.title}</TableCell>
                      <TableCell>{itemDocumentation.comment}</TableCell>
                      <TableCell>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item style={{ margin: "auto" }}>
                            <DefectStatusChip
                              corrected={itemDocumentation.defectCorrected}
                              verified={itemDocumentation.verified}
                            />
                          </Grid>
                          <Grid item style={{ margin: "auto" }}>
                            <ItemCheckResult
                              result={itemDocumentation.itemCheck.checkResult}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <CompactTableCell size="small">
                        <ClickableThumbnailContainer
                          style={{ width: "100px" }}
                          onClick={() => setDetailsOpen(itemDocumentation)}
                        >
                          {itemDocumentation.defectCorrectionProofs.length >
                            0 &&
                          itemDocumentation.defectCorrectionProofs[0].image ? (
                            <Thumbnail
                              preview={
                                itemDocumentation.defectCorrectionProofs[0]
                              }
                              collection={
                                itemDocumentation.defectCorrectionProofs
                                  .length > 1
                              }
                            />
                          ) : (
                            <Image />
                          )}
                        </ClickableThumbnailContainer>
                      </CompactTableCell>
                      {!editMode ? (
                        <CompactTableCell>
                          <DefectVerifiedLabel
                            verified={itemDocumentation.verified}
                          />
                          {itemDocumentation.verifiedDate && (
                            <StyledTypography variant="overline">
                              <FormattedDate
                                date={itemDocumentation.verifiedDate}
                                format={DateFormat.Default}
                              />
                            </StyledTypography>
                          )}
                          {itemDocumentation.verifiedBy && (
                            <>
                              <Typography variant="body2">
                                {itemDocumentation.verifiedBy.person
                                  ?.displayName ||
                                  itemDocumentation.verifiedBy.username}
                              </Typography>
                              {itemDocumentation.verifiedBy.person
                                ?.primaryCompany && (
                                <Typography variant="body2">
                                  (
                                  {
                                    itemDocumentation.verifiedBy.person
                                      ?.primaryCompany.name
                                  }
                                  )
                                </Typography>
                              )}
                            </>
                          )}
                        </CompactTableCell>
                      ) : (
                        <CompactTableCell>
                          <Controller
                            control={control}
                            name={`defectReview.${index}.verified` as const}
                            render={({ field }) => (
                              <ButtonGroup disableElevation>
                                <ChoiceButton
                                  selected={field.value === true}
                                  customcolor={green[700]}
                                  onClick={() =>
                                    field.onChange(
                                      field.value === true ? null : true
                                    )
                                  }
                                >
                                  {t("Ja")}
                                </ChoiceButton>
                                <ChoiceButton
                                  customcolor={red[700]}
                                  selected={field.value === false}
                                  onClick={() =>
                                    field.onChange(
                                      field.value === false ? null : false
                                    )
                                  }
                                >
                                  {t("Nein")}
                                </ChoiceButton>
                              </ButtonGroup>
                            )}
                          ></Controller>
                        </CompactTableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                {!data.defects && <TableNoResult />}
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <Button
              onClick={() => closeDialog()}
              color="primary"
              variant="contained"
            >
              {t("Speichern & Schließen")}
            </Button>
          ) : (
            <Button onClick={() => closeDialog()} color="secondary">
              {t("Schließen")}
            </Button>
          )}
        </DialogActions>
      </PaperDialog>
      {detailsOpen && (
        <ItemDocumentationDetailDialog
          open={!!detailsOpen}
          onClose={() => setDetailsOpen(null)}
          itemDocumentation={detailsOpen}
        />
      )}
    </>
  );
};

export default DefectListDialog;
