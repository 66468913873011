import { CurrentUser, Nullable } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useContext } from "react";
import AppContext from "../../context/app";

export const useSelectCompanyMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { setUser } = useContext(AppContext);

  return useMutation(
    async (companyId: Nullable<number>) => {
      return request<CurrentUser>(apiRoutes.profile.selectCompany, "put", {
        company: companyId,
      });
    },
    {
      onSuccess: async (res) => {
        setUser(res.data);
        enqueueSnackbar(t("Aktive Organisation wurde gewechselt."), {
          variant: "success",
        });
        // Invalidate all queries when selected company changes
        await queryClient.invalidateQueries();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message, {
          variant: "error",
        });
      },
    }
  );
};
