import React, { useState, VoidFunctionComponent } from "react";
import { Alert, Autocomplete, TextField } from "@mui/material";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { ApiError, Check, ID, Item, Nullable, Product } from "../../model";
import { useTranslation } from "react-i18next";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useItemValidation } from "../../hooks/validations/items/use-item-validation";
import styled from "styled-components";
import LoadingButton from "../loading-button";
import { ProductOption } from "components/product-option";

const StyledLoadingButton = styled(LoadingButton)`
  margin-top: 1em;
`;

const ProductSelector: VoidFunctionComponent<{
  item: Item;
  check: Check;
  onItemUpdate: (item: Item) => void;
}> = ({ item, check, onItemUpdate }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const validation = useItemValidation();
  const {
    data: products,
    isLoading: productLoading,
    error: productError,
  } = useUnlimitedPaginationApi<{}, Product>(apiRoutes.products, undefined, {
    productOption: true,
  });

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<{ product: Nullable<ID> }>({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        product: validation.product,
      })
    ),
    defaultValues: {
      product: null,
    },
  });

  const onProductSubmit = async (
    values: { product: Nullable<ID> },
    setError: UseFormSetError<{ product: Nullable<ID> }>
  ) => {
    setLoading(true);
    await request<Item>(apiRoutes.checkItem(check.id, item.id), "put", values)
      .then((res) => onItemUpdate(res.data))
      .catch((err: AxiosError<ApiError>) => {
        handleHookFormErrors(err, setError);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onProductSubmit(values, setError))}
    >
      <Alert severity="warning">
        {t("Diesem Item ist keinem Produkt zugeteilt!")}
        <Controller
          control={control}
          name={"product"}
          render={({ field, fieldState }) => (
            <Autocomplete
              id="product"
              options={products.filter(
                (product) => !!product.manufacturingYear
              )}
              loading={productLoading || !!productError}
              groupBy={(option) => option.category.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <ProductOption product={option} />
                </li>
              )}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.displayName}
              value={
                products.find((product) => product.id === field.value) || null
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => field.onChange(value?.id)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label={t("Produkt")}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        />
        <StyledLoadingButton
          disabled={!isValid && !isSubmitting}
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting || loading}
        >
          {t("Produkt verknüpfen")}
        </StyledLoadingButton>
      </Alert>
    </form>
  );
};

export default ProductSelector;
