import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProductsOverview from "./overview";
import GuardedRoute from "../../components/guarded-route";
import { routes } from "../../lib/routes";
import { Role } from "../../model";
import CreateProduct from "./create";
import ProductDetails from "./details";
import EditProduct from "./edit";
import ImportProduct from "./importer";

const Products = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ProductsOverview />
      </Route>
      <Route path={routes.productCreate}>
        <CreateProduct />
      </Route>
      <Route path={routes.productImport}>
        <ImportProduct />
      </Route>
      <GuardedRoute
        path={routes.productEdit(":productId")}
        requiredRole={Role.User}
      >
        <EditProduct />
      </GuardedRoute>
      <Route path={routes.product(":productId")}>
        <ProductDetails />
      </Route>
    </Switch>
  );
};

export default Products;
