import { IconButton, Tooltip } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import React, { ReactChild, VoidFunctionComponent } from "react";

export interface IconLinkProps extends LinkProps {
  icon: ReactChild;
  tooltip?: string;
  size?: "small" | "medium";
  disabled?: boolean;
}

export const IconLink: VoidFunctionComponent<IconLinkProps> = ({
  icon,
  tooltip,
  size = "small",
  disabled = false,
  ...props
}) => {
  const button = (
    <IconButton aria-label={tooltip} size={size} disabled={disabled}>
      {icon}
    </IconButton>
  );

  const tooltippedButton = tooltip ? (
    <Tooltip title={tooltip}>{button}</Tooltip>
  ) : (
    button
  );

  if (disabled) {
    return <>{tooltippedButton}</>;
  }

  return <Link {...props}>{tooltippedButton}</Link>;
};
