import React, { FunctionComponent, useContext } from "react";
import { TableCell as Cell, TableCellProps } from "@mui/material";
import { TableContext } from "../table-context-container";

const TableCell: FunctionComponent<{ columnId?: string } & TableCellProps> = ({
  columnId,
  children,
  ...props
}) => {
  const { tableSettings, tableId } = useContext(TableContext);

  if (tableSettings?.hiddenColumns[tableId || ""]?.includes(columnId || "")) {
    return null;
  }

  return <Cell {...props}>{children}</Cell>;
};

export default TableCell;
