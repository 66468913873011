import React, { VoidFunctionComponent } from "react";
import { Chip, ChipProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber, red } from "@mui/material/colors";
import { ItemCheckStatus, Nullable } from "../../model";
import styled from "styled-components";

const StyledCriticalChip = styled(Chip)`
  background-color: ${red[500]};
  color: white;
`;

const StyledPlanningChip = styled(Chip)`
  background-color: ${amber[500]};
  color: black;
`;

const ItemCheckStatusChip: VoidFunctionComponent<
  { checkStatus: Nullable<ItemCheckStatus> } & ChipProps
> = ({ checkStatus, ...props }) => {
  const { t } = useTranslation();

  if (!checkStatus) {
    return null;
  }

  const ChipComponent = getChipComponent(checkStatus);
  return (
    <ChipComponent
      label={t(`item.checkStatus.${checkStatus}`)}
      size="small"
      {...props}
    />
  );
};

function getChipComponent(checkStatus: Nullable<ItemCheckStatus>) {
  switch (checkStatus) {
    case ItemCheckStatus.Critical:
      return StyledCriticalChip;
    case ItemCheckStatus.Planning:
      return StyledPlanningChip;
  }
  return Chip;
}

export default ItemCheckStatusChip;
