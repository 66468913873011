import React, { FunctionComponent } from "react";
import { Company, Order, OrderFilters } from "../../model";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes } from "../../lib/api";
import { Typography } from "@mui/material";
import OrderList from "../../components/order-list";
import { useTranslation } from "react-i18next";
import LoadingContainer from "../../components/loading-container";

const Orders: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { t } = useTranslation();
  const {
    data: orders,
    isLoading: ordersLoading,
    error: ordersError,
  } = useUnlimitedPaginationApi<OrderFilters, Order>(apiRoutes.orders, {
    customerId: `${company.id}`,
  });

  if (ordersLoading) {
    return <LoadingContainer />;
  }

  if (ordersError) {
    return (
      <Typography>{t("Aufträge konnten nicht geladen werden")}</Typography>
    );
  }

  return <OrderList orders={orders} company={company} />;
};

export default Orders;
