import { ID } from "./types";
import { User } from "./user";
import {
  Blameable,
  Dict,
  Grantable,
  ItemFilters,
  Timestampable,
} from "./index";

// Sync with QueueTaskVoter in backend
export enum QueueTaskGrant {
  VIEW = "view",
  DOWNLOAD = "download",
}

export enum QueueTaskStatus {
  PENDING = 0,
  PROCESSING = 1,
  DONE = 2,
  FAILED = 3,
  ABORTED = 4,
}

export interface QueueTask<TWorkload = unknown>
  extends Timestampable,
    Blameable,
    Grantable<QueueTaskGrant> {
  id: ID;
  status: QueueTaskStatus;
  progress: number;
  type: string;
  workload: Workload<TWorkload>;
  result?: Result;
  error?: Error;
  start?: string;
  finish?: string;
  user?: User;
}

export interface ItemQueueTask
  extends QueueTask<QueueTaskWorkload<ItemFilters>> {}

export interface QueueTaskWorkload<TFilter> {
  format: string;
  filters: TFilter;
  context: Dict;
  orderBy: string[];
  offset: number;
  limit: number;
}

export interface Error {
  code: string;
  statusCode: string;
  headers: [];
  exceptions: Array<{
    message: string;
    class: string;
    trace: [];
  }>;
  messages: Array<{
    message: string;
  }>;
}

export interface Result {
  filename: string;
  itemsCount: number;
}

export interface Workload<TWorkload> {
  format: string;
  parameters: TWorkload;
}

export interface QueueTaskFilter {
  search: string;
}

export interface QueueTaskProgressStatus {
  id: ID;
  status: QueueTaskStatus;
  progress: number;
}
