import { Button, CardContent, Divider, Grid, IconButton } from "@mui/material";
import { Edit, FindInPage } from "@mui/icons-material";
import BillingEditDialog from "components/billing-edit-dialog";
import FormattedDate from "components/formatted-date";
import { Definition, StyledCardTitle } from "components/globals";
import LoadingContainer from "components/loading-container";
import { useApi } from "hooks/use-api";
import { apiRoutes, getAbsoluteApiUrl } from "lib/api";
import { Company, DateFormat } from "model";
import { Billing as BillingType } from "model/billing";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

const Billing: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: billing, isLoading: billingLoading } = useApi<BillingType>(
    apiRoutes.comapanyBilling(company.id)
  );

  const [open, setOpen] = useState(false);

  if (billingLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Kontakt: Organisatorisch")}
            </StyledCardTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Definition title={t("Name")} value={billing?.organizerName} />
              </Grid>
              <Grid item>
                <Definition
                  title={t("E-Mail Adresse")}
                  value={billing?.organizerEmail}
                />
              </Grid>
              <Grid item>
                <Definition
                  title={t("Telefonnummer")}
                  value={billing?.organizerPhone}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Kontakt: Buchhaltung")}
            </StyledCardTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Definition title={t("Name")} value={billing?.accountantName} />
              </Grid>
              <Grid item>
                <Definition
                  title={t("E-Mail Adresse")}
                  value={billing?.accountantEmail}
                />
              </Grid>
              <Grid item>
                <Definition
                  title={t("Telefonnummer")}
                  value={billing?.accountantPhone}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Rechnungsinformationen")}
            </StyledCardTitle>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={6}>
                <Definition title={t("E-Mail")} value={billing?.invoiceEmail} />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Rechnungsvermerk")}
                  value={billing?.invoiceRemark}
                />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Bestellnummer")}
                  value={billing?.orderNumber}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Divider
          flexItem
          orientation="vertical"
          style={{ marginRight: "-2px" }}
        />
        <Grid item xs={12} md={6}>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Tarif")}
                </StyledCardTitle>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                  startIcon={<Edit />}
                >
                  {t("Bearbeiten")}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Definition
                  title={t("Tarifmodell")}
                  value={
                    billing?.tariffModel
                      ? t(`billing.tariffModel.${billing.tariffModel}`)
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Zugriffstatus")}
                  value={t(
                    billing?.accessStatus
                      ? t(`billing.accessStatus.${billing.accessStatus}`)
                      : ""
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Vertragsdaten")}
            </StyledCardTitle>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Definition
                  title={t("Vertrag")}
                  value={
                    billing?.contract ? (
                      <>
                        {billing.contract.name}
                        {billing.contract.fileName && (
                          <a
                            href={getAbsoluteApiUrl(
                              apiRoutes.documentFile(billing.contract.id)
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconButton aria-label={t("Ansehen")} size="small">
                              <FindInPage />
                            </IconButton>
                          </a>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Upload am")}
                  value={
                    billing?.contractUploadAt && (
                      <FormattedDate
                        date={billing.contractUploadAt}
                        format={DateFormat.Default}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Kommentar")}
                  value={billing?.contract?.comment}
                />
              </Grid>
              <Grid item xs={6}>
                <Definition
                  title={t("Stichtag")}
                  value={
                    billing?.dueDate && (
                      <FormattedDate
                        date={billing.dueDate}
                        format={DateFormat.Default}
                      />
                    )
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
      <BillingEditDialog
        open={open}
        billing={billing}
        company={company}
        onUpdate={() =>
          queryClient.invalidateQueries(apiRoutes.comapanyBilling(company.id))
        }
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Billing;
