import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ApiError } from "../model";
import { Theme } from "@mui/material";
import { Path, UseFormSetError } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

export function handleFormErrors(
  error: AxiosError<ApiError>,
  actions: FormikHelpers<any>
) {
  if (error.response?.data.context?.type !== "ConstraintViolationList") {
    return;
  }
  const violations = error.response.data.context.violations;
  Object.keys(violations).forEach((field) =>
    actions.setFieldError(field, violations[field].join(" "))
  );
}

export function handleHookFormErrors<TFieldValues extends FieldValues>(
  error: AxiosError<ApiError>,
  setError: UseFormSetError<TFieldValues>
) {
  if (error.response?.data.context?.type !== "ConstraintViolationList") {
    return;
  }
  const violations = error.response.data.context.violations;
  Object.keys(violations).forEach((field) =>
    setError(
      field
        .replace("][", ".")
        .replace("]", "")
        .replace("[", "") as Path<TFieldValues>,
      { message: violations[field].join(" ") }
    )
  );
}

export function getErrorMessage(error: AxiosError) {
  if (error.response?.data.message) {
    return error.response?.data.message;
  }
  return "Error";
}

export function getSelectMenuItemStyles<T extends string | number>(
  value: T,
  selectedValues: T | T[],
  theme: Theme
) {
  const selected = Array.isArray(selectedValues)
    ? selectedValues.indexOf(value) !== -1
    : value === selectedValues;
  return {
    fontWeight: selected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}
