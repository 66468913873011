import React, { FunctionComponent, useEffect, useState } from "react";
import { Company, Location } from "../../model";
import { apiRoutes, request } from "../../lib/api";
import LocationList from "../../components/location-list";
import { useApi } from "../../hooks/use-api";
import HttpError from "../../components/http-error";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const Locations: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, error } = useApi<Location[]>(
    apiRoutes.companyLocations(company.id)
  );

  useEffect(() => {
    if (data) {
      setLocations(data);
    }
  }, [data]);

  const onLocationUpdate = () => {
    request<Location[]>(apiRoutes.companyLocations(company.id), "get")
      .then((res) => {
        setLocations(res.data);
      })
      .catch(() => {
        enqueueSnackbar(t("Ort konnte nicht aktualisiert werden."), {
          variant: "error",
        });
      });
  };

  if (error) {
    return <HttpError error={error} />;
  }

  return (
    <LocationList
      locations={locations}
      company={company}
      onLocationUpdate={onLocationUpdate}
      loading={isLoading}
    />
  );
};

export default Locations;
