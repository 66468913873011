import { DateTimeString, ID } from "./types";

export interface Entity {
  id: ID;
}

export interface Timestampable {
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface Blameable {
  createdBy: string | null;
  updatedBy: string | null;
}

export interface BlameableUser extends Blameable {
  createdByUserId: ID | null;
  updatedByUserId: ID | null;
}

export interface Commentable {
  comment: string | null;
}

export type Grant = string;

export interface Grantable<T extends Grant> {
  grants: T[];
}

export function isTimestampable(object: object): object is Timestampable {
  return "createdAt" in object;
}

export function isBlameable(object: object): object is Blameable {
  return "createdBy" in object;
}

export enum DateFormat {
  Default = "dd.MM.yyyy",
  DefaultDateTime = "dd.MM.yyyy HH:mm",
}

export type Dict<T = any> = {
  [key: string]: T;
};

export * from "./http-status";
export * from "./types";
export * from "./check-process";
export * from "./user";
export * from "./search";
export * from "./order";
export * from "./standard";
export * from "./document";
export * from "./error";
export * from "./company";
export * from "./location";
export * from "./item";
export * from "./category";
export * from "./product";
export * from "./table";
export * from "./person";
export * from "./log";
export * from "./check";
export * from "./qualification";
export * from "./defect";
export * from "./action";
export * from "./item-documentation";
