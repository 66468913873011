import { useForm } from "react-hook-form";
import { PersonFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePersonValidationSchema } from "../validations/persons/use-person-validation-schema";

export const usePersonForm = (defaultValues: PersonFormValues) => {
  const validationSchema = usePersonValidationSchema();

  return useForm<PersonFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
