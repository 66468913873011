import { ID, Nullable } from "./types";
import { Order } from "./order";
import { Item, ItemCheck } from "./item";
import { Person } from "./person";
import { CheckProcess } from "./check-process";
import { Location } from "./location";

export enum CheckStatus {
  Done = "done",
  InProgress = "in-progress",
}

export interface Check {
  id: ID;
  status: CheckStatus;
  order: Order;
  comment: string;
  currentItem: Nullable<Item>;
  person: Nullable<Person>;
  location: Nullable<Location>;
  checkProcess: Nullable<CheckProcess>;
  itemChecks: ItemCheck[];
}
