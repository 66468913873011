import React, { createContext, FunctionComponent } from "react";
import { TableContainer, TableContainerProps } from "@mui/material";
import { useLocalStorage } from "../../hooks/use-local-storage";

export interface TableSettings {
  hiddenColumns: { [key: string]: string[] };
}

export const TableSettingsKey = "table-settings";

export const TableContext = createContext<{
  tableSettings: TableSettings;
  setTableSettings: (data: TableSettings) => void;
  tableId: string;
}>({
  tableSettings: { hiddenColumns: {} },
  setTableSettings: (data: TableSettings) => {},
  tableId: "",
});

const TableContextContainer: FunctionComponent<
  { tableId: string } & TableContainerProps
> = ({ tableId, children, ...props }) => {
  const [tableSettings, setTableSettings] = useLocalStorage<TableSettings>(
    TableSettingsKey,
    { hiddenColumns: {} }
  );

  return (
    <TableContext.Provider value={{ tableSettings, setTableSettings, tableId }}>
      <TableContainer {...props}>{children}</TableContainer>
    </TableContext.Provider>
  );
};

export default TableContextContainer;
