import React, { useEffect, VoidFunctionComponent } from "react";
import { CheckStep, CheckStepCondition, ID } from "../../model";
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { StyledCardTitle, StyledColoredCardContent } from "../globals";
import { useTranslation } from "react-i18next";
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  UseFormSetError,
} from "react-hook-form";
import { Flipped, Flipper } from "react-flip-toolkit";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Cancel,
  CheckCircle,
  Delete,
} from "@mui/icons-material";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import LoadingContainer from "../loading-container";
import { useSnackbar } from "notistack";
import { handleHookFormErrors } from "../../helpers";
import { green, red } from "@mui/material/colors";
import LoadingButton from "../loading-button";

interface CheckStepConditionValue {
  sortOrder: number;
  condition: boolean;
  checkStep: ID | null;
}

interface CheckStepBoolConditionValues {
  truthyConditions: CheckStepConditionValue[];
  falsyConditions: CheckStepConditionValue[];
}

const buildCheckStepConditionValues = (
  conditions: CheckStepCondition[],
  filter: (condition: CheckStepCondition) => boolean
): CheckStepConditionValue[] =>
  conditions.filter(filter).map(
    (condition): CheckStepConditionValue => ({
      sortOrder: condition.sortOrder,
      condition: condition.condition.value,
      checkStep: condition.checkStep.id,
    })
  );

const BoolConditionFields: VoidFunctionComponent<{
  control: Control<CheckStepBoolConditionValues>;
  fieldArrayName: keyof CheckStepBoolConditionValues;
  checkSteps: CheckStep[];
}> = ({ control, fieldArrayName, checkSteps }) => {
  const { t } = useTranslation();
  const { fields, remove, swap, append } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  return (
    <>
      <Flipper flipKey={fields.map((field) => field.id).join("-")}>
        <Grid container spacing={3}>
          {fields.map((field, index) => (
            <Flipped key={field.id} flipId={field.id}>
              <Grid item xs={12} key={field.id}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item md={2} style={{ textAlign: "center" }}>
                    {index > 0 && (
                      <IconButton
                        type="button"
                        onClick={() => swap(index, index - 1)}
                        size="large"
                      >
                        <ArrowUpward />
                      </IconButton>
                    )}
                    {index < fields.length - 1 && (
                      <IconButton
                        type="button"
                        onClick={() => swap(index, index + 1)}
                        size="large"
                      >
                        <ArrowDownward />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item md={7}>
                    <Controller
                      control={control}
                      name={`${fieldArrayName}.${index}.checkStep` as const}
                      defaultValue={field.checkStep as any}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          id={`${fieldArrayName}.${index}.checkStep`}
                          options={checkSteps}
                          getOptionKey={(option) => option.id}
                          getOptionLabel={(option) => option.title}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          value={
                            checkSteps.find(
                              (checkStep) => checkStep.id === field.value
                            ) || null
                          }
                          onChange={(event, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label={t("Prüfschritt")}
                              required
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={t<string>("Prüfschritt löschen")}>
                      <IconButton
                        type="button"
                        onClick={() => remove(index)}
                        size="large"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Flipped>
          ))}
        </Grid>
      </Flipper>
      <Grid container spacing={5} justifyContent="space-around">
        <Grid item>
          <Tooltip title={t<string>("Prüfschritt hinzufügen")}>
            <Fab
              type="button"
              onClick={() =>
                append({
                  sortOrder: fields.length,
                  condition: false,
                  checkStep: null,
                })
              }
              color="primary"
              size="small"
            >
              <Add />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

const CheckStepBoolEditor: VoidFunctionComponent<{
  checkStep: CheckStep;
  onUpdate: (checkStep: CheckStep) => void;
}> = ({ checkStep, onUpdate }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<CheckStepBoolConditionValues>({
    mode: "all",
    defaultValues: {
      truthyConditions: checkStep.conditions
        ? buildCheckStepConditionValues(
            checkStep.conditions,
            (c) => c.condition.value
          )
        : [],
      falsyConditions: checkStep.conditions
        ? buildCheckStepConditionValues(
            checkStep.conditions,
            (c) => !c.condition.value
          )
        : [],
    },
  });
  const {
    data: checkSteps,
    isLoading: checkStepsLoading,
    error: checkStepsError,
  } = useUnlimitedPaginationApi<{}, CheckStep>(apiRoutes.checkSteps);
  useEffect(() => {
    checkStepsError?.response?.data.message &&
      enqueueSnackbar(checkStepsError?.response?.data.message, {
        variant: "error",
      });
  }, [checkStepsError, enqueueSnackbar]);

  if (checkStepsLoading) {
    return <LoadingContainer />;
  }

  if (checkStepsError) {
    return null;
  }

  const onSubmit = async (
    values: CheckStepBoolConditionValues,
    setError: UseFormSetError<CheckStepBoolConditionValues>
  ) => {
    await request<CheckStep>(
      apiRoutes.checkStepConditions(checkStep.id),
      "put",
      [
        ...values.truthyConditions.map((v, index) => ({
          ...v,
          sortOrder: index,
          condition: true,
        })),
        ...values.falsyConditions.map((v, index) => ({
          ...v,
          sortOrder: index,
          condition: false,
        })),
      ]
    )
      .then((res) => {
        enqueueSnackbar(t("Prüfschritte wurde erfolgreich aktualisiert."), {
          variant: "success",
        });
        onUpdate(res.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Card>
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Weitere Schritte durch Ja/Nein Antwort:")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <StyledColoredCardContent>
                  <StyledCardTitle
                    variant="h6"
                    color="secondary"
                    gutterBottom
                    style={{ marginBottom: 0 }}
                  >
                    <CheckCircle
                      style={{ color: green[500], marginRight: ".25em" }}
                    />
                    {t("Ja")}
                  </StyledCardTitle>
                </StyledColoredCardContent>
                <StyledColoredCardContent>
                  <BoolConditionFields
                    control={control}
                    checkSteps={checkSteps}
                    fieldArrayName={`truthyConditions`}
                  />
                </StyledColoredCardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <StyledColoredCardContent>
                  <StyledCardTitle
                    variant="h6"
                    color="secondary"
                    gutterBottom
                    style={{ marginBottom: 0 }}
                  >
                    <Cancel style={{ color: red[500], marginRight: ".25em" }} />
                    {t("Nein")}
                  </StyledCardTitle>
                </StyledColoredCardContent>
                <StyledColoredCardContent>
                  <BoolConditionFields
                    control={control}
                    checkSteps={checkSteps}
                    fieldArrayName={`falsyConditions`}
                  />
                </StyledColoredCardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            disabled={!isValid}
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
};

export default CheckStepBoolEditor;
