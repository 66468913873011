import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { useTranslation } from "react-i18next";
import PaperDialog from "../paper-dialog";
import {
  AttachmentPreview,
  DateFormat,
  FilePreview,
  getItemDocumentationDetailFormValues,
  ItemDocumentation,
  ItemDocumentationDetailValues,
  ItemDocumentationPreview,
  ItemDocumentationType,
  Nullable,
} from "../../model";
import {
  apiRoutes,
  request,
  useItemDocumentationDetailsApi,
} from "../../lib/api";
import { ClickableThumbnailContainer, Definition } from "../globals";
import { ModifiedEntity } from "../modified-entity";
import { Add, Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { Thumbnail } from "../thumbnail";
import LoadingButton from "../loading-button";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useSnackbar } from "notistack";
import LoadingContainer from "../loading-container";
import { listItemsQueryKey } from "../item-list-filterable";
import { yupResolver } from "@hookform/resolvers/yup";
import { useItemDocumentationDetailValidation } from "hooks/validations/item-documentation/use-item-documentation-detail-validation";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const markCorrectedDefectAsUncorrectConfirmation =
  "Wenn Sie den behobenen Mangel als unbehoben markieren, dann werden alle Daten und Uploads assoziiert mit der Mangelbehebung gelöscht. Wollen Sie trotzdem den behobenen Mangel als unbehoben markieren?";

const StyledTypography = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

const ItemDocumentationDetailDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  itemDocumentation: ItemDocumentationPreview | ItemDocumentation;
}> = ({ open, onClose, itemDocumentation }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useItemDocumentationDetailsApi(
    itemDocumentation.id
  );

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting },
    setError,
  } = useForm<ItemDocumentationDetailValues>({
    mode: "all",
    resolver: yupResolver(useItemDocumentationDetailValidation()),
    defaultValues: getItemDocumentationDetailFormValues(data),
  });

  const { mutateAsync: onSubmitAsync } = useMutation(
    async (values: ItemDocumentationDetailValues) => {
      if (!data) {
        return;
      }

      const formData = new FormData();

      files.forEach(
        (file) =>
          file.file && formData.append("defectCorrectionProofs[]", file.file)
      );
      formData.append(
        "content",
        JSON.stringify({
          ...values,
          deletableDefectCorrectionProofs: data.defectCorrectionProofs
            .filter((attachment) =>
              files.every((file) => file.id !== attachment.id)
            )
            .map((attachment) => attachment.id),
        })
      );

      return await request<ItemDocumentation>(
        apiRoutes.itemDocumentation(data.id),
        "post",
        formData
      );
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }

        await queryClient.invalidateQueries([listItemsQueryKey]);
        await queryClient.invalidateQueries([
          apiRoutes.item(res.data.itemCheck.item.id),
        ]);
        enqueueSnackbar(t("Bilddokumentation wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  const openAttachment = (attachment: AttachmentPreview) => {
    if (!attachment.image) {
      return;
    }
    return window.open(attachment.image, "_blank");
  };

  const defectCorrected = watch("defectCorrected");
  const [files, setFiles] = useState<
    Partial<AttachmentPreview & FilePreview>[]
  >(data?.defectCorrectionProofs || []);

  useEffect(() => {
    if (!open) {
      return;
    }

    reset(getItemDocumentationDetailFormValues(data));
    setFiles(data?.defectCorrectionProofs || []);
  }, [open, data, reset]);

  if (!data) {
    return null;
  }

  const addFiles = (uploadedFiles: Nullable<FileList>) => {
    if (!uploadedFiles || files.length + uploadedFiles.length > 5) {
      return;
    }

    Array.from(uploadedFiles).forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () =>
        files.length < 5
          ? setFiles([...files, { file, preview: reader.result as string }])
          : files;
      reader.readAsDataURL(file);
    });
  };
  const removeFile = (
    unwantedFile: Partial<AttachmentPreview & FilePreview>
  ) => {
    setFiles(
      files.filter(
        (file) =>
          file.id !== unwantedFile?.id || file.file !== unwantedFile?.file
      )
    );
  };

  // Don't allow to set correction details if defect is already verified
  const correctionLocked = data.verified !== null;

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="caption">
              {data.itemCheck.item.customer?.name}
            </Typography>
            <Typography variant="body2">
              {data.itemCheck.item.location
                ? data.itemCheck.item.location?.name
                : t("Kein Ort/Abteilung")}
              {data.itemCheck.item.location?.parent && (
                <>
                  {" ("}
                  {data.itemCheck.item.location?.parent?.name})
                </>
              )}
              {" / "}
              {data.itemCheck.item.person
                ? data.itemCheck.item.person?.displayName
                : t("Kein Verwender")}
            </Typography>
            <Typography variant="h3">
              {data.itemCheck.item.serialNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            <IconButton onClick={onClose} size="large">
              <Close />
            </IconButton>
            <ModifiedEntity entity={data} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isLoading && <LoadingContainer />}
        <Grid container spacing={3} style={{ marginBottom: 1 }}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1}>
              {data.attachments.map((attachment) => (
                <Fragment key={attachment.id}>
                  {attachment.image && (
                    <Grid item xs={6} key={attachment.id}>
                      <ClickableThumbnailContainer
                        onClick={() => openAttachment(attachment)}
                      >
                        <Thumbnail preview={attachment} onView={() => null} />
                      </ClickableThumbnailContainer>
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              {data.type === ItemDocumentationType.Defect && (
                <>
                  <Grid item xs={12}>
                    <Definition
                      title={t("Mangel")}
                      value={data.defect?.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Definition
                      title={t("Vorgeschlagene Maßnahme")}
                      value={data.action?.title}
                    />
                  </Grid>
                </>
              )}
              {data.type === ItemDocumentationType.Documentation && (
                <Grid item xs={12}>
                  <Definition
                    title={t("Beschreibung")}
                    value={data.description}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Kommentar")}
                      fullWidth
                      {...field}
                      multiline={true}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {data.type === ItemDocumentationType.Defect && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="defectCorrected"
                      render={({ field }) => (
                        <FormControlLabel
                          label={t("Mangel behoben")}
                          control={
                            <Checkbox
                              color="primary"
                              disabled={correctionLocked}
                              readOnly={correctionLocked}
                              checked={field.value}
                              onChange={(_, value) => field.onChange(value)}
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                  {defectCorrected && (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledTypography color="secondary" variant="h6">
                          {t("Mangelbehebung")}
                        </StyledTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="defectCorrectedAt"
                          render={({ field, fieldState }) => (
                            <DatePicker
                              format={DateFormat.Default}
                              label={t("Umgesetzt am")}
                              name={field.name}
                              onChange={field.onChange}
                              value={field.value ? new Date(field.value) : null}
                              disabled={correctionLocked}
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  error:
                                    fieldState.isTouched && fieldState.invalid,
                                  helperText: fieldState.error?.message,
                                  fullWidth: true,
                                  required: true,
                                  onBlur: () =>
                                    !field.value && field.onChange(null),
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="defectCorrectionComment"
                          render={({ field, fieldState }) => (
                            <TextField
                              variant="standard"
                              {...field}
                              fullWidth
                              multiline
                              label={t("Kommentar")}
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                              disabled={correctionLocked}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Definition
                          title={t("Nachweis Upload")}
                          value={
                            files.length <= 0 ? (
                              <Typography>
                                {t("Keine Nachweise vorhanden.")}
                              </Typography>
                            ) : (
                              <Grid container spacing={1}>
                                {files.map((file, index) => (
                                  <Grid item key={index} xs={6} sm={4}>
                                    <Thumbnail
                                      preview={file}
                                      onDelete={
                                        correctionLocked
                                          ? undefined
                                          : () => removeFile(file)
                                      }
                                      onView={
                                        file.image
                                          ? () =>
                                              openAttachment(
                                                file as AttachmentPreview
                                              )
                                          : undefined
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            )
                          }
                        />
                        <Box mt={1}>
                          <Button
                            fullWidth
                            color="secondary"
                            component="label"
                            disabled={correctionLocked || files.length > 4}
                            startIcon={<Add />}
                            variant="contained"
                          >
                            {t("Hinzufügen")}
                            <input
                              hidden
                              multiple
                              id="attachment.fileUpload"
                              accept="image/*, application/pdf"
                              disabled={files.length > 4}
                              type="file"
                              onChange={(event) =>
                                addFiles(event.currentTarget.files)
                              }
                            />
                          </Button>
                        </Box>
                        <Typography variant="caption">
                          {t("1-5 Bilder oder PDF Dokumente auswählen")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {!correctionLocked && (
                          <Alert severity="warning">
                            <AlertTitle>{t("Freigabe")}</AlertTitle>
                            {t(
                              "Verifizierung durch eine fachkundige Person notwendig."
                            )}
                          </Alert>
                        )}
                        {correctionLocked && data.verified && (
                          <Alert severity="success">
                            <AlertTitle>{t("Freigabe erfolgreich")}</AlertTitle>
                            {t(
                              "Mangelbehebung wurde durch eine fachkundige Person verifiziert."
                            )}
                          </Alert>
                        )}
                        {correctionLocked && data.verified === false && (
                          <Alert severity="error">
                            <AlertTitle>{t("Freigabe abgelehnt")}</AlertTitle>
                            {t(
                              "Mangelbehebung wurde durch eine fachkundige Person abgelehnt."
                            )}
                          </Alert>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("Schließen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={(event) => {
            if (
              data.defectCorrected &&
              !defectCorrected &&
              !window.confirm(t(markCorrectedDefectAsUncorrectConfirmation))
            ) {
              return;
            }

            return handleSubmit((values) => onSubmitAsync(values))(event);
          }}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};
export default ItemDocumentationDetailDialog;
