import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { getPersonFormValues } from "../../model";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import { useLocation } from "react-router-dom";
import { PersonFormFields } from "../../components/person-form-fields";
import { usePersonCreateMutation, usePersonForm } from "../../hooks";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyClient } from "../../lib/security";

const CreatePerson: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neue Person"));
  const location = useLocation<{ name?: string }>();
  const user = useCurrentUser();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Personen"), link: routes.persons },
    { label: t("Neu") },
  ];

  const personCompany = isOnlyClient(user)
    ? user.person?.primaryCompany || undefined
    : undefined;

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    trigger,
    watch,
    setValue,
    getValues,
  } = usePersonForm({
    ...getPersonFormValues(),
    primaryCompany: personCompany
      ? personCompany.id
      : getPersonFormValues().primaryCompany,
  });

  const { mutateAsync: createMutation } = usePersonCreateMutation(setError);

  useEffect(() => {
    if (!location.state || !location.state.name) {
      return;
    }
    const [firstName, lastName] = location.state.name.split(" ");
    reset({
      firstName,
      lastName,
    });
    setTimeout(() => trigger(["firstName", "lastName"]));
  }, [location.state, reset, trigger]);

  return (
    <Container maxWidth="md">
      <Header title={t("Personen")} breadcrumbs={breadcrumbs} />
      <Card>
        <PersonFormFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          company={personCompany}
        />
        <Divider />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={handleSubmit((values) => createMutation(values))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default CreatePerson;
