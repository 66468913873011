import { useStandardValidation } from "./use-standard-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useStandardValidationSchema = () => {
  const standardValidation = useStandardValidation();

  return useMemo(() => {
    return yup.object({
      ...standardValidation,
    });
  }, [standardValidation]);
};
