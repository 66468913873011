import React, { VoidFunctionComponent } from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue, green, grey } from "@mui/material/colors";
import { CheckProcessState } from "../../model";

const CheckProcessStatus: VoidFunctionComponent<{ status: string }> = ({
  status,
}) => {
  const { t } = useTranslation();

  switch (status) {
    case CheckProcessState.Draft:
      return (
        <Chip
          label={t(`checkProcess.status.${CheckProcessState.Draft}`)}
          style={{ backgroundColor: blue[500], color: "white" }}
          size={`small`}
        />
      );
    case CheckProcessState.Approved:
      return (
        <Chip
          label={t(`checkProcess.status.${CheckProcessState.Approved}`)}
          style={{ backgroundColor: green[500], color: "white" }}
          size={`small`}
        />
      );
    case CheckProcessState.Inactive:
      return (
        <Chip
          label={t(`checkProcess.status.${CheckProcessState.Inactive}`)}
          style={{ backgroundColor: grey[500], color: "white" }}
          size={`small`}
        />
      );
  }

  return <Chip label={t("Kein Status")} size={`small`} />;
};

export default CheckProcessStatus;
