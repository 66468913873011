import { useTitle } from "../../hooks/use-title";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import Header from "../../components/header";
import {
  ApiError,
  Category,
  getQualificationTagFormValues,
  QualificationTagFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { StyledCardTitle } from "../../components/globals";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import LoadingButton from "../../components/loading-button";
import { useQualificationTagValidationSchema } from "../../hooks/validations/qualification-tags/use-qualification-tag-validation-schema";

const CreateQualificationTag = () => {
  const { t } = useTranslation();
  useTitle(t("Neuer Qualifikationen-Tag"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikationen-Tag"), link: routes.qualificationTags },
    { label: t("Neu") },
  ];

  const validationSchema = useQualificationTagValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<QualificationTagFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getQualificationTagFormValues(),
  });

  const onSubmit = async (
    values: QualificationTagFormValues,
    setError: UseFormSetError<QualificationTagFormValues>
  ) => {
    await request<Category>(apiRoutes.qualificationTagCreate, "post", values)
      .then(() => {
        enqueueSnackbar(t("Qualifikationen-Tag wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.qualificationTags);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Qualifikationen-Tag")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Qualifikationen-Tag")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"name"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Name")}
                      fullWidth
                      {...field}
                      required
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateQualificationTag;
