import {
  Blameable,
  Commentable,
  Grantable,
  ID,
  Location,
  Order,
  Timestampable,
} from "./";
import { BillingPreview } from "./billing";

// Sync with CompanyVoter in backend
export enum CompanyGrant {
  VIEW = "view",
  VIEW_BILLING = "viewBilling",
  VIEW_DESIGN = "viewDesign",
  VIEW_MAPPED = "viewMapped",
  VIEW_TEST_PARTNERS = "viewTestPartners",
  UPDATE = "update",
  UPDATE_FULL = "update-full",
  DELETE = "delete",
  CREATE = "create",
}

export interface Company
  extends CompanyPreview,
    Timestampable,
    Blameable,
    Commentable,
    Grantable<CompanyGrant> {
  country: string;
  customerNumber: string | null;
  customerGroup: string | null;
  supplierNumber: string | null;
  status: string | null;
  customer: boolean;
  supplier: boolean;
  manufacturer: boolean;
  partner: boolean;
  distributor: boolean;
  opinionLeader: boolean;
  trainingCenter: boolean;
  testPartner: boolean;
  importer: boolean;
  parent: Company | null;
  companies: Company[];
  orders: Order[];
  locations?: Location[];
  portfolio?: Company[];
  testPartners?: Company[];
  owner: Company | null;
  itemCount: number;
  orderCount: number;
  registerNumber: string | null;
  uidNumber: string | null;
  phone: string | null;
  email: string | null;
  website: string | null;
  additionalAddressInformation: string | null;
  billing?: BillingPreview | null;
  logo?: string | null;
  footer?: string | null;
  seal?: string | null;
}

export interface CompanyPreview {
  id: ID;
  name: string;
  term: string;
  street: string;
  zipCode: string;
  city: string;
}

export interface CompanyOption {
  id: ID;
  name: string;
  term?: string;
  create?: boolean;
}

export enum CompanyType {
  Customer = "customer",
  Supplier = "supplier",
  Manufacturer = "manufacturer",
  Partner = "partner",
  Distributor = "distributor",
  OpinionLeader = "opinionLeader",
  TestPartner = "testPartner",
  Importer = "importer",
  TrainingCenter = "trainingCenter",
}

export interface CompanyFormValues {
  name: string;
  term: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  comment: string;
  customer: boolean;
  supplier: boolean;
  manufacturer: boolean;
  partner: boolean;
  trainingCenter: boolean;
  distributor: boolean;
  opinionLeader: boolean;
  testPartner: boolean;
  importer: boolean;
  customerGroup: string | null;
  customerNumber: string | null;
  supplierNumber: string | null;
  parent: ID | null;
  locations: ID[];
  portfolio: ID[];
  status: string | null;
  testPartners: ID[];
  owner: ID | null;
  registerNumber: string;
  uidNumber: string;
  phone: string;
  email: string;
  website: string;
  additionalAddressInformation: string;
  logoUpload?: File | null;
  footerUpload?: File | null;
  sealUpload?: File | null;
  deleteLogo?: boolean;
  deleteSeal?: boolean;
  deleteFooter?: boolean;
}

export interface CompanyFilters {
  types: string[];
  search: string;
  zipCode: string;
  city: string;
  country: string;
  origin: string;
}

export const getCompanyFormValues = (company?: Company): CompanyFormValues => ({
  name: company?.name || "",
  term: company?.term || "",
  street: company?.street || "",
  zipCode: company?.zipCode || "",
  city: company?.city || "",
  country: company?.country || "",
  comment: company?.comment || "",
  customer: company?.customer || false,
  supplier: company?.supplier || false,
  manufacturer: company?.manufacturer || false,
  partner: company?.partner || false,
  trainingCenter: company?.trainingCenter || false,
  distributor: company?.distributor || false,
  opinionLeader: company?.opinionLeader || false,
  testPartner: company?.testPartner || false,
  importer: company?.importer || false,
  customerGroup: company?.customerGroup || null,
  customerNumber: company?.customerNumber || null,
  supplierNumber: company?.supplierNumber || null,
  status: company?.status || "",
  parent: company?.parent?.id || null,
  locations: company?.locations
    ? company.locations.map((location) => location.id)
    : [],
  portfolio: company?.portfolio
    ? company.portfolio.map((company) => company.id)
    : [],
  testPartners: company?.testPartners
    ? company.testPartners.map((company) => company.id)
    : [],
  owner: company?.owner?.id || null,
  registerNumber: company?.registerNumber || "",
  uidNumber: company?.uidNumber || "",
  phone: company?.phone || "",
  email: company?.email || "",
  website: company?.website || "",
  additionalAddressInformation: company?.additionalAddressInformation || "",
  logoUpload: null,
  footerUpload: null,
  sealUpload: null,
  deleteLogo: false,
  deleteSeal: false,
  deleteFooter: false,
});
