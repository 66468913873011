import { Switch, useRouteMatch } from "react-router-dom";
import { routes } from "../../lib/routes";
import CheckProcessesOverview from "./overview";
import CheckProcessDetails from "./details";
import GuardedRoute from "../../components/guarded-route";
import CreateCheckProcess from "./create";
import { Role } from "../../model";
import EditCheckProcess from "./edit";

const CheckProcesses = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <GuardedRoute exact path={path} requiredRole={Role.QA}>
        <CheckProcessesOverview />
      </GuardedRoute>
      <GuardedRoute path={routes.checkProcessCreate} requiredRole={Role.QA}>
        <CreateCheckProcess />
      </GuardedRoute>
      <GuardedRoute
        path={routes.checkProcessEdit(":checkProcessId")}
        requiredRole={Role.QA}
      >
        <EditCheckProcess />
      </GuardedRoute>
      <GuardedRoute
        path={routes.checkProcess(":checkProcessId")}
        requiredRole={Role.QA}
      >
        <CheckProcessDetails />
      </GuardedRoute>
    </Switch>
  );
};

export default CheckProcesses;
