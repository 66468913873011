import { useMemo } from "react";
import * as yup from "yup";
import { useCategoryValidation } from "./use-category-validation";

export const useCategoryValidationSchema = () => {
  const categoryValidation = useCategoryValidation();

  return useMemo(() => {
    return yup.object({
      ...categoryValidation,
    });
  }, [categoryValidation]);
};
