import React, { FunctionComponent } from "react";
import { DateFormat, HeadCell, Item, ItemCheck, Role } from "../../model";
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edit, PictureAsPdf } from "@mui/icons-material";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import ItemCheckResult from "../item-check-result";
import { TableNoResult } from "../table-no-result";
import { red } from "@mui/material/colors";
import FormattedDate from "../formatted-date";

const ItemCheckItemsList: FunctionComponent<{
  itemChecks: ItemCheck[];
  loading?: boolean;
  onEditItemCheckClick?: (itemCheck: ItemCheck) => void;
  showReport: boolean;
}> = ({ itemChecks, loading, onEditItemCheckClick, showReport }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<Item & { parentLocation: unknown }>[] = [
    { id: "category", label: t("Kategorie") },
    { id: "serialNumber", label: t("Seriennummer") },
    { id: "product", label: t("Modell") },
    { id: "manufacturerName", label: t("Hersteller") },
    { id: "personName", label: t("Verwender") },
    { id: "location", label: t("Abteilung") },
    { id: "parentLocation", label: t("Übergeordnete Abteilung") },
    { id: "lastCheckDate", label: t("Letztes Prüfdatum") },
    { id: "lastCheckedBy", label: t("Überprüfer") },
    { id: "lastCheckResult", label: t("Letztes Prüfurteil") },
  ];

  return (
    <>
      <TableContainer style={{ maxHeight: `800px` }}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={false}
                >
                  <>{headCell.label}</>
                </TableCell>
              ))}
              <TableCell>{showReport ? t("Protokoll") : ""}</TableCell>
            </TableRow>
          </TableHead>
          {!loading ? (
            <>
              <TableBody>
                {itemChecks.map((itemCheck) => (
                  <TableRow key={itemCheck.id} hover={true}>
                    <TableCell style={{ maxWidth: "200px" }}>
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {itemCheck.item.product?.category ? (
                          itemCheck.item.product.category.name
                        ) : (
                          <Chip
                            label={t("Nicht zugewiesen")}
                            style={{
                              backgroundColor: red[500],
                              color: "white",
                            }}
                            size={`small`}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{itemCheck.item.serialNumber}</TableCell>
                    <TableCell>
                      {itemCheck.item.product ? (
                        itemCheck.item.product.model
                      ) : (
                        <i>{itemCheck.item.productName}</i>
                      )}
                    </TableCell>
                    <TableCell>
                      {itemCheck.item.product?.manufacturer ? (
                        itemCheck.item.product.manufacturer.name
                      ) : (
                        <i>{itemCheck.item.manufacturerName}</i>
                      )}
                    </TableCell>
                    <TableCell>
                      {itemCheck.person
                        ? itemCheck.person.firstName +
                          " " +
                          itemCheck.person.lastName
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {itemCheck.location ? itemCheck.location.name : "-"}
                    </TableCell>
                    <TableCell>
                      {itemCheck.location?.parent
                        ? itemCheck.location.parent?.name
                        : "-"}
                    </TableCell>
                    <CompactTableCell>
                      {itemCheck.item.lastCheck ? (
                        <FormattedDate
                          date={itemCheck.item.lastCheck.checkDate}
                          format={DateFormat.Default}
                        />
                      ) : (
                        "-"
                      )}
                    </CompactTableCell>
                    <CompactTableCell>
                      {itemCheck.item.lastCheck?.checkedBy
                        ? itemCheck.item.lastCheck.checkedBy.firstName +
                          " " +
                          itemCheck.item.lastCheck.checkedBy.lastName
                        : "-"}
                    </CompactTableCell>
                    <CompactTableCell>
                      <ItemCheckResult
                        result={
                          itemCheck.item.lastCheck
                            ? itemCheck.item.lastCheck.checkResult
                            : null
                        }
                      />
                    </CompactTableCell>
                    <CompactTableCell>
                      <Guarded requiredRole={[Role.Admin, Role.Tester]}>
                        <>
                          {showReport &&
                          itemCheck.item.lastCheck?.checkReport ? (
                            <>
                              <a
                                href={itemCheck.item.lastCheck.checkReport.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <IconButton size="large">
                                  <PictureAsPdf />
                                </IconButton>
                              </a>
                            </>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={() => {
                                if (!onEditItemCheckClick) {
                                  throw new Error(
                                    `Missing onEditItemClick callback`
                                  );
                                }
                                onEditItemCheckClick(itemCheck);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </>
                      </Guarded>
                    </CompactTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {!itemChecks.length && (
                <TableNoResult text={t("Es wurden keine Items gefunden.")} />
              )}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={100}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default ItemCheckItemsList;
