import { useTitle } from "../../hooks/use-title";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import React from "react";
import Header from "../../components/header";
import { useItemCheckDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { StyledContainer } from "../../components/globals";
import { DateFormat, Role } from "../../model";
import { ModifiedEntity } from "../../components/modified-entity";
import { format as dateFormat, isValid, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import Guarded from "../../components/guarded";
import { Edit } from "@mui/icons-material";
import { ItemCheckDetails as ItemCheckData } from "../../components/item-check-details";

const ItemCheckDetails = () => {
  const { t } = useTranslation();
  const { itemCheckId } = useParams<{ itemCheckId: string | undefined }>();
  const { isLoading, error, data } = useItemCheckDetailsApi(
    itemCheckId as unknown as number
  );
  useTitle(data?.item?.serialNumber || t("Item Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.items}>
            {t("Zurück zu Items")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data || !data.item) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    {
      label: t("Item") + " " + data.item.serialNumber,
      link: routes.item(data.item.id),
    },
    {
      label:
        t("Prüfung") +
        " " +
        (isValid(parseISO(data.checkDate))
          ? dateFormat(parseISO(data.checkDate), DateFormat.Default, {
              locale: de,
            })
          : ""),
    },
  ];

  return (
    <StyledContainer>
      <Header
        title={t("Item Prüfung")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.SuperAdmin}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.itemCheckEdit(data.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <ItemCheckData itemCheck={data} />
    </StyledContainer>
  );
};

export default ItemCheckDetails;
