import axios, { AxiosError, CancelToken, Method } from "axios";
import {
  Category,
  CheckGroup,
  CheckProcess,
  CheckStep,
  Company,
  CurrentUser,
  Document,
  HttpStatus,
  ID,
  Item,
  ItemCheck,
  ItemDocumentation,
  Location,
  Log,
  Order,
  Person,
  PersonQualification,
  Product,
  Qualification,
  QualificationTag,
  SearchResult,
  Standard,
  User,
} from "../model";
import { useApi } from "../hooks/use-api";
import { routes } from "./routes";
import { config } from "../config";

export const apiRoutes = {
  login: "login",
  logout: "logout",
  requestPasswordReset: "request-password-reset",
  resetPassword: "reset-password",
  search: (query: string) => `search?q=${query}`,

  profile: {
    me: "profile/me",
    changePassword: "profile/password",
    selectCompany: "profile/select-company",
  },

  users: "users",
  user: (id: ID) => `users/${id}`,
  userCreate: "users/create",
  sendSupportEmail: (id: ID) => `users/${id}/send-support-email`,

  logs: "logs",
  log: (id: number) => `logs/${id}`,

  companies: "companies",
  company: (id: ID) => `companies/${id}`,
  companyCreate: "companies/create",
  companyCount: "companies/count",
  companyParents: (id: ID) => `companies/${id}/parents`,
  companyLocations: (id: ID) => `companies/${id}/locations`,
  companyPersons: (id: ID) => `companies/${id}/persons`,
  companyPrimaryPersons: (id: ID) => `companies/${id}/primary-persons`,
  comapanyBilling: (id: ID) => `companies/${id}/billing`,
  companyReportPreview: (id: ID) => `companies/${id}/report-preview`,

  locations: "locations",
  location: (id: ID) => `locations/${id}`,
  locationCreate: "locations/create",
  locationParents: (id: ID) => `locations/${id}/parents`,

  orders: "orders",
  order: (id: ID) => `orders/${id}`,
  orderCreate: "orders/create",
  orderComplete: (id: ID) => `orders/${id}/complete`,
  orderReport: (id: ID) => `orders/${id}/report`,
  orderOpenChecks: (id: ID) => `orders/${id}/open-checks`,

  checkReports: "check-reports",
  checkReportsExport: "check-reports/export",

  categories: "categories",
  category: (id: ID) => `categories/${id}`,
  categoryCreate: "categories/create",
  categoryParents: (id: ID) => `categories/${id}/parents`,

  products: "products",
  product: (id: number) => `products/${id}`,
  productVerify: (id: number) => `products/${id}/verify`,
  productCreate: "products/create",
  productImport: "products/import",

  standards: "standards",
  standard: (id: ID) => `standards/${id}`,
  standardSubstitutes: (id: ID) => `standards/substitutes/${id}`,
  standardDuplicateNumberSubstitutes: (id: ID) =>
    `standards/substitutes/${id}/duplicate`,
  standardAvailableSubstitutes: (id: ID) =>
    `standards/substitutes/${id}/available`,
  standardSetSubstitute: (id: ID, substituteId: ID) =>
    `standards/${id}/substitute/${substituteId}`,
  standardCreate: "standards/create",
  standardUsers: "standards/users",

  documents: "documents",
  document: (id: ID) => `documents/${id}`,
  documentCreate: "documents/create",
  documentFile: (id: ID) => `documents/${id}/file`,

  persons: "persons",
  person: (id: ID) => `persons/${id}`,
  personCreate: "persons/create",

  personQualifications: "person-qualifications",
  personQualification: (id: ID) => `person-qualifications/${id}`,
  personQualificationCreate: "person-qualifications/create",

  items: "items",
  item: (id: ID) => `items/${id}`,
  itemArchive: (id: ID) => `items/${id}/archive`,
  itemCreate: "items/create",
  itemImport: "items/import",
  itemExport: "items/export",

  itemCheck: (id: ID) => `item-checks/${id}`,
  itemCheckPartialUpdate: (id: ID) => `item-checks/${id}/partial`,

  checkProcesses: "check-processes",
  checkProcess: (id: ID) => `check-processes/${id}`,
  checkProcessCreate: "check-processes/create",
  checkProcessGroups: (id: ID) => `check-processes/${id}/check-groups`,
  checkProcessApprove: (id: ID) => `check-processes/${id}/approve`,
  checkProcessDuplicate: (id: ID) => `check-processes/${id}/duplicate`,

  checkGroup: (id: ID) => `check-groups/${id}`,
  checkGroupCreate: "check-groups/create",

  checkSteps: "check-steps",
  checkStep: (id: ID) => `check-steps/${id}`,
  checkStepCreate: "check-steps/create",
  checkStepConditions: (id: ID) => `check-steps/${id}/conditions`,

  checks: "checks",
  check: (id: ID) => `checks/${id}`,
  checkItem: (check: ID, item: ID) => `checks/${check}/item/${item}`,
  checkComplete: (id: ID) => `checks/${id}/complete`,

  qualificationTags: "qualification-tags",
  qualificationTag: (id: number) => `qualification-tags/${id}`,
  qualificationTagCreate: "qualification-tags/create",

  qualifications: "qualifications",
  qualification: (id: number) => `qualifications/${id}`,
  qualificationConditions: (id: number) => `qualifications/${id}/conditions`,
  qualificationCreate: "qualifications/create",

  defects: "defects",
  defect: (id: number) => `defects/${id}`,
  defectCreate: "defects/create",

  actions: "actions",
  action: (id: number) => `actions/${id}`,
  actionCreate: "actions/create",

  itemDocumentation: (id: number) => `item-documentations/${id}`,

  queueTasks: `queue-tasks`,
  queueTaskStatus: `queue-tasks/status`,
  queueTasksDownload: (id: number) => `queue-tasks/${id}`,
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err: AxiosError) => {
    if (err.response?.status !== HttpStatus.Unauthorized) {
      throw err;
    }
    const api = process.env.REACT_APP_API_URL;
    if (
      [`${api}/${apiRoutes.profile.me}`, `${api}/${apiRoutes.login}`].includes(
        err.response.config.url || ""
      )
    ) {
      return;
    }
    window.location.href = routes.dashboard;
  }
);

export function getAbsoluteApiUrl(url: string) {
  return `${process.env.REACT_APP_API_URL}/${url}`;
}

export async function request<TResponse>(
  url: string,
  method: Method = "get",
  data?: any,
  params?: any,
  headers: object = {},
  cancelToken?: CancelToken
) {
  if (config.locale.sendAcceptLanguageHeader) {
    headers = {
      ...headers,
      "Accept-Language": config.locale.defaultLocale,
    };
  }
  return axios.request<TResponse>({
    method,
    url: `${process.env.REACT_APP_API_URL}/${url}`,
    data,
    params,
    headers,
    cancelToken,
    withCredentials: true,
  });
}

export async function getUser(): Promise<CurrentUser | null> {
  try {
    const user = await request<CurrentUser>(apiRoutes.profile.me);
    return user.data;
  } catch (e) {
    return null;
  }
}

export async function logout(): Promise<unknown> {
  return await request(apiRoutes.logout);
}

export const useUserApi = () => useApi<User[]>(apiRoutes.users);
export const useUserDetailsApi = (id: ID) => useApi<User>(apiRoutes.user(id));
export const useLogDetailsApi = (id: ID) => useApi<Log[]>(apiRoutes.log(id));
export const useSearchApi = (query: string) =>
  useApi<SearchResult[]>(apiRoutes.search(query));
export const useCompanyApi = () => useApi<Company[]>(apiRoutes.companies);
export const useCompanyDetailsApi = (id: ID) =>
  useApi<Company>(apiRoutes.company(id));
export const useOrderDetailsApi = (id: ID) =>
  useApi<Order>(apiRoutes.order(id));
export const useCategoryDetailsApi = (id: ID) =>
  useApi<Category>(apiRoutes.category(id));
export const useProductDetailsApi = (id: ID) =>
  useApi<Product>(apiRoutes.product(id));
export const useDocumentDetailsApi = (id: ID) =>
  useApi<Document>(apiRoutes.document(id));
export const useStandardDetailsApi = (id: ID) =>
  useApi<Standard>(apiRoutes.standard(id));
export const usePersonDetailsApi = (id: ID) =>
  useApi<Person>(apiRoutes.person(id));
export const useLocationDetailsApi = (id: ID) =>
  useApi<Location>(apiRoutes.location(id));
export const useItemDetailsApi = (id: ID) => useApi<Item>(apiRoutes.item(id));
export const useCheckStepDetailsApi = (id: ID) =>
  useApi<CheckStep>(apiRoutes.checkStep(id));
export const useCheckProcessDetailsApi = (id: ID) =>
  useApi<CheckProcess>(apiRoutes.checkProcess(id));
export const useCheckGroupDetailsApi = (id: ID) =>
  useApi<CheckGroup>(apiRoutes.checkGroup(id));
export const useItemCheckDetailsApi = (id: ID) =>
  useApi<ItemCheck>(apiRoutes.itemCheck(id));
export const useQualificationTagDetailsApi = (id: ID) =>
  useApi<QualificationTag>(apiRoutes.qualificationTag(id));
export const useQualificationDetailsApi = (id: ID) =>
  useApi<Qualification>(apiRoutes.qualification(id));
export const usePersonQualificationApi = (id: ID) =>
  useApi<PersonQualification>(apiRoutes.personQualification(id));
export const useOrderOpenChecksApi = (id: ID) =>
  useApi<Person[]>(apiRoutes.orderOpenChecks(id));
export const useItemDocumentationDetailsApi = (id: ID) =>
  useApi<ItemDocumentation>(apiRoutes.itemDocumentation(id));
