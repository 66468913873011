import { Route, Switch, useRouteMatch } from "react-router-dom";
import OrdersOverview from "./overview";
import { routes } from "../../lib/routes";
import OrderDetails from "./details";
import GuardedRoute from "../../components/guarded-route";
import { AppGrant, Role } from "../../model";
import EditOrder from "./edit";
import CreateOrder from "./create";
import OrderReport from "./report";

const Orders = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <OrdersOverview />
      </Route>
      <GuardedRoute path={routes.orderCreate} grant={AppGrant.ORDER_CREATE}>
        <CreateOrder />
      </GuardedRoute>
      <GuardedRoute
        path={routes.orderEdit(":orderId")}
        requiredRole={[Role.Admin, Role.QA, Role.CEO, Role.Tester]}
      >
        <EditOrder />
      </GuardedRoute>
      <GuardedRoute
        path={routes.orderReport(":orderId")}
        requiredRole={[Role.Admin, Role.QA, Role.CEO, Role.Tester]}
      >
        <OrderReport />
      </GuardedRoute>
      <GuardedRoute
        path={routes.order(":orderId")}
        requiredRole={[Role.Admin, Role.QA, Role.CEO, Role.Client, Role.Tester]}
      >
        <OrderDetails />
      </GuardedRoute>
    </Switch>
  );
};

export default Orders;
