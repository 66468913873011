import {
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  Tooltip,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { apiRoutes, request } from "../../lib/api";
import { useSnackbar } from "notistack";
import { PersonPin } from "@mui/icons-material";
import UserDropdown from "../user-dropdown";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useTranslation } from "react-i18next";
import UserAvatar from "../user-avatar";
import { grey } from "@mui/material/colors";
import { useQueryUser } from "../../hooks/use-query-user";

const StyledActionsContainer = styled.div`
  margin: auto 0;
`;

const StyledHeader = styled.div`
  min-height: 63px;
  color: white;
  background-color: ${grey[700]};
  padding: ${(props) => props.theme.spacing(2)};
`;

const StyledDrawerContent = styled.div`
  width: ${(props) => props.theme.navigation.width}px;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoContainer = styled(StyledContainer)`
  margin: ${(props) =>
    `${props.theme.spacing(1.5)} ${props.theme.spacing(
      0
    )} ${props.theme.spacing(0)}`};
`;

const StyledUserName = styled.h3`
  margin: ${(props) => props.theme.spacing(0)};
`;

const Navigation: FunctionComponent = ({ children }) => {
  const user = useCurrentUser();
  useQueryUser(user);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const exitImpersonate = () => {
    request(apiRoutes.profile.me, "get", {}, { _switch_user: "_exit" })
      .then(() => window.location.reload())
      .catch(() =>
        enqueueSnackbar(t("Imitieren ist fehlgeschlagen."), {
          variant: "error",
        })
      );
  };

  return (
    <Drawer variant="permanent" anchor="left">
      <StyledDrawerContent>
        <StyledHeader>
          <StyledContainer>
            <UserAvatar user={user} />
            <StyledActionsContainer>
              {user.impersonator && (
                <Tooltip
                  title={
                    <>
                      {t("Imitieren von")} <strong>{user.username}</strong>{" "}
                      {t("als")} <strong>{user.impersonator.username}</strong>.{" "}
                      {t("Klicke, um das imitieren zu beenden")}.
                    </>
                  }
                >
                  <IconButton
                    color="inherit"
                    onClick={exitImpersonate}
                    size="large"
                  >
                    <Badge badgeContent="!" color="secondary">
                      <PersonPin />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </StyledActionsContainer>
          </StyledContainer>
          <StyledInfoContainer>
            <div>
              <StyledUserName>{user.fullName ?? user.username}</StyledUserName>
              <small>
                {user.roles.map((role) => t(`role.${role}`)).join(", ")}
              </small>
            </div>
            <UserDropdown />
          </StyledInfoContainer>
        </StyledHeader>
        <Divider />
        <List>{children}</List>
      </StyledDrawerContent>
    </Drawer>
  );
};

export default Navigation;
