import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const useItemDocumentationDetailValidation = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return yup.object({
      defectCorrectedAt: yup.mixed().when("defectCorrected", {
        is: (defectCorrected: boolean) => defectCorrected,
        then: yup
          .date()
          .nullable()
          .required(t("Datum der Mangelbehebung darf nicht leer sein")),
      }),
    });
  }, [t]);
};
