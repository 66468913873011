import { styled } from "@mui/material/styles";
import {
  Box,
  CardContent,
  CardContentProps,
  Container,
  DialogContent,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  TableCell,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  VoidFunctionComponent,
} from "react";
import { Link } from "react-router-dom";
import { VerifiedUser } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { CustomTheme } from "../../styles/theme";

export const FilterFormGroup = styled(FormGroup)`
  align-items: center;
`;

export const FilterFormControl = styled(FormControl)`
  min-width: 120px;
  margin-right: 0.5em;
`;

export const CompactTableCell = styled(TableCell)`
  white-space: nowrap;
  width: 1px;
`;

export const DatePickerSmall = styled(DatePicker)`
  label {
    transform: translate(14px, 13px) scale(1);
  }

  input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
`;

export const Definition: VoidFunctionComponent<{
  title: string;
  value: any;
}> = ({ title, value }) => (
  <>
    <Typography variant="overline" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="body1">{value || "Keine Angabe"}</Typography>
  </>
);

// export const StyledContainer = styled(Container)`
//   width: ${(props) => props.theme.width.full};
//   max-width: none;
// `;

export const StyledContainer = ({
  children,
  ...props
}: {
  children: ReactNode;
}) => {
  return (
    <Container
      sx={{ width: "100% !important", maxWidth: "none !important" }}
      {...props}
    >
      {children}
    </Container>
  );
};

export const StyledCardTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)};
  font-weight: ${(props) => props.theme.typography?.fontWeightRegular};
  display: flex;
`;

export const StyledNotFound = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

export const DisabledIconButton = styled(IconButton)`
  color: rgba(0, 0, 0, 0.25);
`;

export const TabPanel: FunctionComponent<{
  index: number;
  value: number;
}> = ({ index, value, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <>{children}</>}
  </div>
);

export const StyledHighlightedGrid = styled(Grid)`
  background-color: rgba(0, 103, 127, 0.1);
  padding: 1em;
`;

export const StyledLoadingDialogContent = ({
  children,
  ...props
}: {
  children: ReactNode;
}) => (
  <DialogContent
    sx={{ minHeight: "400px", display: "flex", alignItems: "center" }}
    {...props}
  >
    {children}
  </DialogContent>
);

export const StyledDialogContent = ({
  children,
  ...props
}: {
  children: ReactNode;
}) => (
  <DialogContent sx={{ paddingBottom: "1em" }} {...props}>
    {children}
  </DialogContent>
);

export const StyledColoredCardContent = ({
  children,
  ...props
}: {
  children: ReactNode;
} & CardContentProps) => (
  <CardContent
    sx={{ backgroundColor: () => CustomTheme.color?.lightBackground }}
    {...props}
  >
    {children}
  </CardContent>
);

export const UnstyledList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyleType: "none",
});

export const StyledLink = styled(Link)`
  margin-right: 1em;
`;

export const StyledHeadline = styled(Typography)`
  font-weight: ${(props) => props.theme.typography?.fontWeightBold};
  text-decoration: underline;
  color: ${(props) =>
    props.theme.palette.mode === "light"
      ? "rgb(0, 0, 0, 0.54)"
      : "rgb(255, 255, 255, 0.64)"};
  letter-spacing: 0.08333em;
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: 1em;
`;

export const textEllipsisStyles: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const ClickableThumbnailContainer = styled(Box)`
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[5]};
  }
`;

export const StyledVerifedIcon = styled(VerifiedUser)`
  color: ${() => blue[500]};
`;

export const StyledLogo = styled("img")({
  maxHeight: 150,
  maxWidth: 150,
  height: "auto",
  width: "auto",
});

export const StyledFooter = styled("img")({
  maxHeight: "140px",
  maxWidth: "100%",
  height: "auto",
  width: "auto",
});

export const capitalizeStyle = {
  textTransform: "capitalize",
};
