import React, { FunctionComponent } from "react";
import { CheckStep, CheckStepFilters, HeadCell, Role } from "../../model";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { TableNoResult } from "../table-no-result";
import { IconLink } from "../icon-link";
import { routes } from "../../lib/routes";
import { Check, Edit, Visibility } from "@mui/icons-material";
import Guarded from "../guarded";
import { CompactTableCell } from "../globals";

const CheckStepList: FunctionComponent<{
  checkSteps: CheckStep[];
  loading?: boolean;
  params?: FilterParams<CheckStepFilters, CheckStep>;
  createSortHandler?: SortHandler<CheckStep>;
}> = ({ checkSteps, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<CheckStep>[] = [
    { id: "title", label: t("Titel") },
    { id: "type", label: t("Typ") },
    { id: "securityRelated", label: t("Sicherheitsrelevant") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {checkSteps.map((checkStep) => (
                <TableRow key={checkStep.id} hover={true}>
                  <TableCell>{checkStep.title}</TableCell>
                  <TableCell>{t(`checkStep.type.${checkStep.type}`)}</TableCell>
                  <TableCell>
                    {checkStep.securityRelated ? <Check /> : null}
                  </TableCell>
                  <CompactTableCell align={"right"}>
                    <IconLink
                      to={routes.checkStep(checkStep.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <Guarded requiredRole={Role.Admin}>
                      <IconLink
                        to={routes.checkStepEdit(checkStep.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {checkSteps.length === 0 && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CheckStepList;
