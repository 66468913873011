import { useTitle } from "../../hooks/use-title";
import Header from "../../components/header";
import { Container, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import GeneralProfileSettings from "./general";
import ChangePassword from "./change-password";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  useTitle(t("Profil"));

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Profil") },
  ];

  const [tab, setTab] = useState(0);
  return (
    <Container maxWidth="md" data-test="profile-content">
      <Header title={t("Profil")} breadcrumbs={breadcrumbs} />
      <Tabs value={tab} onChange={(e, v) => setTab(v)}>
        <Tab label={t("Profil")} component={Link} to={routes.profile} />
        <Tab
          label={t("Passwort ändern")}
          component={Link}
          to={routes.profilePassword}
        />
      </Tabs>
      <Switch>
        <Route path={routes.profilePassword}>
          <ChangePassword />
        </Route>
        <Route exact path={routes.profile}>
          <GeneralProfileSettings />
        </Route>
      </Switch>
    </Container>
  );
};

export default Profile;
