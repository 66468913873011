import { useForm } from "react-hook-form";
import { CompanyFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanyValidationSchema } from "../validations/companies/use-company-validation-schema";

export const useCompanyForm = (defaultValues: CompanyFormValues) => {
  const validationSchema = useCompanyValidationSchema();

  return useForm<CompanyFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
