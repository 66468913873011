import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const TableNoResult: FunctionComponent<{ text?: string }> = ({
  text,
}) => {
  const { t } = useTranslation();
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={100}>
          {text || t("Es wurden keine Suchergebnisse gefunden.")}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
