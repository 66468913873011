import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { apiRoutes, request, useCheckStepDetailsApi } from "../../lib/api";
import { useHistory } from "react-router";
import { useTitle } from "../../hooks/use-title";
import HttpError from "../../components/http-error";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { routes } from "../../lib/routes";
import LoadingContainer from "../../components/loading-container";
import Header from "../../components/header";
import Guarded from "../../components/guarded";
import { CheckStep, CheckStepType, Role } from "../../model";
import { Check, Delete, Edit } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import {
  Definition,
  StyledCardTitle,
  StyledContainer,
} from "../../components/globals";
import CheckStepBoolEditor from "../../components/check-step-bool-editor";

const CheckStepDetails: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { checkStepId } = useParams<{ checkStepId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useCheckStepDetailsApi(
    checkStepId as unknown as number
  );
  const [checkStep, setCheckStep] = useState<CheckStep | undefined>(undefined);
  const history = useHistory();

  useTitle(checkStep?.title || t("Prüfschritte Details"));
  useEffect(() => setCheckStep(data), [data]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.checkSteps}>
            {t("Zurück zu Prüfschritten")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data || !checkStep) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prüfschritte"), link: routes.checkSteps },
    { label: checkStep.title },
  ];

  const handleDelete = async () => {
    const confirm = window.confirm(
      t(`Wollen Sie diesen Prüfschritt {{title}} wirklich löschen?`, {
        title: checkStep.title,
      })
    );
    if (!confirm) {
      return;
    }
    await request(apiRoutes.checkStep(checkStep.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Prüfschritt wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.checkSteps);
      })
      .catch(() =>
        enqueueSnackbar(t("Prüfschritt konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <StyledContainer>
      <Header
        title={t("Prüfschritte")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.checkStepEdit(checkStep.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>
            <ModifiedEntity entity={checkStep} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Prüfschritt")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Definition title={t("Titel")} value={checkStep.title} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Typ")}
                    value={t(`checkStep.type.${checkStep.type}`)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Sicherheitsrelevant")}
                    value={checkStep.securityRelated ? <Check /> : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Definition
                    title={t("Beschreibung")}
                    value={checkStep.description}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {checkStep.type === CheckStepType.Bool && (
            <CheckStepBoolEditor
              checkStep={checkStep}
              onUpdate={(updatedCheckStep) => setCheckStep(updatedCheckStep)}
            />
          )}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default CheckStepDetails;
