import { DateFormat, Role, Standard } from "../../model";
import { useTitle } from "../../hooks/use-title";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Delete, Edit, FindInPage } from "@mui/icons-material";
import Header from "../../components/header";
import {
  apiRoutes,
  getAbsoluteApiUrl,
  request,
  useStandardDetailsApi,
} from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Definition, StyledCardTitle } from "../../components/globals";
import Guarded from "../../components/guarded";
import { ModifiedEntity } from "../../components/modified-entity";
import FormattedDate from "../../components/formatted-date";
import {
  StandardReviewStyledChip,
  StandardTypeStyledChip,
} from "components/standard-list";
import { format as dateFormat, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { useApi } from "../../hooks/use-api";
import CategoryTree from "../../components/category-tree";

export const StandardSubstitute: FunctionComponent<{
  standard: Standard;
  substitute: Standard;
  setStandard: any;
}> = ({ standard, substitute, setStandard }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSubstituteUpdate = () => {
    const confirm = window.confirm(
      t(
        `Wollen Sie den Standard '{{number}}' mit \n{{date}}{{created}} wirklich als Ersatz festlegen?`,
        {
          number: standard.number,
          date:
            standard.date &&
            t("Ausgabedatum") +
              " " +
              dateFormat(parseISO(standard.date), DateFormat.Default, {
                locale: de,
              }) +
              "\n",
          created:
            t("Erstelldatum") +
            " " +
            dateFormat(parseISO(standard.createdAt), DateFormat.Default, {
              locale: de,
            }) +
            "\n",
        }
      )
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.standardSetSubstitute(substitute.id, standard.id), "put")
      .then((res) => {
        enqueueSnackbar(t("Norm wurde erfolgreich als Ersatz festgelegt."), {
          variant: "success",
        });
        setStandard(res.data);
        history.replace(routes.standard(standard.id));
      })
      .catch(() =>
        enqueueSnackbar(t("Norm konnte nicht aktualisiert werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Alert
      variant="standard"
      severity="info"
      action={
        <Button
          variant={"outlined"}
          color="primary"
          size="small"
          onClick={handleSubstituteUpdate}
        >
          {t("Festlegen")}
        </Button>
      }
    >
      <AlertTitle>
        {t("Eine Norm mit dieser Nummer existiert bereits!")}
      </AlertTitle>
      {t("Norm")}{" "}
      <Link component={RouterLink} to={routes.standard(substitute.id)}>
        {substitute.number}
      </Link>
      {substitute.scope && <> ({substitute.scope})</>}
      {substitute.date && (
        <>
          {" "}
          {t("Ausgabedatum")}{" "}
          <FormattedDate date={substitute.date} format={DateFormat.Default} />
        </>
      )}
      <Typography variant={"body2"} gutterBottom={true}>
        {t("Die aktuelle Norm als Ersatz festlegen?")}
      </Typography>
    </Alert>
  );
};

const StandardDetails = () => {
  const { t } = useTranslation();
  const { standardId } = useParams<{ standardId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const [standard, setStandard] = useState<Standard | null>(null);
  const { isLoading, error, data } = useStandardDetailsApi(
    standardId as unknown as number
  );
  const {
    data: duplicateStandards,
    isLoading: duplicateStandardsLoading,
    error: duplicateStandardsError,
  } = useApi<Standard[]>(
    apiRoutes.standardDuplicateNumberSubstitutes(
      standardId as unknown as number
    )
  );

  const history = useHistory();

  useTitle(standard ? standard.number : standardId || t("Standard Details"));

  useEffect(() => {
    if (!data) {
      return;
    }

    setStandard(data);
  }, [data]);

  if (error || duplicateStandardsError) {
    return (
      <HttpError
        error={[error, duplicateStandardsError]}
        actions={
          <Button component={RouterLink} to={`/standards`}>
            {t("Zurück zu Normen")}
          </Button>
        }
      />
    );
  }

  if (
    isLoading ||
    duplicateStandardsLoading ||
    !data ||
    !standard ||
    !duplicateStandards
  ) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Norm"), link: routes.standards },
    { label: standard.number },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie das Standard {{number}} wirklich löschen?`, {
        number: standard.number,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.standard(standard.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Norm wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/standards");
      })
      .catch(() =>
        enqueueSnackbar(t("Norm konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={t("Normen")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.User}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.standardEdit(standard.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>
            </Guarded>
            <Guarded requiredRole={Role.Admin}>
              {" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>

            <ModifiedEntity entity={standard} />
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Norm")}
          </StyledCardTitle>
          {duplicateStandards && (
            <Grid container spacing={3}>
              {!standard.substitute &&
                duplicateStandards &&
                duplicateStandards.map((substitute) => (
                  <Grid item xs={12}>
                    <StandardSubstitute
                      standard={standard}
                      substitute={substitute}
                      key={substitute.id}
                      setStandard={setStandard}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Definition title={t("Nummer")} value={standard.number} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                {t("Typ")}
              </Typography>
              <StandardTypeStyledChip standard={standard} />
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Ausgabedatum")}
                value={
                  standard.date && (
                    <FormattedDate
                      date={standard.date}
                      format={DateFormat.Default}
                    />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Gültig bis")}
                value={
                  standard.validUntil && (
                    <FormattedDate
                      date={standard.validUntil}
                      format={DateFormat.Default}
                    />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Definition title={t("Geltungsbereich")} value={standard.scope} />
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Beschreibung")}
                value={standard.description}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Sonstiges")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Definition title={t("Notiz")} value={standard.comment} />
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Prüfintervall")}
                value={standard.reviewInterval}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={true}
                    checked={standard.complete}
                    readOnly={true}
                    name="complete"
                    color="primary"
                  />
                }
                label={t("Vollständig")}
              />
            </Grid>
            {standard.substitute && (
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {t("Ersetzt durch Norm")}
                </Typography>
                <Typography variant="body1">
                  <Link
                    component={RouterLink}
                    to={routes.standard(standard.substitute.id)}
                    key={standard.substitute.id}
                  >
                    {standard.substitute.number}
                  </Link>
                  {standard.substitute.date && (
                    <em>
                      {" "}
                      {t("Ausgabedatum")}{" "}
                      {dateFormat(
                        parseISO(standard.substitute.date),
                        DateFormat.Default,
                        { locale: de }
                      )}
                    </em>
                  )}
                </Typography>
              </Grid>
            )}
            {standard.substituteFor && (
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {t("Ersetzt Norm")}
                </Typography>
                <Typography variant="body1">
                  <Link
                    component={RouterLink}
                    to={routes.standard(standard.substituteFor.id)}
                    key={standard.substituteFor.id}
                  >
                    {standard.substituteFor.number}
                  </Link>
                  {standard.substituteFor.date && (
                    <em>
                      {" "}
                      {t("Ausgabedatum")}{" "}
                      {dateFormat(
                        parseISO(standard.substituteFor.date),
                        DateFormat.Default,
                        { locale: de }
                      )}
                    </em>
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Dateien")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                {t("Link")}
              </Typography>
              <div>
                {standard.link && (
                  <Link
                    component={RouterLink}
                    to={{ pathname: standard.link }}
                    sx={{ wordBreak: "break-all" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {standard.link}
                  </Link>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                {t("PDF")}
              </Typography>
              {standard.document && (
                <Box sx={{ wordBreak: "break-all" }}>
                  {standard.document.name}
                  <a
                    href={getAbsoluteApiUrl(
                      apiRoutes.documentFile(standard.document.id)
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton aria-label={t("Ansehen")} size="small">
                      <FindInPage />
                    </IconButton>
                  </a>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Prüfung")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Definition
                title={t("Prüfer")}
                value={standard.reviewedBy?.username}
              />
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Prüfdatum")}
                value={
                  standard.reviewedAt && (
                    <FormattedDate
                      date={standard.reviewedAt}
                      format={DateFormat.Default}
                    />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                {t("Prüfergebnis")}
              </Typography>
              <>
                <StandardReviewStyledChip standard={standard} />
              </>
            </Grid>
            <Grid item xs={6}>
              <Definition
                title={t("Nächste Prüfung")}
                value={
                  standard.nextReviewDate && (
                    <FormattedDate
                      date={standard.nextReviewDate}
                      format={DateFormat.Default}
                    />
                  )
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t(`Zugewiesene Kategorien (${standard.categories.length})`)}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item md={12}>
              {standard.categories.map((category) => (
                <Typography key={category.id}>
                  <CategoryTree category={category} />
                </Typography>
              ))}
              {!data.categories.length && (
                <Typography>
                  {t("Es ist keine Kategorie zugeordnet")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </Container>
  );
};

export default StandardDetails;
