import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import { Company, getCompanyFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { useCompanyForm } from "../../hooks/form/use-company-form";
import { CompanyFormFields } from "../company-form-fields";
import { useCompanyUpdateMutation } from "../../hooks/mutation/use-company-mutation";

const EditCompanyDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  company: Company;
  onUpdate?: (data: Company) => void;
}> = ({ open, onClose, company, onUpdate }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    getValues,
    watch,
  } = useCompanyForm(getCompanyFormValues(company));

  const updateMutation = useCompanyUpdateMutation(setError, company, (data) => {
    const values = getCompanyFormValues();
    reset(values);
    if (onUpdate) {
      onUpdate(data);
    }
    onClose();
  });

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>{t("Organisation bearbeiten")}</DialogTitle>
      <DialogContent>
        <Card>
          <CompanyFormFields
            control={control}
            watch={watch}
            data={company}
            getValues={getValues}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => updateMutation.mutateAsync(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default EditCompanyDialog;
