import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  Role,
  Standard,
  StandardFilters,
  StandardReview,
  StandardType,
} from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { Link } from "react-router-dom";
import StandardList from "../../components/standard-list";
import { StyledFilterFormControl } from "../companies/overview";
import { config } from "../../config";

const StandardOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Normen"));
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Normen") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [scope, setFilterScope] = useQueryState("", "scope", queryStateOptions);
  const [inputScope, setInputScope] = useDebounceState(scope, setFilterScope);
  const [standardType, setFilterStandardType] = useQueryState(
    "",
    "standardType",
    queryStateOptions
  );
  const [review, setFilterReview] = useQueryState(
    "",
    "review",
    queryStateOptions
  );
  const [notPaid, setFilterNotPaid] = useQueryState(
    false,
    "notPaid",
    queryStateOptions
  );
  const [complete, setFilterComplete] = useQueryState(
    "",
    "complete",
    queryStateOptions
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<StandardFilters, Standard>(
      { search, standardType, scope, review, notPaid, complete },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    StandardFilters,
    Standard
  >(apiRoutes.standards, params);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterScope("");
    setFilterStandardType("");
    setFilterReview("");
    setFilterNotPaid(false);
    setFilterComplete("");
  };

  const hasFilters =
    search !== "" ||
    scope !== "" ||
    standardType !== "" ||
    review !== "" ||
    notPaid ||
    complete !== "";

  return (
    <StyledContainer data-test="standards-content">
      <Header
        title={`Normen`}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.User}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.standardCreate}
            >
              <Add /> {t("Neue Norm")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("zB: Nummer, Bezeichnung,...")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="review">{t("Typ")}</InputLabel>
              <Select
                labelId="type"
                id="type"
                value={standardType}
                onChange={(e) =>
                  setFilterStandardType(e.target.value as string)
                }
                label={t("Typ")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                {Object.values(StandardType).map((type) => (
                  <MenuItem sx={capitalizeStyle} value={type} key={type}>
                    {t(`standard.type.${type}`)}
                  </MenuItem>
                ))}
              </Select>
            </FilterFormControl>
            <StyledFilterFormControl>
              <TextField
                label={t("Geltungsbereich")}
                variant="outlined"
                placeholder={t("zB: ...")}
                value={inputScope}
                onChange={(e) => setInputScope(e.target.value)}
                size="small"
              />
            </StyledFilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="review">{t("Prüfergebnis")}</InputLabel>
              <Select
                labelId="review"
                id="review"
                value={review}
                onChange={(e) => setFilterReview(e.target.value as string)}
                label={t("Prüfergebnis")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                {Object.values(StandardReview).map((review) => (
                  <MenuItem sx={capitalizeStyle} value={review} key={review}>
                    {t(`standard.review.${review}`)}
                  </MenuItem>
                ))}
              </Select>
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="review">{t("Status")}</InputLabel>
              <Select
                labelId="complete"
                id="complete"
                value={complete}
                onChange={(e) => setFilterComplete(e.target.value as string)}
                label={t("Status")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value={"complete"}>
                  {t("Vollständig")}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value={"incomplete"}>
                  {t("Unvollständig")}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notPaid}
                    onChange={(e) => setFilterNotPaid(e.target.checked)}
                    name="notPaid"
                    color="primary"
                  />
                }
                label="Noch nicht gekauft"
              />
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <StandardList
          standards={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default StandardOverview;
