import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useItemDocumentationValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      type: yup.string().required(t("Typ darf nicht leer sein.")),
      description: yup.mixed().when("type", {
        is: (type: string) => type === "documentation",
        then: yup.string().required(t("Beschreibung darf nicht leer sein.")),
      }),
      defect: yup.mixed().when("type", {
        is: (type: string) => type === "defect",
        then: yup
          .number()
          .nullable()
          .required(t("Mangel darf nicht leer sein.")),
      }),
      action: yup.mixed().when("type", {
        is: (type: string) => type === "defect",
        then: yup
          .number()
          .nullable()
          .required(t("Maßnahme darf nicht leer sein.")),
      }),
    }),
    [t]
  );
};
