import { IconButton as Button, IconButtonProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

export const StyledIconButton = styled(Button)`
  color: ${(props) =>
    props.theme.palette.mode === "light"
      ? "rgb(0, 0, 0, 0.54)"
      : "rgb(255, 255, 255)"};
  margin: 0 0.5em;
`;

export const IconButton: FunctionComponent<IconButtonProps> = ({
  children,
  ...props
}) => {
  return <StyledIconButton {...props}>{children}</StyledIconButton>;
};
