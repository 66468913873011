import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import { Company, Defect, getDefectFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { useDefectCreateMutation, useDefectForm } from "../../hooks";
import { DefectFormFields } from "../defect-form-fields";

const CreateDefectDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  company?: Company;
  locationId?: number;
  title?: string;
  onCreate?: (data: Defect) => void;
}> = ({ open, onClose, company, locationId, title, onCreate }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, isSubmitting },
    watch,
  } = useDefectForm({
    ...getDefectFormValues(),
    company: company ? company.id : getDefectFormValues().company,
    location: locationId ? locationId : null,
    title: title || "",
  });

  const { mutateAsync: createMutation } = useDefectCreateMutation(
    setError,
    (res) => {
      const values = getDefectFormValues();
      reset({
        ...values,
        company: company ? company.id : values.company,
        location: locationId ? locationId : null,
        title: title || "",
      });
      onCreate && onCreate(res);
      onClose();
    }
  );

  const onSubmit = async (values: any) => {
    await createMutation(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const values = getDefectFormValues();
    reset({
      ...values,
      company: company ? company.id : values.company,
      location: locationId ? locationId : null,
      title: title || "",
    });
  }, [open, reset, company, title, locationId]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("Neuer Mangel")}</DialogTitle>
      <DialogContent>
        <Card>
          <DefectFormFields
            control={control}
            watch={watch}
            setValue={setValue}
            company={company}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreateDefectDialog;
