import { useMemo } from "react";
import * as yup from "yup";
import { useQualificationValidation } from "./use-qualification-validation";

export const useQualificationValidationSchema = () => {
  const qualificationValidation = useQualificationValidation();

  return useMemo(() => {
    return yup.object({
      ...qualificationValidation,
    });
  }, [qualificationValidation]);
};
