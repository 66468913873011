import React, { useEffect, VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Add, Clear } from "@mui/icons-material";
import {
  ApiError,
  Company,
  CompanyFilters,
  CompanyType,
  DateFormat,
  Document,
  getPersonQualificationFormValues,
  PersonQualification,
  PersonQualificationFormValues,
  PersonQualificationStatus,
  Qualification,
} from "../../model";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import {
  apiRoutes,
  request,
  usePersonDetailsApi,
  usePersonQualificationApi,
} from "../../lib/api";
import HttpError from "../../components/http-error";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../lib/routes";
import LoadingContainer from "../../components/loading-container";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  capitalizeStyle,
  StyledCardTitle,
  StyledHighlightedGrid,
} from "../../components/globals";
import DocumentUploadsArray from "../../components/document-upload/array";
import { useSnackbar } from "notistack";
import Header from "../../components/header";
import LoadingButton from "../../components/loading-button";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useHistory } from "react-router";
import { usePersonQualificationValidationSchema } from "../../hooks/validations/person-qualifications/use-person-qualification-validation-schema";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const PersonQualificationEdit: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string | undefined }>();
  const { personQualificationId } = useParams<{
    personQualificationId: string | undefined;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { data, isLoading, error } = usePersonQualificationApi(
    personQualificationId as unknown as number
  );
  const {
    data: person,
    isLoading: personLoading,
    error: personError,
  } = usePersonDetailsApi(personId as unknown as number);

  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<CompanyFilters, Company>(apiRoutes.companies, {
    types: [CompanyType.TrainingCenter],
  });
  const {
    data: qualifications,
    isLoading: qualificationsLoading,
    error: qualificationsError,
  } = useUnlimitedPaginationApi<{}, Qualification>(apiRoutes.qualifications);
  const {
    data: documents,
    isLoading: documentsLoading,
    error: documentsError,
  } = useUnlimitedPaginationApi<{}, Document>(apiRoutes.documents);

  const validationSchema = usePersonQualificationValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
  } = useForm<PersonQualificationFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getPersonQualificationFormValues(data),
  });

  const showUpload = watch("showUpload", false);

  useEffect(() => reset(getPersonQualificationFormValues(data)), [reset, data]);

  if (
    error ||
    companiesError ||
    qualificationsError ||
    documentsError ||
    personError
  ) {
    return (
      <HttpError
        error={[error, companiesError, qualificationsError, documentsError]}
        actions={
          <Button component={Link} to={routes.persons}>
            {t("Zurück zu Personen")}
          </Button>
        }
      />
    );
  }

  if (
    isLoading ||
    !data ||
    !person ||
    companiesLoading ||
    qualificationsLoading ||
    documentsLoading ||
    personLoading
  ) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Personen"), link: routes.persons },
    {
      label: person.firstName + " " + person.lastName,
      link: routes.person(person.id),
    },
    { label: data.qualification.name },
  ];

  const onSubmit = async (
    values: PersonQualificationFormValues,
    setError: UseFormSetError<PersonQualificationFormValues>
  ) => {
    const formData = new FormData();
    let documentUploadsErrors = false;
    const documentUploads: any[] = !values.showUpload
      ? []
      : values.documentUploads.map((documentUpload, index) => {
          if (!documentUpload.documentUpload) {
            documentUploadsErrors = true;
          }
          formData.append(
            "documentUploadFile" + index,
            documentUpload.documentUpload
          );
          return {
            name: documentUpload.name,
            type: documentUpload.type,
            comment: documentUpload.comment,
          };
        });

    if (documentUploadsErrors) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    formData.append(
      "content",
      JSON.stringify({
        qualification: values.qualification,
        company: values.company,
        person: person.id,
        status: values.status,
        date: values.date,
        expirationDate: values.expirationDate,
        documents: values.documents,
        ...(values.showUpload ? { documentUploads } : {}),
      })
    );

    await request<PersonQualification>(
      apiRoutes.personQualification(data.id),
      "post",
      formData
    )
      .then(() => {
        enqueueSnackbar(t("Qualifikation wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.person(person.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Person")} breadcrumbs={detailBreadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Qualifikation")}
            </StyledCardTitle>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"qualification"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      options={qualifications || []}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        qualifications?.find(
                          (qualification) => qualification.id === field.value
                        ) || null
                      }
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Qualifikation")}
                          required
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"company"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      options={companies || []}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        companies?.find(
                          (company) => company.id === field.value
                        ) || null
                      }
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          label={t("Ausstellende Stelle")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name={"status"}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{t("Status")}</InputLabel>
                      <Select
                        variant="standard"
                        fullWidth
                        {...field}
                        required
                        value={field.value}
                      >
                        <MenuItem
                          sx={capitalizeStyle}
                          value={PersonQualificationStatus.Invalid}
                          key={PersonQualificationStatus.Invalid}
                        >
                          {t(
                            `personQualification.status.${PersonQualificationStatus.Invalid}`
                          )}
                        </MenuItem>
                        <MenuItem
                          sx={capitalizeStyle}
                          value={PersonQualificationStatus.PartiallyValid}
                          key={PersonQualificationStatus.PartiallyValid}
                        >
                          {t(
                            `personQualification.status.${PersonQualificationStatus.PartiallyValid}`
                          )}
                        </MenuItem>
                        <MenuItem
                          sx={capitalizeStyle}
                          value={PersonQualificationStatus.Valid}
                          key={PersonQualificationStatus.Valid}
                        >
                          {t(
                            `personQualification.status.${PersonQualificationStatus.Valid}`
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name={"date"}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name={field.name}
                        value={field.value ? new Date(field.value) : null}
                        format={DateFormat.Default}
                        onChange={field.onChange}
                        label={t("Ausstelldatum")}
                        slotProps={{
                          textField: {
                            error: fieldState.isTouched && fieldState.invalid,
                            helperText: fieldState.error?.message,
                            required: true,
                            fullWidth: true,
                            variant: "standard",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name={"expirationDate"}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name={field.name}
                        value={field.value ? new Date(field.value) : null}
                        format={DateFormat.Default}
                        onChange={field.onChange}
                        label={t("Ablaufdatum")}
                        slotProps={{
                          textField: {
                            error: fieldState.isTouched && fieldState.invalid,
                            helperText: fieldState.error?.message,
                            fullWidth: true,
                            variant: "standard",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"documents"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="documents"
                      multiple={true}
                      options={documents || []}
                      defaultValue={data.documents}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.name +
                        (option.type
                          ? " (" + t(`document.type.${option.type}`) + ")"
                          : "")
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) =>
                        field.onChange(
                          value.map((standard: Document) => standard.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Dokumente")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"showUpload"}
                    render={({ field }) => (
                      <>
                        <Button
                          type="button"
                          onClick={() => field.onChange(!showUpload)}
                        >
                          {showUpload ? (
                            <>
                              {" "}
                              <Clear /> {t("Abbrechen")}
                            </>
                          ) : (
                            <>
                              <Add /> {t("Neue Dokumente hinzufügen")}
                            </>
                          )}
                        </Button>
                        <Checkbox
                          aria-hidden={true}
                          style={{ display: "none" }}
                          checked={showUpload}
                          onChange={field.onChange}
                          color="primary"
                        />
                      </>
                    )}
                  />
                </Grid>
                {showUpload && (
                  <StyledHighlightedGrid item xs={12}>
                    <DocumentUploadsArray control={control} />
                  </StyledHighlightedGrid>
                )}
                <input type="hidden" name={"person"} value={data.id} />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default PersonQualificationEdit;
