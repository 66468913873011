import { Route, Switch, useRouteMatch } from "react-router-dom";
import LocationsOverview from "./overview";

const Locations = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <LocationsOverview />
      </Route>
    </Switch>
  );
};

export default Locations;
