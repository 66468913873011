import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import { Category, Company, Product, ProductFilters } from "../../model";
import { Add, Clear, Publish } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
  StyledLink,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import ProductList from "../../components/product-list";
import { config } from "../../config";

export const StyledFilterFormControl = styled(FilterFormControl)`
  width: ${(props) => props.theme.width["1/5"]};
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const ProductsOverview = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  useTitle(t("Produkte"));

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Produkte") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [manufacturer, setFilterManufacturer] = useQueryState(
    "",
    "manufacturer",
    queryStateOptions
  );
  const [inputManufacturer, setInputManufacturer] = useDebounceState(
    manufacturer,
    setFilterManufacturer
  );
  const [category, setFilterCategory] = useQueryState(
    "",
    "category",
    queryStateOptions
  );
  const [inputCategory, setInputCategory] = useDebounceState(
    category,
    setFilterCategory
  );
  const [verified, setFilterVerified] = useQueryState(
    "",
    "verified",
    queryStateOptions
  );
  const [image, setFilterImage] = useQueryState("", "image", queryStateOptions);

  const { params: companyParams } = useFilters<{ type: string }, Company>(
    { type: "manufacturer" },
    "name",
    undefined,
    0
  );
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = usePaginationApi<{ type: string }, Company>(
    apiRoutes.companies,
    companyParams
  );
  const {
    data: allCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<ProductFilters, Product>(
      { search, manufacturer, category, verified, image },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<ProductFilters, Product>(
    apiRoutes.products,
    params
  );

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
    companiesError?.response?.data.message &&
      enqueueSnackbar(companiesError?.response?.data.message, {
        variant: "error",
      });
    categoriesError?.response?.data.message &&
      enqueueSnackbar(categoriesError?.response?.data.message, {
        variant: "error",
      });
  }, [error, companiesError, categoriesError, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterManufacturer("");
    setFilterCategory("");
    setFilterVerified("");
    setFilterImage("");
  };

  const hasFilters =
    search !== "" ||
    manufacturer !== "" ||
    category !== "" ||
    verified !== "" ||
    image !== "";

  return (
    <StyledContainer data-test="products-content">
      <Header
        title={t("Produkte")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Button
              variant="contained"
              component={StyledLink}
              color="secondary"
              to={routes.productImport}
            >
              <Publish /> {t("Produkte importieren")}
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.productCreate}
            >
              <Add /> {t("Neues Produkt")}
            </Button>
          </>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <StyledFilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("Alle Felder durchsuchen...")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <Autocomplete
                id="manufacturer"
                style={{ width: theme?.width.full }}
                loading={companiesLoading}
                disabled={!!companiesError}
                onChange={(_, value) =>
                  setInputManufacturer(value ? value.name : "")
                }
                options={companies?.results || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) =>
                  option.name === inputManufacturer
                }
                value={
                  companies?.results.find(
                    (company) => company.name === inputManufacturer
                  ) || null
                }
                getOptionKey={(option) => option.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Hersteller")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <Autocomplete
                id="category"
                loading={categoriesLoading}
                disabled={!!categoriesError}
                style={{ width: theme?.width.full }}
                onChange={(_, value) =>
                  setInputCategory(value ? value.name : "")
                }
                options={allCategories}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) => option.name === inputCategory}
                value={
                  allCategories.find(
                    (category) => category.name === inputCategory
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Kategorie")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="verified">{t("Status")}</InputLabel>
              <Select
                labelId="status"
                id="status"
                value={verified}
                onChange={(e) => setFilterVerified(e.target.value as string)}
                label={t("Status")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="1">
                  {t("Verifiziert")}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="0">
                  {t("Nicht verifiziert")}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="image">{t("Bild")}</InputLabel>
              <Select
                labelId="image"
                id="image"
                value={image}
                onChange={(e) => setFilterImage(e.target.value as string)}
                label={t("Bild")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="1">
                  {t("Ja")}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="0">
                  {t("Nein")}
                </MenuItem>
              </Select>
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <ProductList
          products={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default ProductsOverview;
