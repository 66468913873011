import {
  Blameable,
  CheckProcess,
  ID,
  Standard,
  StandardPreview,
  Timestampable,
} from "./";

// Sync with CategoryVoter in backend
export enum CategoryGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface Category extends CategoryPreview, Timestampable, Blameable {
  categories: Category[];
  parents: Category[];
  standards: Standard[];
  checkProcesses: CheckProcess[];
}

export interface CategoryPreview {
  id: ID;
  name: string;
  standards: StandardPreview[];
  parent: CategoryPreview;
}

export interface CategoryFormValues {
  name: string;
  standards: ID[];
  parent: ID | null;
}

export const getCategoryFormValues = (
  category?: Category
): CategoryFormValues => ({
  name: category?.name || "",
  standards: category?.standards?.map((s) => s.id) || [],
  parent: category?.parents[category.parents.length - 1]?.id || null,
});
