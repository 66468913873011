import { useTitle } from "../../hooks/use-title";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  Category,
  CheckProcess,
  CheckProcessFormValues,
  CheckProcessState,
  getCheckProcessFormValues,
  Product,
  Qualification,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { capitalizeStyle, StyledCardTitle } from "../../components/globals";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import LoadingContainer from "../../components/loading-container";
import HttpError from "../../components/http-error";
import { Link as RouterLink } from "react-router-dom";
import LoadingButton from "../../components/loading-button";
import { useCheckProcessValidationSchema } from "../../hooks/validations/check-processes/use-check-process-validation-schema";
import { ProductOption } from "components/product-option";

const CreateCheckProcess: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neuer Prüfprozess"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCheckProcessValidationSchema();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prozess"), link: routes.checkProcesses },
    { label: t("Neu") },
  ];

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useUnlimitedPaginationApi<{}, Product>(apiRoutes.products, undefined, {
    productOption: true,
  });
  const {
    data: qualifications,
    isLoading: qualificationsLoading,
    error: qualificationsError,
  } = useUnlimitedPaginationApi<{}, Qualification>(apiRoutes.qualifications);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<CheckProcessFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCheckProcessFormValues(),
  });

  useEffect(() => {
    categoriesError?.response?.data.message &&
      enqueueSnackbar(categoriesError?.response?.data.message, {
        variant: "error",
      });
  }, [categoriesError, enqueueSnackbar]);

  if (categoriesError || productsError || qualificationsError) {
    return (
      <HttpError
        error={[categoriesError, productsError, qualificationsError]}
        actions={
          <Button component={RouterLink} to={routes.checkProcesses}>
            {t("Zurück zu Prüfprozessen")}
          </Button>
        }
      />
    );
  }

  if (categoriesLoading || productsLoading || qualificationsLoading) {
    return <LoadingContainer />;
  }

  const onSubmit = async (
    values: CheckProcessFormValues,
    setError: UseFormSetError<CheckProcessFormValues>
  ) => {
    await request<CheckProcess>(apiRoutes.checkProcessCreate, "post", values)
      .then(() => {
        enqueueSnackbar(t("Prüfprozess wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.checkProcesses);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Prüfprozess")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prozessdaten")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"name"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Name")}
                      fullWidth
                      {...field}
                      required
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"status"}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{t("Typ")}</InputLabel>
                      <Select
                        variant="standard"
                        fullWidth
                        {...field}
                        value={field.value}
                      >
                        <MenuItem
                          sx={capitalizeStyle}
                          value={CheckProcessState.Draft}
                          key={CheckProcessState.Draft}
                        >
                          {t("Entwurf")}
                        </MenuItem>
                        <MenuItem
                          sx={capitalizeStyle}
                          value={CheckProcessState.Inactive}
                          key={CheckProcessState.Inactive}
                        >
                          {t("Inaktiv")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Beschreibung")}
                      fullWidth
                      {...field}
                      multiline={true}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="protocolSubtitle"
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Untertitel für PDF Protokoll")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Zuweisungen")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"categories"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="categories"
                      multiple={true}
                      options={categories}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(value.map((company) => company.id))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Kategorien")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"products"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="products"
                      multiple={true}
                      options={products}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          <ProductOption product={option} />
                        </li>
                      )}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.displayName}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(value.map((company) => company.id))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Produkte")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"qualifications"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="qualifications"
                      multiple={true}
                      options={qualifications}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Notwendige Qualifikationen")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Sonstiges")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Interne Notiz")}
                      fullWidth
                      {...field}
                      multiline={true}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateCheckProcess;
