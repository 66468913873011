import React, { FunctionComponent } from "react";
import {
  Document,
  DocumentFilters,
  HeadCell,
  ManualType,
  Role,
} from "../../model";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, FindInPage } from "@mui/icons-material";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { routes } from "../../lib/routes";
import { TableNoResult } from "../table-no-result";
import { apiRoutes, getAbsoluteApiUrl } from "../../lib/api";
import { useCurrentUser } from "hooks/use-current-user";
import { isOnlyTester } from "lib/security";
import { IconButton } from "../icon-button";

const DocumentList: FunctionComponent<{
  documents: Document[];
  loading?: boolean;
  params?: FilterParams<DocumentFilters, Document>;
  createSortHandler?: SortHandler<Document>;
}> = ({ documents, loading, params, createSortHandler }) => {
  const user = useCurrentUser();
  const { t } = useTranslation();

  const headCells: HeadCell<Document>[] = [
    { id: "name", label: t("Name") },
    { id: "type", label: t("Typ") },
    { id: "comment", label: t("Kommentar") },
  ];

  function canDocumentBeSeen(manualType: ManualType) {
    if (isOnlyTester(user)) {
      switch (manualType) {
        case ManualType.StandardPDF:
        case ManualType.LocationPlan:
        case ManualType.Qualification:
          return false;
        default:
          return true;
      }
    }

    return true;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {documents.map((document) => (
                <TableRow key={document.id} hover={true}>
                  <CompactTableCell>{document.name}</CompactTableCell>
                  <CompactTableCell>
                    {" "}
                    {t(`document.type.${document.type}`)}
                  </CompactTableCell>
                  <CompactTableCell>{document.comment}</CompactTableCell>
                  <CompactTableCell align={"right"}>
                    {canDocumentBeSeen(document.type) && (
                      <a
                        href={getAbsoluteApiUrl(
                          apiRoutes.documentFile(document.id)
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconButton aria-label={t("Ansehen")} size="small">
                          <FindInPage />
                        </IconButton>
                      </a>
                    )}
                    <Guarded requiredRole={Role.Admin}>
                      <IconLink
                        to={routes.documentEdit(document.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!documents.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default DocumentList;
