import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useProductValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      model: yup.string().required(t("Modell darf nicht leer sein.")),
      category: yup
        .number()
        .nullable()
        .required(t("Kategorie darf nicht leer sein.")),
      manufacturer: yup
        .number()
        .nullable()
        .required(t("Hersteller darf nicht leer sein.")),
      showUpload: yup.boolean(),
      documentUploads: yup.array().when("showUpload", {
        is: (showUpload: boolean) => showUpload,
        then: yup.array().of(
          yup.object({
            name: yup.string().required(t("Name darf nicht leer sein.")),
            type: yup.string().required(t("Typ darf nicht leer sein.")),
            documentUpload: yup
              .mixed()
              .required(t("Dokument darf nicht leer sein.")),
          })
        ),
      }),
      manufacturingYear: yup
        .string()
        .required(t("Herstellungsjahr darf nicht leer sein.")),
    }),
    [t]
  );
};
