import { useTitle } from "../../hooks/use-title";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import React from "react";
import Header from "../../components/header";
import { apiRoutes, request, usePersonDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Definition, StyledCardTitle } from "../../components/globals";
import Guarded from "../../components/guarded";
import {DateFormat, Role} from "../../model";
import { Delete, Edit, VerifiedUser } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import CompanyList from "../../components/company-list";
import PersonQualificationList from "../../components/person-qualification-list";
import LocationList from "../../components/location-list";
import FormattedDate from "../../components/formatted-date";

const PersonDetails = () => {
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePersonDetailsApi(
    personId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.lastName || t("Kontakt Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={`/persons`}>
            {t("Zurück zu Personen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Personen"), link: routes.persons },
    { label: data.firstName + " " + data.lastName },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie die Person {{firstName}} {{lastName}} wirklich löschen?`, {
        firstName: data.firstName,
        lastName: data.lastName,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.person(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Person wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/persons");
      })
      .catch(() =>
        enqueueSnackbar(t("Person konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={t("Person")}
        breadcrumbs={!data.reducedView ? detailBreadcrumbs : undefined}
        actions={
          !data.reducedView && (
            <>
              {data.editable !== false && (
                <Guarded requiredRole={[Role.Admin, Role.Client]}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={routes.personEdit(data.id)}
                  >
                    <Edit /> {t("Bearbeiten")}
                  </Button>
                </Guarded>
              )}{" "}
              <Guarded requiredRole={Role.Admin}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  <Delete /> {t("Löschen")}
                </Button>
              </Guarded>
              <ModifiedEntity entity={data} />
            </>
          )
        }
      />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Kontaktdaten")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Definition title={t("Vorname")} value={data.firstName} />
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={6}>
                    <Definition title={t("Nachname")} value={data.lastName} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Definition
                    title={t("Voranstehender Titel")}
                    value={data.titleBefore}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Definition
                    title={t("Nachstehender Titel")}
                    value={data.titleAfter}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Definition title={t("E-Mail Adresse")} value={data.email} />
                </Grid>
                <Grid item xs={6}>
                  <Definition title={t("Telefonnummer")} value={data.phone} />
                </Grid>
                <Grid item xs={6}>
                  <Definition
                    title={t("Mobiltelefon")}
                    value={data.mobilePhone}
                  />
                </Grid>
                {!data.reducedView && (
                  <Grid item xs={6}>
                    <Definition
                      title={t("Zuordnung")}
                      value={data.externalCode}
                    />
                  </Grid>
                )}
                <Guarded requiredRole={Role.Admin}>
                  <Grid item xs={6}>
                    <Definition
                      title={t("Geburtsdatum")}
                      value={<FormattedDate date={data.birthdate || ""} format={DateFormat.Default}/>}
                    />
                  </Grid>
                </Guarded>
                {!data.reducedView && (
                  <Grid item xs={12}>
                    <Definition title={t("Kommentar")} value={data.comment} />
                  </Grid>
                )}
                <Guarded requiredRole={Role.Admin}>
                  <Grid item xs={6}>
                    <Definition
                      title={t("User")}
                      value={data.user?.fullName || data.user?.username}
                    />
                  </Grid>
                </Guarded>
                <Guarded requiredRole={Role.Admin}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.archived}
                          readOnly={true}
                          name="archived"
                          color="primary"
                        />
                      }
                      label={t("Archiviert")}
                    />
                  </Grid>
                </Guarded>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {!data.reducedView && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Zuordnung zu Organisationen")}
                  </StyledCardTitle>
                </CardContent>
                {(data.companies || []).length > 0 || data.primaryCompany ? (
                  <CompanyList
                    companies={
                      data.primaryCompany
                        ? [data.primaryCompany, ...(data.companies || [])]
                        : data.companies || []
                    }
                    primaryCompany={data.primaryCompany || undefined}
                  />
                ) : (
                  <CardContent>
                    <Typography variant="body1">
                      {t("Es wurde noch keine Organisation verknüpft")}
                    </Typography>
                  </CardContent>
                )}
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Freigeschaltene Orte/Abteilungen")}
                  </StyledCardTitle>
                </CardContent>
                {(data.locations || []).length ? (
                  <LocationList
                    locations={data.locations || []}
                    onLocationUpdate={() => {}}
                    editable={false}
                  />
                ) : (
                  <CardContent>
                    <Typography variant="body1">
                      {t("Es wurde noch keine Orte/Abteilungen verknüpft")}
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={0} justifyContent="space-between">
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("Qualifikationen")}
                    </StyledCardTitle>
                    <Guarded requiredRole={Role.QA}>
                      <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={routes.personQualificationCreate(data.id)}
                      >
                        <VerifiedUser /> {t("Qualifikation hinzufügen")}
                      </Button>
                    </Guarded>
                  </Grid>
                </CardContent>
                <PersonQualificationList person={data} />
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default PersonDetails;
