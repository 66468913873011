import { UseFormSetError } from "react-hook-form";
import { Person, PersonFormValues } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";

export const usePersonCreateMutation = (
  setError: UseFormSetError<PersonFormValues>,
  onSuccess?: (data: Person) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: PersonFormValues) => {
      return await request<Person>(apiRoutes.personCreate, "post", values);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.persons]);
        if (res.data?.primaryCompany) {
          await queryClient.invalidateQueries([
            apiRoutes.companyPrimaryPersons(res.data.primaryCompany.id),
          ]);
        }

        enqueueSnackbar(t("Person wurde erfolgreich erstellt."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.person(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const usePersonUpdateMutation = (
  setError: UseFormSetError<PersonFormValues>,
  data?: Person,
  onSuccess?: (data: Person) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: PersonFormValues) => {
      if (!data) {
        return;
      }
      return await request<Person>(apiRoutes.person(data.id), "put", values);
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }

        await queryClient.invalidateQueries([apiRoutes.persons]);
        if (res.data?.primaryCompany) {
          await queryClient.invalidateQueries([
            apiRoutes.companyPrimaryPersons(res.data.primaryCompany.id),
          ]);
        }
        enqueueSnackbar(t("Person wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.person(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};
