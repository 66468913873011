import { Blameable, Commentable, ID, Timestampable } from "./index";

// Sync with DocumentVoter in backend
export enum DocumentGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  DOWNLOAD = "download",
}

export interface Document extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  type: ManualType;
  file: string;
  fileName: string | null;
}

export enum ManualType {
  Manual = "manual",
  TestInstructions = "testInstructions",
  AssemblyInstructions = "assemblyInstructions",
  Certificate = "certificate",
  ConformityDeclaration = "conformityDeclaration",
  DataSheet = "dataSheet",
  StandardPDF = "standardPDF",
  SafetyDataSheet = "safetyDataSheet",
  TechnicalDataSheet = "technicalDataSheet",
  LocationPlan = "locationPlan",
  Qualification = "qualification",
  Contract = "contract",
}

export const MappedManualTypes: { [group: string]: ManualType[] } = {
  product: [
    ManualType.Manual,
    ManualType.TestInstructions,
    ManualType.AssemblyInstructions,
    ManualType.Certificate,
    ManualType.ConformityDeclaration,
    ManualType.DataSheet,
    ManualType.SafetyDataSheet,
    ManualType.TechnicalDataSheet,
  ],
  standard: [ManualType.StandardPDF],
  location: [ManualType.LocationPlan],
  qualification: [ManualType.Qualification],
  contract: [ManualType.Contract],
};

export interface DocumentFormValues {
  name: string;
  type: ManualType;
  comment: string;
  documentUpload: any;
}

export interface DocumentFilters {
  search: string;
  type: string;
}

export const getDocumentFormValues = (
  document?: Document
): DocumentFormValues => ({
  name: document?.name || "",
  type: document?.type || ManualType.Manual,
  comment: document?.comment || "",
  documentUpload: document?.file || "",
});
