import React, { VoidFunctionComponent } from "react";
import { CheckStep } from "../../model";
import BoolCheckListItem from "./bool-check-list-item";
import TextCheckListItem from "./text-check-list-item";

const BoolCheckList: VoidFunctionComponent<{
  checkStep: CheckStep;
}> = ({ checkStep }) => (
  <>
    <BoolCheckListItem checkStep={checkStep} />
    {checkStep.conditions &&
      checkStep.conditions.length > 0 &&
      checkStep.conditions
        .filter((c) => c.condition.value === checkStep.value)
        .map((condition) => {
          switch (condition.checkStep.type) {
            case "bool":
              return (
                <BoolCheckListItem
                  paddingLeft="1em"
                  checkStep={condition.checkStep}
                />
              );
            case "text":
            default:
              return (
                <TextCheckListItem
                  paddingLeft="1em"
                  checkStep={condition.checkStep}
                />
              );
          }
        })}
  </>
);

export default BoolCheckList;
