import React, { FunctionComponent, useState } from "react";
import { Company, HeadCell, Person, PersonFilters } from "../../model";
import {
  Button,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { TableNoResult } from "../table-no-result";
import { Link as RouterLink } from "react-router-dom";
import CreatePersonDialog from "../create-person-dialog";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyClient } from "../../lib/security";

const PersonList: FunctionComponent<{
  persons: Person[];
  loading?: boolean;
  params?: FilterParams<PersonFilters, Person>;
  createSortHandler?: SortHandler<Person>;
  company?: Company;
}> = ({ persons, loading, params, createSortHandler, company }) => {
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const user = useCurrentUser();
  const onlyClient = isOnlyClient(user);

  const headCells: HeadCell<Person>[] = [
    { id: "firstName", label: t("Vorname") },
    { id: "lastName", label: t("Nachname") },
    { id: "email", label: t("E-Mail") },
    { id: "companies", label: t("Hauptorganisation") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells
              .filter((headCell) => {
                if (onlyClient) {
                  return headCell.id !== "companies";
                }
                return true;
              })
              .map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={
                    params && params.orderBy === headCell.id
                      ? params.order
                      : false
                  }
                >
                  {createSortHandler && headCell.sortable !== false ? (
                    <TableSortLabel
                      active={params && params.orderBy === headCell.id}
                      direction={
                        params && params.orderBy === headCell.id
                          ? params.order
                          : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
            {company ? (
              <CompactTableCell align={"right"}>
                <Button
                  color="primary"
                  onClick={() => setCreateDialogOpen(true)}
                  variant="contained"
                  startIcon={<Add />}
                >
                  {t("Neue Person")}
                </Button>
                <CreatePersonDialog
                  open={createDialogOpen}
                  onClose={() => setCreateDialogOpen(false)}
                  company={company}
                />
              </CompactTableCell>
            ) : (
              <TableCell />
            )}
          </TableRow>
        </TableHead>
        {!loading && persons ? (
          <>
            <TableBody>
              {persons.map((person) => (
                <TableRow key={person.id} hover={true}>
                  <CompactTableCell>{person.firstName}</CompactTableCell>
                  <TableCell>{person.lastName}</TableCell>
                  <TableCell>{person.email}</TableCell>
                  {!onlyClient && (
                    <TableCell>
                      {person.primaryCompany && (
                        <Link
                          component={RouterLink}
                          to={routes.company(person.primaryCompany.id)}
                          display="block"
                          key={person.primaryCompany.id}
                        >
                          {person.primaryCompany.name}
                        </Link>
                      )}
                    </TableCell>
                  )}
                  <CompactTableCell align={"right"}>
                    <IconLink
                      to={routes.person(person.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <IconLink
                      to={routes.personEdit(person.id)}
                      tooltip={t("Bearbeiten")}
                      icon={<Edit />}
                    />
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!persons.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default PersonList;
