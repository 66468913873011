import {
  Blameable,
  Category,
  Commentable,
  DateTimeString,
  Document,
  DocumentFormValues,
  ID,
  ManualType,
  Timestampable,
  User,
} from "./";

// Sync with StandardVoter in backend
export enum StandardGrant {
  VIEW = "view",
  VIEW_FULL = "view-full",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  UPDATE_FULL = "update-full",
}

export interface Standard
  extends StandardPreview,
    Timestampable,
    Blameable,
    Commentable {
  date: DateTimeString | null;
  validUntil: DateTimeString | null;
  description: string | null;
  link: string | null;
  review: string | null;
  reviewedAt: DateTimeString | null;
  nextReviewDate: DateTimeString | null;
  reviewedBy: User | null;
  reviewInterval: number;
  complete: boolean;
  substitute: Standard | null;
  substituteFor: Standard | null;
  categories: Category[];
}

export interface StandardPreview extends Timestampable, Blameable, Commentable {
  id: ID;
  number: string;
  type: string | null;
  scope: string | null;
  document: Document | null;
}

export enum StandardType {
  Broschure = "broschure",
  Order = "order",
  Law = "law",
  Leaflet = "leaflet",
  Standard = "standard",
  Paragraph = "paragraph",
  TechnicalRegulation = "technical_regulation",
  Act = "act",
}

export enum StandardReview {
  Valid = "valid",
  Invalid = "invalid",
  Irrelevant = "irrelevant",
  Required = "required",
}

export interface StandardFormValues {
  number: string;
  date: DateTimeString | null;
  validUntil: DateTimeString | null;
  type: string | StandardType.Standard;
  scope: string | null;
  description: string | null;
  link: string | null;
  review: string | null;
  reviewedAt: DateTimeString | null;
  reviewedBy: ID | null;
  reviewInterval: number;
  complete: boolean;
  comment: string | null;
  substitute: ID | null;
  document: ID | null;
  showUpload: boolean;
  categories: ID[];
  documentUpload: DocumentFormValues;
}

export interface StandardFilters {
  search?: string;
  standardType?: string;
  scope?: string;
  review?: string;
  notPaid?: boolean;
  complete?: string;
}

export const getStandardFormValues = (
  standard?: Standard
): StandardFormValues => ({
  number: standard?.number || "",
  date: standard?.date || null,
  validUntil: standard?.validUntil || null,
  type: standard?.type || "",
  scope: standard?.scope || "",
  description: standard?.description || "",
  link: standard?.link || "",
  review: standard?.review || "",
  reviewedAt: standard?.reviewedAt || null,
  reviewedBy: standard?.reviewedBy ? standard.reviewedBy.id : null,
  reviewInterval: standard?.reviewInterval || 12,
  complete: !!standard?.complete,
  comment: standard?.comment || "",
  substitute: standard?.substitute ? standard.substitute.id : null,
  document: standard?.document ? standard.document.id : null,
  categories: standard?.categories
    ? standard.categories.map((category) => category.id)
    : [],
  documentUpload: {
    name: "",
    type: ManualType.StandardPDF,
    comment: "",
    documentUpload: "",
  },
  showUpload: false,
});
