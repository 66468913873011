import { ReactChild, VoidFunctionComponent } from "react";
import { Container, Typography } from "@mui/material";
import styled from "styled-components";
import { SentimentDissatisfied } from "@mui/icons-material";

const StyledContainer = styled(Container)`
  text-align: center;
`;

const StyledActionsContainer = styled(Container)`
  margin-top: 1.5em;
`;

const Error: VoidFunctionComponent<{
  title: string;
  description?: string;
  actions?: ReactChild;
}> = ({ title, description, actions }) => {
  return (
    <StyledContainer maxWidth="sm">
      <SentimentDissatisfied fontSize="large" color="error" />
      <Typography variant="h5" component="h1" gutterBottom color="error">
        {title}
      </Typography>
      {description}
      {actions && <StyledActionsContainer>{actions}</StyledActionsContainer>}
    </StyledContainer>
  );
};

export default Error;
