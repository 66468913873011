import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { apiRoutes, logout, request } from "../../lib/api";
import { handleFormErrors } from "../../helpers";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { usePasswordValidationSchema } from "../../hooks/validations/profile/use-password-validation-schema";
import LoadingButton from "../../components/loading-button";

interface FormValues {
  currentPassword: string;
  plainPassword: string;
  passwordRepeat: string;
}

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const validationSchema = usePasswordValidationSchema();

  const formik = useFormik<FormValues>({
    initialValues: {
      currentPassword: "",
      plainPassword: "",
      passwordRepeat: "",
    },
    validationSchema,
    onSubmit: (values, actions) => {
      request(apiRoutes.profile.changePassword, "post", values)
        .then(async () => {
          enqueueSnackbar(
            t("Passwort wurde geändert. Sie werden ausgeloggt."),
            { variant: "success" }
          );
          logout().finally(() => {
            history.push(routes.dashboard);
            window.location.reload();
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.response?.data.message, { variant: "error" });
          handleFormErrors(err, actions);
        })
        .finally(() => actions.setSubmitting(false));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardContent>
          <Alert severity="warning">
            {t(
              "Nach dem Ändern des Passworts, werden Sie aus Sicherheitsgründen ausgeloggt."
            )}
          </Alert>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                sx={{ width: "100%" }}
                id="currentPassword"
                type="password"
                name="currentPassword"
                label={t("Aktuelles Passwort")}
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={formik.errors.currentPassword}
                autoComplete=""
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                sx={{ width: "100%" }}
                id="plainPassword"
                type="password"
                name="plainPassword"
                label={t("Neues Passwort")}
                value={formik.values.plainPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.plainPassword &&
                  Boolean(formik.errors.plainPassword)
                }
                helperText={formik.errors.plainPassword}
                autoComplete=""
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                sx={{ width: "100%" }}
                id="passwordRepeat"
                type="password"
                name="passwordRepeat"
                label={t("Neues Passwort wiederholen")}
                value={formik.values.passwordRepeat}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordRepeat &&
                  Boolean(formik.errors.passwordRepeat)
                }
                helperText={formik.errors.passwordRepeat}
                autoComplete=""
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </form>
  );
};

export default ChangePassword;
