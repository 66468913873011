import { Blameable, Commentable, ID, Timestampable } from "./index";

// Sync with QualificationVoter in backend
export enum QualificationGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export enum QualificationType {
  ManufacturingTraining = "manufacturer-training",
  EmployeeTraining = "employee-training",
  Instructions = "instructions",
}

export interface Qualification extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  type: QualificationType;
  conditions: Qualification[];
  refreshInterval: number;
  qualificationTags: QualificationTag[];
}

export interface QualificationFormValues {
  name: string;
  type: string;
  conditions: ID[];
  refreshInterval: number;
  qualificationTags: ID[];
  comment: string;
}

export const getQualificationFormValues = (
  qualification?: Qualification
): QualificationFormValues => ({
  name: qualification?.name || "",
  type: qualification?.type || QualificationType.ManufacturingTraining,
  refreshInterval: qualification?.refreshInterval || 0,
  conditions: qualification?.conditions
    ? qualification.conditions.map((condition) => condition.id)
    : [],
  qualificationTags: qualification?.qualificationTags
    ? qualification.qualificationTags.map((tag) => tag.id)
    : [],
  comment: qualification?.comment || "",
});

// Sync with QualificationTagVoter in backend
export enum QualificationTagGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface QualificationTag extends Timestampable, Blameable {
  id: ID;
  name: string;
  qualifications: Qualification[];
}

export interface QualificationTagFormValues {
  name: string;
}

export const getQualificationTagFormValues = (
  qualificationTag?: QualificationTag
): QualificationTagFormValues => ({
  name: qualificationTag?.name || "",
});
