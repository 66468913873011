import { Route, Switch } from "react-router-dom";
import { routes } from "../../lib/routes";
import ItemCheckDetails from "./details";
import GuardedRoute from "../../components/guarded-route";
import { Role } from "../../model";
import EditItemCheck from "./edit";

const ItemChecks = () => {
  return (
    <Switch>
      <GuardedRoute
        path={routes.itemCheckEdit(":itemCheckId")}
        requiredRole={Role.SuperAdmin}
      >
        <EditItemCheck />
      </GuardedRoute>
      <Route path={routes.itemCheck(":itemCheckId")}>
        <ItemCheckDetails />
      </Route>
    </Switch>
  );
};

export default ItemChecks;
