import { UseFormSetError } from "react-hook-form";
import { Defect, DefectFormValues } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";

export const useDefectCreateMutation = (
  setError: UseFormSetError<DefectFormValues>,
  onSuccess?: (data: Defect) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: DefectFormValues) => {
      return await request<Defect>(apiRoutes.defectCreate, "post", values);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.defects]);
        enqueueSnackbar(t("Mangel wurde erfolgreich erstellt."), {
          variant: "success",
        });

        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.defects);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useDefectUpdateMutation = (
  setError: UseFormSetError<DefectFormValues>,
  data?: Defect,
  onSuccess?: (defect: Defect) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: DefectFormValues) => {
      if (!data) {
        return;
      }
      return await request<Defect>(apiRoutes.defect(data.id), "put", values);
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.defects]);
        enqueueSnackbar(t("Mangel wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.defects);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useDefectDeleteMutation = (
  data?: Defect,
  onSuccess?: (defect: Defect) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request<Defect>(apiRoutes.defect(data.id), "delete");
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.defects]);
        enqueueSnackbar(t("Mangel wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.actions);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
      },
    }
  );
};
