import { Dict, Entity } from "../model";
import { PaginationResponse, usePaginationApi } from "./use-pagination-api";
import { UseQueryOptions } from "react-query/types/react/types";
import { AxiosError } from "axios";
import { QueryKey } from "react-query";

/**
 * Consumes paginated APIs without the results being paginated
 */
export const useUnlimitedPaginationApi = <
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
>(
  url: string,
  filters: Partial<TFilters> = {},
  context?: TContext,
  options?: UseQueryOptions<
    PaginationResponse<TEntity>,
    AxiosError<any>,
    PaginationResponse<TEntity>,
    QueryKey
  >
) => {
  const query = usePaginationApi<TFilters, TEntity, TContext>(
    url,
    {
      filters,
      page: 0,
      pageSize: 0,
      orderBy: "id",
      order: "asc",
      context,
    },
    undefined,
    undefined,
    options
  );
  return { ...query, data: query.data?.results || [] };
};
