import React, {
  Dispatch,
  SetStateAction,
  useState,
  VoidFunctionComponent,
} from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  Definition,
  StyledColoredCardContent,
  StyledHeadline,
} from "../globals";
import { useTranslation } from "react-i18next";
import {
  Defect,
  FilePreview,
  Item,
  ItemDocumentationValues,
} from "../../model";
import { Control, useFieldArray, UseFormGetValues } from "react-hook-form";
import { FormValues } from "./index";
import CreateItemDocumentationDialog from "../create-item-documentation-dialog";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes } from "../../lib/api";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { Thumbnail } from "../thumbnail";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "../icon-button";
import EditItemDocumentationDialog from "../edit-item-documentation-dialog";

const ItemDocumentationCard: VoidFunctionComponent<{
  control: Control<FormValues>;
  item: Item;
  getValues: UseFormGetValues<FormValues>;
  attachments: Array<FilePreview[]>;
  setAttachments: Dispatch<SetStateAction<Array<FilePreview[]>>>;
}> = ({ control, item, getValues, attachments, setAttachments }) => {
  const { t } = useTranslation();
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editItemDocumentation, setEditItemDocumentation] =
    useState<ItemDocumentationValues | null>(null);

  const { data: defects, error: defectsError } = useUnlimitedPaginationApi<
    {},
    Defect
  >(apiRoutes.defects, {
    companyId: item.customer?.id || "",
    locationId: item.location?.id || "",
  });

  const { data: actions, error: actionsError } = useUnlimitedPaginationApi<
    {},
    Defect
  >(apiRoutes.actions, {
    companyId: item.customer?.id || "",
    locationId: item.location?.id || "",
  });

  const { fields, remove, append, insert } = useFieldArray({
    control,
    name: "itemDocumentation",
  });

  const onItemDocumentationCreated = (
    values: ItemDocumentationValues,
    files: FilePreview[]
  ) => {
    append(values);
    setAttachments([...attachments, files]);
    setCreateOpen(false);
  };

  const onItemDocumentationUpdated = (
    values: ItemDocumentationValues,
    files: FilePreview[]
  ) => {
    if (editIndex < 0) {
      return;
    }
    remove(editIndex);
    insert(editIndex, values);
    const currentAttachments = [...attachments];
    currentAttachments[editIndex] = files;
    setAttachments(currentAttachments);
    setEditOpen(false);
  };

  if (defectsError || actionsError) {
    return (
      <HttpError
        error={[defectsError, actionsError]}
        actions={
          <Button component={Link} to={routes.dashboard}>
            {t("Zurück zu Home")}
          </Button>
        }
      />
    );
  }

  return (
    <Card>
      <StyledColoredCardContent>
        <StyledHeadline>{t("Bilddokumentation")}</StyledHeadline>
        {fields.length <= 0 && (
          <Typography>{t("Keine Bilddokumentation vorhanden.")}</Typography>
        )}
        {fields.map((field, index) => (
          <Card style={{ marginBottom: "1em" }} key={field.id}>
            <CardContent>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={9}>
                  {field.type === "defect" && (
                    <>
                      {field.defect && (
                        <Definition
                          title={t(`defect.type.${field.type}`)}
                          value={
                            defects.find((defect) => defect.id === field.defect)
                              ?.title || field.defect
                          }
                        />
                      )}
                      {field.action && (
                        <Definition
                          title="Vorgeschlagene Maßnahme"
                          value={
                            actions.find((action) => action.id === field.action)
                              ?.title || field.action
                          }
                        />
                      )}
                    </>
                  )}
                  {field.type === "documentation" && (
                    <>
                      {field.description && (
                        <Definition
                          title={t(`defect.type.${field.type}`)}
                          value={field.description}
                        />
                      )}
                    </>
                  )}
                  {field.comment && (
                    <Typography variant="caption">
                      {t("Kommentar:")} {field.comment}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <IconButton
                    aria-label={t("Bearbeiten")}
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setEditIndex(index);
                      setEditItemDocumentation(field);
                      setEditOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label={t("Entfernen")}
                    size="small"
                    color="secondary"
                    onClick={() => {
                      remove(index);
                      setAttachments(
                        attachments.filter((attachment, i) => i !== index)
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
                {attachments[index] && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {attachments[index].map((attachment, i) => (
                        <Grid item xs={2} key={i}>
                          <Thumbnail preview={attachment} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ))}
        <Button
          style={{ marginTop: "1em" }}
          type="submit"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => setCreateOpen(true)}
        >
          {t("Bilddokumentation hinzufügen")}
        </Button>
      </StyledColoredCardContent>
      {createOpen && (
        <CreateItemDocumentationDialog
          open={createOpen}
          onClose={() => setCreateOpen(false)}
          onCreate={(values, files) =>
            onItemDocumentationCreated(values, files)
          }
          item={item}
          getValues={getValues}
        />
      )}
      {editOpen && editItemDocumentation && (
        <EditItemDocumentationDialog
          itemDocumentation={editItemDocumentation}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onUpdate={(values, files) =>
            onItemDocumentationUpdated(values, files)
          }
          item={item}
          getValues={getValues}
          index={editIndex}
          attachments={attachments[editIndex] || []}
        />
      )}
    </Card>
  );
};
export default ItemDocumentationCard;
