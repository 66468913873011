import { HeadCell } from "../../model";
import { Params, SortHandler } from "../../hooks/use-filters";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TableCell,
  TableSortLabel,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { IconButton } from "../icon-button";
import { Settings } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { TableContext } from "../table-context-container";

interface ColumnSettings {
  tableId: string;
  show: boolean;
  width: number;
}

export const TableHeadCells = <T extends {}>({
  cells,
  params,
  sort,
  columnSettings,
}: {
  cells: HeadCell<T>[];
  params: Params<T>;
  sort: SortHandler<T>;
  columnSettings?: ColumnSettings;
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const { t } = useTranslation();
  const { tableSettings, setTableSettings } = useContext(TableContext);

  const handleColumnSettings = (key: string) => {
    if (!columnSettings) {
      return;
    }
    const settings = tableSettings?.hiddenColumns || {};
    if (!settings[columnSettings?.tableId]) {
      settings[columnSettings?.tableId] = [];
    }
    const index = settings[columnSettings?.tableId].findIndex((c) => c === key);
    if (index > -1) {
      settings[columnSettings?.tableId].splice(index, 1);
    } else {
      settings[columnSettings?.tableId].push(key);
    }
    setTableSettings({
      ...tableSettings,
      hiddenColumns: settings,
    });
  };

  const grantedCells = cells.filter((cell) => !cell.hidden);

  const visibleCells = grantedCells.filter(
    (cell) =>
      !tableSettings?.hiddenColumns[columnSettings?.tableId || ""]?.includes(
        (cell.id as string) || ""
      )
  );

  return (
    <>
      {visibleCells.map((cell) => (
        <TableCell
          key={cell.id as string}
          width={cell.width}
          align={cell.align}
          padding={cell.padding || "normal"}
          sortDirection={params.orderBy === cell.id ? params.order : false}
        >
          {cell.sortable !== false ? (
            <TableSortLabel
              active={params.orderBy === cell.id}
              direction={params.orderBy === cell.id ? params.order : "asc"}
              onClick={sort(cell.id)}
            >
              {cell.label}
            </TableSortLabel>
          ) : (
            <>{cell.label}</>
          )}
        </TableCell>
      ))}
      {columnSettings && columnSettings.show ? (
        <TableCell align="right" width={columnSettings.width}>
          <IconButton
            onClick={() => setOpenSettings(!openSettings)}
            size="small"
            style={{ marginRight: 0 }}
          >
            <Settings />
          </IconButton>
          <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
            <DialogTitle>{t("Tabellenspalten ein/auslenden")}</DialogTitle>
            <DialogContent>
              {grantedCells.map((cell) => (
                <FormControlLabel
                  key={cell.id as string}
                  style={{ display: "block" }}
                  control={
                    <Switch
                      checked={
                        !tableSettings?.hiddenColumns?.[
                          columnSettings.tableId
                        ]?.includes(cell.id as string)
                      }
                      onChange={() => handleColumnSettings(cell.id as string)}
                      name={String(cell.id)}
                      color="primary"
                    />
                  }
                  label={cell.label}
                />
              ))}
            </DialogContent>
          </Dialog>
        </TableCell>
      ) : (
        <TableCell align="right" width={columnSettings?.width || 150} />
      )}
    </>
  );
};
