import React, { VoidFunctionComponent } from "react";
import { Chip, ChipProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber, green, red } from "@mui/material/colors";
import styled from "styled-components";

const StyledChip = styled(Chip)`
  width: 100%;
  overflow: "hidden";
`;

const DefectStatusChip: VoidFunctionComponent<
  { corrected?: boolean; verified?: boolean | null } & ChipProps
> = ({ corrected, verified, ...props }) => {
  const { t } = useTranslation();
  const label = getLabel(corrected, verified);
  const style = getStyle(corrected, verified);

  return (
    <Tooltip title={t<string>(label)}>
      <StyledChip label={t(label)} style={style} size="small" {...props} />
    </Tooltip>
  );
};

export default DefectStatusChip;

function getStyle(corrected?: boolean, verified?: boolean | null) {
  if (corrected && verified) {
    return {
      backgroundColor: green[500],
      color: "white",
    };
  }
  if (corrected && verified === false) {
    return {
      backgroundColor: red[500],
      color: "white",
    };
  }
  if (corrected) {
    return {};
  }
  return {
    backgroundColor: amber[500],
    color: "black",
  };
}

function getLabel(corrected?: boolean, verified?: boolean | null): string {
  if (corrected && verified) {
    return "Mangel behoben";
  }
  if (corrected && verified === false) {
    return "Verifizierung negativ";
  }
  if (corrected) {
    return "Mangel behoben";
  }
  return "Mangelhaft";
}
