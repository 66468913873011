import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { StyledCardTitle } from "../globals";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Company,
  DateFormat,
  Item,
  ItemFormValues,
  Location,
  Nullable,
  Person,
  ProductOption as ProductOptionType,
} from "../../model";
import { useTranslation } from "react-i18next";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { useMutation } from "react-query";
import { isOnlyClient, isOnlyTester } from "lib/security";
import { useCurrentUser } from "hooks/use-current-user";
import { ProductOption } from "components/product-option";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import { ItemProductFormFields } from "components/item-product-form-fields";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const confirmMessageTemplate =
  "Sind Sie sich sicher, dass Sie {{change}} des Items überschreiben möchten?";

const filterProducts = createFilterOptions<ProductOptionType>({
  matchFrom: "start",
  stringify: (option) => option.displayName,
});

const ItemFormFields: FunctionComponent<{
  control: Control<ItemFormValues>;
  setValue: UseFormSetValue<ItemFormValues>;
  watch: UseFormWatch<ItemFormValues>;
  company?: Company;
  allowProductCreation?: boolean;
  item?: Item;
}> = ({
  control,
  setValue,
  watch,
  company,
  allowProductCreation = false,
  item,
}) => {
  const user = useCurrentUser();
  const selectedCompany = useSelectedCompany();
  const { t } = useTranslation();
  const {
    data: companies,
    isLoading: companyLoading,
    error: companyError,
  } = useUnlimitedPaginationApi<{}, Company>(apiRoutes.companies);

  const {
    data: products,
    isLoading: productLoading,
    error: productError,
  } = useUnlimitedPaginationApi<{}, ProductOptionType>(
    apiRoutes.products,
    undefined,
    {
      productOption: true,
    }
  );
  const [locations, setLocations] = useState<Location[]>([]);
  const [persons, setPersons] = useState<Person[]>([]);
  const [product, setProduct] = useState<Nullable<ProductOptionType>>(null);
  const [createProduct, setCreateProduct] = useState(false);

  const customer = watch("customer");

  const { mutateAsync: locationMutation, isLoading: locationsLoading } =
    useMutation(
      async () => {
        if (!customer) {
          return;
        }
        return await request<Location[]>(
          apiRoutes.companyLocations(+customer),
          "get"
        );
      },
      {
        onSuccess: (res) => {
          if (!res) {
            return;
          }
          setLocations(res.data);
        },
      }
    );

  const { mutateAsync: personMutation, isLoading: personsLoading } =
    useMutation(
      async () => {
        if (!customer) {
          return;
        }
        return await request<Person[]>(
          apiRoutes.companyPrimaryPersons(+customer),
          "get"
        );
      },
      {
        onSuccess: (res) => {
          if (!res) {
            return;
          }
          setPersons(res.data);
        },
      }
    );

  useEffect(() => {
    if (isOnlyClient(user) && user.person?.primaryCompany) {
      setValue("customer", user.person.primaryCompany.id);
    }
    if (isOnlyTester(user) && selectedCompany) {
      setValue("customer", selectedCompany.id);
    }
    if (!company) {
      return;
    }
    setValue("customer", company.id);
  }, [company, user, selectedCompany, setValue]);

  useEffect(() => {
    if (!customer) {
      setValue("location", null);
      setLocations([]);
      setValue("person", null);
      setPersons([]);
      return;
    }
    locationMutation();
    personMutation();
  }, [customer, setValue, locationMutation, personMutation]);

  if (companyError || productError) {
    return (
      <HttpError
        error={[companyError, productError]}
        actions={
          <Button component={Link} to={routes.items}>
            {t("Zurück zu Items")}
          </Button>
        }
      />
    );
  }

  const disableCompany = !!company || isOnlyClient(user) || isOnlyTester(user);

  const resetProductForm = () => {
    if (!allowProductCreation) {
      return;
    }
    setValue("newProduct", {
      model: "",
      category: null,
      manufacturer: null,
      manufacturingYear: "",
    });
    setCreateProduct(false);
  };

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Item")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"serialNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Seriennummer")}
                  fullWidth
                  {...field}
                  required
                  onBlur={() => {
                    if (
                      item &&
                      item.serialNumber !== field.value &&
                      !window.confirm(
                        t(confirmMessageTemplate, {
                          change: t("die Seriennummer"),
                        })
                      )
                    ) {
                      field.onChange(item.serialNumber);
                    }
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"systemNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Systemnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"product"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="product"
                  options={products.filter(
                    (product) => !!product.manufacturingYear
                  )}
                  loading={productLoading}
                  loadingText={t("Lädt Produkte...")}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <ProductOption product={option} />
                    </li>
                  )}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.displayName}
                  value={
                    products.find(
                      (option) =>
                        option.id && field.value && +option.id === +field.value
                    ) ||
                    product ||
                    null
                  }
                  isOptionEqualToValue={(option, value) =>
                    value.id === option.id || !!value.create
                  }
                  onBlur={() => {
                    if (
                      item?.product &&
                      item.product.id !== field.value &&
                      !window.confirm(
                        t(confirmMessageTemplate, { change: t("das Produkt") })
                      )
                    ) {
                      return field.onChange(item.product.id);
                    }

                    if (!field.value) {
                      field.onChange(null);
                    }
                  }}
                  onChange={(_, value) => {
                    if (!value) {
                      field.onChange(null);
                      setProduct(null);
                      resetProductForm();
                      return;
                    }

                    field.onChange(value.id);
                    setProduct(value);

                    if (value.create) {
                      setValue("newProduct", {
                        model: value.model,
                        category: null,
                        manufacturer: null,
                        manufacturingYear: "",
                      });
                      return setCreateProduct(true);
                    }

                    resetProductForm();
                  }}
                  filterOptions={(options, state) => {
                    const filtered = filterProducts(options, state);
                    if (allowProductCreation && state.inputValue !== "") {
                      filtered.push({
                        id: -1,
                        manufacturingYear: "",
                        model: state.inputValue,
                        displayName: `Produkt "${state.inputValue}" hinzufügen`,
                        create: true,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Produkt")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"customer"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="customer"
                  disabled={disableCompany}
                  options={companies}
                  loading={companyLoading}
                  loadingText={t("Lädt Kunden...")}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={
                    companies.find((company) => company.id === field.value) ||
                    null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  onBlur={() => {
                    if (
                      item?.customer &&
                      item.customer.id !== field.value &&
                      !window.confirm(
                        t(confirmMessageTemplate, { change: t("den Kunden") })
                      )
                    ) {
                      return field.onChange(item.customer.id);
                    }

                    if (!field.value) {
                      field.onChange(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Kunde")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {allowProductCreation && createProduct && (
            <Grid item xs={12}>
              <ItemProductFormFields
                control={control}
                setValue={setValue}
                onCancel={() => {
                  resetProductForm();
                  setProduct(null);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"location"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="location"
                  noOptionsText={t(
                    "Es wurden keine Orte/Abteilungen für diesen Kunden gefunden"
                  )}
                  loading={locationsLoading}
                  loadingText={t("Lädt Orte/Abteilungen für Kunden...")}
                  options={locations}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) =>
                    `${option.name}${
                      option.parent ? ` (${option.parent.name})` : ""
                    }`
                  }
                  value={
                    locations.find((location) => location.id === field.value) ||
                    null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Aktueller Ort/Abteilung")}
                      required={!company}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="person"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="person"
                  noOptionsText={t(
                    "Es wurde keine Person für diesen Kunden gefunden"
                  )}
                  loading={personsLoading}
                  loadingText={t("Lädt Personen für Kunden...")}
                  options={persons}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName
                  }
                  value={
                    persons.find((person) => person.id === field.value) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Aktueller Verwender")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"manufacturingYear"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Spezifisches Herstellungsjahr des Items")}
                  fullWidth
                  required
                  {...field}
                  onBlur={() => {
                    if (
                      item &&
                      item.manufacturingYear !== field.value &&
                      !window.confirm(
                        t(confirmMessageTemplate, {
                          change: t("das Herstellungsjahr"),
                        })
                      )
                    ) {
                      field.onChange(item.manufacturingYear);
                    }
                  }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={"firstUse"}
              render={({ field, fieldState }) => (
                <DatePicker
                  value={field.value ? new Date(field.value) : null}
                  format={DateFormat.Default}
                  onChange={field.onChange}
                  label={t("Erster Gebrauch")}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: fieldState.isTouched && fieldState.invalid,
                      helperText: fieldState.error?.message,
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Sonstiges")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"comment"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Kommentar")}
                  fullWidth
                  multiline
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default ItemFormFields;
