import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Role, User } from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import { useUserApi } from "../../lib/api";
import { useTitle } from "../../hooks/use-title";
import { FilterFormControl, FilterFormGroup } from "../../components/globals";
import HttpError from "../../components/http-error";
import UserList from "../../components/user-list";
import { routes } from "../../lib/routes";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";

const UserOverview = () => {
  const { t } = useTranslation();
  useTitle(t("Benutzer"));
  const { isLoading, error, data } = useUserApi();

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [filteredText, setFilteredText] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [filteredRole, setFilteredRole] = useQueryState(
    "",
    "role",
    queryStateOptions
  );
  const [filteredEnabled, setFilteredEnabled] = useQueryState(
    "",
    "status",
    queryStateOptions
  );
  const [filtered, setFiltered] = useState<User[] | undefined>([]);

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Benutzer") },
  ];

  useEffect(() => setFiltered(data), [data]);
  useEffect(() => {
    if (!data) {
      return;
    }
    setFiltered(
      data.filter((u) => {
        let filteredTextMatch = true;
        let filteredRoleMatch = true;
        let filteredEnabledMatch = true;
        if (filteredText.trim() !== "") {
          filteredTextMatch =
            u.username.toLowerCase().includes(filteredText) ||
            u.email.toLowerCase().includes(filteredText);
        }
        if (filteredRole !== "") {
          filteredRoleMatch =
            filteredRole !== "" && u.roles.includes(filteredRole as Role);
        }
        if (filteredEnabled !== "") {
          filteredEnabledMatch = u.enabled === (filteredEnabled === "enabled");
        }
        return filteredTextMatch && filteredRoleMatch && filteredEnabledMatch;
      })
    );
  }, [data, filteredText, filteredRole, filteredEnabled]);

  const resetFilter = () => {
    setFilteredText("");
    setFilteredRole("");
    setFilteredEnabled("");
  };

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.users}>
            Back to User overview
          </Button>
        }
      />
    );
  }

  return (
    <Container maxWidth="md" data-test="user-management-content">
      <Header
        title={t("Benutzer")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.userCreate}
              startIcon={<Add />}
            >
              {t("Neuer Benutzer")}
            </Button>
          </>
        }
      />
      <Card>
        {!isLoading ? (
          <>
            <CardContent>
              <FilterFormGroup row>
                <FilterFormControl>
                  <TextField
                    label={t("Suche")}
                    variant="outlined"
                    value={filteredText}
                    onChange={(e) => setFilteredText(e.target.value)}
                    size={`small`}
                  />
                </FilterFormControl>
                <FilterFormControl variant="outlined" size={`small`}>
                  <InputLabel id="role">{t("Rolle")}</InputLabel>
                  <Select
                    labelId="role"
                    id="role"
                    value={filteredRole}
                    onChange={(e) => setFilteredRole(e.target.value as string)}
                    label={t("Rolle")}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {Object.keys(Role).map((role, key) => (
                      <MenuItem
                        value={Role[role as keyof typeof Role]}
                        key={key}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FilterFormControl>
                <FilterFormControl variant="outlined" size={`small`}>
                  <InputLabel id="status">{t("Status")}</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={filteredEnabled}
                    onChange={(e) =>
                      setFilteredEnabled(e.target.value as string)
                    }
                    label={t("Status")}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="enabled">{t("Aktiviert")}</MenuItem>
                    <MenuItem value="disabled">{t("Deaktiviert")}</MenuItem>
                  </Select>
                </FilterFormControl>
                {(filteredText !== "" ||
                  filteredRole !== "" ||
                  filteredEnabled !== "") && (
                  <IconButton size={`small`} onClick={resetFilter}>
                    <Clear />
                  </IconButton>
                )}
              </FilterFormGroup>
            </CardContent>
            {filtered && <UserList user={filtered} />}
          </>
        ) : (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
      </Card>
    </Container>
  );
};

export default UserOverview;
