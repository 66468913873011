import { Box, Typography } from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import {
  Blameable,
  isBlameable,
  isTimestampable,
  Timestampable,
} from "../../model";
import FormattedDate from "../formatted-date";
import { useTranslation } from "react-i18next";

interface Props {
  entity: Timestampable | Blameable;
  multiline?: boolean;
}

export const ModifiedEntity: VoidFunctionComponent<Props> = ({
  entity,
  multiline = true,
}) => {
  const { t } = useTranslation();
  const showUpdated =
    (isTimestampable(entity) && entity.createdAt !== entity.updatedAt) ||
    (isBlameable(entity) && entity.createdBy !== entity.updatedBy);

  const showCreated =
    (isTimestampable(entity) && entity.createdAt !== "") ||
    (isBlameable(entity) && entity.createdBy !== "");

  const display = multiline ? "block" : "inline";

  return (
    <Typography variant="caption" sx={{ display: "block", marginTop: ".5em" }}>
      {showCreated && (
        <Box sx={{ display }}>
          {t("erstellt")}{" "}
          {isTimestampable(entity) && <FormattedDate date={entity.createdAt} />}
          {isBlameable(entity) && entity.createdBy && (
            <>
              {" "}
              {t("von")} {entity.createdBy}
            </>
          )}
        </Box>
      )}
      {showUpdated && (
        <Box sx={{ display }}>
          {!multiline && ","} {t("aktualisiert")}{" "}
          {isTimestampable(entity) && <FormattedDate date={entity.updatedAt} />}
          {isBlameable(entity) && entity.updatedBy && (
            <>
              {" "}
              {t("von")} {entity.updatedBy}
            </>
          )}
        </Box>
      )}
    </Typography>
  );
};
