import { FunctionComponent } from "react";
import {
  format as dateFormat,
  formatDistance,
  isValid,
  parseISO,
} from "date-fns";
import { Tooltip } from "@mui/material";
import { de } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const FormattedDate: FunctionComponent<{ date: string; format?: string }> = ({
  date,
  format = null,
}) => {
  const { t } = useTranslation();

  if (!isValid(parseISO(date))) {
    return t("Kein Datum");
  }
  return (
    <Tooltip title={date}>
      {format ? (
        <time>{dateFormat(parseISO(date), format, { locale: de })}</time>
      ) : (
        <time>
          {formatDistance(parseISO(date), new Date(), {
            addSuffix: true,
            locale: de,
          })}
        </time>
      )}
    </Tooltip>
  );
};

export default FormattedDate;
