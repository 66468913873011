import { ReactNode } from "react";
import { Grant } from "../../model";
import { useCurrentUser } from "../../hooks/use-current-user";
import { Guardable, hasRole, isGranted } from "../../lib/security";
import { config } from "../../config";

function Guarded<TGrant extends Grant>({
  requiredRole,
  grant,
  subject,
  children,
}: Guardable<TGrant> & {
  children: ReactNode;
}) {
  const user = useCurrentUser();

  if (requiredRole === undefined && grant === undefined) {
    if (config.env === "dev") {
      throw new Error("You must provide either a requiredRole or a grant");
    }
    return null;
  }

  if (
    !user ||
    (requiredRole !== undefined && !hasRole(user, requiredRole)) ||
    (grant !== undefined && !isGranted(user, grant, subject))
  ) {
    return null;
  }

  return <>{children}</>;
}

export default Guarded;
