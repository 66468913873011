import { FunctionComponent } from "react";
import { isValid, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { DateFormat } from "../../model";
import FormattedDate from "../formatted-date";
import { Typography } from "@mui/material";
import styled from "styled-components";

export const StyledTypography = styled(Typography)`
  line-height: 1.5;
  color: ${(props) => props.theme.palette.grey[700]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const HistoryDateEntry: FunctionComponent<{
  date: string;
  format?: string;
  name?: string;
}> = ({ date, format = DateFormat.Default, name }) => {
  const { t } = useTranslation();

  if (!isValid(parseISO(date))) {
    return t("Kein Datum");
  }
  return (
    <StyledTypography variant="overline">
      <FormattedDate date={date} format={format} />
      {name && ` / ${name}`}
    </StyledTypography>
  );
};

export default HistoryDateEntry;
