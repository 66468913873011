import { useTitle } from "../../hooks/use-title";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { apiRoutes, request, useOrderDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import FormattedDate from "../../components/formatted-date";
import {
  Definition,
  StyledCardTitle,
  StyledContainer,
} from "../../components/globals";
import Guarded from "../../components/guarded";
import { AppGrant, DateFormat, Order, OrderGrant, Person } from "../../model";
import {
  Delete,
  Description,
  DoneAll,
  Edit,
  ReportProblem,
} from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import ItemListFilterable from "../../components/item-list-filterable";
import LoadingButton from "../../components/loading-button";
import OpenChecksDialog from "../../components/open-checks-dialog";
import styled from "styled-components";
import { useCurrentUser } from "hooks/use-current-user";
import { isGranted, isOnlyClient, isOnlyTester } from "lib/security";
import GuardedLink from "../../components/guarded-link";
import { useApi } from "../../hooks/use-api";

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const OrderDetails = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useOrderDetailsApi(
    orderId as unknown as number
  );
  const {
    data: openChecks,
    isLoading: openChecksLoading,
    error: openChecksError,
  } = useApi<Person[]>(
    apiRoutes.orderOpenChecks(orderId as unknown as number),
    "get",
    undefined,
    undefined,
    undefined,
    { enabled: isGranted(user, OrderGrant.OPEN_CHECKS, data) }
  );
  const [orderCompleted, setOrderCompleted] = useState(false);
  const history = useHistory();
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const [openChecksOpen, setOpenChecksOpen] = useState<boolean>(false);
  useTitle(data?.orderNumber || t("Auftrag Details"));

  useEffect(() => {
    if (!data) {
      return;
    }
    setOrderCompleted(!!data.complete);
  }, [data]);

  if (error || openChecksError) {
    return (
      <HttpError
        error={[error, openChecksError]}
        actions={
          <Button component={RouterLink} to={`/order`}>
            {t("Zurück zu Aufträgen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data || openChecksLoading) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Auträge"), link: routes.orders },
    { label: data.orderNumber },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Auftrag {{orderNumber}} wirklich löschen?`, {
        number: data.orderNumber,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.order(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Auftrag wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.orders);
      })
      .catch(() =>
        enqueueSnackbar(t("Auftrag konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const handleComplete = async () => {
    const confirm = window.confirm(
      t(
        `Wollen Sie Auftrag {{orderNumber}} wirklich abschließen? Sie können die Prüfdaten danach nicht mehr ändern.`,
        { number: data.orderNumber }
      )
    );
    if (!confirm) {
      return;
    }
    setCompleteLoading(true);
    await request<Order>(apiRoutes.orderComplete(data.id))
      .then((res) => {
        setOrderCompleted(!!res.data.complete);
        enqueueSnackbar(t("Auftrag wurde erfolgreich abgeschlossen."), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("Auftrag konnte nicht abgeschlossen werden."), {
          variant: "error",
        });
      })
      .finally(() => {
        setCompleteLoading(false);
      });
  };

  const onlyClient = isOnlyClient(user);
  const onlyTester = isOnlyTester(user);

  return (
    <StyledContainer>
      <Header
        title={t("Auftrag")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded grant={OrderGrant.UPDATE} subject={data}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.orderEdit(data.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>
            </Guarded>{" "}
            <Guarded grant={OrderGrant.DELETE} subject={data}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Auftragsdaten")}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Auftragsnummer")}
                        value={data.orderNumber}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Kunde")}
                        value={
                          <GuardedLink
                            disabled={onlyClient || onlyTester}
                            to={routes.company(data.customer.id)}
                          >
                            {data.customer.name}
                          </GuardedLink>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition title={t("Projektname")} value={data.sign} />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Datum")}
                        value={
                          <FormattedDate
                            date={data.date}
                            format={DateFormat.Default}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Definition
                        title={t("Auftraggeber")}
                        value={
                          data.externalContact
                            ? data.externalContact.displayName
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Definition
                        title={t("Ansprechpartner")}
                        value={
                          data.internalContact
                            ? data.internalContact.displayName +
                              (data.internalContact.primaryCompany
                                ? " (" +
                                  data.internalContact.primaryCompany.name +
                                  ")"
                                : "")
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Guarded grant={AppGrant.ORDER_MANAGE_DELIVERY_DATES}>
                <Divider
                  orientation={"vertical"}
                  flexItem
                  style={{ marginRight: "-2px" }}
                />
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("Liefertermine")}
                    </StyledCardTitle>
                    {data.deliveryDates.map((deliveryDate, index) => (
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                        key={deliveryDate.id}
                      >
                        <Grid item>{index + 1}</Grid>
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Definition
                                title={t("Datum")}
                                value={
                                  deliveryDate.date ? (
                                    <FormattedDate
                                      date={deliveryDate.date}
                                      format={DateFormat.Default}
                                    />
                                  ) : (
                                    "Kein Datum gesetzt"
                                  )
                                }
                              />
                            </Grid>
                            {deliveryDate.dateTo ? (
                              <Grid item xs={4}>
                                <Definition
                                  title={t("Datum bis")}
                                  value={
                                    <FormattedDate
                                      date={deliveryDate.dateTo}
                                      format={DateFormat.Default}
                                    />
                                  }
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={4} />
                            )}
                            <Grid item xs={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={deliveryDate.badWeather}
                                    readOnly={true}
                                    name="enabled"
                                    color="primary"
                                  />
                                }
                                label={t("Schlechtwetter-Auftrag")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    {data.deliveryDates.length === 0 && (
                      <Typography variant="body1">
                        {t("Es wurde noch kein Liefertermin hinzugefügt")}
                      </Typography>
                    )}
                  </CardContent>
                </Grid>
              </Guarded>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={t("Items")}
              action={
                <StyledActions>
                  {!orderCompleted && openChecks && openChecks.length > 0 && (
                    <Guarded grant={OrderGrant.OPEN_CHECKS} subject={data}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenChecksOpen(true)}
                      >
                        <ReportProblem /> {t("Unterschriften kontrollieren")}
                      </Button>
                      <OpenChecksDialog
                        persons={openChecks}
                        open={openChecksOpen}
                        onClose={() => setOpenChecksOpen(false)}
                      />
                    </Guarded>
                  )}
                  {!onlyClient &&
                    openChecks &&
                    openChecks.length <= 0 &&
                    data.isCompletable &&
                    !orderCompleted && (
                      <Guarded grant={OrderGrant.COMPLETE} subject={data}>
                        <LoadingButton
                          loading={completeLoading}
                          variant="contained"
                          color="primary"
                          onClick={handleComplete}
                        >
                          <DoneAll /> {t("Prüfung abschließen")}
                        </LoadingButton>
                      </Guarded>
                    )}
                  {orderCompleted && (
                    <Guarded grant={AppGrant.CHECK_REPORTS}>
                      <Button
                        variant="contained"
                        color="secondary"
                        component={RouterLink}
                        to={routes.orderReport(data.id)}
                      >
                        <Description /> {t("Protokoll erstellen")}
                      </Button>
                    </Guarded>
                  )}
                </StyledActions>
              }
            />
            {data.clientLocationViolations &&
              data.clientLocationViolations.length > 0 && (
                <CardContent>
                  <Alert severity="error">
                    {onlyClient ? (
                      <Typography>
                        Achtung, in diesem Auftrag wurden auch Items von fremden
                        Orten/Abteilungen geprüft. Bitte wenden Sie sich an Ihre
                        Ansprechperson.
                      </Typography>
                    ) : (
                      <>
                        <Typography>
                          Achtung! Orte/Abteilungen sind dem Auftraggeber nicht
                          zugeteilt:{" "}
                        </Typography>
                        <Tooltip
                          title={data.clientLocationViolations
                            .map((location) => location.name)
                            .join(", ")}
                        >
                          <Typography>
                            {data.clientLocationViolations[0].name}
                            {data.clientLocationViolations[1] &&
                              ", " + data.clientLocationViolations[1].name}
                            {data.clientLocationViolations.length > 2 &&
                              ` + ${
                                data.clientLocationViolations.length - 2
                              } weitere`}
                          </Typography>
                        </Tooltip>
                      </>
                    )}
                  </Alert>
                </CardContent>
              )}
            <ItemListFilterable filters={{ order: data.id }} order={data} />
          </Card>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default OrderDetails;
