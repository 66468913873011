import React, { VoidFunctionComponent } from "react";
import { Chip, ChipProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber, blue, green, red } from "@mui/material/colors";
import styled from "styled-components";
import { QueueTaskStatus } from "../../model/queue-task";

const StyledChip = styled(Chip)`
  overflow: "hidden";
`;

const QueueTaskStatusChip: VoidFunctionComponent<
  { status: number } & ChipProps
> = ({ status, ...props }) => {
  const { t } = useTranslation();
  const style = getStyle(status);
  const label =
    status >= QueueTaskStatus.PENDING && status <= QueueTaskStatus.ABORTED
      ? t(`queueTask.status.${status}`)
      : t("Unbekannt");

  return (
    <Tooltip title={t<string>(label)}>
      <StyledChip label={label} style={style} size="small" {...props} />
    </Tooltip>
  );
};

export default QueueTaskStatusChip;

function getStyle(status: number) {
  switch (status) {
    case QueueTaskStatus.PENDING:
      return {};
    case QueueTaskStatus.PROCESSING:
      return {
        backgroundColor: blue[500],
        color: "white",
      };
    case QueueTaskStatus.DONE:
      return {
        backgroundColor: green[500],
        color: "white",
      };
    case QueueTaskStatus.FAILED:
      return {
        backgroundColor: red[500],
        color: "white",
      };
    case QueueTaskStatus.ABORTED:
      return {
        backgroundColor: amber[500],
        color: "black",
      };
    default:
      return {};
  }
}
