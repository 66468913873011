import { FunctionComponent } from "react";
import { User as UserModel } from "../../model";
import { Avatar, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";

const StyledAvatar = styled(Avatar)<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

export enum AvatarSize {
  Medium = 32,
  Default = 40,
}

const getNameInitials = (name: string) => {
  if (!name) {
    return "?";
  }
  const matches = name.match(/(\b\S)?/g);
  if (!matches || !matches.length) {
    return "?";
  }
  return matches.join("").toUpperCase();
};

const UserAvatar: FunctionComponent<{
  user: UserModel;
  size?: AvatarSize;
}> = ({ user, size = AvatarSize.Default }) => {
  const fallback =
    user.fullName && user.fullName !== ""
      ? getNameInitials(user.fullName)
      : user.username.slice(0, 1).toUpperCase();
  return (
    <Tooltip title={user.fullName || user.username}>
      {user.avatar ? (
        <StyledAvatar size={size} src={`${user.avatar}`} />
      ) : (
        <StyledAvatar size={size} src={user.avatar ? user.avatar : undefined}>
          <Typography variant="body2">{fallback}</Typography>
        </StyledAvatar>
      )}
    </Tooltip>
  );
};

export default UserAvatar;
