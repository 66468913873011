import { useForm } from "react-hook-form";
import { OrderFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOrderValidationSchema } from "../validations/orders/use-order-validation-schema";

export const useOrderForm = (defaultValues: OrderFormValues) => {
  const validationSchema = useOrderValidationSchema();

  return useForm<OrderFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
