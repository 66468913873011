import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, useMemo, VoidFunctionComponent } from "react";
import { Company, getItemFormValues, Item, ItemFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import ItemFormFields from "../../components/item-form-fields";
import PaperDialog from "../paper-dialog";
import { useItemCreateMutation, useItemForm } from "../../hooks";

const CreateItemDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  onCreated?: (item: Item) => void;
  company?: Company;
  serialNumber?: string;
}> = ({ open, onClose, onCreated, company, serialNumber = "" }) => {
  const { t } = useTranslation();

  const defaultFormValues = useMemo(
    () => ({
      ...getItemFormValues(undefined, company),
      newProduct: {
        model: "",
        category: null,
        manufacturer: null,
        manufacturingYear: "",
      },
    }),
    [company]
  );
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
  } = useItemForm(defaultFormValues);

  const createMutation = useItemCreateMutation(setError, (item) => {
    reset(defaultFormValues);
    if (onCreated) {
      onCreated(item);
    }
    onClose();
  });
  const onSubmit = async (values: ItemFormValues) => {
    await createMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    reset(defaultFormValues);
  }, [open, reset, company, defaultFormValues]);

  useEffect(() => {
    setValue("serialNumber", serialNumber);
  }, [serialNumber, setValue]);

  if (!open) {
    return null;
  }

  return (
    <PaperDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Neues Item")}</DialogTitle>
      <DialogContent>
        <Card>
          <ItemFormFields
            control={control}
            setValue={setValue}
            watch={watch}
            company={company}
            allowProductCreation={true}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreateItemDialog;
