import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { StyledCardTitle, StyledColoredCardContent } from "components/globals";
import { useUnlimitedPaginationApi } from "hooks/use-unlimited-pagination-api";
import { apiRoutes } from "lib/api";
import {
  Category,
  CompanyFilters,
  CompanyOption,
  CompanyType,
  ItemFormValues,
  Nullable,
} from "model";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Clear } from "@mui/icons-material";

const manufacturerFilter = createFilterOptions<CompanyOption>();

export const ItemProductFormFields: VoidFunctionComponent<{
  control: Control<ItemFormValues>;
  setValue: UseFormSetValue<ItemFormValues>;
  onCancel: () => void;
}> = ({ control, setValue, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: manufacturers,
    isLoading: manufacturersLoading,
    error: manufacturersError,
  } = useUnlimitedPaginationApi<CompanyFilters, CompanyOption>(
    apiRoutes.companies,
    {
      types: [CompanyType.Manufacturer],
    },
    {
      companyOption: true,
    }
  );

  useEffect(() => {
    manufacturersError?.response?.data.message &&
      enqueueSnackbar(manufacturersError?.response?.data.message, {
        variant: "error",
      });
    categoriesError?.response?.data.message &&
      enqueueSnackbar(categoriesError?.response?.data.message, {
        variant: "error",
      });
  }, [manufacturersError, categoriesError, enqueueSnackbar]);

  const [manufacturer, setManufacturer] =
    useState<Nullable<CompanyOption>>(null);

  return (
    <>
      <StyledColoredCardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Neues Produkt hinzufügen")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="newProduct.model"
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Modell")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="newProduct.manufacturer"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="manufacturer"
                  options={manufacturers || []}
                  loading={manufacturersLoading}
                  loadingText={t("Lädt Hersteller...")}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.create
                      ? `Hersteller "${option.name}" hinzufügen`
                      : option.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    value.id === option.id || !!value.create
                  }
                  filterOptions={(options, state) => {
                    const filtered = manufacturerFilter(options, state);
                    if (state.inputValue !== "") {
                      filtered.push({
                        id: -1,
                        name: state.inputValue,
                        create: true,
                      });
                    }
                    return filtered;
                  }}
                  value={manufacturer}
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => {
                    if (!value) {
                      field.onChange(null);
                      setManufacturer(null);
                      setValue("newManufacturer", "");
                      return;
                    }

                    field.onChange(value.id);
                    setManufacturer(value);
                    setValue("newManufacturer", value.create ? value.name : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Hersteller")}
                      required
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ??
                        (manufacturer?.create
                          ? "Hersteller wird beim Speichern erstellt."
                          : null)
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="newProduct.category"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="category"
                  loading={categoriesLoading}
                  loadingText={t("Lädt Kategorien...")}
                  options={categories || []}
                  noOptionsText={t(
                    "Die Kategorie ist leider nicht vorhanden. Bitte wenden Sie sich an den Support."
                  )}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={
                    categories.find(
                      (category) => category.id === field.value
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Kategorie")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="newProduct.manufacturingYear"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="manufacturingYear"
                  options={["Vor 2016", "Ab 2016"]}
                  onBlur={() => !field.value && field.onChange("")}
                  onChange={(_, value) => field.onChange(value || "")}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Herstellungsjahr")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="button" onClick={onCancel}>
              <Clear /> {t("Abbrechen")}
            </Button>
          </Grid>
        </Grid>
      </StyledColoredCardContent>
    </>
  );
};
