import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import { Company, DeliveryDatesValues, getOrderFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { useOrderCreateMutation, useOrderForm } from "../../hooks";
import { OrderFormFields } from "../order-form-fields";

const CreateOrderDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  company?: Company;
}> = ({ open, onClose, company }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    setValue,
    watch,
  } = useOrderForm({
    ...getOrderFormValues(),
    customer: company ? company.id : getOrderFormValues().customer,
  });

  const createMutation = useOrderCreateMutation(setError, () => {
    const values = getOrderFormValues();
    reset({
      ...values,
      customer: company ? company.id : values.customer,
    });
    onClose();
  });

  const onSubmit = async (values: any) => {
    const deliveryDatesValues: DeliveryDatesValues[] = values.deliveryDates;
    values.deliveryDates = deliveryDatesValues.map((date) => ({
      date: date.date,
      dateTo: date.dateTo,
      badWeather: date.badWeather,
    }));

    await createMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const values = getOrderFormValues();
    reset({
      ...values,
      customer: company ? company.id : values.customer,
    });
  }, [open, reset, company]);

  return (
    <PaperDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Neuer Auftrag")}</DialogTitle>
      <DialogContent>
        <Card>
          <OrderFormFields
            control={control}
            watch={watch}
            company={company}
            setValue={setValue}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreateOrderDialog;
