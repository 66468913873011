import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import {
  Check,
  CheckProcessGroup,
  DefectReviewFormValues,
  FilePreview,
  ID,
  Item,
  ItemDocumentationValues,
  Location,
  Nullable,
  Order,
  Person,
} from "../../model";
import OrderSelector from "./order-selector";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import { Controller, useForm } from "react-hook-form";
import OrderDetails from "./order-details";
import { useTranslation } from "react-i18next";
import LoadingButton from "../loading-button";
import ItemSelector, { orderProcessingItemsQueryKey } from "./item-selector";
import ItemDetails from "./item-details";
import LastItemCheckDetails from "./last-item-check-details";
import PersonSelector from "./person-selector";
import CheckProcess from "./check-process";
import { useSnackbar } from "notistack";
import LocationSelector from "./location-selector";
import LoadingContainer from "../loading-container";
import OrderCompletion from "../order-completion";
import { useQueryClient } from "react-query";
import { StyledHeadline } from "../globals";
import styled from "styled-components";
import { isAllowedClientLocation } from "../../helpers/roles";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import { useSelectCompanyMutation } from "../../hooks/mutation/use-select-company-mutation";
import { isOnlyTester } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";
import {
  useCancelCheckMutation,
  useStartCheckMutation,
} from "./hooks/use-check-mutations";
import { CheckQueryKey } from "../../hooks/queries/use-check-query";
import DefectListDialog from "../defect-list-dialog";

export interface FormValues {
  check: Nullable<ID>;
  order: Nullable<ID>;
  note: string;
  item: Nullable<ID>;
  person: Nullable<ID>;
  location: Nullable<ID>;
  result: Nullable<Record<string, unknown>>;
  comment: Nullable<string>;
  itemDocumentation: ItemDocumentationValues[];
  defectReview: DefectReviewFormValues[];
}

export const StyledCardContent = styled(CardContent)`
  padding-top: 0;
`;

const OrderProcessing: VoidFunctionComponent<{
  check: Nullable<Check>;
  onCheckUpdate: (check: Nullable<Check>) => void;
}> = ({ check, onCheckUpdate }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [checkProcessLoading, setCheckProcessLoading] =
    useState<boolean>(false);
  const { data: persons, isLoading: personsLoading } =
    useUnlimitedPaginationApi<{}, Person>(
      apiRoutes.persons,
      {
        primaryCompany: check?.order.customer.id,
      },
      { personOption: true },
      {
        enabled: !!check,
      }
    );
  const { data: locations, isLoading: locationsLoading } =
    useUnlimitedPaginationApi<{}, Location>(
      apiRoutes.locations,
      {
        company: check?.order.customer.id,
      },
      { locationOption: true },
      {
        enabled: !!check,
      }
    );
  const {
    control,
    watch,
    getValues,
    formState: { isValid },
    reset,
    setValue,
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      check: null,
      order: null,
      note: "",
      item: null,
      person: null,
      location: null,
      result: null,
      comment: "",
      itemDocumentation: [],
      defectReview: [],
    },
  });
  const [currentCheck, setCurrentCheck] = useState<Nullable<Check>>(null);
  const [orderCompletionOpen, setOrderCompletionOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Nullable<Order>>(null);
  const [selectedItem, setSelectedItem] = useState<Nullable<Item>>(null);
  const [openDefectsDialog, setOpenDefectsDialog] = useState(false);
  const queryClient = useQueryClient();
  const selectedCompany = useSelectedCompany();
  const selectedCompanyRef = useRef(selectedCompany);
  const selectCompanyMutation = useSelectCompanyMutation();
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  const orderNumber = watch("order");
  const item = watch("item");
  const person = watch("person");
  const location = watch("location");

  useEffect(
    () =>
      reset({
        check: check?.id || null,
        order: check?.order.id || null,
        note: check?.comment || "",
        item: check?.currentItem?.id || item,
        person: check?.person?.id || null,
        location: check?.location?.id || null,
        comment: check?.currentItem?.comment || "",
        itemDocumentation: [],
        defectReview:
          check?.currentItem?.openDefects?.map((defect) => ({
            defect: defect.id,
            verified: null,
          })) || [],
      }),
    [check, reset, item]
  );

  // Reset unstarted checks in case of company change
  useEffect(() => {
    if (check?.id) {
      return;
    }
    if (selectedCompanyRef.current !== selectedCompany) {
      selectedCompanyRef.current = selectedCompany;
      // Don't reset form in case the selected order customer equals the selected company
      if (selectedCompany?.id === selectedOrder?.customer.id) {
        return;
      }
      onCheckUpdate(null);
      reset({
        check: null,
        item: null,
        order: null,
        location: null,
        note: "",
        person: null,
        result: null,
        comment: "",
        itemDocumentation: [],
        defectReview: [],
      });
    }
  }, [
    reset,
    selectedCompanyRef,
    selectedCompany,
    onCheckUpdate,
    check,
    selectedOrder,
  ]);

  const resetItem = useCallback(() => {
    // workaround: we need to make sure this runs in the next tick to prevent
    // the item to be re-set by the item selector.
    setTimeout(() => {
      setSelectedItem(null);
      setValue("item", null);
      setValue("person", null);
      setValue("result", null);
      setCheckProcessLoading(false);
    });
  }, [setSelectedItem, setValue, setCheckProcessLoading]);

  useEffect(() => {
    if (!currentCheck) {
      return;
    }
    setCurrentCheck(check);
  }, [check, currentCheck, setCurrentCheck]);

  // Start new check
  const startCheckMutation = useStartCheckMutation();
  const startCheck = useCallback(
    (values: FormValues) =>
      startCheckMutation.mutate(values, {
        onSuccess: (res) => {
          onCheckUpdate(res.data);
          setOpenDefectsDialog(
            (res.data.currentItem?.openDefects || []).length > 0
          );
        },
        onError: (error) => {
          enqueueSnackbar(
            error.response?.data.message ||
              t("Leider ist etwas schief gelaufen."),
            { variant: "error" }
          );
          resetItem();
        },
      }),
    [startCheckMutation, onCheckUpdate, enqueueSnackbar, t, resetItem]
  );

  // Cancel running check
  const cancelCheckMutation = useCancelCheckMutation();
  const cancelCheck = (checkId: number) => {
    cancelCheckMutation.mutate(
      { checkId },
      {
        onSuccess: (response) => {
          if (!response) {
            return;
          }
          onCheckUpdate(null);
          reset({
            check: null,
            item: null,
            order: null,
            location: null,
            note: "",
            person: null,
            result: null,
            comment: "",
            itemDocumentation: [],
            defectReview: [],
          });
        },
        onError: () => {
          enqueueSnackbar(t("Es ist ein Fehler aufgetreten"));
        },
      }
    );
  };

  const onSubmit = async (
    value: CheckProcessGroup[],
    attachments: Array<FilePreview[]>
  ) => {
    if (!check) {
      return;
    }
    setCheckProcessLoading(true);

    const formData = new FormData();
    attachments.forEach((files, documentationIndex) => {
      files.forEach((file) => {
        formData.append(
          `itemDocumentation.${documentationIndex}.attachments[]`,
          file.file
        );
      });
    });

    formData.append(
      "content",
      JSON.stringify({
        ...getValues(),
        result: value,
      })
    );

    await request<Check>(apiRoutes.checks, "post", formData)
      .then((response) => {
        enqueueSnackbar(t("Item Prüfung gespeichert."), { variant: "success" });
        onCheckUpdate(response.data);
        resetItem();
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response?.data.message ||
            t("Leider ist etwas schief gelaufen."),
          { variant: "error" }
        );
        setCheckProcessLoading(false);
      });
  };

  const onCancelCurrentItem = async () => {
    if (!check) {
      return;
    }
    setCheckProcessLoading(true);
    const response = await request<Check>(apiRoutes.check(check.id), "delete");
    setCheckProcessLoading(false);
    enqueueSnackbar(t("Item verworfen."), { variant: "success" });
    onCheckUpdate(response.data);
    resetItem();
  };

  // Workaround for clearing autocompletes
  useEffect(() => {
    if (orderNumber === null) {
      setSelectedOrder(null);
    }
    if (item === null) {
      setSelectedItem(null);
    }
  }, [person, item, orderNumber, persons]);

  useEffect(() => {
    if (check?.currentItem || !selectedItem) {
      return;
    }
    if (selectedItem && selectedItem.location) {
      setValue("location", selectedItem.location.id);
    }
    if (selectedItem && selectedItem.person) {
      setValue("person", selectedItem.person.id);
    }
    if (selectedItem && selectedItem.comment) {
      setValue("comment", selectedItem.comment);
    }
    setValue("item", selectedItem.id);
  }, [check, selectedItem, setValue]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <div style={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item lg={check?.id ? 3 : 4} md={12} xs={12}>
                  <CardContent>
                    <OrderSelector
                      check={check}
                      control={control}
                      watch={watch}
                      onOrderSelect={(order) => {
                        if (!order) {
                          return setSelectedOrder(null);
                        }
                        if (order.id === selectedOrder?.id) {
                          return;
                        }
                        setSelectedOrder(order);
                        if (
                          !onlyTester ||
                          !order ||
                          order.customer.id === selectedCompanyRef.current?.id
                        ) {
                          return;
                        }
                        selectCompanyMutation.mutate(order.customer.id);
                      }}
                    />
                  </CardContent>
                  {selectedOrder && (
                    <>
                      <Divider />
                      <CardContent>
                        <OrderDetails order={selectedOrder} />
                      </CardContent>
                      <Divider />
                      <CardContent>
                        <Controller
                          control={control}
                          name={`note` as const}
                          render={({ field, fieldState }) => (
                            <TextField
                              label={t("Notiz für Büro")}
                              disabled={!!check?.id}
                              fullWidth
                              {...field}
                              multiline={true}
                              variant="standard"
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </CardContent>
                      <Divider />
                      <CardContent>
                        <LoadingButton
                          disabled={!isValid || !!check?.id}
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          loading={startCheckMutation.isLoading && !check?.id}
                          onClick={() => startCheck(getValues())}
                        >
                          {check?.id
                            ? t("Prüfung gestartet")
                            : t("Prüfung starten")}
                        </LoadingButton>
                      </CardContent>
                    </>
                  )}
                </Grid>
                <Divider
                  orientation={"vertical"}
                  flexItem
                  style={{ marginRight: "-2px" }}
                />
                {check && check.id && (
                  <>
                    <Grid item lg={check.checkProcess ? 3 : 4} md={12} xs={12}>
                      <CardContent>
                        <ItemSelector
                          check={check}
                          control={control}
                          watch={watch}
                          onItemSelect={(item) => {
                            setSelectedItem(item);
                          }}
                        />
                      </CardContent>
                      <Divider />
                      {selectedItem && (
                        <>
                          <CardContent>
                            <ItemDetails
                              item={selectedItem}
                              check={check}
                              onItemUpdate={() =>
                                queryClient.invalidateQueries([
                                  orderProcessingItemsQueryKey,
                                ])
                              }
                            />
                          </CardContent>
                          {selectedItem.lastCheck && (
                            <>
                              <Divider />
                              <CardContent>
                                <LastItemCheckDetails item={selectedItem} />
                              </CardContent>
                            </>
                          )}
                          {selectedItem && (
                            <StyledCardContent>
                              <Controller
                                control={control}
                                name={`comment` as const}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    label={t("Kommentar")}
                                    fullWidth
                                    disabled={!!check.currentItem}
                                    {...field}
                                    multiline={true}
                                    variant="standard"
                                    error={
                                      fieldState.isTouched && fieldState.invalid
                                    }
                                    helperText={fieldState.error?.message}
                                  />
                                )}
                              />
                            </StyledCardContent>
                          )}
                          <Divider />
                          <CardContent>
                            <StyledHeadline>
                              {t("Aktuelle Zuordnung")}
                            </StyledHeadline>
                            {!personsLoading ? (
                              <PersonSelector
                                check={check}
                                control={control}
                                persons={persons}
                                setValue={setValue}
                              />
                            ) : (
                              <LoadingContainer />
                            )}
                          </CardContent>
                          <Divider />
                          <CardContent>
                            {!locationsLoading ? (
                              <LocationSelector
                                check={check}
                                control={control}
                                locations={locations}
                                setValue={setValue}
                                allowedLocation={
                                  location
                                    ? isAllowedClientLocation(
                                        selectedOrder?.externalContact,
                                        location
                                      )
                                    : true
                                }
                              />
                            ) : (
                              <LoadingContainer />
                            )}
                          </CardContent>
                          {selectedItem && (
                            <>
                              <Divider />
                              <CardContent>
                                <LoadingButton
                                  disabled={
                                    !isValid ||
                                    !!check.currentItem ||
                                    !selectedItem.product
                                  }
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  fullWidth
                                  loading={startCheckMutation.isLoading}
                                  onClick={() => startCheck(getValues())}
                                >
                                  {t("Zu den Prüfschritten")}
                                </LoadingButton>
                              </CardContent>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                    <Divider
                      orientation={"vertical"}
                      flexItem
                      style={{ marginRight: "-2px" }}
                    />
                  </>
                )}
                <Grid
                  item
                  lg={check?.currentItem ? (check?.checkProcess ? 6 : 5) : 3}
                  md={12}
                  xs={12}
                >
                  {!check?.currentItem ? (
                    <CardContent>
                      {/* guide / introductions / ...*/}
                    </CardContent>
                  ) : (
                    <CardContent>
                      {check?.checkProcess ? (
                        <CheckProcess
                          checkProcess={check.checkProcess}
                          onCancel={onCancelCurrentItem}
                          onSubmit={onSubmit}
                          loading={checkProcessLoading}
                          control={control}
                          getValues={getValues}
                          item={check?.currentItem}
                          openDefectReview={() => setOpenDefectsDialog(true)}
                        />
                      ) : (
                        <Alert severity="error">
                          {t("Zu diesem Item wurde kein Prüfprozess gefunden!")}
                          <Button
                            variant="outlined"
                            onClick={onCancelCurrentItem}
                          >
                            {t("Neues Item wählen")}
                          </Button>
                        </Alert>
                      )}
                    </CardContent>
                  )}
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {check?.id && (
            <>
              {check.itemChecks.length > 0 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!!check?.checkProcess}
                  onClick={() => {
                    if (!check) {
                      return;
                    }
                    setCurrentCheck(check);
                    setOrderCompletionOpen(true);
                  }}
                >
                  {t("Prüfung unterzeichnen")}
                </Button>
              ) : (
                <LoadingButton
                  color="secondary"
                  onClick={() => cancelCheck(check.id)}
                  loading={cancelCheckMutation.isLoading}
                >
                  {t("Prüfung vorzeitig beenden")}
                </LoadingButton>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {check && selectedItem && check.currentItem?.openDefects && (
        <DefectListDialog
          data={selectedItem}
          defects={check.currentItem?.openDefects}
          open={openDefectsDialog}
          onClose={() => setOpenDefectsDialog(false)}
          control={control}
        />
      )}
      {currentCheck && orderCompletionOpen && (
        <OrderCompletion
          checkId={currentCheck.id}
          open={orderCompletionOpen}
          onClose={() => {
            setCurrentCheck(null);
            setOrderCompletionOpen(false);
            queryClient.invalidateQueries([CheckQueryKey]);
          }}
        />
      )}
    </>
  );
};

export default OrderProcessing;
