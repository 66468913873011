import React, { FunctionComponent } from "react";
import {
  DateFormat,
  HeadCell,
  Person,
  PersonQualification,
  PersonQualificationStatus,
  Role,
} from "../../model";
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { Edit, FindInPage } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { TableNoResult } from "../table-no-result";
import { Link as RouterLink } from "react-router-dom";
import FormattedDate from "../formatted-date";
import { green, red } from "@mui/material/colors";
import { apiRoutes, getAbsoluteApiUrl } from "../../lib/api";
import GuardedLink from "../guarded-link";
import { isOnlyClient } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";

const PersonQualificationList: FunctionComponent<{
  person: Person;
  loading?: boolean;
  params?: FilterParams<{}, PersonQualification>;
  createSortHandler?: SortHandler<PersonQualification>;
}> = ({ person, loading, params, createSortHandler }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const headCells: HeadCell<PersonQualification>[] = [
    { id: "qualification", label: t("Qualifikation") },
    { id: "status", label: t("Status"), align: "center" },
    { id: "date", label: t("Ausstelldatum") },
    { id: "expirationDate", label: t("Ablaufdatum") },
    { id: "documents", label: t("Dokumente"), align: "center" },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {person.personQualifications?.map((personQualification) => (
                <TableRow key={personQualification.id} hover={true}>
                  <TableCell>
                    <GuardedLink
                      component={RouterLink}
                      to={routes.qualification(
                        personQualification.qualification.id
                      )}
                      disabled={isOnlyClient(user)}
                    >
                      {personQualification.qualification.name}
                    </GuardedLink>
                  </TableCell>
                  <TableCell align="center">
                    {personQualification.status ===
                      PersonQualificationStatus.Invalid && (
                      <Chip
                        label={t(
                          `personQualification.status.${personQualification.status}`
                        )}
                        style={{ backgroundColor: red[500], color: "white" }}
                        size="small"
                      />
                    )}
                    {personQualification.status ===
                      PersonQualificationStatus.Valid && (
                      <Chip
                        label={t(
                          `personQualification.status.${personQualification.status}`
                        )}
                        style={{ backgroundColor: green[500], color: "white" }}
                        size="small"
                      />
                    )}
                    {personQualification.status ===
                      PersonQualificationStatus.PartiallyValid && (
                      <Chip
                        label={t(
                          `personQualification.status.${personQualification.status}`
                        )}
                        color="secondary"
                        size="small"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <FormattedDate
                      date={personQualification.date}
                      format={DateFormat.Default}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: personQualification.expired ? red[500] : "",
                    }}
                  >
                    {personQualification.expirationDate && (
                      <FormattedDate
                        date={personQualification.expirationDate}
                        format={DateFormat.Default}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {personQualification.documents.map((document) => (
                      <Tooltip title={document.name} key={document.id}>
                        <a
                          href={getAbsoluteApiUrl(
                            apiRoutes.documentFile(document.id)
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton aria-label={t("Ansehen")} size="small">
                            <FindInPage />
                          </IconButton>
                        </a>
                      </Tooltip>
                    ))}
                  </TableCell>
                  <CompactTableCell align="right">
                    <Guarded requiredRole={Role.QA}>
                      <IconLink
                        to={routes.personQualificationEdit(
                          person.id,
                          personQualification.id
                        )}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {(person.personQualifications || []).length <= 0 && (
              <TableNoResult
                text={t("Es wurde noch keine Qualifikation hinzugefügt.")}
              />
            )}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default PersonQualificationList;
