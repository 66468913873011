import { CheckProcess, CheckProcessState, DateFormat, Role } from "../../model";
import { useTitle } from "../../hooks/use-title";
import { Link as RouterLink, Link, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link as UILink,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileCopy, ThumbUp } from "@mui/icons-material";
import Header from "../../components/header";
import { apiRoutes, request, useCheckProcessDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Definition,
  StyledCardTitle,
  StyledContainer,
  UnstyledList,
} from "../../components/globals";
import Guarded from "../../components/guarded";
import { ModifiedEntity } from "../../components/modified-entity";
import CheckProcessStatus from "../../components/check-process-status";
import styled from "styled-components";
import CheckGroups from "./check-groups";
import { green } from "@mui/material/colors";
import FormattedDate from "../../components/formatted-date";
import LoadingButton from "../../components/loading-button";

const StyledProcessCardTitle = styled(StyledCardTitle)`
  display: flex;
  justify-content: space-between;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const StyledApproveButton = styled(LoadingButton)`
  background-color: ${green[500]};
  color: white;

  &:hover {
    background-color: ${green[700]};
  }
`;

const CheckProcessDetails = () => {
  const { t } = useTranslation();
  const { checkProcessId } = useParams<{
    checkProcessId: string | undefined;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useCheckProcessDetailsApi(
    checkProcessId as unknown as number
  );
  const [checkProcess, setCheckProcess] = useState<CheckProcess | null>(null);
  const history = useHistory();
  const [approveLoading, setApproveLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  useTitle(data?.name || t("Prozess Details"));

  useEffect(() => {
    if (data) {
      setCheckProcess(data);
    }
  }, [data]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.checkProcesses}>
            {t("Zurück zu Prüfprozessen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !checkProcess) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prozesse"), link: routes.checkProcesses },
    { label: checkProcess.name },
  ];

  const handleDelete = async () => {
    const confirm = window.confirm(
      t(`Wollen Sie Prozess {{name}} wirklich löschen?`, {
        name: checkProcess.name,
      })
    );
    if (!confirm) {
      return;
    }
    await request(apiRoutes.checkProcess(checkProcess.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Prüfprozess wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.checkProcesses);
      })
      .catch(() =>
        enqueueSnackbar(t("Prüfprozess konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const handleApproval = async () => {
    setApproveLoading(true);
    await request<CheckProcess>(
      apiRoutes.checkProcessApprove(checkProcess.id),
      "PUT"
    )
      .then((res) => {
        setCheckProcess(res.data);
        enqueueSnackbar(t("Prüfprozess wurde erfolgreich freigegeben."), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("Prüfprozess konnte nicht freigegeben werden."), {
          variant: "error",
        });
      })
      .finally(() => setApproveLoading(false));
  };

  const duplicateCheckProcess = async () => {
    setDuplicateLoading(true);
    await request<CheckProcess>(
      apiRoutes.checkProcessDuplicate(checkProcess.id),
      "put"
    )
      .then((res) => {
        enqueueSnackbar(t("Der Prüfprozess wurde erfolgreich kopiert."), {
          variant: "success",
        });
        history.push(routes.checkProcess(res.data.id));
      })
      .catch(() => {
        enqueueSnackbar(t("Der Prüfprozess konnte nicht kopiert werden."), {
          variant: "error",
        });
      })
      .finally(() => setDuplicateLoading(false));
  };

  return (
    <StyledContainer>
      <Header
        title={t("Prozess")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.QA}>
              <LoadingButton
                variant="outlined"
                color="primary"
                onClick={duplicateCheckProcess}
                loading={duplicateLoading}
              >
                <FileCopy /> {t("Duplizieren")}
              </LoadingButton>{" "}
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.checkProcessEdit(checkProcess.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                disabled={
                  checkProcess.products.length +
                    checkProcess.categories.length >
                  0
                }
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>

            <ModifiedEntity entity={checkProcess} />
          </>
        }
      />
      <Card>
        <Grid container>
          <Grid item xs={12} md={4}>
            <CardContent>
              <StyledProcessCardTitle
                variant="h6"
                color="secondary"
                gutterBottom
              >
                {t("Prozessdaten")}{" "}
                <CheckProcessStatus status={checkProcess.status} />
              </StyledProcessCardTitle>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Definition title={t("Name")} value={checkProcess.name} />
                </Grid>
                <Grid item md={12}>
                  <Definition
                    title={t("Beschreibung")}
                    value={checkProcess.description}
                  />
                </Grid>
                <Grid item md={12}>
                  <Definition
                    title={t("Untertitel für PDF Protokoll")}
                    value={checkProcess.protocolSubtitle}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Sonstiges")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Definition
                    title={t("Kommentar")}
                    value={checkProcess.comment}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Zuordnung")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Definition
                    title={t("Kategorien")}
                    value={
                      <>
                        {checkProcess.categories.length > 0
                          ? checkProcess.categories.map((category) => (
                              <UILink
                                component={RouterLink}
                                to={routes.category(category.id)}
                                key={category.id}
                              >
                                {category.name}
                              </UILink>
                            ))
                          : t("Keiner Kategorie zugeordnet")}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Definition
                    title={t("Produkte")}
                    value={
                      <>
                        {checkProcess.products.length > 0
                          ? checkProcess.products.map((product) => (
                              <UILink
                                component={RouterLink}
                                to={routes.product(product.id)}
                                key={product.id}
                                display="block"
                              >
                                {product.displayName}
                              </UILink>
                            ))
                          : t("Keinem Produkt zugeordnet")}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Definition
                    title={t("Notwendige Qualifikationen")}
                    value={
                      <>
                        {checkProcess.qualifications.length > 0
                          ? checkProcess.qualifications.map((qualification) => (
                              <UILink
                                component={RouterLink}
                                to={routes.qualification(qualification.id)}
                                key={qualification.id}
                              >
                                {qualification.name}
                              </UILink>
                            ))
                          : t("Keiner Qualifikation erforderlich")}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Freigabe")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {checkProcess.status === CheckProcessState.Approved ? (
                    <Definition
                      title={t("Freigegeben am")}
                      value={
                        <>
                          {checkProcess.approvalDate ? (
                            <FormattedDate
                              date={checkProcess.approvalDate}
                              format={DateFormat.Default}
                            />
                          ) : (
                            t("Keine Angabe")
                          )}
                        </>
                      }
                    />
                  ) : (
                    <>
                      {checkProcess.collidingCategoryApprovals.length +
                        checkProcess.collidingProductApprovals.length ===
                      0 ? (
                        <>
                          <StyledAlert severity="warning">
                            {t(
                              "Der Prüfprozess ist noch nicht von der Geschäftsführung freigegeben."
                            )}
                          </StyledAlert>
                          <Guarded requiredRole={Role.CEO}>
                            <StyledApproveButton
                              variant="contained"
                              startIcon={<ThumbUp />}
                              onClick={() => handleApproval()}
                              loading={approveLoading}
                            >
                              {t("Jetzt Freigeben")}
                            </StyledApproveButton>
                          </Guarded>
                        </>
                      ) : (
                        <StyledAlert severity="error">
                          <AlertTitle>{t("Keine Freigabe möglich")}</AlertTitle>
                          {t(
                            "Der Prüfprozess kann nicht freigegeben werden, da es bereits andere freigegebene Prüfprozesse für zugewiesene Kategorien oder Produkte gibt. Kollidierende Prozesse:"
                          )}
                          <UnstyledList>
                            {checkProcess.collidingCategoryApprovals.map(
                              (collidingProcess) => (
                                <li>
                                  <UILink
                                    component={RouterLink}
                                    to={routes.checkProcess(
                                      collidingProcess.id
                                    )}
                                    key={collidingProcess.id}
                                  >
                                    {collidingProcess.name}
                                  </UILink>
                                </li>
                              )
                            )}
                            {checkProcess.collidingProductApprovals.map(
                              (collidingProcess) => (
                                <li>
                                  <UILink
                                    component={RouterLink}
                                    to={routes.checkProcess(
                                      collidingProcess.id
                                    )}
                                    key={collidingProcess.id}
                                  >
                                    {collidingProcess.name}
                                  </UILink>
                                </li>
                              )
                            )}
                          </UnstyledList>
                        </StyledAlert>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Divider
            orientation={"vertical"}
            flexItem
            style={{ marginRight: "-2px" }}
          />
          <Grid item xs={12} md={8}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Kapitel")}
              </StyledCardTitle>
              {checkProcess.status === CheckProcessState.Approved && (
                <StyledAlert severity="info">
                  {t(
                    "Nach dem Bearbeiten der Prüfkapitel, muss der Prüfprozess erneut von der Geschäftsführung freigegeben werden."
                  )}
                </StyledAlert>
              )}
              <CheckGroups
                checkProcess={checkProcess}
                onCheckProcessUpdate={(updatedProcess) =>
                  setCheckProcess(updatedProcess)
                }
              />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </StyledContainer>
  );
};

export default CheckProcessDetails;
