import React, { FunctionComponent, useState } from "react";
import {
  Company,
  DateFormat,
  HeadCell,
  Item,
  ItemGrant,
  ItemListPreview,
  Order,
} from "../../model";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit, PictureAsPdf, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { CategoryFilters } from "../../views/categories/overview";
import ItemCheckResult from "../item-check-result";
import { TableNoResult } from "../table-no-result";
import { red } from "@mui/material/colors";
import { Link as RouterLink } from "react-router-dom";
import FormattedDate from "../formatted-date";
import CreateItemDialog from "../create-item-dialog";
import DefectListDialog from "../defect-list-dialog";
import DefectChip from "../defect-chip";
import { useCurrentUser } from "hooks/use-current-user";
import GuardedLink from "../guarded-link";
import { isOnlyClient, isOnlyTester } from "lib/security";
import ArchivedIndicator from "../archived-indicator";
import ItemCheckStatusChip from "components/item-check-status-chip";

const ItemList: FunctionComponent<{
  items: ItemListPreview[];
  loading?: boolean;
  params?: FilterParams<CategoryFilters, Item>;
  createSortHandler?: SortHandler<Item>;
  company?: Company;
  order?: Order;
  showForOrder?: boolean;
}> = ({
  items,
  loading,
  params,
  createSortHandler,
  company,
  order,
  showForOrder,
}) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [defectsDetailDialog, setDefectsDetailDialog] =
    useState<ItemListPreview | null>(null);

  const headCells: HeadCell<Item & { parentLocation: unknown }>[] = [
    { id: "archived", label: t("Status") },
    { id: "category", label: t("Kategorie") },
    { id: "serialNumber", label: t("Seriennummer") },
    { id: "product", label: t("Modell") },
    { id: "manufacturerName", label: t("Hersteller") },
    { id: "manufacturingYear", label: t("Herstel- lungsjahr") },
    { id: "personName", label: t("Verwender") },
    { id: "location", label: t("Abteilung") },
    {
      id: "parentLocation",
      label: t("Übergeordnete Abteilung"),
      sortable: false,
    },
    {
      id: "lastCheckDate",
      label: t((showForOrder ? "" : "Letztes ") + "Prüfdatum"),
    },
    { id: "lastCheckedBy", label: t("Überprüfer") },
    {
      id: "lastCheckResult",
      label: t((showForOrder ? "" : "Letztes ") + "Prüfurteil"),
    },
    { id: "defects", label: t("Mängel"), initSortDir: "desc" },
    { id: "checkStatus", label: t("Itemstatus"), sortable: false },
  ];

  const currentItemCheck = (item: ItemListPreview) => {
    if (showForOrder && order) {
      return item.itemChecks.find((ic) => ic.order && ic.order.id === order.id);
    }
    return item.lastCheck;
  };

  const onlyClient = isOnlyClient(user);
  const onlyTester = isOnlyTester(user);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={
                    params && params.orderBy === headCell.id
                      ? params.order
                      : false
                  }
                >
                  {createSortHandler && headCell.sortable !== false ? (
                    <TableSortLabel
                      active={params && params.orderBy === headCell.id}
                      direction={
                        params && params.orderBy === headCell.id
                          ? params.order
                          : headCell.initSortDir || "asc"
                      }
                      onClick={createSortHandler(
                        headCell.id,
                        headCell.initSortDir
                      )}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
              {company ? (
                <CompactTableCell align={"right"}>
                  <Button
                    color="primary"
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                    startIcon={<Add />}
                  >
                    {t("Neues Item")}
                  </Button>
                  <CreateItemDialog
                    open={createDialogOpen}
                    onClose={() => setCreateDialogOpen(false)}
                    company={company}
                  />
                </CompactTableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          {!loading ? (
            <>
              <TableBody>
                {items.map((item) => {
                  const itemCheck = currentItemCheck(item);
                  return (
                    <TableRow
                      key={item.id}
                      hover={true}
                      style={{
                        opacity: item.archived ? 0.5 : 1,
                        backgroundColor: item.archived
                          ? "rgba(0,0,0,0.05)"
                          : "inherit",
                      }}
                    >
                      <CompactTableCell>
                        <ArchivedIndicator archived={item.archived} />
                      </CompactTableCell>
                      <TableCell style={{ maxWidth: "150px" }}>
                        <Box
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.product?.category ? (
                            <GuardedLink
                              disabled={onlyClient || onlyTester}
                              to={routes.category(item.product.category.id)}
                            >
                              {item.product.category.name}
                            </GuardedLink>
                          ) : (
                            <Chip
                              label={t("Nicht zugewiesen")}
                              style={{
                                backgroundColor: red[500],
                                color: "white",
                              }}
                              size={`small`}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{item.serialNumber}</TableCell>
                      <TableCell>
                        {item.product ? (
                          <GuardedLink
                            disabled={onlyTester || onlyClient}
                            component={RouterLink}
                            to={routes.product(item.product.id)}
                          >
                            {item.product.model}
                          </GuardedLink>
                        ) : (
                          <i>{item.productName}</i>
                        )}
                      </TableCell>
                      <TableCell>
                        {item.product?.manufacturer ? (
                          item.product.manufacturer.name
                        ) : (
                          <i>{item.manufacturerName}</i>
                        )}
                      </TableCell>
                      <CompactTableCell>
                        {item.manufacturingYear}
                      </CompactTableCell>
                      <TableCell style={{ maxWidth: "150px" }}>
                        {item.person
                          ? item.person.firstName + " " + item.person.lastName
                          : item.personName
                          ? item.personName
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {item.location ? (
                          <Typography
                            variant="body2"
                            color={
                              order?.clientLocationViolations?.find(
                                (location) =>
                                  location.name === item.location?.name
                              )
                                ? "error"
                                : "initial"
                            }
                          >
                            {item.location.name}
                          </Typography>
                        ) : (
                          item.locationName
                        )}
                      </TableCell>
                      <TableCell>
                        {item.location ? item.location.parent?.name : "-"}
                      </TableCell>
                      <CompactTableCell>
                        {itemCheck ? (
                          <FormattedDate
                            date={itemCheck.checkDate}
                            format={DateFormat.Default}
                          />
                        ) : (
                          "-"
                        )}
                      </CompactTableCell>
                      <CompactTableCell style={{ maxWidth: "100px" }}>
                        {itemCheck?.checkedBy && (
                          <Tooltip
                            title={
                              itemCheck.checkedBy.firstName +
                              " " +
                              itemCheck.checkedBy.lastName
                            }
                          >
                            <Box
                              style={{
                                maxWidth: "100px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {itemCheck.checkedBy.firstName.substring(0, 1) +
                                ". " +
                                itemCheck.checkedBy.lastName}
                            </Box>
                          </Tooltip>
                        )}
                      </CompactTableCell>
                      <CompactTableCell align="center">
                        <ItemCheckResult
                          result={itemCheck ? itemCheck.checkResult : null}
                        />
                      </CompactTableCell>
                      <CompactTableCell align="center">
                        <DefectChip
                          item={item}
                          short={true}
                          onClick={() => {
                            if (!item.defects) {
                              return;
                            }
                            setDefectsDetailDialog(item);
                          }}
                        />
                      </CompactTableCell>
                      <CompactTableCell align="center">
                        <ItemCheckStatusChip checkStatus={item?.checkStatus} />
                      </CompactTableCell>
                      <CompactTableCell align="right">
                        {itemCheck?.checkReport && (
                          <a
                            href={itemCheck.checkReport.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconButton size="small">
                              <PictureAsPdf />
                            </IconButton>
                          </a>
                        )}
                        <IconLink
                          to={routes.item(item.id)}
                          tooltip={t("Ansehen")}
                          icon={<Visibility />}
                        />
                        {!(order && onlyClient) && (
                          <Guarded grant={ItemGrant.UPDATE} subject={item}>
                            <IconLink
                              to={routes.itemEdit(item.id)}
                              tooltip={t("Bearbeiten")}
                              icon={<Edit />}
                              disabled={item.archived}
                            />
                          </Guarded>
                        )}
                      </CompactTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {!items.length && (
                <TableNoResult text={t("Es wurden keine Items gefunden.")} />
              )}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={100}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {defectsDetailDialog && (
        <DefectListDialog
          open={!!defectsDetailDialog}
          onClose={() => setDefectsDetailDialog(null)}
          data={
            items.find((item) => item.id === defectsDetailDialog.id) ||
            defectsDetailDialog
          }
        />
      )}
    </>
  );
};

export default ItemList;
