import React, { FunctionComponent, useState } from "react";
import {
  Company,
  DateFormat,
  HeadCell,
  Order,
  OrderDeliveryDate,
  OrderFilters,
  OrderGrant,
} from "../../model";
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { CompactTableCell } from "../globals";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import FormattedDate from "../formatted-date";
import { routes } from "../../lib/routes";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { IconLink } from "../icon-link";
import { TableNoResult } from "../table-no-result";
import CreateOrderDialog from "../create-order-dialog";
import { useCurrentUser } from "hooks/use-current-user";
import Guarded from "components/guarded";
import { isOnlyClient, isOnlyTester } from "lib/security";
import GuardedLink from "../guarded-link";

function getNextDeliveryDate(deliversDates: OrderDeliveryDate[]) {
  const dates = deliversDates
    .filter((deliveryDate) => new Date(deliveryDate.date) > new Date())
    .sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

  if (dates.length > 0) {
    return <FormattedDate date={dates[0].date} format={DateFormat.Default} />;
  }
  return null;
}

const OrderList: FunctionComponent<{
  orders: Order[];
  loading?: boolean;
  params?: FilterParams<OrderFilters, Order>;
  createSortHandler?: SortHandler<Order>;
  company?: Company;
}> = ({ orders, loading, params, createSortHandler, company }) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const headCells: HeadCell<Order>[] = [
    { id: "orderNumber", label: t("Auftragsnummer") },
    { id: "customer", label: t("Kunde") },
    { id: "sign", label: t("Projektname") },
    { id: "date", label: t("Nächster Liefertermin") },
    { id: "externalContact", label: t("Auftraggeber") },
    { id: "internalContact", label: t("Ansprechpartner") },
  ];

  const onlyClient = isOnlyClient(user);
  const onlyTester = isOnlyTester(user);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            {company ? (
              <CompactTableCell align={"right"}>
                <Button
                  color="primary"
                  onClick={() => setCreateDialogOpen(true)}
                  variant="contained"
                  startIcon={<Add />}
                >
                  {t("Neuer Auftrag")}
                </Button>
                <CreateOrderDialog
                  open={createDialogOpen}
                  onClose={() => setCreateDialogOpen(false)}
                  company={company}
                />
              </CompactTableCell>
            ) : (
              <TableCell />
            )}
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id} hover={true}>
                  <CompactTableCell>{order.orderNumber}</CompactTableCell>
                  <TableCell>
                    <GuardedLink
                      disabled={onlyClient || onlyTester}
                      to={routes.company(order.customer.id)}
                    >
                      {order.customer.name}
                    </GuardedLink>
                  </TableCell>
                  <TableCell>{order.sign}</TableCell>
                  <TableCell>
                    {order.deliveryDates &&
                      getNextDeliveryDate(order.deliveryDates)}
                  </TableCell>
                  <TableCell>
                    {order.externalContact && (
                      <GuardedLink
                        disabled={onlyTester}
                        component={RouterLink}
                        to={routes.person(order.externalContact.id)}
                      >
                        {order.externalContact.displayName}
                      </GuardedLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {order.internalContact && (
                      <GuardedLink
                        disabled={onlyTester}
                        component={RouterLink}
                        to={routes.person(order.internalContact.id)}
                      >
                        {order.internalContact.displayName}
                        {order.internalContact.primaryCompany
                          ? `(${order.internalContact.primaryCompany.name})`
                          : ""}
                      </GuardedLink>
                    )}
                  </TableCell>
                  <TableCell align={"right"}>
                    <IconLink
                      to={routes.order(order.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <Guarded grant={OrderGrant.UPDATE} subject={order}>
                      <IconLink
                        to={routes.orderEdit(order.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!orders.length && (
              <TableNoResult text={t("Es wurden keine Aufträge gefunden.")} />
            )}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default OrderList;
