import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getUserFormValues,
  Person,
  UserFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { useUserManagementValidationSchema } from "../../hooks/validations/user-management/use-user-management-validation-schema";
import LoadingButton from "../../components/loading-button";
import { useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormFields } from "../../components/user-form-fields";
import { UserPersonFormFields } from "../../components/user-person-form-fields";

const CreateUser: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neuer Benutzer"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useUserManagementValidationSchema();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Benutzer"), link: routes.users },
    { label: t("Neu") },
  ];

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<UserFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getUserFormValues(),
  });

  const onSubmit = async (
    values: UserFormValues,
    setError: UseFormSetError<UserFormValues>
  ) => {
    await request<Person>(apiRoutes.userCreate, "post", values)
      .then(() => {
        enqueueSnackbar(t("Benutzer wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.users);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Benutzer")} breadcrumbs={breadcrumbs} />
      <Card>
        <UserFormFields control={control} />
        <Divider />
        <UserPersonFormFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
        />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values, setError))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default CreateUser;
