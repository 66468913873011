import { Role } from "../../model";
import { useTitle } from "../../hooks/use-title";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Delete, Edit, PersonPin, Visibility } from "@mui/icons-material";
import Header from "../../components/header";
import { apiRoutes, request, useUserDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useCurrentUser } from "../../hooks/use-current-user";
import Guarded from "../../components/guarded";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { GridData } from "../../components/grid-data";
import { ModifiedEntity } from "../../components/modified-entity";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
  const { userId } = useParams<{ userId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useUserDetailsApi(
    userId as unknown as number
  );
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  useTitle(data?.username || t("Benutzer Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={`/users`}>
            Back to User overview
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Benutzer"), link: routes.users },
    { label: data.username },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t("Wollen Sie Benutzer {{name}} wirklich löschen?", {
        name: data.username,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.user(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Benutzer wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/users");
      })
      .catch(() =>
        enqueueSnackbar(t("Benutzer konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const impersonate = () => {
    request(apiRoutes.profile.me, "get", {}, { _switch_user: data.email })
      .then(() => window.location.reload())
      .catch(() =>
        enqueueSnackbar(t("Benutzer konnte nicht immitiert werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={t("Benutzer")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.AllowedToSwitch}>
              {currentUser.id !== data.id &&
                !data.roles.includes(Role.SuperAdmin) && (
                  <Tooltip title={t<string>("Immitieren")}>
                    <IconButton
                      color="inherit"
                      disabled={!!currentUser.impersonator}
                      onClick={impersonate}
                      size="large"
                    >
                      <PersonPin />
                    </IconButton>
                  </Tooltip>
                )}{" "}
            </Guarded>
            {data.person && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={routes.person(data.person.id)}
                  startIcon={<Visibility />}
                >
                  {t("Zur Person")}
                </Button>{" "}
              </>
            )}
            {!data.roles.includes(Role.SuperAdmin) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={routes.userEdit(data.id)}
                  startIcon={<Edit />}
                >
                  {t("Bearbeiten")}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                  startIcon={<Delete />}
                >
                  {t("Löschen")}
                </Button>
              </>
            )}
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={6} label={t("Benutzername")}>
              {data.username}
            </GridData>
            <GridData xs={6} label={t("Name")}>
              {data.fullName ? data.fullName : "-"}
            </GridData>
            <GridData xs={12} label={t("E-Mail")}>
              {data.email}
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={6} label={t("Rollen")}>
              {data.roles.join(", ")}
            </GridData>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.enabled}
                    readOnly={true}
                    name="enabled"
                    color="primary"
                  />
                }
                label={t("Aktiviert")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UserDetails;
