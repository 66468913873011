import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, Box, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import LoadingButton from "../../components/loading-button";
import { useUserValidation } from "../../hooks/validations/user/use-user-validation";
import { useResetPasswordMutation } from "../../hooks/mutation/use-reset-password-mutation";
import { Helmet } from "react-helmet";

interface ResetPasswordFormValues {
  password: string;
  passwordRepeat: string;
  token: string;
}

const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const search = useLocation();
  const token = new URLSearchParams(search.search).get("token") || "";
  const userValidation = useUserValidation();

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<ResetPasswordFormValues>({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        password: userValidation.password,
        passwordRepeat: userValidation.passwordRepeat("password"),
      })
    ),
    defaultValues: {
      token,
    },
  });
  const resetPasswordMutation = useResetPasswordMutation();

  const onSubmit = (v: ResetPasswordFormValues) => {
    resetPasswordMutation.mutate({
      plainPassword: v.password,
      passwordRepeat: v.passwordRepeat,
      token: v.token,
    });
  };

  if (!token) {
    return <Alert severity="error">{t("Ungültiger Link")}</Alert>;
  }

  if (resetPasswordMutation.isSuccess) {
    return (
      <Alert severity="success">{t("Passwort erfolgreich geändert.")}</Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("Passwort zurücksetzen")}</title>
      </Helmet>
      <form onSubmit={handleSubmit((values) => onSubmit(values))}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          type="password"
          label={t("Passwort")}
          autoFocus
          error={!!errors.password}
          helperText={errors.password?.message}
          {...register("password")}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="passwordRepeat"
          type="password"
          label={t("Neues Passwort wiederholen")}
          error={!!errors.passwordRepeat}
          helperText={errors.passwordRepeat?.message}
          {...register("passwordRepeat")}
        />
        {resetPasswordMutation.isError && (
          <Box my={1}>
            <Alert severity="error">
              {t("Es ist ein Fehler aufgetreten.")}
            </Alert>
          </Box>
        )}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid}
          loading={resetPasswordMutation.isLoading}
        >
          {t("Speichern")}
        </LoadingButton>
      </form>
    </>
  );
};

export default ResetPassword;
