import { VoidFunctionComponent } from "react";
import { CheckStepType } from "../../model";
import { Spellcheck } from "@mui/icons-material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";

const CheckStepIcon: VoidFunctionComponent<{
  checkStepType?: CheckStepType;
}> = ({ checkStepType }) => {
  switch (checkStepType) {
    case CheckStepType.Text:
      return <Spellcheck fontSize="small" />;
    case CheckStepType.Bool:
      return <GridCheckCircleIcon fontSize="small" />;
    default:
      return null;
  }
};

export default CheckStepIcon;
