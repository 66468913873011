import {
  ActionPreview,
  Blameable,
  Commentable,
  DateTimeString,
  DefectPreview,
  ID,
  ItemCheckPreview,
  PersonPreview,
  Timestampable,
  User,
} from "./index";

// Sync with ItemDocumentationVoter in backend
export enum ItemDocumentationGrant {
  VIEW = "view",
  UPDATE = "update",
  VERIFY = "verify",
}

export enum ItemDocumentationType {
  Defect = "defect",
  Documentation = "documentation",
}

// Sync with AttachmentVoter in backend
export enum AttachmentGrant {
  VIEW_IMG = "view_img",
  VIEW_PDF = "view_pdf",
}

export interface Attachment
  extends AttachmentPreview,
    Timestampable,
    Blameable,
    Commentable {
  file?: string;
  fileName?: string | null;
}

export interface AttachmentPreview {
  id: ID;
  image: string | null;
  fileMimeType: string | null;
}

export interface AttachmentFormValues {
  upload: any;
}

export interface FilePreview {
  file: File;
  preview: string;
}

export interface ItemDocumentationPreview
  extends Timestampable,
    Blameable,
    Commentable {
  id: ID;
  type: string;
  attachments: AttachmentPreview[];
  description?: string;
  defect?: DefectPreview | null;
  defectCorrected: boolean;
  defectCorrectedAt: DateTimeString;
  defectCorrectionComment: string | null;
  defectCorrectionProofs: AttachmentPreview[];
  action?: ActionPreview | null;
  createdPerson?: PersonPreview | null;
  verified?: boolean | null;
  verifiedDate?: DateTimeString | null;
  verifiedBy?: User | null;
  verifiedByName?: string | null;
  itemCheck: ItemCheckPreview;
}

export interface ItemDocumentation extends ItemDocumentationPreview {
  id: ID;
  type: string;
  attachments: AttachmentPreview[];
  itemCheck: ItemCheckPreview;
  description?: string;
  defect?: DefectPreview | null;
  action?: ActionPreview | null;
  createdPerson?: PersonPreview | null;
}

export interface ItemDocumentationValues {
  type: string;
  attachments: AttachmentFormValues[];
  description: string;
  defect?: ID | null;
  action?: ID | null;
  comment?: string;
  bypassResultLock?: boolean;
}

export interface DefectReviewFormValues {
  defect: ID;
  verified: boolean | null;
}

export interface ItemDocumentationDetailValues {
  comment: string;
  defectCorrected: boolean;
  defectCorrectedAt: DateTimeString;
  defectCorrectionComment: string;
}

export const getItemDocumentationFormValues = (
  data?: ItemDocumentation
): ItemDocumentationValues => ({
  type: data?.type || "",
  attachments: [],
  description: data?.description || "",
  comment: data?.comment || "",
  defect: data?.defect?.id || null,
  action: data?.action?.id || null,
});

export const getItemDocumentationDetailFormValues = (
  data?: ItemDocumentation
): ItemDocumentationDetailValues => ({
  comment: data?.comment || "",
  defectCorrected: data?.defectCorrected || false,
  defectCorrectedAt: data?.defectCorrectedAt || "",
  defectCorrectionComment: data?.defectCorrectionComment || "",
});
