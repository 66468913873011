import { UseFormSetError } from "react-hook-form";
import { Order, OrderFormValues } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";

export const useOrderCreateMutation = (
  setError: UseFormSetError<OrderFormValues>,
  onSuccess?: (order: Order) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: OrderFormValues) => {
      return await request<Order>(apiRoutes.orderCreate, "post", values);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.orders]);
        enqueueSnackbar(t("Auftrag wurde erfolgreich erstellt."), {
          variant: "success",
        });

        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.order(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useOrderUpdateMutation = (
  setError: UseFormSetError<OrderFormValues>,
  data?: Order,
  onSuccess?: (order: Order) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: OrderFormValues) => {
      if (!data) {
        return;
      }
      return await request<Order>(apiRoutes.order(data.id), "put", values);
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.orders]);
        enqueueSnackbar(t("Auftrag wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.order(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};
