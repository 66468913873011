import React, { FunctionComponent, useState } from "react";
import {
  AppGrant,
  Company,
  CompanyFilters,
  CompanyGrant,
  DateFormat,
  HeadCell,
  Role,
} from "../../model";
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit, Star, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import styled from "styled-components";
import { green, purple } from "@mui/material/colors";
import { TableNoResult } from "../table-no-result";
import { isGranted, isOnlyTester } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";
import { listCompanyQueryKey } from "../../views/companies/overview";
import { useQueryClient } from "react-query";
import EditCompanyDialog from "../edit-company-dialog";
import { DisabledIconButton } from "../globals";
import { useCompanyDeleteMutation } from "../../hooks/mutation/use-company-mutation";
import LoadingIconButton from "../loading-icon-button";
import { useCheckQuery } from "../../hooks/queries/use-check-query";
import { useSelectCompanyMutation } from "../../hooks/mutation/use-select-company-mutation";
import { Link as RouterLink } from "react-router-dom";
import { BillingFilters, BillingPreview } from "model/billing";
import FormattedDate from "components/formatted-date";
import { TableHeadCells } from "../table-head-cells";
import TableCell from "../table-cell";
import TableContextContainer from "../table-context-container";

const StyledChip = styled(Chip)`
  margin: 1px;
`;

const StyledStar = styled(Star)`
  color: #ffc107;
`;

export const CompanyListTableID = "company-list";

const CompanyList: FunctionComponent<{
  companies: Company[];
  loading?: boolean;
  params?: FilterParams<
    CompanyFilters & BillingFilters,
    Company & BillingPreview
  >;
  createSortHandler?: SortHandler<Company & BillingPreview>;
  primaryCompany?: Company;
}> = ({ companies, loading, params, createSortHandler, primaryCompany }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [editDialogOpen, setEditDialogOpen] = useState<Company | null>(null);
  const queryClient = useQueryClient();
  const { mutateAsync: deleteMutation, isLoading: deleteIsLoading } =
    useCompanyDeleteMutation(() =>
      queryClient.invalidateQueries(listCompanyQueryKey)
    );
  const [deleteLoading, setDeleteLoading] = useState<Company | null>(null);
  const { data } = useCheckQuery();
  const checkRunning = !!data;
  const selectCompanyMutation = useSelectCompanyMutation();
  const onlyTester = isOnlyTester(user);
  const granted = isGranted(user, AppGrant.COMPANY_CREATE_FULL);
  const grantedCounts = isGranted(user, AppGrant.COMPANY_COUNT);

  const headCells: HeadCell<Company & BillingPreview>[] = [
    { id: "customer", label: t("Typ"), sortable: false },
    { id: "customerNumber", label: t("Kundennummer"), sortable: false },
    { id: "name", label: t("Name") },
    {
      id: "testPartners",
      label: t("Prüfpartner"),
      sortable: false,
      hidden: !granted,
    },
    {
      id: "owner",
      label: t("Eigentümer"),
      sortable: false,
      hidden: !granted,
    },
    { id: "accessStatus", label: t("Zugriffstatus"), hidden: !granted },
    { id: "tariffModel", label: t("Tarif"), hidden: !granted },
    { id: "dueDate", label: t("Stichtag"), hidden: !granted },
    { id: "street", label: t("Straße") },
    { id: "zipCode", label: t("PLZ") },
    { id: "city", label: t("Ort") },
    { id: "country", label: t("Land") },
    {
      id: "itemCount",
      label: t("Items"),
      sortable: false,
      hidden: !grantedCounts,
    },
    {
      id: "orderCount",
      label: t("Aufträge"),
      sortable: false,
      hidden: !grantedCounts,
    },
  ];

  const handleDelete = (company: Company) => {
    const confirm = window.confirm(
      t(`Wollen Sie die Organisation {{name}} wirklich löschen?`, {
        name: company.name,
      })
    );
    if (!confirm) {
      return;
    }
    setDeleteLoading(company);
    deleteMutation(company).finally(() => {
      setDeleteLoading(null);
    });
  };

  const disableLink = checkRunning && onlyTester;

  return (
    <TableContextContainer tableId={CompanyListTableID}>
      <Table>
        <TableHead>
          <TableRow>
            {params && createSortHandler && (
              <TableHeadCells
                cells={headCells}
                params={params}
                sort={createSortHandler}
                columnSettings={{
                  show: granted,
                  width: 150,
                  tableId: CompanyListTableID,
                }}
              />
            )}
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {companies.map((company, index) => (
                <TableRow key={index} hover={true}>
                  <TableCell width="12%" columnId="customer">
                    {company.customer && (
                      <StyledChip
                        label={t("Kunde")}
                        size="small"
                        color="primary"
                      />
                    )}
                    {company.manufacturer && (
                      <StyledChip
                        label={t("Hersteller")}
                        size="small"
                        color="secondary"
                      />
                    )}
                    {company.supplier && (
                      <StyledChip
                        label={t("Lieferant")}
                        style={{
                          backgroundColor: green[500],
                          color: "white",
                        }}
                        size="small"
                      />
                    )}
                    {company.partner && (
                      <StyledChip label={t("Vertriebspartner")} size="small" />
                    )}
                    {company.distributor && (
                      <StyledChip label={t("Distributeur")} size="small" />
                    )}
                    {company.opinionLeader && (
                      <StyledChip label={t("Meinungsbildner")} size="small" />
                    )}
                    {company.testPartner && (
                      <StyledChip label={t("Prüfpartner")} size="small" />
                    )}
                    {company.importer && (
                      <StyledChip label={t("Importeur")} size="small" />
                    )}
                    {company.trainingCenter && (
                      <StyledChip
                        label={t("Schulungszentrum")}
                        style={{
                          backgroundColor: purple[500],
                          color: "white",
                        }}
                        size="small"
                      />
                    )}
                  </TableCell>
                  <TableCell columnId="customerNumber">
                    {company.customerNumber}
                  </TableCell>
                  <TableCell columnId="name">{company.name}</TableCell>
                  <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
                    <TableCell columnId="testPartners">
                      <Grid container direction="column" spacing={1}>
                        {company.testPartners?.map((testPartner) => (
                          <Grid item key={testPartner.id}>
                            {testPartner.name}
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell columnId="owner">
                      {company.owner?.name}
                    </TableCell>
                    <TableCell columnId="accessStatus">
                      {company.billing?.accessStatus &&
                        t(
                          `billing.accessStatus.${company.billing.accessStatus}`
                        )}
                    </TableCell>
                    <TableCell columnId="tariffModel">
                      {company.billing?.tariffModel &&
                        t(`billing.tariffModel.${company.billing.tariffModel}`)}
                    </TableCell>
                    <TableCell columnId="dueDate">
                      <FormattedDate
                        date={company.billing?.dueDate || ""}
                        format={DateFormat.Default}
                      />
                    </TableCell>
                  </Guarded>
                  <TableCell columnId="street">{company.street}</TableCell>
                  <TableCell columnId="zipCode">{company.zipCode}</TableCell>
                  <TableCell columnId="city">{company.city}</TableCell>
                  <TableCell columnId="country">{company.country}</TableCell>
                  <Guarded grant={AppGrant.COMPANY_COUNT}>
                    <TableCell columnId="itemCount">
                      {disableLink ? (
                        <Tooltip
                          title={t<string>(
                            "Während einer laufenden Prüfung kann die aktive Organisation nicht gewechselt werden. Bitte Prüfung zuvor beenden."
                          )}
                        >
                          <Typography>{company.itemCount}</Typography>
                        </Tooltip>
                      ) : (
                        <Link
                          component={RouterLink}
                          to={
                            onlyTester
                              ? routes.items
                              : routes.company(company.id) + `?tab=items`
                          }
                          onClick={() => {
                            if (onlyTester) {
                              selectCompanyMutation.mutate(company.id);
                            }
                          }}
                        >
                          {company.itemCount}
                        </Link>
                      )}
                    </TableCell>
                  </Guarded>
                  <Guarded grant={AppGrant.COMPANY_COUNT}>
                    <TableCell columnId="orderCount">
                      {disableLink ? (
                        <Tooltip
                          title={t<string>(
                            "Während einer laufenden Prüfung kann die aktive Organisation nicht gewechselt werden. Bitte Prüfung zuvor beenden."
                          )}
                        >
                          <Typography>{company.orderCount}</Typography>
                        </Tooltip>
                      ) : (
                        <Link
                          component={RouterLink}
                          to={
                            onlyTester
                              ? routes.orders
                              : routes.company(company.id) + `?tab=orders`
                          }
                          onClick={() => {
                            if (onlyTester) {
                              selectCompanyMutation.mutate(company.id);
                            }
                          }}
                        >
                          {company.orderCount}
                        </Link>
                      )}
                    </TableCell>
                  </Guarded>
                  <TableCell align={"right"}>
                    <Guarded requiredRole={[Role.Admin, Role.QA, Role.CEO]}>
                      <>
                        {primaryCompany && company.id === primaryCompany.id ? (
                          <IconLink
                            to={routes.company(company.id)}
                            tooltip={t("Hauptorganisation")}
                            icon={<StyledStar />}
                          />
                        ) : (
                          <IconLink
                            to={routes.company(company.id)}
                            tooltip={t("Ansehen")}
                            icon={<Visibility />}
                          />
                        )}
                      </>
                    </Guarded>
                    <Guarded grant={CompanyGrant.UPDATE_FULL} subject={company}>
                      <IconLink
                        to={routes.companyEdit(company.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                    {!isGranted(user, CompanyGrant.UPDATE_FULL, company) && (
                      <Guarded grant={CompanyGrant.UPDATE} subject={company}>
                        {company.itemCount <= 0 ? (
                          <LoadingIconButton
                            onClick={() => handleDelete(company)}
                            size="small"
                            disabled={company.itemCount > 0}
                            loading={
                              deleteIsLoading && deleteLoading === company
                            }
                          >
                            <Delete />
                          </LoadingIconButton>
                        ) : (
                          <Tooltip
                            title={t<string>(
                              "Kann nicht gelöscht werden, da Items zugeordnet sind."
                            )}
                          >
                            <DisabledIconButton
                              disableRipple={true}
                              size="small"
                            >
                              <Delete />
                            </DisabledIconButton>
                          </Tooltip>
                        )}

                        <Tooltip title={t<string>("Bearbeiten")}>
                          <IconButton
                            onClick={() => setEditDialogOpen(company)}
                            size="small"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Guarded>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!companies.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      {editDialogOpen && (
        <EditCompanyDialog
          open={!!editDialogOpen}
          onClose={() => setEditDialogOpen(null)}
          onUpdate={() => queryClient.invalidateQueries(listCompanyQueryKey)}
          company={editDialogOpen}
        />
      )}
    </TableContextContainer>
  );
};

export default CompanyList;
