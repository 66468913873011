import {
  Blameable,
  BlameableUser,
  Category,
  CheckProcessGroup,
  CheckReport,
  Commentable,
  Company,
  CompanyPreview,
  DateTimeString,
  Grantable,
  ID,
  ItemDocumentation,
  ItemDocumentationPreview,
  Location,
  LocationPreview,
  Nullable,
  Order,
  OrderPreview,
  Person,
  PersonPreview,
  Product,
  ProductPreview,
  Timestampable,
} from "./";

// Sync with ItemVoter in backend
export enum ItemGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
}

export enum ItemCheckStatus {
  Critical = "critical",
  Pending = "pending",
  Planning = "planning",
}

export interface ItemPreview {
  id: ID;
  serialNumber: string;
  product: ProductPreview | null;
  customer: CompanyPreview | null;
  location: LocationPreview | null;
  person: PersonPreview;
  archived: boolean;
}

export interface ItemListPreview
  extends ItemPreview,
    Timestampable,
    BlameableUser,
    Commentable,
    Grantable<ItemGrant> {
  productName: string;
  manufacturerName: string;
  manufacturingYear: string;
  itemChecks: ItemCheckListPreview[];
  lastCheck: ItemCheckListPreview | null;
  firstUse: DateTimeString | null;
  personName: string | null;
  locationName: string | null;
  parentLocation: string; // not from the API but for table head cells
  customerName: string | null;
  address: string | null;
  zipCode: string | null;
  city: string | null;
  lastCheckDate: Nullable<DateTimeString>;
  lastCheckedBy: Nullable<Person>;
  lastCheckResult: Nullable<number>;
  lastCheckHasDefect: Nullable<boolean>;
  defects?: ItemDocumentationPreview[];
  customer: CompanyPreview | null;
  archived: boolean;
  checkStatus: ItemCheckStatus | null;
}

export interface Item extends ItemListPreview {
  systemNumber: string;
  category: Category | null;
  product: Product | null;
  customer: Company | null;
  itemChecks: ItemCheck[];
  lastCheck: ItemCheck | null;
  location: Location | null;
  person: Person;
  defects?: ItemDocumentation[];
  openDefects?: ItemDocumentation[];
}

// Sync with ItemCheckVoter in backend
export enum ItemCheckGrant {
  VIEW = "view",
  UPDATE = "update",
  UPDATE_PARTIAL = "update_partial",
}

export interface ItemCheck
  extends ItemCheckPreview,
    Timestampable,
    Blameable,
    Commentable {
  order: Order | null;
  orderNumber: string;
  checkedBy: Person;
  person: Person;
  personName: Person | null;
  location: Location;
  item: Item;
  result: CheckProcessGroup[] | null;
  checkReport?: CheckReport;
  hasDefect?: boolean;
  itemDocumentations: ItemDocumentationPreview[];
}

export interface ItemCheckListPreview {
  id: ID;
  checkResult: number;
  order: OrderPreview | null;
  checkedBy: PersonPreview;
  checkDate: DateTimeString;
  checkReport?: CheckReport;
  hasDefect?: boolean;
}

export interface ItemCheckPreview {
  id: ID;
  checkResult: number;
  order: OrderPreview | null;
  checkDate: DateTimeString;
  item: ItemPreview;
}

export interface ItemProductFormValues {
  model: string;
  category: ID | null;
  manufacturer: ID | null;
  manufacturingYear: string;
}

export interface ItemFormValues {
  serialNumber: string;
  product: ID | null;
  customer: ID | null;
  manufacturingYear: string;
  systemNumber: string;
  firstUse: DateTimeString | null;
  comment: string;
  location: ID | null;
  person: ID | null;
  newManufacturer?: string;
  newProduct?: ItemProductFormValues;
}

export interface ItemFilters {
  search?: string;
  category?: string;
  contact?: string;
  checkResult?: string;
  company?: ID | string;
  order?: ID | string;
  location?: ID | string;
  contactId?: ID | string;
  status?: string;
  checkStatus?: ItemCheckStatus | "";
}

export const getItemFormValues = (
  item?: Item,
  company?: Company
): ItemFormValues => ({
  serialNumber: item?.serialNumber || "",
  systemNumber: item?.systemNumber || "",
  product: item?.product?.id || null,
  manufacturingYear: item?.manufacturingYear || "",
  firstUse: item?.firstUse || null,
  customer: company ? company.id : item?.customer?.id || null,
  comment: item?.comment || "",
  location: item?.location?.id || null,
  person: item?.person?.id || null,
});

export interface ItemCheckFormValues {
  checkDate: DateTimeString | null;
  checkedBy: ID | string;
  person: ID | null;
}

export const getItemCheckFormValues = (
  itemCheck?: ItemCheck
): ItemCheckFormValues => ({
  checkDate: itemCheck?.checkDate || "",
  checkedBy: itemCheck?.checkedBy.id || "",
  person: itemCheck?.person?.id || null,
});
