import React, { VoidFunctionComponent } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { CheckStep } from "../../model";
import { Spellcheck } from "@mui/icons-material";

const TextCheckListItem: VoidFunctionComponent<{
  checkStep: CheckStep;
  paddingLeft?: string;
}> = ({ checkStep, paddingLeft }) => (
  <ListItem style={{ paddingLeft: paddingLeft || 0 }} key={checkStep.id}>
    <ListItemText
      primary={
        checkStep.title +
        (checkStep.description ? ` (${checkStep.description})` : "")
      }
      secondary={checkStep.value as string}
    />
    <ListItemIcon>
      <Spellcheck />
    </ListItemIcon>
  </ListItem>
);

export default TextCheckListItem;
