import React, { useState, VoidFunctionComponent } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { Check, Location } from "../../model";
import { FormValues } from "./index";
import { Option } from "./helper";
import { useTranslation } from "react-i18next";
import LocationCreateDialog from "../location-create-dialog";
import { apiRoutes, request } from "../../lib/api";
import { useSnackbar } from "notistack";

const filter = createFilterOptions<Option>();
const createLocationOptions = (locations: Location[]): Option[] =>
  locations.map((location) => ({
    value: location.id.toString(),
    label: `${location.name}${
      location.parent ? ` (${location.parent.name})` : ""
    }`,
  }));

const LocationSelector: VoidFunctionComponent<{
  check: Check;
  control: Control<FormValues>;
  locations: Location[];
  setValue: UseFormSetValue<FormValues>;
  allowedLocation: boolean;
}> = ({ check, control, locations, setValue, allowedLocation = true }) => {
  const { t } = useTranslation();
  const [locationOptions, setLocationOptions] = useState(
    createLocationOptions(locations)
  );
  const [createLocationOpen, setCreateLocationOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [currentName, setCurrentName] = useState<string>("");

  const onLocationUpdate = (newLocation: Location) => {
    request<Location[]>(
      apiRoutes.companyLocations(check.order.customer.id),
      "get"
    )
      .then((res) => {
        setLocationOptions(createLocationOptions(res.data));
        setValue("location", newLocation.id);
      })
      .catch(() => {
        enqueueSnackbar(t("Orte konnten nicht geladen werden."), {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Controller
        control={control}
        name="location"
        render={({ field, fieldState }) => (
          <Autocomplete
            id="location"
            options={locationOptions}
            disabled={!!check.currentItem}
            getOptionKey={(option) => option.value || ""}
            getOptionLabel={(option) => option.label}
            value={
              locationOptions.find(
                (item) =>
                  item.value && field.value && +item.value === +field.value
              ) || null
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, value) => {
              if (!value || !value.value) {
                field.onChange(null);
                return;
              }
              if (value.value.substr(0, 3) === "new") {
                setCurrentName(value.value.substr(3));
                return setCreateLocationOpen(true);
              }
              field.onChange(value.value);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  value: `new${params.inputValue}`,
                  label: t(`Ort "{{name}}" hinzufügen`, {
                    name: params.inputValue,
                  }),
                });
              }
              return filtered;
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label={t("Ort/Abteilung")}
                  error={fieldState.isTouched && fieldState.invalid}
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
                {!allowedLocation && (
                  <Typography color="error">
                    Achtung: Ort/Abteilung ist dem Auftraggeber nicht zugeteilt!
                  </Typography>
                )}
              </>
            )}
          />
        )}
      />
      {createLocationOpen && (
        <LocationCreateDialog
          open={createLocationOpen}
          onClose={() => setCreateLocationOpen(false)}
          company={check.order.customer}
          onLocationUpdate={onLocationUpdate}
          name={currentName}
        />
      )}
    </>
  );
};

export default LocationSelector;
