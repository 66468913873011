import { Grid, GridProps, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

interface GridDataProps extends GridProps {
  label: React.ReactNode;
}

export const GridData: FunctionComponent<GridDataProps> = ({
  label,
  children,
  ...props
}) => {
  return (
    <Grid item {...props}>
      <Typography variant="overline" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </Grid>
  );
};
