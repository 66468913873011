import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { getPersonFormValues, Location } from "../../model";
import { usePersonDetailsApi } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LoadingButton from "../../components/loading-button";
import { Option } from "../../components/order-processing/helper";
import { PersonFormFields } from "../../components/person-form-fields";
import { usePersonForm, usePersonUpdateMutation } from "../../hooks";

export const locationsFilter = createFilterOptions<Option>();
export const createLocationOptions = (locations: Location[]): Option[] =>
  locations.map((location) => ({
    value: location.id.toString(),
    label: `${location.name}${
      location.parent ? ` (${location.parent.name})` : ""
    }`,
  }));

const EditPerson: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string | undefined }>();
  const { isLoading, error, data } = usePersonDetailsApi(
    personId as unknown as number
  );
  const history = useHistory();

  useTitle(data?.lastName || t("Person bearbeiten"));

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
    getValues,
  } = usePersonForm(getPersonFormValues(data));

  const { mutateAsync: updateMutation } = usePersonUpdateMutation(
    setError,
    data
  );

  useEffect(() => reset(getPersonFormValues(data)), [reset, data]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.persons}>
            {t("Zurück zu Personen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  if (data?.reducedView || data?.editable === false) {
    history.push(routes.person(data?.id));
    return <></>;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Personen"), link: routes.persons },
    {
      label: data.firstName + " " + data.lastName,
      link: routes.person(data.id),
    },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Person")} breadcrumbs={breadcrumbs} />
      <Card>
        <PersonFormFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          person={data}
        />
        <Divider />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit((values) => updateMutation(values))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default EditPerson;
