import React, { FunctionComponent, useState } from "react";
import {
  Company,
  HeadCell,
  ItemCheckStatus,
  Location,
  LocationFilters,
  Role,
} from "../../model";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { Add, Delete, Edit, FindInPage } from "@mui/icons-material";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { TableNoResult } from "../table-no-result";
import LocationEditDialog from "../location-edit-dialog";
import LocationCreateDialog from "../location-create-dialog";
import { apiRoutes, getAbsoluteApiUrl, request } from "../../lib/api";
import { useSnackbar } from "notistack";
import { DisabledIconButton } from "../globals";
import { routes } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import LoadingIconButton from "../loading-icon-button";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyClient } from "../../lib/security";

const LocationList: FunctionComponent<{
  locations: Location[];
  loading?: boolean;
  params?: FilterParams<LocationFilters, Location>;
  createSortHandler?: SortHandler<Location>;
  company?: Company;
  onLocationUpdate: (location: Location) => void;
  editable?: boolean;
  hideCreate?: boolean;
}> = ({
  locations,
  loading,
  params,
  createSortHandler,
  company,
  onLocationUpdate,
  editable = true,
  hideCreate = false,
}) => {
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [deleteLocation, setDeleteLocation] = useState<Location | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const onlyClient = isOnlyClient(user);

  const headCells: HeadCell<Location>[] = [
    { id: "name", label: t("Name") },
    { id: "comment", label: t("Kommentar"), sortable: false },
    { id: "companies", label: t("Organisation"), sortable: false },
    { id: "parent", label: t("Übergeordneter Ort"), sortable: false },
    { id: "locations", label: t("Zugeordnete Orte"), sortable: false },
    {
      id: "personCount",
      label: t("# Personen"),
      align: "center",
      sortable: false,
    },
    {
      id: "itemsCount",
      label: t("# Items"),
      align: "center",
      initSortDir: "desc",
    },
    {
      id: "itemCheckStatusPlanningCount",
      label: t("# Planen"),
      align: "center",
      initSortDir: "desc",
    },
    {
      id: "itemCheckStatusCriticalCount",
      label: t("# Kritisch"),
      align: "center",
      initSortDir: "desc",
    },
    {
      id: "itemCheckStatusPendingCount",
      label: t("# Ausständig"),
      align: "center",
      initSortDir: "desc",
    },
  ];

  const handleOpen = (location: Location) => {
    setCurrentLocation(location);
    setEditDialogOpen(true);
  };

  const handleClose = () => setEditDialogOpen(false);

  const handleDelete = (location: Location) => {
    const confirm = window.confirm(
      t(`Wollen Sie die Ort {{name}} wirklich löschen?`, {
        name: location.name,
      })
    );
    if (!confirm) {
      return;
    }
    setDeleteLocation(location);
    request<Location>(apiRoutes.location(location.id), "delete")
      .then((res) => {
        enqueueSnackbar(t("Ort wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        onLocationUpdate(res.data);
        setDeleteLocation(null);
      })
      .catch(() =>
        enqueueSnackbar(t("Ort konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells
              .filter((headCell) => {
                if (onlyClient) {
                  return headCell.id !== "companies";
                }
                return true;
              })
              .map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={
                    params && params.orderBy === headCell.id
                      ? params.order
                      : false
                  }
                >
                  {createSortHandler && headCell.sortable !== false ? (
                    <TableSortLabel
                      active={params && params.orderBy === headCell.id}
                      direction={
                        params && params.orderBy === headCell.id
                          ? params.order
                          : headCell.initSortDir || "asc"
                      }
                      onClick={createSortHandler(
                        headCell.id,
                        headCell.initSortDir
                      )}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
            <TableCell align={"right"} width="150px">
              {editable && !hideCreate && (
                <>
                  <Button
                    color="primary"
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                    startIcon={<Add />}
                  >
                    {t("Neuer Ort/Abteilung")}
                  </Button>
                  {createDialogOpen && (
                    <LocationCreateDialog
                      open={createDialogOpen}
                      onClose={() => setCreateDialogOpen(false)}
                      company={company}
                      onLocationUpdate={onLocationUpdate}
                      redirectRoute={
                        company ? routes.company(company.id) : undefined
                      }
                    />
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {editable && currentLocation && (
                <LocationEditDialog
                  open={editDialogOpen}
                  onClose={() => handleClose()}
                  location={currentLocation}
                  company={company}
                  onLocationUpdate={onLocationUpdate}
                />
              )}
              {locations.map((location) => (
                <TableRow key={location.id} hover={true}>
                  <TableCell>{location.name}</TableCell>
                  <TableCell>{location.comment}</TableCell>
                  {!onlyClient && (
                    <TableCell>
                      {location.companies.map((company) => (
                        <Link
                          component={RouterLink}
                          to={routes.company(company.id)}
                          display="block"
                          key={company.id}
                        >
                          {company.name}
                        </Link>
                      ))}
                    </TableCell>
                  )}
                  <TableCell>
                    {location.parent && (
                      <>
                        {editable && !onlyClient ? (
                          <Link
                            onClick={() => {
                              if (location.parent) {
                                handleOpen(location.parent);
                              }
                            }}
                            display={"block"}
                          >
                            {location.parent.name}
                          </Link>
                        ) : (
                          <>{location.parent.name}</>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {location.locations.map((child) => (
                      <Box key={child.id}>
                        {editable && !onlyClient ? (
                          <Link
                            onClick={() => handleOpen(child)}
                            display={"block"}
                            key={child.id}
                          >
                            {child.name}
                          </Link>
                        ) : (
                          <Box>{child.name}</Box>
                        )}
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell align={"center"}>
                    <Link
                      component={RouterLink}
                      to={`${routes.persons}?company=${
                        location.companies[0] ? location.companies[0].id : ""
                      }&location=${location.id}`}
                      target="_blank"
                    >
                      {location.personCount}
                    </Link>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Link
                      component={RouterLink}
                      to={`${routes.items}?location=${location.id}&status=active`}
                      target="_blank"
                    >
                      {location.itemsCount}
                    </Link>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Link
                      component={RouterLink}
                      to={`${routes.items}?checkStatus=${ItemCheckStatus.Planning}&location=${location.id}&status=active`}
                      target="_blank"
                    >
                      {location.itemCheckStatusPlanningCount}
                    </Link>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Link
                      component={RouterLink}
                      to={`${routes.items}?checkStatus=${ItemCheckStatus.Critical}&location=${location.id}&status=active`}
                      target="_blank"
                    >
                      {location.itemCheckStatusCriticalCount}
                    </Link>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Link
                      component={RouterLink}
                      to={`${routes.items}?checkStatus=${ItemCheckStatus.Pending}&location=${location.id}&status=active`}
                      target="_blank"
                    >
                      {location.itemCheckStatusPendingCount}
                    </Link>
                  </TableCell>
                  <TableCell align={"right"}>
                    {location.document && (
                      <Tooltip title={t<string>("Lagerplan ansehen")}>
                        <a
                          href={getAbsoluteApiUrl(
                            apiRoutes.documentFile(location.document.id)
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton
                            aria-label={t("Lagerplan ansehen")}
                            size="small"
                          >
                            <FindInPage />
                          </IconButton>
                        </a>
                      </Tooltip>
                    )}
                    {editable && (
                      <>
                        <Guarded requiredRole={Role.Admin}>
                          {!location.locations.length &&
                          location.companies.length <= 1 &&
                          location.itemsCount === 0 ? (
                            <Tooltip title={t<string>("Löschen")}>
                              <span>
                                <LoadingIconButton
                                  onClick={() => handleDelete(location)}
                                  size="small"
                                  loading={deleteLocation === location}
                                >
                                  <Delete />
                                </LoadingIconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t<string>(
                                "Kann nicht gelöscht werden, da Items, andere Orte oder weitere Organisationen zugeordnet sind."
                              )}
                            >
                              <DisabledIconButton
                                disableRipple={true}
                                size="small"
                              >
                                <Delete />
                              </DisabledIconButton>
                            </Tooltip>
                          )}
                        </Guarded>
                        <Guarded requiredRole={[Role.Admin, Role.Client]}>
                          <Tooltip title={t<string>("Bearbeiten")}>
                            <IconButton
                              onClick={() => handleOpen(location)}
                              size="small"
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Guarded>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!locations.length && (
              <TableNoResult text={t("Es wurden noch keine Orte verknüpft.")} />
            )}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default LocationList;
