import { useMemo } from "react";
import * as yup from "yup";
import { useQualificationTagValidation } from "./use-qualification-tag-validation";

export const useQualificationTagValidationSchema = () => {
  const qualificationTagValidation = useQualificationTagValidation();

  return useMemo(() => {
    return yup.object({
      ...qualificationTagValidation,
    });
  }, [qualificationTagValidation]);
};
