import { Box, CircularProgress, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

const QueueTaskProgressIndicator: FunctionComponent<{
  progress: number;
}> = ({ progress }) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      <Typography variant="caption" style={{ marginRight: ".5em" }}>
        {progress}%
      </Typography>
      <CircularProgress size={18} color="inherit" />
    </Box>
  );
};

export default QueueTaskProgressIndicator;
