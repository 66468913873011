import { useTitle } from "../../hooks/use-title";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  Category,
  CheckProcessFormValues,
  CheckProcessState,
  getCheckProcessFormValues,
  Product,
  Qualification,
} from "../../model";
import { apiRoutes, request, useCheckProcessDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { capitalizeStyle, StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleHookFormErrors } from "../../helpers";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import LoadingButton from "../../components/loading-button";
import { useCheckProcessValidationSchema } from "../../hooks/validations/check-processes/use-check-process-validation-schema";
import { ProductOption } from "components/product-option";

const EditCheckProcess = () => {
  const { t } = useTranslation();
  const { checkProcessId } = useParams<{
    checkProcessId: string | undefined;
  }>();
  const { isLoading, error, data } = useCheckProcessDetailsApi(
    checkProcessId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCheckProcessValidationSchema();

  useTitle(data?.name || t("Prüfprozess bearbeiten"));

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useUnlimitedPaginationApi<{}, Product>(apiRoutes.products, undefined, {
    productOption: true,
  });
  const {
    data: qualifications,
    isLoading: qualificationsLoading,
    error: qualificationsError,
  } = useUnlimitedPaginationApi<{}, Qualification>(apiRoutes.qualifications);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<CheckProcessFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCheckProcessFormValues(data),
  });

  const onSubmit = async (
    values: CheckProcessFormValues,
    setError: UseFormSetError<CheckProcessFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<Category>(apiRoutes.checkProcess(data.id), "put", values)
      .then(() => {
        enqueueSnackbar(t("Prüfprozess wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.checkProcess(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getCheckProcessFormValues(data)), [reset, data]);

  if (error || categoriesError || productsError || qualificationsError) {
    return (
      <HttpError
        error={[error, categoriesError, productsError, qualificationsError]}
        actions={
          <Button component={Link} to={routes.checkProcesses}>
            {t("Zurück zu Prüfprozessen")}
          </Button>
        }
      />
    );
  }

  if (
    isLoading ||
    !data ||
    categoriesLoading ||
    productsLoading ||
    qualificationsLoading
  ) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prüfprozess"), link: routes.checkProcesses },
    { label: data.name, link: routes.checkProcess(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Kategorie")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prozessdaten")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"name"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Name")}
                      fullWidth
                      {...field}
                      required
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"status"}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{t("Status")}</InputLabel>
                      <Select
                        variant="standard"
                        fullWidth
                        {...field}
                        value={field.value}
                      >
                        <MenuItem
                          sx={capitalizeStyle}
                          value={CheckProcessState.Draft}
                          key={CheckProcessState.Draft}
                        >
                          {t(`checkProcess.status.${CheckProcessState.Draft}`)}
                        </MenuItem>
                        <MenuItem
                          sx={capitalizeStyle}
                          value={CheckProcessState.Inactive}
                          key={CheckProcessState.Inactive}
                        >
                          {t(
                            `checkProcess.status.${CheckProcessState.Inactive}`
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Beschreibung")}
                      fullWidth
                      {...field}
                      multiline={true}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="protocolSubtitle"
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Untertitel für PDF Protokoll")}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Zuweisungen")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"categories"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="categories"
                      multiple={true}
                      options={categories}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={data.categories}
                      onChange={(event, value) =>
                        field.onChange(value.map((category) => category.id))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Kategorien")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"products"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="products"
                      multiple={true}
                      options={products}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          <ProductOption product={option} />
                        </li>
                      )}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.displayName}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={data.products}
                      onChange={(event, value) =>
                        field.onChange(value.map((product) => product.id))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Produkte")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"qualifications"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="qualifications"
                      multiple={true}
                      options={qualifications}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={data.qualifications}
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Notwendige Qualifikationen")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Sonstiges")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Interne Notiz")}
                      fullWidth
                      {...field}
                      multiline={true}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {data.status === CheckProcessState.Approved && (
                  <Alert severity="warning">
                    <AlertTitle>{t("Achtung")}</AlertTitle>
                    {t(
                      "Nach dem Bearbeiten eines Prüfprozesses, muss er erneut von der Geschäftsführung freigegeben werden."
                    )}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  size="medium"
                  color="primary"
                  disabled={!isValid}
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t("Speichern")}
                </LoadingButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditCheckProcess;
