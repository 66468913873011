import {
  Blameable,
  Commentable,
  Company,
  CompanyPreview,
  DateTimeString,
  Document,
  DocumentFormValues,
  ID,
  Location,
  Nullable,
  Qualification,
  Timestampable,
  User,
} from "./";

// Sync with PersonVoter in backend
export enum PersonGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  FULL_VIEW = "fullView",
  SAME_COMPANY = "sameCompany",
}

export interface Person extends Timestampable, Blameable, Commentable {
  id: ID;
  firstName: string;
  lastName: string;
  titleBefore: string | null;
  titleAfter: string | null;
  email: string | null;
  phone: string | null;
  mobilePhone: string | null;
  birthdate?: DateTimeString | null;
  externalCode?: string | null;
  enabled?: boolean;
  companies?: Company[];
  user?: User | null;
  displayName: Nullable<string>;
  personQualifications?: PersonQualification[];
  primaryCompany: Company | null;
  locations?: Location[];
  archived?: boolean;
  reducedView?: boolean;
  editable?: boolean;
}

export interface PersonPreview {
  id: ID;
  firstName: string;
  lastName: string;
  titleBefore: string | null;
  titleAfter: string | null;
  displayName: Nullable<string>;
  primaryCompany: CompanyPreview | null;
}

// Sync with PersonQualificationVoter in backend
export enum PersonQualificationGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export enum PersonQualificationStatus {
  Valid = "valid",
  Invalid = "invalid",
  PartiallyValid = "partially-valid",
}

export interface PersonQualification extends Timestampable, Blameable {
  id: ID;
  date: DateTimeString;
  expirationDate: DateTimeString | null;
  expired: boolean;
  company: Company;
  qualification: Qualification;
  status: PersonQualificationStatus;
  documents: Document[];
  person?: Person;
}

export interface PersonFormValues {
  firstName: string;
  lastName: string;
  titleBefore: string | null;
  titleAfter: string | null;
  email: string | null;
  phone: string | null;
  mobilePhone: string | null;
  birthdate: DateTimeString | null;
  externalCode: string | null;
  archived: boolean;
  comment: string | null;
  user: ID | string;
  companies: ID[];
  primaryCompany: ID | null;
  locations: ID[];
}

export interface PersonQualificationFormValues {
  date: DateTimeString;
  expirationDate: DateTimeString | null;
  company: ID | null;
  qualification: ID | null;
  person: ID | null;
  status: PersonQualificationStatus;
  documents: ID[];
  showUpload: boolean;
  documentUploads: DocumentFormValues[];
}

export interface PersonFilters {
  search: string;
  company: string;
  location: string;
}

export const getPersonFormValues = (
  person?: Person,
  user?: User
): PersonFormValues => ({
  firstName: person?.firstName || "",
  lastName: person?.lastName || "",
  titleBefore: person?.titleBefore || "",
  titleAfter: person?.titleAfter || "",
  email: person?.email || "",
  phone: person?.phone || "",
  mobilePhone: person?.mobilePhone || "",
  externalCode: person?.externalCode || "",
  birthdate: person?.birthdate || null,
  archived: !!person?.archived,
  comment: person?.comment || "",
  companies: person?.companies
    ? person?.companies.map((company) => company.id)
    : [],
  user: user ? user.id : person?.user?.id || "",
  primaryCompany: person?.primaryCompany?.id || null,
  locations: person?.locations
    ? person?.locations.map((location) => location.id)
    : [],
});

export const getPersonQualificationFormValues = (
  personQualification?: PersonQualification
): PersonQualificationFormValues => ({
  company: personQualification?.company.id || null,
  qualification: personQualification?.qualification.id || null,
  date: personQualification?.date || "",
  expirationDate: personQualification?.expirationDate || "",
  status: personQualification?.status || PersonQualificationStatus.Invalid,
  documents: personQualification?.documents
    ? personQualification?.documents.map((document) => document.id)
    : [],
  person: personQualification?.person?.id || null,
  showUpload: false,
  documentUploads: [],
});
