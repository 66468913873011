import { FunctionComponent } from "react";
import { CircularProgress, IconButton, IconButtonProps } from "@mui/material";

const LoadingIconButton: FunctionComponent<
  { loading: boolean } & IconButtonProps
> = ({ children, loading, ...props }) => {
  return (
    <IconButton size="large" {...props} disabled={loading || props.disabled}>
      {loading ? <CircularProgress size={16} /> : children}
    </IconButton>
  );
};

export default LoadingIconButton;
