import React, { FunctionComponent } from "react";
import { Control, useFieldArray } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import DocumentUpload from "./index";
import { Add, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledGrid = styled(Grid)`
  margin-left: auto;
`;

const DocumentUploadsArray: FunctionComponent<{
  control: Control<any>;
}> = ({ control }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: "documentUploads",
  });
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} key={field.id}>
            <Card>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>{index + 1}</Grid>
                  <Grid item xs={10}>
                    <DocumentUpload
                      control={control}
                      formPrefix={`documentUploads.${index}.`}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      style={{ height: "100%" }}
                    >
                      <Grid item>
                        <Tooltip title={t<string>("Dokument entfernen")}>
                          <IconButton
                            type="button"
                            onClick={() => remove(index)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
      <StyledGrid item>
        <Button type="button" onClick={() => append({})}>
          <Add />
          {t("Weiteres Dokument")}
        </Button>
      </StyledGrid>
    </Grid>
  );
};

export default DocumentUploadsArray;
