import React, { VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTranslation } from "react-i18next";
import { StyledContainer } from "../../components/globals";
import OrderProcessing from "../../components/order-processing";
import LoadingContainer from "../../components/loading-container";
import { useTitle } from "../../hooks/use-title";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyTester } from "../../lib/security";
import { useQueryClient } from "react-query";
import {
  CheckQueryKey,
  useCheckQuery,
} from "../../hooks/queries/use-check-query";

const Check: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  useTitle(onlyTester ? t("Prüfung") : t("Auftragsabwicklung"));
  const { data, isLoading } = useCheckQuery();
  const queryClient = useQueryClient();

  return (
    <StyledContainer data-test="item-check-content">
      <Header
        title={onlyTester ? t("Prüfung durchführen") : t("Auftragsabwicklung")}
      />
      {!isLoading ? (
        <OrderProcessing
          check={data || null}
          onCheckUpdate={(check) =>
            queryClient.setQueryData(CheckQueryKey, check)
          }
        />
      ) : (
        <LoadingContainer />
      )}
    </StyledContainer>
  );
};

export default Check;
