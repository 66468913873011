import React, { VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  useFieldArray,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledCardTitle, StyledColoredCardContent } from "../globals";
import { Flipped, Flipper } from "react-flip-toolkit";
import {
  Autocomplete,
  Card,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Add, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { CheckGroupFormValues, CheckStep } from "../../model";
import { apiRoutes } from "../../lib/api";

const CheckStepsFieldArray: VoidFunctionComponent<{
  control: Control<CheckGroupFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data: checkSteps,
    isLoading: checkStepsLoading,
    error: checkStepsError,
  } = useUnlimitedPaginationApi<{}, CheckStep>(apiRoutes.checkSteps);

  const { fields, remove, append, swap } = useFieldArray({
    control,
    name: `checkSteps`,
  });

  return (
    <Card style={{ marginTop: theme.spacing(3) }}>
      <StyledColoredCardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Prüfschritte")}
        </StyledCardTitle>
        {fields.length > 0 && (
          <Flipper
            flipKey={fields
              .map((field: FieldArrayWithId) => field.id)
              .join("-")}
          >
            <Grid container spacing={3}>
              {fields.map(
                (
                  field: FieldArrayWithId<CheckGroupFormValues>,
                  stepIndex: number
                ) => {
                  return (
                    <Flipped key={field.id} flipId={field.id}>
                      <Grid item xs={12} key={field.id}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item md={2} style={{ textAlign: "center" }}>
                            {stepIndex > 0 && (
                              <IconButton
                                type="button"
                                onClick={() => swap(stepIndex, stepIndex - 1)}
                                size="large"
                              >
                                <ArrowUpward />
                              </IconButton>
                            )}
                            {stepIndex < fields.length - 1 && (
                              <IconButton
                                type="button"
                                onClick={() => swap(stepIndex, stepIndex + 1)}
                                size="large"
                              >
                                <ArrowDownward />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item md={9}>
                            <Controller
                              control={control}
                              name={
                                `checkSteps.${stepIndex}.checkStep` as const
                              }
                              defaultValue={field.checkStep as any}
                              render={({ field, fieldState }) => {
                                return (
                                  <Autocomplete
                                    id={`checkSteps.${stepIndex}.checkStep`}
                                    options={checkSteps}
                                    loading={checkStepsLoading}
                                    disabled={!!checkStepsError}
                                    getOptionKey={(option) => option.id}
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    value={
                                      checkSteps.find(
                                        (checkStep) =>
                                          checkStep.id === field.value
                                      ) || null
                                    }
                                    onChange={(event, value) =>
                                      field.onChange(value?.id)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        variant="standard"
                                        {...params}
                                        label={t("Prüfschritt")}
                                        required
                                        error={
                                          fieldState.isTouched &&
                                          fieldState.invalid
                                        }
                                        helperText={fieldState.error?.message}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Tooltip title={t<string>("Prüfschritt löschen")}>
                              <IconButton
                                type="button"
                                onClick={() => remove(stepIndex)}
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Flipped>
                  );
                }
              )}
            </Grid>
          </Flipper>
        )}
        <Grid container spacing={5} justifyContent="space-around">
          <Grid item>
            <Tooltip title={t<string>("Prüfschritt hinzufügen")}>
              <Fab
                type="button"
                onClick={() =>
                  append({
                    checkStep: null,
                    sortOrder: fields.length,
                  })
                }
                color="primary"
                size="small"
              >
                <Add />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledColoredCardContent>
    </Card>
  );
};

export default CheckStepsFieldArray;
