import React, { VoidFunctionComponent } from "react";
import { Nullable, Order } from "../../model";
import { Definition } from "../globals";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const OrderDetails: VoidFunctionComponent<{
  order: Nullable<Order>;
}> = ({ order }) => {
  const { t } = useTranslation();

  if (!order) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Definition title={t("Projektname")} value={order.sign} />
      </Grid>
      <Grid item xs={12}>
        <Definition title={t("Kunde")} value={order.customer.name} />
      </Grid>
      {order.internalContact && (
        <Grid item xs={12}>
          <Definition
            title={t("Interne Kontaktperson")}
            value={`${order.internalContact.firstName} ${order.internalContact.lastName}`}
          />
        </Grid>
      )}
      {order.externalContact && (
        <Grid item xs={12}>
          <Definition
            title={t("Externe Kontaktperson")}
            value={`${order.externalContact.firstName} ${order.externalContact.lastName}`}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderDetails;
