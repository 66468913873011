import { AxiosError, Method } from "axios";
import { QueryKey, useQuery } from "react-query";
import { request } from "../lib/api";
import { Dict } from "../model";
import { UseQueryOptions } from "react-query/types/react/types";

export const useApi = <TData>(
  url: string,
  method: Method = "get",
  requestData?: unknown,
  params?: unknown,
  headers?: Dict,
  options?: UseQueryOptions<TData, AxiosError<any>, TData, QueryKey>
) => {
  return useQuery<TData, AxiosError>(
    [url, params],
    async () => {
      const { data } = await request<TData>(
        url,
        method,
        requestData,
        params,
        headers
      );
      return data;
    },
    {
      ...options,
      refetchOnWindowFocus: false,
    }
  );
};
