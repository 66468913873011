import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { DeliveryDatesValues, getOrderFormValues } from "../../model";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LoadingButton from "../../components/loading-button";
import { useOrderCreateMutation, useOrderForm } from "../../hooks";
import { OrderFormFields } from "../../components/order-form-fields";

const CreateOrder: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neuer Auftrag"));
  const location = useLocation<{ orderNumber?: string }>();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Auftrag"), link: routes.orders },
    { label: t("Neu") },
  ];

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
    trigger,
    setValue,
  } = useOrderForm(getOrderFormValues());

  useEffect(() => {
    if (!location.state || !location.state.orderNumber) {
      return;
    }
    setValue("orderNumber", location.state.orderNumber);
    setTimeout(() => trigger("orderNumber"));
  }, [location.state, setValue, trigger]);

  const { mutateAsync: createMutation } = useOrderCreateMutation(setError);

  const onSubmit = async (values: any) => {
    const deliveryDatesValues: DeliveryDatesValues[] = values.deliveryDates;
    if (deliveryDatesValues) {
      values.deliveryDates = deliveryDatesValues.map((date) => ({
        date: date.date,
        dateTo: date.dateTo,
        badWeather: date.badWeather,
      }));
    }

    await createMutation(values);
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Auftrag")} breadcrumbs={breadcrumbs} />
      <Card>
        <OrderFormFields control={control} watch={watch} setValue={setValue} />
        <Divider />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default CreateOrder;
