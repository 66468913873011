import { useOrderValidation } from "./use-order-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useOrderValidationSchema = () => {
  const orderValidation = useOrderValidation();

  return useMemo(() => {
    return yup.object({
      ...orderValidation,
    });
  }, [orderValidation]);
};
