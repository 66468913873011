import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useStandardValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      number: yup.string().required(t("Nummer darf nicht leer sein.")),
      showUpload: yup.boolean(),
      documentUpload: yup.object().when("showUpload", {
        is: (showUpload: boolean) => showUpload,
        then: yup.object({
          name: yup.string().required(t("Name darf nicht leer sein.")),
          type: yup.string().required(t("Typ darf nicht leer sein.")),
          documentUpload: yup
            .mixed()
            .required(t("Dokument darf nicht leer sein.")),
        }),
      }),
    }),
    [t]
  );
};
