import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useDocumentValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t("Name darf nicht leer sein.")),
      type: yup.string().required(t("Typ darf nicht leer sein.")),
      documentUpload: yup.mixed().required(t("Dokument darf nicht leer sein.")),
    }),
    [t]
  );
};
