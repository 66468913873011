import { useTitle } from "../../hooks/use-title";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  Document,
  DocumentFormValues,
  getDocumentFormValues,
  Role,
} from "../../model";
import { apiRoutes, request, useDocumentDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { StyledCardTitle } from "../../components/globals";
import { useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleHookFormErrors } from "../../helpers";
import { Delete } from "@mui/icons-material";
import Guarded from "../../components/guarded";
import DocumentUpload from "../../components/document-upload";
import LoadingButton from "../../components/loading-button";
import { useDocumentValidationSchema } from "../../hooks/validations/documents/use-document-validation-schema";
import { ValidationMode } from "../../hooks/validations";

const EditDocument = () => {
  const { t } = useTranslation();
  const { documentId } = useParams<{ documentId: string | undefined }>();
  const { isLoading, error, data } = useDocumentDetailsApi(
    documentId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.name || t("Dokument bearbeiten"));

  const validationSchema = useDocumentValidationSchema(ValidationMode.Edit);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<DocumentFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getDocumentFormValues(data),
  });

  const onSubmit = async (
    values: DocumentFormValues,
    setError: UseFormSetError<DocumentFormValues>
  ) => {
    if (!data) {
      return;
    }
    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        name: values.name,
        type: values.type,
        comment: values.comment,
      })
    );

    await request<Document>(apiRoutes.document(data.id), "post", formData)
      .then(() => {
        enqueueSnackbar(t("Dokument wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.documents);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getDocumentFormValues(data)), [reset, data]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.documents}>
            {t("Zurück zu Dokumenten")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Dokument {{name}} wirklich löschen?`, { name: data.name })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.document(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Dokument wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/documents");
      })
      .catch(() =>
        enqueueSnackbar(t("Dokument konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Dokument"), link: routes.documents },
    { label: data.name },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header
        title={t("Dokument")}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.Admin}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <Delete /> {t("Löschen")}
            </Button>
          </Guarded>
        }
      />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Dokument")}
            </StyledCardTitle>
            <DocumentUpload control={control} />
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditDocument;
