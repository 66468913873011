import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Company, DefectFormValues, Location, Role } from "../../model";
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { StyledCardTitle } from "../globals";
import { hasRole } from "../../lib/security";
import { apiRoutes, request } from "../../lib/api";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useMutation } from "react-query";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";

export const DefectFormFields: VoidFunctionComponent<{
  control: Control<DefectFormValues>;
  watch: UseFormWatch<DefectFormValues>;
  setValue: UseFormSetValue<DefectFormValues>;
  company?: Company;
}> = ({ control, watch, setValue, company }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [locations, setLocations] = useState<Location[]>([]);
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<{}, Company>(apiRoutes.companies, undefined, {
    companyOption: true,
  });

  const companyValue = watch("company");

  const { mutateAsync: locationMutation, isLoading: locationLoading } =
    useMutation(
      async () => {
        if (!companyValue) {
          return;
        }
        return await request<Location[]>(
          apiRoutes.companyLocations(+companyValue),
          "get"
        );
      },
      {
        onSuccess: (res) => {
          if (!res) {
            return;
          }
          setLocations(res.data);
        },
      }
    );

  useEffect(() => {
    if (!companyValue) {
      setValue("location", null);
      return setLocations([]);
    }
    locationMutation();
  }, [companyValue, locationMutation, setValue]);

  if (companiesError) {
    return (
      <HttpError
        error={[companiesError]}
        actions={
          <Button component={Link} to={routes.defects}>
            {t("Zurück zu Mängeln")}
          </Button>
        }
      />
    );
  }

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Mangel")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Controller
              control={control}
              name={"title"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Titel")}
                  fullWidth
                  {...field}
                  required
                  disabled={!hasRole(currentUser, Role.Admin)}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"company"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="company"
                  loading={companiesLoading}
                  loadingText={t("Lädt Organisationen...")}
                  noOptionsText={t("Es wurden keine Organisationen gefunden")}
                  options={companies}
                  disabled={!!company}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  value={
                    companies.find((company) => company.id === field.value) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Organisation")}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      required={true}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"location"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="location"
                  noOptionsText={t(
                    "Es wurden keine Orte/Abteilungen zur Organisation gefunden"
                  )}
                  loading={locationLoading}
                  loadingText={t("Lädt Orte/Abteilungen für Organisation...")}
                  options={locations}
                  getOptionLabel={(option) => option.name}
                  value={
                    locations.find((company) => company.id === field.value) ||
                    null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Ort/Abteilung")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
