import { darken, lighten, ThemeOptions } from "@mui/material";

const MaterialCommonTheme: ThemeOptions = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 300,
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
    },
  },
};

export const MaterialLightTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    mode: "light",
    background: {
      default: "#fafafa",
    },
    primary: {
      main: "#00677f",
    },
    secondary: {
      main: "#000",
    },
  },
};

export const MaterialDarkTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#5097ab",
    },
    secondary: {
      main: "#fff",
    },
  },
};

export const CustomTheme = {
  navigation: {
    width: 260,
  },
  width: {
    full: "100%",
    0: "0",
    "1/2": "50%",
    "1/3": "33.33%",
    "2/3": "66.67%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
  },
  color: {
    main: {
      900: darken("#00677f", 0.6),
      800: darken("#00677f", 0.5),
      700: darken("#00677f", 0.4),
      600: darken("#00677f", 0.2),
      500: "#00677f",
      400: lighten("#00677f", 0.25),
      300: lighten("#00677f", 0.5),
      200: lighten("#00677f", 0.75),
      100: lighten("#00677f", 0.95),
    },
    lightBackground: "rgba(0,103,127, 0.1)",
  },
};
