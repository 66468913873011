import React from "react";
import ReactDOM from "react-dom";
import App from "./views/app";
import reportWebVitals from "./reportWebVitals";
import { getUser } from "./lib/api";
import Login from "./views/login";
import "./i18n";

(async () => {
  const user = await getUser();
  ReactDOM.render(
    <React.StrictMode>
      {user ? <App user={user} /> : <Login />}
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
