import { FunctionComponent } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const StyledTypography = styled(Typography)`
  padding: 0 16px;
  margin-top: 2em;

  &:first-child {
    margin-top: 0;
  }
`;

const Category: FunctionComponent<{ label: string }> = ({ label }) => (
  <StyledTypography variant="overline" display="block" gutterBottom>
    {label}
  </StyledTypography>
);

export default Category;
