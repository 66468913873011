import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  Switch,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  AppGrant,
  Company,
  DateFormat,
  Order,
  OrderFilters,
} from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useCallback, useContext, useEffect } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import OrderList from "../../components/order-list";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { useCurrentUser } from "hooks/use-current-user";
import { isOnlyClient, isOnlyTester } from "lib/security";
import Guarded from "../../components/guarded";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid } from "date-fns";

export const StyledFilterFormControl = styled(FilterFormControl)`
  width: ${(props) => props.theme.width["1/5"]};
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const OrdersOverview = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const selectedCompany = useSelectedCompany();

  useTitle(t("Aufträge"));

  const isTester = isOnlyTester(user);

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Aufträge") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [customer, setFilterCustomer] = useQueryState(
    "",
    "customer",
    queryStateOptions
  );
  const [inputCustomer, setInputCustomer] = useDebounceState(
    customer,
    setFilterCustomer
  );
  const [customerId, setCustomerId] = useQueryState(
    isTester && selectedCompany ? "" + selectedCompany.id : null,
    "customerId",
    queryStateOptions
  );
  const [orderNumber, setFilterOrderNumber] = useQueryState(
    "",
    "orderNumber",
    queryStateOptions
  );
  const [inputOrderNumber, setInputOrderNumber] = useDebounceState(
    orderNumber,
    setFilterOrderNumber
  );
  const [sign, setFilterSign] = useQueryState("", "sign", queryStateOptions);
  const [inputSign, setInputSign] = useDebounceState(sign, setFilterSign);
  const [from, setFilterFromDate] = useQueryState<Date | null>(null, "from", {
    ...queryStateOptions,
    valueType: "Date",
  });
  const [to, setFilterToDate] = useQueryState<Date | null>(null, "to", {
    ...queryStateOptions,
    valueType: "Date",
  });
  const [emptyDeliveryDate, setFilterDeliveryDate] = useQueryState(
    false,
    "emptyDeliveryDate",
    { ...queryStateOptions, valueType: "boolean" }
  );
  const [inputDeliveryDate, setInputDeliveryDate] = useDebounceState(
    emptyDeliveryDate,
    setFilterDeliveryDate
  );

  const { params: customerParams } = useFilters<[], Company>(
    [],
    "name",
    undefined,
    0
  );
  const {
    error: errorCustomers,
    isLoading: loadingCustomers,
    data: customers,
  } = usePaginationApi<[], Company>(
    apiRoutes.companies,
    customerParams,
    undefined,
    undefined,
    { enabled: !isOnlyClient(user) }
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<OrderFilters, Order>(
      {
        search,
        customer,
        orderNumber,
        sign,
        from,
        to,
        emptyDeliveryDate,
        customerId,
      },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<OrderFilters, Order>(
    apiRoutes.orders,
    params
  );

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
    errorCustomers?.response?.data.message &&
      enqueueSnackbar(errorCustomers?.response?.data.message, {
        variant: "error",
      });
  }, [error, errorCustomers, enqueueSnackbar]);

  const resetFilter = useCallback(() => {
    setFilterSearch("");
    setFilterCustomer("");
    setFilterOrderNumber("");
    setFilterSign("");
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterDeliveryDate(false);
  }, [
    setFilterSearch,
    setFilterCustomer,
    setFilterOrderNumber,
    setFilterSign,
    setFilterFromDate,
    setFilterToDate,
    setFilterDeliveryDate,
  ]);

  useEffect(() => {
    resetFilter();
    setCustomerId(isTester && selectedCompany ? "" + selectedCompany.id : null);
  }, [selectedCompany, resetFilter, setCustomerId, isTester]);

  const hasFilters =
    search !== "" ||
    orderNumber !== "" ||
    sign !== "" ||
    from !== null ||
    to !== null ||
    emptyDeliveryDate;

  return (
    <StyledContainer data-test="orders-content">
      <Header
        title={t("Aufträge")}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded grant={AppGrant.ORDER_CREATE}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.orderCreate}
            >
              <Add /> {t("Neuer Auftrag")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <StyledFilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("Alle Felder durchsuchen...")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <TextField
                label={t("Auftragsnummer")}
                variant="outlined"
                placeholder={t("AUxxxxxx")}
                value={inputOrderNumber}
                onChange={(e) => setInputOrderNumber(e.target.value)}
                size="small"
              />
            </StyledFilterFormControl>
            {!isOnlyClient(user) && !isOnlyTester(user) && (
              <StyledFilterFormControl>
                <Autocomplete
                  id="customer"
                  loading={loadingCustomers}
                  disabled={!!errorCustomers}
                  style={{ width: theme?.width.full }}
                  onChange={(_, value) =>
                    setInputCustomer(value ? value.name : "")
                  }
                  options={customers?.results || []}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option) =>
                    option.name === inputCustomer
                  }
                  value={
                    customers?.results.find(
                      (customer) => customer.name === inputCustomer
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Kunde")}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </StyledFilterFormControl>
            )}
            <StyledFilterFormControl>
              <TextField
                label={t("Projektname")}
                variant="outlined"
                value={inputSign}
                onChange={(e) => setInputSign(e.target.value)}
                size="small"
              />
            </StyledFilterFormControl>
          </FilterFormGroup>
          <FilterFormGroup row>
            <StyledFilterFormControl>
              <DatePicker
                value={from ? new Date(from) : null}
                format={DateFormat.Default}
                onChange={(date) => {
                  if (!isValid(date)) {
                    return;
                  }
                  setFilterFromDate(date);
                }}
                label={t("Von")}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <DatePicker
                label={t("Bis")}
                value={to ? new Date(to) : null}
                format={DateFormat.Default}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(date) => {
                  if (!isValid(date)) {
                    return;
                  }
                  setFilterToDate(date);
                }}
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={inputDeliveryDate}
                    onChange={() => setInputDeliveryDate(!inputDeliveryDate)}
                    color="primary"
                  />
                }
                label={t("Kein Liefertermin")}
              />
            </StyledFilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <OrderList
          orders={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default OrdersOverview;
