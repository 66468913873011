import { GetApp } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQueueTaskStatusQuery } from "../../hooks/queries/use-queue-task-status-query";

const QueueTaskStatusIndicator = () => {
  const { data: status } = useQueueTaskStatusQuery();

  if (status?.data && status.data.length > 0) {
    return <CircularProgress size={20} color="inherit" />;
  }
  return <GetApp />;
};

export default QueueTaskStatusIndicator;
