import { useForm } from "react-hook-form";
import { DefectFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDefectValidationSchema } from "../validations/defect/use-defect-validation-schema";

export const useDefectForm = (defaultValues: DefectFormValues) => {
  const validationSchema = useDefectValidationSchema();

  return useForm<DefectFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
