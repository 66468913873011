import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useCheckGroupValidationSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return yup.object({
      name: yup.string().required(t("Name darf nicht leer sein")),
    });
  }, [t]);
};
