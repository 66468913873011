import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm, UseFormSetError } from "react-hook-form";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Fab,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
  ExpandMore,
} from "@mui/icons-material";
import {
  ApiError,
  CheckGroup,
  CheckProcess,
  CheckProcessGroup,
  CheckProcessGroupsFormValues,
  ID,
} from "../../model";
import { Flipped, Flipper } from "react-flip-toolkit";
import { Definition } from "../../components/globals";
import CheckGroupCreateDialog from "../../components/check-group-create-dialog";
import { apiRoutes, request } from "../../lib/api";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import CheckGroupEditDialog from "../../components/check-group-edit-dialog";
import CheckStepIcon from "../../components/check-step-icon";
import LoadingButton from "../../components/loading-button";

const buildCheckProcessGroups = (checkGroups: CheckProcessGroup[]) =>
  checkGroups.map((checkProcessGroup) => ({
    id: checkProcessGroup.id,
    sortOrder: checkProcessGroup.sortOrder,
    checkGroup: {
      id: checkProcessGroup.checkGroup.id,
      name: checkProcessGroup.checkGroup.name,
      description: checkProcessGroup.checkGroup.description,
      comment: checkProcessGroup.checkGroup.comment,
      checkSteps: checkProcessGroup.checkGroup.checkSteps.map((step) => ({
        checkStep: step.checkStep.id,
        sortOrder: step.sortOrder,
        checkStepTitle: step.checkStep.title,
        checkStepType: step.checkStep.type,
      })) || [
        {
          checkStep: null,
          sortOrder: 0,
        },
      ],
    },
  }));

const CheckGroups: VoidFunctionComponent<{
  checkProcess: CheckProcess;
  onCheckProcessUpdate: (checkProcess: CheckProcess) => void;
}> = ({ checkProcess, onCheckProcessUpdate }) => {
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [openCheckGroup, setOpenCheckGroup] = useState<CheckGroup | null>(null);
  const [checkGroups, setCheckGroups] = useState<CheckProcessGroup[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<CheckProcessGroupsFormValues>({
    mode: "all",
    defaultValues: {
      checkProcessGroups: buildCheckProcessGroups(checkGroups) || [],
    },
  });

  useEffect(() => {
    setCheckGroups(
      checkProcess.checkGroups.sort((a, b) =>
        a.sortOrder < b.sortOrder ? -1 : 1
      )
    );
  }, [checkProcess]);

  useEffect(() => {
    reset({
      checkProcessGroups: buildCheckProcessGroups(checkGroups) || [],
    });
  }, [checkGroups, reset]);

  const onCheckGroupUpdate = () => {
    setLoading(true);
    request<CheckProcess>(apiRoutes.checkProcess(checkProcess.id), "get")
      .then((res) => onCheckProcessUpdate(res.data))
      .catch(() =>
        enqueueSnackbar(t("Ort konnte nicht aktualisiert werden."), {
          variant: "error",
        })
      )
      .finally(() => setLoading(false));
  };

  const onSubmit = async (
    values: CheckProcessGroupsFormValues,
    setError: UseFormSetError<CheckProcessGroupsFormValues>
  ) => {
    if (!checkProcess) {
      return;
    }
    if (!values.checkProcessGroups) {
      values.checkProcessGroups = [];
    }
    await request<CheckProcess>(
      apiRoutes.checkProcessGroups(checkProcess.id),
      "put",
      values.checkProcessGroups
    )
      .then((res) => {
        enqueueSnackbar(
          t("Kapitel-Reihenfolge wurde erfolgreich aktualisiert."),
          { variant: "success" }
        );
        onCheckProcessUpdate(res.data);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  const handleOpen = (checkGroupId: ID) => {
    const checkProcessGroup = checkGroups.find(
      (checkGroup) => checkGroup.checkGroup.id === checkGroupId
    );
    if (!checkProcessGroup) {
      return;
    }
    setOpenCheckGroup(checkProcessGroup.checkGroup);
    setEditDialogOpen(true);
  };

  const { fields, remove, swap } = useFieldArray({
    control,
    name: "checkProcessGroups",
  });

  if (loading) {
    return <LoadingContainer />;
  }

  return (
    <>
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        {fields.length > 0 && (
          <Flipper flipKey={fields.map((field) => field.id).join("-")}>
            <Grid container spacing={3}>
              {fields.map((field, index) => {
                return (
                  <Flipped key={field.id} flipId={field.id}>
                    <Grid item xs={12}>
                      <Accordion key={field.id}>
                        <AccordionSummary
                          expandIcon={
                            <Tooltip title={t<string>("Prüfkapitel ansehen")}>
                              <ExpandMore />
                            </Tooltip>
                          }
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item>
                              <Chip label={index + 1} />
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "center" }}>
                              {index > 0 && (
                                <IconButton
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    swap(index, index - 1);
                                  }}
                                  size="large"
                                >
                                  <ArrowUpward />
                                </IconButton>
                              )}
                              {index < fields.length - 1 && (
                                <IconButton
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    swap(index, index + 1);
                                  }}
                                  size="large"
                                >
                                  <ArrowDownward />
                                </IconButton>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant={"h6"}>
                                {field.checkGroup.name}
                              </Typography>
                            </Grid>
                            <Tooltip title={t<string>("Kapitel löschen")}>
                              <IconButton
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  remove(index);
                                }}
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            alignContent="center"
                            spacing={3}
                          >
                            <Grid item xs={12} md={6}>
                              <Definition
                                title={t("Beschreibung")}
                                value={field.checkGroup.description}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Definition
                                title={t("Interne Notiz")}
                                value={field.checkGroup.comment}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="overline"
                                color="textSecondary"
                              >
                                {t("Prüfschritte")}
                              </Typography>
                              {field.checkGroup.checkSteps &&
                                field.checkGroup.checkSteps.map(
                                  (checkStep, index) => (
                                    <ListItem dense={true} key={index}>
                                      <ListItemIcon>
                                        <CheckStepIcon
                                          checkStepType={
                                            checkStep.checkStepType
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={checkStep.checkStepTitle}
                                      />
                                    </ListItem>
                                  )
                                )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <AccordionActions>
                          <Tooltip title={t<string>("Kapitel bearbeiten")}>
                            <Fab
                              type="button"
                              color="primary"
                              size="small"
                              onClick={() => handleOpen(field.checkGroup.id)}
                            >
                              <Edit />
                            </Fab>
                          </Tooltip>
                        </AccordionActions>
                      </Accordion>
                    </Grid>
                  </Flipped>
                );
              })}
            </Grid>
          </Flipper>
        )}
        <Grid container spacing={0} justifyContent="space-between" mt={2}>
          <Grid item>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid}
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="text"
              onClick={() => setCreateDialogOpen(true)}
              startIcon={<Add />}
              color="primary"
            >
              {t("Kapitel hinzufügen")}
            </Button>
          </Grid>
        </Grid>
      </form>
      {openCheckGroup && (
        <CheckGroupEditDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          checkGroup={openCheckGroup}
          checkProcess={checkProcess}
          onCheckGroupUpdate={onCheckGroupUpdate}
        />
      )}
      <CheckGroupCreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        checkProcess={checkProcess}
        onCheckGroupUpdate={onCheckGroupUpdate}
      />
    </>
  );
};

export default CheckGroups;
