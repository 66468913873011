import React, { VoidFunctionComponent } from "react";
import { Person } from "../../model";
import PaperDialog from "../paper-dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Error } from "@mui/icons-material";

const OpenChecksDialog: VoidFunctionComponent<{
  persons: Person[];
  open: boolean;
  onClose: () => void;
}> = ({ persons, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("Offene Unterschriften")}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("Folgende Überprüfer haben noch nicht unterschriebene Prüfungen:")}
        </Typography>
        {persons.length > 0 ? (
          <List dense={true}>
            {persons.map((person) => (
              <ListItem>
                <ListItemIcon>
                  <Error />
                </ListItemIcon>
                <ListItemText>{person.displayName}</ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2">{t("Keine Person")}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="contained">
          {t("Schließen")}
        </Button>
      </DialogActions>
    </PaperDialog>
  );
};

export default OpenChecksDialog;
