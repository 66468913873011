import LoadingContainer from "../loading-container";
import React, { useState, VoidFunctionComponent } from "react";
import {
  Check,
  CheckStatus,
  ItemCheck,
  Location,
  Nullable,
  Person,
} from "../../model";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes } from "../../lib/api";
import ItemCheckEditDialog from "../item-check-edit-dialog";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import ItemCheckItemsList from "../item-check-items-list";

const ItemListView: VoidFunctionComponent<{
  check: Check;
  onCancel: () => void;
  onContinue: () => void;
  showActions: boolean;
}> = ({ check, onCancel, onContinue, showActions }) => {
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentItemCheck, setCurrentItemCheck] =
    useState<Nullable<ItemCheck>>(null);
  const { data: locations, isLoading: locationsLoading } =
    useUnlimitedPaginationApi<{}, Location>(
      apiRoutes.locations,
      {
        company: check?.order.customer.id,
      },
      { locationOption: true },
      {
        enabled: editDialogOpen,
      }
    );
  const { data: persons, isLoading: personsLoading } =
    useUnlimitedPaginationApi<{}, Person>(
      apiRoutes.persons,
      {
        primaryCompany: check.order.customer.id,
      },
      { personOption: true },
      {
        enabled: editDialogOpen,
      }
    );

  return (
    <>
      {locationsLoading || personsLoading ? (
        <LoadingContainer />
      ) : (
        <ItemCheckItemsList
          itemChecks={check.itemChecks}
          showReport={check.status === CheckStatus.Done}
          onEditItemCheckClick={(itemCheck) => {
            setCurrentItemCheck(itemCheck);
            setEditDialogOpen(true);
          }}
        />
      )}
      {editDialogOpen && currentItemCheck && locations && (
        <ItemCheckEditDialog
          itemCheck={currentItemCheck}
          check={check}
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          locations={locations}
          persons={persons}
        />
      )}
      {showActions && (
        <DialogActions>
          <Button
            type="button"
            size="medium"
            color="secondary"
            onClick={onCancel}
          >
            {t("Abbrechen")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={check.itemChecks.length === 0}
            onClick={onContinue}
          >
            {t("Prüfung unterzeichnen")}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default ItemListView;
