import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import {
  Company,
  getPersonFormValues,
  Person,
  PersonFormValues,
} from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { usePersonCreateMutation, usePersonForm } from "../../hooks";
import { PersonFormFields } from "../person-form-fields";

const CreatePersonDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  company?: Company;
  name?: string;
  onUpdate?: (data: Person) => void;
  reduced?: boolean;
}> = ({ open, onClose, company, name = "", onUpdate, reduced = false }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
    getValues,
  } = usePersonForm({
    ...getPersonFormValues(),
    primaryCompany: company ? company.id : getPersonFormValues().primaryCompany,
  });

  const createMutation = usePersonCreateMutation(setError, (data) => {
    const values = getPersonFormValues();
    reset({
      ...values,
      primaryCompany: company ? company.id : values.primaryCompany,
    });
    if (onUpdate) {
      onUpdate(data);
    }
    onClose();
  });

  const onSubmit = async (values: PersonFormValues) => {
    await createMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const values = getPersonFormValues();
    reset({
      ...values,
      primaryCompany: company ? company.id : values.primaryCompany,
    });
  }, [open, reset, company]);

  useEffect(() => {
    const split = name.split(" ");
    setValue("firstName", split[0] ?? "");
    setValue("lastName", split[1] ?? "");
  }, [name, setValue]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth={reduced ? "sm" : "lg"}>
      <DialogTitle>{t("Neue Person")}</DialogTitle>
      <DialogContent>
        <Card>
          <PersonFormFields
            control={control}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            company={company}
            reduced={reduced}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreatePersonDialog;
