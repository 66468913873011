import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Avatar, Box, BoxProps, Fab, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconFab } from "components/icon-fab";
import {
  Collections,
  Delete,
  Description,
  Fullscreen,
} from "@mui/icons-material";
import { AttachmentPreview, FilePreview } from "model";

const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1 / 1;
  height: 100%;
  width: 100%;
`;

const StyledBox = styled(Box)`
  position: relative;
  width: 100%;
`;

const StyledCollectionFabBox = styled(Box)`
  position: absolute;
  bottom: 0.25em;
  left: 0.25em;
`;

const StyledDeleteFabBox = styled(Box)`
  position: absolute;
  bottom: 0.25em;
  right: 0.25em;
`;

const StyledViewFabBox = styled(Box)`
  position: absolute;
  top: 0.25em;
  right: 0.25em;
`;

export const Thumbnail: FunctionComponent<
  {
    preview: Partial<AttachmentPreview & FilePreview>;
    collection?: boolean;
    onDelete?: () => void;
    onView?: () => void;
  } & BoxProps
> = ({ preview, collection, onDelete, onView, ...props }) => {
  const { t } = useTranslation();

  const fileIsPdf =
    preview.file?.type === "application/pdf" ||
    preview.fileMimeType === "application/pdf";
  const fileType = fileIsPdf ? "Dokument" : "Bild";
  const style = !fileIsPdf
    ? {
        backgroundImage: `url(${preview.image || preview.preview || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: "100%",
      }
    : {};

  return (
    <StyledBox {...props} style={style}>
      {fileIsPdf &&
        (preview.file?.name ? (
          <Tooltip title={preview.file.name}>
            <StyledAvatar variant="square">
              <Description fontSize="large" />
            </StyledAvatar>
          </Tooltip>
        ) : (
          <StyledAvatar variant="square">
            <Description fontSize="large" />
          </StyledAvatar>
        ))}
      {collection && (
        <StyledCollectionFabBox>
          <Fab aria-label={t("Multiple")} color="secondary" size="small">
            <Collections />
          </Fab>
        </StyledCollectionFabBox>
      )}
      {onDelete && (
        <StyledDeleteFabBox>
          <IconFab
            title={t(`${fileType} entfernen`)}
            handleClick={onDelete}
            icon={<Delete />}
          />
        </StyledDeleteFabBox>
      )}
      {onView && (
        <StyledViewFabBox>
          <IconFab
            title={t(`${fileType} anzeigen`)}
            handleClick={onView}
            icon={<Fullscreen />}
          />
        </StyledViewFabBox>
      )}
    </StyledBox>
  );
};
