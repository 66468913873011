import { useTitle } from "../../hooks/use-title";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../../components/header";
import {
  apiRoutes,
  getAbsoluteApiUrl,
  request,
  useItemDetailsApi,
} from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Definition,
  StyledCardTitle,
  StyledContainer,
} from "../../components/globals";
import Guarded from "../../components/guarded";
import { DateFormat, ItemGrant, Role } from "../../model";
import { Archive, Delete, Edit, FindInPage } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import ItemImportInfo from "../../components/item-import-info";
import FormattedDate from "../../components/formatted-date";
import ItemCheckList from "../../components/item-check-list";
import DocumentList from "../../components/document-list";
import ItemDocumentationGallery from "../../components/item-documentation-gallery";
import { useCurrentUser } from "hooks/use-current-user";
import { isOnlyClient, isOnlyTester } from "lib/security";
import GuardedLink from "../../components/guarded-link";
import { useItemArchiveMutation } from "../../hooks";
import LoadingButton from "../../components/loading-button";

const ItemDetails = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const { itemId } = useParams<{ itemId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useItemDetailsApi(
    itemId as unknown as number
  );
  const history = useHistory();
  const { mutateAsync: archiveMutate, isLoading: archiveIsLoading } =
    useItemArchiveMutation(data);

  useTitle(data?.serialNumber || t("Item Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.items}>
            {t("Zurück zu Items")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Items"), link: routes.items },
    { label: data?.serialNumber },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Item {{serialNumber}} wirklich löschen?`, {
        serialNumber: data.serialNumber,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.item(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Item wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.items);
      })
      .catch(() =>
        enqueueSnackbar(t("Item konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const onlyClient = isOnlyClient(user);
  const onlyTester = isOnlyTester(user);

  const handleArchive = () => {
    const text = data.archived
      ? t(
          "Sind Sie sich sicher, dass Sie das Item aktivieren möchten? Aktivierte Items können im Zuge des Prüfprozesses gefunden werden."
        )
      : t(
          "Sind Sie sich sicher, dass Sie das Item archivieren möchten? Archivierte Items können im Zuge des Prüfprozesses nicht mehr gefunden werden."
        );

    if (!window.confirm(text)) {
      return;
    }
    archiveMutate();
  };

  return (
    <StyledContainer>
      <Header
        title={t("Item")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded grant={ItemGrant.UPDATE} subject={data}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.itemEdit(data.id)}
                startIcon={<Edit />}
                disabled={data.archived}
              >
                {t("Bearbeiten")}
              </Button>{" "}
              <LoadingButton
                variant={!data.archived ? "contained" : "outlined"}
                color="secondary"
                onClick={handleArchive}
                startIcon={<Archive />}
                loading={archiveIsLoading}
              >
                {data.archived ? t("Archiviert") : t("Archivieren")}
              </LoadingButton>
            </Guarded>{" "}
            {data.archived && (
              <Guarded grant={ItemGrant.DELETE} subject={data}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                  startIcon={<Delete />}
                >
                  {t("Löschen")}
                </Button>
              </Guarded>
            )}
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Item")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Seriennummer")}
                    value={data.serialNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Systemnummer")}
                    value={data.systemNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Produkt")}
                    value={
                      data.product ? (
                        <GuardedLink
                          disabled={onlyClient || onlyTester}
                          to={routes.product(data.product.id)}
                        >
                          {data.product.displayName}
                        </GuardedLink>
                      ) : (
                        t("Nicht zugewiesen")
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Kunde")}
                    value={
                      data.customer ? (
                        <GuardedLink
                          disabled={onlyClient || onlyTester}
                          to={routes.company(data.customer.id)}
                        >
                          {data.customer.name}
                        </GuardedLink>
                      ) : (
                        t("Nicht zugewiesen")
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Aktueller Ort/Abteilung")}
                    value={
                      data.location && (
                        <GuardedLink
                          disabled={onlyTester}
                          component={RouterLink}
                          to={`${routes.items}?location=${data.location.id}`}
                        >
                          {`${data.location.name} ${
                            data.location.parent
                              ? data.location.parent.name
                              : ""
                          }
                        `}
                        </GuardedLink>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Aktueller Verwender")}
                    value={
                      data.person ? (
                        <GuardedLink
                          disabled={onlyTester}
                          component={RouterLink}
                          to={
                            onlyClient
                              ? `${routes.items}?contact=${data.person.lastName}&contactId=${data.person.id}`
                              : routes.person(data.person.id)
                          }
                        >
                          {data.person.displayName}
                        </GuardedLink>
                      ) : (
                        t("Nicht zugewiesen")
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Hertellungsjahr")}
                    value={data.manufacturingYear}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Erster Gebrauch")}
                    value={
                      data.firstUse ? (
                        <FormattedDate
                          date={data.firstUse}
                          format={DateFormat.Default}
                        />
                      ) : null
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Sonstiges")}
              </StyledCardTitle>
              <Grid item xs={12}>
                <Definition title={t("Kommentar")} value={data.comment} />
              </Grid>
            </CardContent>
            {data.product && (
              <>
                <Divider />
                <CardContent>
                  <Grid item xs={12}>
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("Produkt Normen")}
                    </StyledCardTitle>
                    <List dense={true}>
                      {data.product.category.standards.map(
                        (standard, index) => (
                          <ListItem style={{ paddingLeft: 0 }} key={index}>
                            <ListItemIcon>
                              {standard.document ? (
                                <a
                                  href={getAbsoluteApiUrl(
                                    apiRoutes.documentFile(standard.document.id)
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <IconButton
                                    aria-label={t("Ansehen")}
                                    size="small"
                                  >
                                    <FindInPage />
                                  </IconButton>
                                </a>
                              ) : (
                                <IconButton size="small" disabled>
                                  <FindInPage />
                                </IconButton>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <GuardedLink
                                  disabled={onlyTester || onlyClient}
                                  component={RouterLink}
                                  to={routes.standard(standard.id)}
                                >
                                  {standard.number}
                                </GuardedLink>
                              }
                              secondary={
                                <>
                                  {t(`standard.type.${standard.type}`)} -{" "}
                                  {standard.scope}
                                </>
                              }
                            />
                          </ListItem>
                        )
                      )}
                    </List>
                    {data.product.category.standards.length < 1 && (
                      <Typography>
                        {t(
                          "Es ist keine Norm der Produkt-Kategorie zugewiesen."
                        )}
                      </Typography>
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Produkt Dokumente")}
                  </StyledCardTitle>
                </CardContent>
                <Grid item xs={12}>
                  <DocumentList documents={data.product.documents} />
                </Grid>
              </>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Guarded requiredRole={Role.Admin}>
              <Grid item xs={12}>
                <ItemImportInfo item={data} />
              </Grid>
            </Guarded>
            <Grid item xs={12}>
              <ItemDocumentationGallery item={data} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Prüfungen")}
              </StyledCardTitle>
            </CardContent>
            <ItemCheckList itemChecks={data.itemChecks} />
          </Card>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default ItemDetails;
