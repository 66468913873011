import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, useRef, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { ApiError, getProductFormValues, ProductFormValues } from "../../model";
import { apiRoutes, request, useProductDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProductValidationSchema } from "../../hooks/validations/products/use-product-validation-schema";
import LoadingButton from "../../components/loading-button";
import ProductFormFields from "components/product-form-fields";

const EditProduct: VoidFunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const imageRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const { productId } = useParams<{ productId: string | undefined }>();
  const { isLoading, error, data } = useProductDetailsApi(
    productId as unknown as number
  );

  const validationSchema = useProductValidationSchema();
  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<ProductFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getProductFormValues(data),
  });

  const onSubmit = async (
    values: ProductFormValues,
    setError: UseFormSetError<ProductFormValues>
  ) => {
    if (!data) {
      return;
    }

    const formData = new FormData();
    let documentUploadsErrors = false;
    const documentUploads: any[] = !values.showUpload
      ? []
      : values.documentUploads.map((documentUpload, index) => {
          if (!documentUpload.documentUpload) {
            documentUploadsErrors = true;
          }
          formData.append(
            "documentUploadFile" + index,
            documentUpload.documentUpload
          );
          return {
            name: documentUpload.name,
            type: documentUpload.type,
            comment: documentUpload.comment,
          };
        });

    if (documentUploadsErrors) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    if (!values.deleteImage && values.imageUpload) {
      formData.append("imageUploadFile", values.imageUpload);
    }

    formData.append(
      "content",
      JSON.stringify({
        model: values.model,
        manufacturingYear: values.manufacturingYear,
        category: values.category,
        manufacturer: values.manufacturer,
        articleNumber: values.articleNumber,
        shortDescription: values.shortDescription,
        description: values.description,
        reviewInterval: values.reviewInterval,
        lifetime: values.lifetime,
        documents: values.documents,
        deleteImage: values.deleteImage,
        ...(values.showUpload ? { documentUploads } : {}),
      })
    );

    await request(apiRoutes.product(data.id), "post", formData)
      .then(() => {
        enqueueSnackbar(t("Produkt wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.product(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getProductFormValues(data)), [reset, data]);
  useTitle(data?.model || t("Produkt bearbeiten"));

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.products()}>
            {t("Zurück zu Produkten")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Produkte"), link: routes.products() },
    { label: data.model, link: routes.product(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="lg">
      <Header title={t("Produkt")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <ProductFormFields
            control={control}
            watch={watch}
            product={data}
            imageRef={imageRef}
          />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditProduct;
