import GriehserLogo from "../../images/logo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";

const StyledLink = styled(Link)`
  display: flex;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.5em;
  font-weight: 500;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledImg = styled.img`
  height: 1.3em;
  width: auto;
  margin: 0 auto;
`;

const Logo = () => {
  return (
    <StyledLink to={routes.dashboard}>
      <StyledImg src={GriehserLogo} />
    </StyledLink>
  );
};

export default Logo;
