import { UseFormSetError } from "react-hook-form";
import { Action, ActionFormValues } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";

export const useActionCreateMutation = (
  setError: UseFormSetError<ActionFormValues>,
  onSuccess?: (data: Action) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: ActionFormValues) => {
      return await request<Action>(apiRoutes.actionCreate, "post", values);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.actions]);
        enqueueSnackbar(t("Maßnahme wurde erfolgreich erstellt."), {
          variant: "success",
        });

        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.actions);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useActionUpdateMutation = (
  setError: UseFormSetError<ActionFormValues>,
  data?: Action,
  onSuccess?: (action: Action) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: ActionFormValues) => {
      if (!data) {
        return;
      }
      return await request<Action>(apiRoutes.action(data.id), "put", values);
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.actions]);
        enqueueSnackbar(t("Maßnahme wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.actions);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useActionDeleteMutation = (
  data?: Action,
  onSuccess?: (action: Action) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request<Action>(apiRoutes.action(data.id), "delete");
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.actions]);
        enqueueSnackbar(t("Maßnahme wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.actions);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
      },
    }
  );
};
