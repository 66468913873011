import { useItemValidation } from "./use-item-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useItemValidationSchema = () => {
  const itemValidation = useItemValidation();

  return useMemo(() => {
    return yup.object({
      ...itemValidation,
    });
  }, [itemValidation]);
};
