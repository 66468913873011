import { useForm } from "react-hook-form";
import { ItemDocumentationValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useItemDocumentationValidationSchema } from "../validations/item-documentation/use-item-documentation-validation-schema";

export const useItemDocumentationForm = (
  defaultValues: ItemDocumentationValues
) => {
  const validationSchema = useItemDocumentationValidationSchema();

  return useForm<ItemDocumentationValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
