import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";
import { ID } from "model";

export const useItemValidation = () => {
  const { t } = useTranslation();
  const isNotValidId = (id: ID | null) => id === null || id < 0;

  return useMemo(
    () => ({
      serialNumber: yup
        .string()
        .required(t("Seriennummer darf nicht leer sein.")),
      product: yup
        .number()
        .nullable()
        .required(t("Produkt darf nicht leer sein.")),
      customer: yup
        .number()
        .nullable()
        .required(t("Kunde darf nicht leer sein.")),
      manufacturingYear: yup
        .string()
        .required(t("Herstellungsjahr darf nicht leer sein."))
        .matches(/^[0-9]{4}$/, t("Die Eingabe ist auf 4 Zahlen beschränkt.")),
      newProduct: yup.object().when("product", {
        is: isNotValidId,
        then: yup.object({
          model: yup.string().required(t("Modell darf nicht leer sein.")),
          category: yup
            .number()
            .nullable()
            .required(t("Kategorie darf nicht leer sein.")),
          manufacturer: yup
            .number()
            .nullable()
            .required(t("Hersteller darf nicht leer sein.")),
          manufacturingYear: yup
            .string()
            .required(t("Herstellungsjahr darf nicht leer sein.")),
        }),
      }),
    }),
    [t]
  );
};
