import { UseFormSetError } from "react-hook-form";
import { Company, CompanyFormValues } from "../../model";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";

export const useCompanyCreateMutation = (
  setError: UseFormSetError<CompanyFormValues>,
  onSuccess?: (data: Company) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: CompanyFormValues) => {
      return await request<Company>(
        apiRoutes.companyCreate,
        "post",
        generateCompanyMutationFormData(values)
      );
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.companies]);
        await queryClient.invalidateQueries([apiRoutes.companyCount]);

        enqueueSnackbar(t("Organisation wurde erfolgreich erstellt."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.company(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useCompanyUpdateMutation = (
  setError: UseFormSetError<CompanyFormValues>,
  data?: Company,
  onSuccess?: (company: Company) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (values: CompanyFormValues) => {
      if (!data) {
        return;
      }
      return await request<Company>(
        apiRoutes.company(data.id),
        "post",
        generateCompanyMutationFormData(values)
      );
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.companies]);
        await queryClient.invalidateQueries([apiRoutes.company(res.data.id)]);
        await queryClient.invalidateQueries([
          apiRoutes.company(res.data.id) + "/design",
        ]);
        enqueueSnackbar(t("Organisation wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.company(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};

export const useCompanyDeleteMutation = (
  onSuccess?: (data: Company) => void
) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  return useMutation(
    async (data: Company) => {
      if (!data) {
        return;
      }
      return await request<Company>(apiRoutes.company(data.id), "delete");
    },
    {
      onSuccess: async (res) => {
        if (!res) {
          return;
        }
        await queryClient.invalidateQueries([apiRoutes.companies]);
        enqueueSnackbar(t("Organisation wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        if (onSuccess) {
          onSuccess(res.data);
          return;
        }
        history.push(routes.companies);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
      },
    }
  );
};

export function generateCompanyMutationFormData(
  values: CompanyFormValues
): FormData {
  const formData = new FormData();
  if (values.logoUpload) {
    formData.append("logoUpload", values.logoUpload);
  }
  delete values.logoUpload;

  if (values.footerUpload) {
    formData.append("footerUpload", values.footerUpload);
  }
  delete values.footerUpload;

  if (values.sealUpload) {
    formData.append("sealUpload", values.sealUpload);
  }
  delete values.sealUpload;

  formData.append("content", JSON.stringify(values));
  return formData;
}
