import { useTitle } from "../../hooks/use-title";
import Header from "../../components/header";
import { Button, Card, CardHeader, Container, Grid } from "@mui/material";
import React from "react";
import UserList from "../../components/user-list";
import { useSearchApi } from "../../lib/api";
import { Link, useLocation } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import CompanyList from "../../components/company-list";
import OrderList from "../../components/order-list";
import CategoryList from "../../components/category-list";
import { StyledNotFound } from "../../components/globals";
import ProductList from "../../components/product-list";
import StandardList from "../../components/standard-list";
import DocumentList from "../../components/document-list";
import PersonList from "../../components/person-list";
import CheckProcessList from "../../components/check-process-list";
import ItemList from "../../components/item-list";

const Search = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search).get("q") || "";
  const { isLoading, error, data } = useSearchApi(search);
  const { t } = useTranslation();
  useTitle(t("Suche"));

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Suche") },
  ];

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.dashboard}>
            Back to dashboard
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const results = data.filter((result) => result.results.length);

  return (
    <Container maxWidth="lg">
      <Header
        title={t("Suche für {{search}}", { search })}
        breadcrumbs={breadcrumbs}
      />
      <Grid container spacing={3} direction="row">
        {results.map((result, key) => (
          <Grid item key={key} xs={12}>
            <Card>
              <CardHeader title={t(`entity.${result.label}`)} />
              {result.label === "companies" && (
                <CompanyList companies={result.results} />
              )}
              {result.label === "orders" && (
                <OrderList orders={result.results} />
              )}
              {result.label === "products" && (
                <ProductList products={result.results} />
              )}
              {result.label === "persons" && (
                <PersonList persons={result.results} />
              )}
              {result.label === "users" && <UserList user={result.results} />}
              {result.label === "categories" && (
                <CategoryList categories={result.results} />
              )}
              {result.label === "documents" && (
                <DocumentList documents={result.results} />
              )}
              {result.label === "standards" && (
                <StandardList standards={result.results} />
              )}
              {result.label === "checkProcesses" && (
                <CheckProcessList checkProcesses={result.results} />
              )}
              {result.label === "items" && <ItemList items={result.results} />}
            </Card>
          </Grid>
        ))}
      </Grid>
      {!results.length && (
        <StyledNotFound variant="body2">
          {t("Es wurden Suchergebnisse gefunden.")}
        </StyledNotFound>
      )}
    </Container>
  );
};

export default Search;
