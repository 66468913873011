import React, { FunctionComponent } from "react";
import { Role, User } from "../../model";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UserBadge from "../user-badge";
import { Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { IconLink } from "../icon-link";
import { useTranslation } from "react-i18next";

const UserList: FunctionComponent<{ user: User[] }> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={70} align={`center`}>
              {t("ID")}
            </TableCell>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("E-Mail")}</TableCell>
            <TableCell width={110} />
          </TableRow>
        </TableHead>
        <TableBody>
          {user &&
            user.map((user) => (
              <TableRow key={user.id} hover={true}>
                <TableCell align={`center`}>{user.id}</TableCell>
                <TableCell>
                  <UserBadge user={user} />
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <IconLink
                    to={routes.user(user.id)}
                    tooltip={t("Ansehen")}
                    icon={<Visibility />}
                  />
                  {!user.inheritedRoles.includes(Role.SuperAdmin) && (
                    <IconLink
                      to={routes.userEdit(user.id)}
                      tooltip={t("Bearbeiten")}
                      icon={<Edit />}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserList;
