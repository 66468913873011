import {
  Blameable,
  Category,
  Commentable,
  DateTimeString,
  ID,
  Product,
  Qualification,
  Timestampable,
} from "./";

// Sync with CheckProcessVoter in backend
export enum CheckProcessGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  APPROVE = "approve",
}

export enum CheckProcessState {
  Draft = "draft",
  Inactive = "inactive",
  Approved = "approved",
}

export interface CheckProcess extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  description: string;
  categories: Category[];
  products: Product[];
  qualifications: Qualification[];
  checkGroups: CheckProcessGroup[];
  approvalDate: DateTimeString | null;
  status: CheckProcessState;
  collidingCategoryApprovals: CheckProcess[];
  collidingProductApprovals: CheckProcess[];
  protocolSubtitle: string | null;
}

export interface CheckProcessGroup {
  id: ID;
  checkProcess: CheckProcess;
  checkGroup: CheckGroup;
  sortOrder: number;
}

// Sync with CheckGroupVoter in backend
export enum CheckGroupGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface CheckGroup extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  description: string;
  sortOrder: number;
  comment: string;
  checkSteps: CheckGroupStep[];
}

export interface CheckGroupStep {
  id: ID;
  checkGroup: CheckGroup;
  checkStep: CheckStep;
  sortOrder: number;
}

export enum CheckStepType {
  Text = "text",
  Bool = "bool",
}

// Sync with CheckStepVoter in backend
export enum CheckStepGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface CheckStep extends Timestampable, Blameable {
  id: ID;
  title: string;
  description: string | null;
  securityRelated: boolean;
  conditions?: CheckStepCondition[];
  type: CheckStepType;
  value?: unknown; // TODO: hmmm...
}

export interface CheckStepCondition {
  id: ID;
  checkStep: CheckStep;
  condition: { value: boolean };
  sortOrder: number;
}

export interface CheckProcessFormValues {
  name: string;
  description: string;
  comment: string;
  categories: ID[];
  products: ID[];
  qualifications: ID[];
  status: CheckProcessState;
  protocolSubtitle: string;
}

export interface CheckGroupFormValues {
  name: string;
  description: string;
  comment: string;
  checkSteps: CheckGroupStepsFormValues[];
  checkProcess?: ID;
}

export interface CheckProcessGroupsFormValues {
  checkProcessGroups: Array<{
    id: ID;
    sortOrder: number;
    checkGroup: {
      id: ID;
      name: string;
      description: string;
      comment: string;
      checkSteps: CheckGroupStepsFormValues[];
    };
  }>;
}

export interface CheckStepFormValues {
  title: string;
  securityRelated: boolean;
  description: string;
  type: CheckStepType;
}

export interface CheckGroupStepsFormValues {
  checkStep: ID | null;
  sortOrder: number;
  checkStepTitle?: string;
  checkStepType?: CheckStepType;
}

export interface CheckStepFilters {
  search: string;
  type: string;
  securityRelated: boolean;
}

export const getCheckProcessFormValues = (
  checkProcess?: CheckProcess
): CheckProcessFormValues => ({
  name: checkProcess?.name || "",
  description: checkProcess?.description || "",
  comment: checkProcess?.comment || "",
  categories: checkProcess?.categories.map((c) => c.id) || [],
  products: checkProcess?.products.map((c) => c.id) || [],
  qualifications: checkProcess?.qualifications.map((c) => c.id) || [],
  status:
    checkProcess?.status && checkProcess.status !== CheckProcessState.Approved
      ? checkProcess.status
      : CheckProcessState.Draft,
  protocolSubtitle: checkProcess?.protocolSubtitle || "",
});

export const getCheckStepFormValues = (
  checkStep?: CheckStep
): CheckStepFormValues => ({
  title: checkStep?.title || "",
  type: checkStep?.type || CheckStepType.Bool,
  securityRelated: checkStep?.securityRelated || false,
  description: checkStep?.description || "",
});

export const getCheckGroupFormValues = (
  checkGroup?: CheckGroup,
  checkProcess?: CheckProcess
): CheckGroupFormValues => {
  const formValues: CheckGroupFormValues = {
    name: checkGroup?.name || "",
    description: checkGroup?.description || "",
    checkSteps:
      checkGroup?.checkSteps.map((step) => ({
        checkStep: step.checkStep.id,
        sortOrder: step.sortOrder,
      })) || [],
    comment: checkGroup?.comment || "",
  };
  if (checkProcess) {
    formValues.checkProcess = checkProcess.id;
  }
  return formValues;
};
