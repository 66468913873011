import { useUserValidation } from "../user/use-user-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useCredentialValidationSchema = () => {
  const userValidation = useUserValidation();

  return useMemo(() => {
    return yup.object({
      email: userValidation.email,
      fullName: userValidation.fullName,
    });
  }, [userValidation]);
};
