import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";

export const useActionValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: yup.string().required(t("Titel darf nicht leer sein.")),
      company: yup
        .number()
        .nullable()
        .required(t("Organisation darf nicht leer sein.")),
      location: yup.number().nullable(),
    }),
    [t]
  );
};
