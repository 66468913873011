import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import { Action, Company, getActionFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { ActionFormFields } from "../action-form-fields";
import { useActionForm } from "../../hooks/form/use-action-form";
import { useActionCreateMutation } from "../../hooks/mutation/use-action-mutation";

const CreateActionDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  company?: Company;
  locationId?: number;
  title?: string;
  onCreate?: (data: Action) => void;
}> = ({ open, onClose, company, locationId, title, onCreate }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, isSubmitting },
    watch,
  } = useActionForm({
    ...getActionFormValues(),
    company: company ? company.id : getActionFormValues().company,
    location: locationId ? locationId : null,
    title: title || "",
  });

  const { mutateAsync: createMutation } = useActionCreateMutation(
    setError,
    (res) => {
      const values = getActionFormValues();
      reset({
        ...values,
        company: company ? company.id : values.company,
        location: locationId ? locationId : null,
        title: title || "",
      });
      onCreate && onCreate(res);
      onClose();
    }
  );

  const onSubmit = async (values: any) => {
    await createMutation(values);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const values = getActionFormValues();
    reset({
      ...values,
      company: company ? company.id : values.company,
      location: locationId ? locationId : null,
      title: title || "",
    });
  }, [open, reset, company, locationId, title]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("Neuer Mangel")}</DialogTitle>
      <DialogContent>
        <Card>
          <ActionFormFields
            control={control}
            watch={watch}
            setValue={setValue}
            company={company}
          />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid}
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreateActionDialog;
