import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  FilePreview,
  getItemDocumentationFormValues,
  Item,
  ItemDocumentationType,
  ItemDocumentationValues,
} from "../../model";
import { useTranslation } from "react-i18next";
import PaperDialog from "../paper-dialog";
import { useItemDocumentationForm } from "../../hooks/form/use-item-documentation-form";
import { UseFormGetValues } from "react-hook-form";
import { FormValues } from "../order-processing";
import {
  bypassResultConfirmationText,
  ItemDocumentationFormFields,
} from "../item-documentation-form-fields";

const CreateItemDocumentationDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  onCreate: (values: ItemDocumentationValues, files: FilePreview[]) => void;
  item: Item;
  getValues: UseFormGetValues<FormValues>;
}> = ({ open, onClose, onCreate, item, getValues }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FilePreview[]>([]);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
  } = useItemDocumentationForm(getItemDocumentationFormValues());

  const type = watch("type");
  const location = getValues("location");

  useEffect(() => {
    if (!open) {
      return;
    }
    reset(getItemDocumentationFormValues());
    setFiles([]);
  }, [open, reset]);

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>{t("Neue Bilddokumentation")}</DialogTitle>
      <DialogContent>
        <Typography variant="caption" display="block">
          {t(
            "Mangel: Items mit Mängeln werden automatisch gesperrt, in Mängellisten zusammengefasst und können zu einem späteren Zeitpunkt freigegeben werden."
          )}
        </Typography>
        <Typography
          variant="caption"
          display="block"
          style={{ marginBottom: "1em" }}
        >
          {t(
            "Dokumentation: Reine Dokumentation, beeinflusst das Prüfurteil nicht."
          )}
        </Typography>
        <Card>
          <ItemDocumentationFormFields
            control={control}
            type={type}
            item={item}
            files={files}
            setFiles={setFiles}
            locationId={location ? location : undefined}
            setValue={setValue}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary">
          {t("Abbrechen")}
        </Button>
        <Button
          type="submit"
          size="medium"
          color="primary"
          disabled={!isValid || files.length === 0}
          variant="contained"
          onClick={handleSubmit((values) => {
            if (
              values.type === ItemDocumentationType.Defect &&
              values.bypassResultLock
            ) {
              if (!window.confirm(t(bypassResultConfirmationText))) {
                return;
              }
            }

            onCreate(values, files);
          })}
        >
          {t("Speichern")}
        </Button>
      </DialogActions>
    </PaperDialog>
  );
};

export default CreateItemDocumentationDialog;
