import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  AppGrant,
  Company,
  Location,
  Person,
  PersonFilters,
  Role,
} from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { Link } from "react-router-dom";
import PersonList from "../../components/person-list";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { StyledFilterFormControl } from "../orders/overview";
import { ThemeContext } from "styled-components";
import { config } from "../../config";
import { useMutation } from "react-query";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyClient } from "../../lib/security";

const PersonsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Personen"));
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const userCompany = isOnlyClient(user)
    ? user.person?.primaryCompany?.id
    : null;

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Personen") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [company, setFilterCompany] = useQueryState(
    "",
    "company",
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    company,
    setFilterCompany
  );
  const [location, setFilterLocation] = useQueryState(
    "",
    "location",
    queryStateOptions
  );
  const [inputLocation, setInputLocation] = useDebounceState(
    location,
    setFilterLocation
  );

  const [locations, setLocations] = useState<Location[]>([]);

  const {
    mutateAsync: locationMutation,
    isLoading: locationsLoading,
    error: locationsError,
  } = useMutation(
    async () => {
      const companyId = company || userCompany;
      if (!companyId) {
        return;
      }
      return await request<Location[]>(
        apiRoutes.companyLocations(+companyId),
        "get"
      );
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        setLocations(res.data);
      },
    }
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<PersonFilters, Person>(
      { search, company, location },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<PersonFilters, Person>(
    apiRoutes.persons,
    params
  );
  const { data: companies, error: companyError } = useUnlimitedPaginationApi<
    {},
    Company
  >(apiRoutes.companies);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
    companyError?.response?.data.message &&
      enqueueSnackbar(companyError?.response?.data.message, {
        variant: "error",
      });
  }, [error, enqueueSnackbar, companyError]);

  useEffect(() => {
    if (!company && !userCompany) {
      setLocations([]);
      return;
    }
    locationMutation();
  }, [company, locationMutation, userCompany]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterCompany("");
    setFilterLocation("");
  };
  const hasFilters = search !== "" || company !== "";

  return (
    <StyledContainer data-test="persons-content">
      <Header
        title={t("Personen")}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={[Role.Admin, Role.Client]}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.personCreate}
            >
              <Add /> {t("Neue Person")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("zB: Name, E-Mail")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <Guarded grant={AppGrant.LOCATION_COMPANY_FILTER}>
              <StyledFilterFormControl>
                <Autocomplete
                  id="company"
                  style={{ width: theme?.width.full }}
                  onChange={(event, value) =>
                    setInputCompany(value ? "" + value.id : "")
                  }
                  options={companies}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option) =>
                    "" + option.id === inputCompany
                  }
                  value={
                    companies.find(
                      (company) => "" + company.id === inputCompany
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Organisation")}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </StyledFilterFormControl>
            </Guarded>
            <StyledFilterFormControl>
              <Autocomplete
                id="location"
                fullWidth
                onChange={(event, value) =>
                  setInputLocation(value ? "" + value.id : "")
                }
                options={locations}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) =>
                  "" + option.id === inputLocation
                }
                loading={locationsLoading}
                disabled={!!locationsError}
                noOptionsText={t("Bitte zuerst Organisation auswählen")}
                value={
                  locations.find(
                    (location) => "" + location.id === inputLocation
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Ort/Abteilung")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <PersonList
          persons={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default PersonsOverview;
