import { Route, Switch, useRouteMatch } from "react-router-dom";
import UserOverview from "./overview";
import UserDetails from "./details";
import CreateUser from "./create";
import EditUser from "./edit";
import { routes } from "../../lib/routes";

const UserManagement = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <UserOverview />
      </Route>
      <Route path={routes.userCreate}>
        <CreateUser />
      </Route>
      <Route path={routes.userEdit(":userId")}>
        <EditUser />
      </Route>
      <Route path={routes.user(":userId")}>
        <UserDetails />
      </Route>
    </Switch>
  );
};

export default UserManagement;
