import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  AppGrant,
  Company,
  Location,
  LocationFilters,
  Role,
} from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { StyledFilterFormControl } from "../orders/overview";
import { ThemeContext } from "styled-components";
import { config } from "../../config";
import LocationList from "../../components/location-list";
import { useQueryClient } from "react-query";
import LocationCreateDialog from "../../components/location-create-dialog";
import { isOnlyClient } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";

const LocationsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Orte/Abteilungen"));
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const user = useCurrentUser();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Orte/Abteilungen") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [company, setFilterCompany] = useQueryState(
    "",
    "company",
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    company,
    setFilterCompany
  );
  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<LocationFilters, Location>(
      { search, company },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    LocationFilters,
    Location
  >(apiRoutes.locations, params);

  const { data: companies, error: companyError } = useUnlimitedPaginationApi<
    {},
    Company
  >(apiRoutes.companies, undefined, { companyOption: true });

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
    companyError?.response?.data.message &&
      enqueueSnackbar(companyError?.response?.data.message, {
        variant: "error",
      });
  }, [error, enqueueSnackbar, companyError]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterCompany("");
  };
  const hasFilters = search !== "" || company !== "";

  const onListUpdate = async () => {
    await queryClient.invalidateQueries(apiRoutes.locations);
  };

  const personCompany = isOnlyClient(user)
    ? user.person?.primaryCompany || undefined
    : undefined;

  return (
    <StyledContainer data-test="persons-content">
      <Header
        title={t("Orte/Abteilungen")}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={[Role.Admin, Role.Client]}>
            <Button
              color="primary"
              onClick={() => setCreateDialogOpen(true)}
              variant="contained"
              startIcon={<Add />}
            >
              {t("Neuer Ort/Abteilung")}
            </Button>
            {createDialogOpen && (
              <LocationCreateDialog
                open={createDialogOpen}
                onClose={() => setCreateDialogOpen(false)}
                onLocationUpdate={onListUpdate}
                company={personCompany}
              />
            )}
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("zB: Name")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <Guarded grant={AppGrant.LOCATION_COMPANY_FILTER}>
              <StyledFilterFormControl>
                <Autocomplete
                  id="company"
                  style={{ width: theme?.width.full }}
                  onChange={(event, value) =>
                    setInputCompany(value ? "" + value.id : "")
                  }
                  options={companies}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option) =>
                    "" + option.id === inputCompany
                  }
                  value={
                    companies.find(
                      (company) => "" + company.id === inputCompany
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Organisation")}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </StyledFilterFormControl>
            </Guarded>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <LocationList
          locations={data?.results || []}
          onLocationUpdate={onListUpdate}
          hideCreate={true}
          loading={isLoading}
          company={personCompany}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default LocationsOverview;
