import React, {
  FunctionComponent,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClickableThumbnailContainer, StyledCardTitle } from "../globals";
import {
  Item,
  ItemDocumentation,
  ItemDocumentationPreview,
  ItemDocumentationType,
} from "../../model";
import { Thumbnail } from "../thumbnail";
import { amber } from "@mui/material/colors";
import ItemDocumentationDetailDialog from "../item-documentation-detail-dialog";
import DefectChip from "../defect-chip";
import DefectListDialog from "../defect-list-dialog";
import HistoryDateEntry from "../date-entry";
import DefectVerifiedLabel from "../defect-verified-label";

const Caption: FunctionComponent = ({ children }) => {
  return (
    <Typography
      variant="caption"
      noWrap={true}
      style={{ display: "inline-block", width: "100%", lineHeight: "1.2" }}
    >
      {children}
    </Typography>
  );
};

const ItemDocumentationPreviewCard: VoidFunctionComponent<{
  itemDocumentation: ItemDocumentation | ItemDocumentationPreview;
}> = ({ itemDocumentation }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const style =
    itemDocumentation.type === ItemDocumentationType.Defect &&
    !itemDocumentation.verified
      ? {
          border: "4px solid",
          borderColor: amber[500],
        }
      : {};

  return (
    <>
      <Card
        style={{
          lineHeight: "1",
          boxShadow: "none",
          cursor: "pointer",
          borderRadius: 0,
        }}
        onClick={() => setDetailsOpen(true)}
      >
        <ClickableThumbnailContainer style={{ marginBottom: ".5em", ...style }}>
          {itemDocumentation.attachments.length > 0 &&
            itemDocumentation.attachments[0].image && (
              <Thumbnail
                preview={itemDocumentation.attachments[0]}
                collection={itemDocumentation.attachments.length > 1}
              />
            )}
        </ClickableThumbnailContainer>
        <CardActionArea>
          {itemDocumentation.verified !== null && (
            <>
              <HistoryDateEntry
                date={itemDocumentation.verifiedDate ?? "-"}
                name={itemDocumentation.verifiedBy?.username ?? "-"}
              />
              <DefectVerifiedLabel verified={itemDocumentation.verified} />
            </>
          )}
          {itemDocumentation.defectCorrected && (
            <>
              <HistoryDateEntry
                date={itemDocumentation.defectCorrectedAt ?? "-"}
              />
              <DefectVerifiedLabel verified={null} corrected={true} />
            </>
          )}
          <HistoryDateEntry
            date={itemDocumentation.createdAt}
            name={itemDocumentation.createdBy ?? "-"}
          />
          {itemDocumentation.type === ItemDocumentationType.Defect && (
            <Caption>{itemDocumentation.defect?.title}</Caption>
          )}
          {itemDocumentation.type === ItemDocumentationType.Documentation && (
            <Caption>{itemDocumentation.description}</Caption>
          )}
        </CardActionArea>
      </Card>
      {detailsOpen && (
        <ItemDocumentationDetailDialog
          open={detailsOpen}
          onClose={() => setDetailsOpen(false)}
          itemDocumentation={itemDocumentation}
        />
      )}
    </>
  );
};

const ItemDocumentationGallery: VoidFunctionComponent<{
  item: Item;
}> = ({ item }) => {
  const { t } = useTranslation();
  const [defectsDetailDialog, setDefectsDetailDialog] = useState<Item | null>(
    null
  );

  const hasItemDocumentations =
    item.itemChecks.length <= 0
      ? false
      : item.itemChecks.find(
          (itemCheck) => itemCheck.itemDocumentations.length > 0
        );

  return (
    <Card>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          <Grid container justifyContent="space-between">
            <Grid item>{t("Dokumentation")}</Grid>
            <Grid item>
              <DefectChip
                item={item}
                onClick={() => {
                  if (!item.defects) {
                    return;
                  }
                  setDefectsDetailDialog(item);
                }}
              />
            </Grid>
          </Grid>
        </StyledCardTitle>
        <Grid container spacing={2}>
          {item.itemChecks.map((itemCheck) =>
            itemCheck.itemDocumentations.map((itemDocumentation) => (
              <Grid item key={itemDocumentation.id} xs={3} md={4} lg={3}>
                <ItemDocumentationPreviewCard
                  itemDocumentation={itemDocumentation}
                />
              </Grid>
            ))
          )}
        </Grid>
        {!hasItemDocumentations && (
          <Typography variant="body2" style={{ marginTop: "1em" }}>
            {t("Keine Bilddokumentationen vorhanden")}
          </Typography>
        )}
      </CardContent>
      {defectsDetailDialog && (
        <DefectListDialog
          open={!!defectsDetailDialog}
          onClose={() => setDefectsDetailDialog(null)}
          data={item}
        />
      )}
    </Card>
  );
};

export default ItemDocumentationGallery;
