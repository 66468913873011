import React, { useState, VoidFunctionComponent } from "react";
import { DateFormat, Item } from "../../model";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Definition, StyledHeadline } from "../globals";
import FormattedDate from "../formatted-date";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import ItemCheckResult from "../item-check-result";
import { Visibility } from "@mui/icons-material";
import { ItemCheckDetailsDialog } from "../item-check-details";
import { useItemCheckDetailsApi } from "../../lib/api";
import LoadingContainer from "../loading-container";
import { IconButton } from "../icon-button";
import styled from "styled-components";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyTester } from "../../lib/security";
import GuardedLink from "../guarded-link";

const StyledBox = styled(Box)`
  margin-top: 2em;
`;

const LastItemCheckDetails: VoidFunctionComponent<{
  item: Item;
}> = ({ item }) => {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  const { isLoading, error, data } = useItemCheckDetailsApi(
    item.lastCheck?.id as unknown as number
  );

  if (!item.lastCheck || error) {
    return null;
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  return (
    <>
      <StyledHeadline>{t("Prüfhistorie")}</StyledHeadline>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Definition
            title={t("Letzte Prüfung")}
            value={
              item.lastCheck.checkDate ? (
                <FormattedDate
                  date={item.lastCheck.checkDate}
                  format={DateFormat.Default}
                />
              ) : null
            }
          />
          {item.lastCheck.order && (
            <Definition
              title={t("Auftrag")}
              value={
                <GuardedLink
                  disabled={onlyTester}
                  component={Link}
                  target="_blank"
                  to={routes.order(item.lastCheck.order.id)}
                >
                  {item.lastCheck.order.orderNumber}
                </GuardedLink>
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <StyledBox>
            <ItemCheckResult result={item.lastCheck.checkResult} />
            <IconButton
              aria-label={t("Ansehen")}
              color="secondary"
              onClick={() => setDetailsOpen(true)}
              size="small"
            >
              <Visibility />
            </IconButton>
          </StyledBox>
          <Definition
            title={t("Überprüfer")}
            value={item.lastCheck.checkedBy?.displayName}
          />
        </Grid>
      </Grid>
      <ItemCheckDetailsDialog
        itemCheck={data}
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default LastItemCheckDetails;
