import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import BillingFormFields from "components/billing-form-fields";
import LoadingButton from "components/loading-button";
import PaperDialog from "components/paper-dialog";
import { useBillingForm } from "hooks/form/use-billing-form";
import { useBillingUpdateMutation } from "hooks/mutation/use-billing-mutation";
import { Company } from "model";
import { Billing, getBillingFormValues } from "model/billing";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

const BillingEditDialog: FunctionComponent<{
  open: boolean;
  billing?: Billing;
  company: Company;
  onUpdate: () => void;
  onClose: () => void;
}> = ({ open, billing, company, onUpdate, onClose }) => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useBillingForm(getBillingFormValues(billing));

  const { mutateAsync: updateMutation, isLoading: updateLoading } =
    useBillingUpdateMutation(company, setError, (updatedBilling) => {
      onUpdate();
      reset(getBillingFormValues(updatedBilling));
      onClose();
    });

  const onSubmit = async (values: any) => {
    const formData = new FormData();

    formData.append("documentUploadFile", values.newContract.documentUpload);

    values.newContract.documentUpload = !!values.newContract.documentUpload;

    formData.append("content", JSON.stringify(values));

    await updateMutation(formData);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    reset(getBillingFormValues(billing));
  }, [open, reset, billing]);

  return (
    <PaperDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Verrechnung bearbeiten")}</DialogTitle>
      <DialogContent>
        <Card>
          <BillingFormFields control={control} billing={billing} />
          <Divider />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("Abbrechen")}
        </Button>
        <LoadingButton
          disabled={!isValid}
          color="primary"
          type="submit"
          variant="contained"
          loading={isSubmitting || updateLoading}
          onClick={handleSubmit((values) => onSubmit(values))}
        >
          {t("Speichern")}
        </LoadingButton>
      </DialogActions>
    </PaperDialog>
  );
};

export default BillingEditDialog;
