import React, { useEffect, VoidFunctionComponent } from "react";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import Guarded from "../../components/guarded";
import { CheckStep, CheckStepFilters, CheckStepType, Role } from "../../model";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Add, Clear } from "@mui/icons-material";
import { useTitle } from "../../hooks/use-title";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { useFilters } from "../../hooks/use-filters";
import CheckStepList from "../../components/check-step-list";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useDebounceState } from "../../hooks/use-debounce-state";
import { useSnackbar } from "notistack";

const CheckStepOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useTitle(t("Prüfschritte"));

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [type, setFilterType] = useQueryState(
    "",
    "checkstepType",
    queryStateOptions
  );
  const [securityRelated, setSecurityRelated] = useQueryState(
    false,
    "securityRelated",
    queryStateOptions
  );

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prüfschritte") },
  ];

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<CheckStepFilters, CheckStep>(
      { search, type, securityRelated },
      "id",
      "desc",
      10
    );
  const { data, isLoading, error } = usePaginationApi<
    CheckStepFilters,
    CheckStep
  >(apiRoutes.checkSteps, params);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterType("");
    setSecurityRelated(false);
  };
  const hasFilters = search !== "" || type !== "" || securityRelated;

  return (
    <StyledContainer data-test="check-steps-content">
      <Header
        title={t("Prüfschritte")}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.Admin}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.checkStepCreate}
            >
              <Add /> {t("Neuer Prüfschritt")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="type">{t("Typ")}</InputLabel>
              <Select
                labelId="type"
                id="type"
                value={type}
                onChange={(e) => setFilterType(e.target.value as string)}
                label={t("Typ")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value={CheckStepType.Text}>
                  {t(`checkStep.type.${CheckStepType.Text}`)}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value={CheckStepType.Bool}>
                  {t(`checkStep.type.${CheckStepType.Bool}`)}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={securityRelated}
                    onChange={(e) => setSecurityRelated(e.target.checked)}
                    name="securityRelated"
                    color="primary"
                  />
                }
                label="Sicherheitsrelevant"
              />
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <CheckStepList
          checkSteps={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default CheckStepOverview;
