import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Container } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import { CompanyFormValues, getCompanyFormValues } from "../../model";
import { useCompanyDetailsApi } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanyValidationSchema } from "../../hooks/validations/companies/use-company-validation-schema";
import LoadingButton from "../../components/loading-button";
import { CompanyFormFields } from "../../components/company-form-fields";
import { useCompanyUpdateMutation } from "../../hooks/mutation/use-company-mutation";

const EditCompany = () => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string | undefined }>();
  const { isLoading, error, data } = useCompanyDetailsApi(
    companyId as unknown as number
  );

  useTitle(data?.name || t("Organisation bearbeiten"));

  const validationSchema = useCompanyValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<CompanyFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCompanyFormValues(data),
  });

  useEffect(() => reset(getCompanyFormValues(data)), [reset, data]);

  watch((data, { name, type }) => {
    if (type !== "change") {
      return;
    }
    if (name === "importer" && (data.portfolio || []).length > 0) {
      setValue("portfolio", [], { shouldValidate: true });
    }
  });
  const { mutateAsync: updateMutate } = useCompanyUpdateMutation(
    setError,
    data
  );

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.companies}>
            {t("Zurück zu Organisationen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Organisationen"), link: routes.companies },
    { label: data.name, link: routes.company(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Organisationen")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => updateMutate(values))}>
        <Card>
          <CompanyFormFields
            control={control}
            watch={watch}
            data={data}
            getValues={getValues}
          />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditCompany;
