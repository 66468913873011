import {
  Blameable,
  Category,
  CategoryPreview,
  Company,
  CompanyPreview,
  Document,
  DocumentFormValues,
  ID,
  Timestampable,
} from "./";

// Sync with ProductVoter in backend
export enum ProductGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
  VERIFY = "verify",
}

export interface Product extends ProductPreview, Timestampable, Blameable {
  manufacturer: Company;
  category: Category;
  articleNumber: string;
  shortDescription: string;
  description: string;
  reviewInterval: number;
  lifetime: number;
  documents: Document[];
  image: string | null;
  verified: boolean;
}

export interface ProductPreview {
  id: ID;
  manufacturer: CompanyPreview;
  category: CategoryPreview;
  model: string;
  manufacturingYear: string;
  displayName: string;
}

export interface ProductOption {
  id: ID;
  manufacturingYear: string;
  model: string;
  displayName: string;
  verified?: boolean;
  create?: boolean;
}

export interface ProductFormValues {
  model: string;
  manufacturingYear: string;
  category: ID | null;
  manufacturer: ID | null;
  articleNumber: string;
  shortDescription: string;
  description: string;
  reviewInterval: number;
  lifetime: number | null;
  documents: ID[];
  showUpload: boolean;
  documentUploads: DocumentFormValues[];
  imageUpload: File | null;
  deleteImage: boolean;
}

export interface ProductFilters {
  search: string;
  manufacturer: string;
  category: string;
  verified: string;
  image: string;
}

export const getProductFormValues = (product?: Product): ProductFormValues => ({
  model: product?.model || "",
  manufacturingYear: product?.manufacturingYear || "",
  category: product?.category.id || null,
  manufacturer: product?.manufacturer.id || null,
  articleNumber: product?.articleNumber || "",
  shortDescription: product?.shortDescription || "",
  description: product?.description || "",
  reviewInterval: product?.reviewInterval || 12,
  lifetime: product?.lifetime || 0,
  documents: product?.documents
    ? product?.documents.map((document) => document.id)
    : [],
  showUpload: false,
  documentUploads: [],
  imageUpload: null,
  deleteImage: false,
});
