import {
  Blameable,
  Commentable,
  Company,
  ID,
  Location,
  Timestampable,
} from "./index";

// Sync with ActionVoter in backend
export enum ActionGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface Action
  extends ActionPreview,
    Timestampable,
    Blameable,
    Commentable {
  company: Company;
  location: Location | null;
}

export interface ActionPreview {
  id: ID;
  title: string;
}

export interface ActionFormValues {
  title: string;
  company: ID | null;
  location: ID | null;
}

export const getActionFormValues = (data?: Action): ActionFormValues => ({
  title: data?.title || "",
  company: data?.company.id || null,
  location: data?.location?.id || null,
});
