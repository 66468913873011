import React, { FunctionComponent } from "react";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { green, grey } from "@mui/material/colors";

const StyledBox = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const ArchivedIndicator: FunctionComponent<{ archived: boolean }> = ({
  archived,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t<string>(getTooltipTitle(archived))}>
      <StyledBox
        style={{ backgroundColor: archived ? grey[500] : green[500] }}
      />
    </Tooltip>
  );
};

export default ArchivedIndicator;

function getTooltipTitle(archived: boolean) {
  if (archived) {
    return "Archiviert";
  }
  return "Aktiv";
}
