import { FunctionComponent } from "react";
import { LinkBaseProps } from "@mui/material/Link/Link";
import { Link } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const GuardedLink: FunctionComponent<
  { disabled: boolean } & LinkBaseProps & LinkProps
> = ({ disabled, children, ...props }) => {
  if (disabled) {
    return <>{children}</>;
  }
  return (
    <Link component={RouterLink} {...props}>
      {children}
    </Link>
  );
};

export default GuardedLink;
