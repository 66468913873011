import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  ApiError,
  Company,
  Document,
  DocumentFormValues,
  getLocationFormValues,
  Location,
  LocationFormValues,
  ManualType,
} from "../../model";
import { apiRoutes, request, useLocationDetailsApi } from "../../lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApi } from "../../hooks/use-api";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useSnackbar } from "notistack";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import DocumentUpload from "../document-upload";
import { Add, Clear } from "@mui/icons-material";
import LoadingContainer from "../loading-container";
import { StyledHighlightedGrid, StyledLoadingDialogContent } from "../globals";
import LoadingButton from "../loading-button";
import { useLocationValidationSchema } from "../../hooks/validations/locations/use-location-validation-schema";
import { useCurrentUser } from "hooks/use-current-user";
import { isOnlyClient } from "lib/security";
import HttpError from "../http-error";
import PaperDialog from "../paper-dialog";

const LocationEditDialog: FunctionComponent<{
  open: boolean;
  onClose: () => void;
  location: Location;
  company?: Company;
  onLocationUpdate: (location: Location) => void;
}> = ({ open, onClose, location, company, onLocationUpdate }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();

  const validationSchema = useLocationValidationSchema();
  const { isLoading, error, data } = useLocationDetailsApi(location.id);
  const {
    data: locations,
    isLoading: locationsLoading,
    error: locationsError,
  } = useApi<Location[]>(apiRoutes.locationParents(location.id));
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<{}, Company>(apiRoutes.companies, undefined, {
    companyOption: true,
  });
  const {
    data: documents,
    isLoading: documentsLoading,
    error: documentsError,
  } = useUnlimitedPaginationApi<DocumentFormValues, Document>(
    apiRoutes.documents,
    { type: ManualType.LocationPlan },
    undefined,
    { enabled: !isOnlyClient(user) }
  );
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
  } = useForm<LocationFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getLocationFormValues(data),
  });

  const showUpload = watch("showUpload", false);
  const parent = watch("parent");
  const locationCompanies = watch("companies");

  useEffect(() => reset(getLocationFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: LocationFormValues,
    setError: UseFormSetError<LocationFormValues>
  ) => {
    if (showUpload && !values.documentUpload.documentUpload) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    if (!data) {
      return;
    }

    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        name: values.name || "",
        parent: values.parent,
        comment: values.comment,
        companies: values.companies,
        document: values.document,
        ...(values.showUpload
          ? {
              documentUpload: {
                name: values.documentUpload.name,
                type: values.documentUpload.type,
                comment: values.documentUpload.comment,
              },
            }
          : {}),
      })
    );

    await request(apiRoutes.location(data.id), "post", formData)
      .then(() => {
        enqueueSnackbar(t("Ort wurde erfolgreich aktualisiert."), {
          variant: "success",
        });
        onLocationUpdate(data);
        // if (company) {
        //   history.push(routes.company(company.id));
        // }
        onClose();
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <PaperDialog open={open} onClose={onClose} maxWidth="sm">
      {error ? (
        <StyledLoadingDialogContent>
          <HttpError
            error={error}
            actions={<Button onClick={onClose}>{t("Schließen")}</Button>}
          />
        </StyledLoadingDialogContent>
      ) : isLoading || companiesLoading || locationsLoading ? (
        <StyledLoadingDialogContent>
          <LoadingContainer />
        </StyledLoadingDialogContent>
      ) : (
        <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
          <DialogTitle>Ort/Abteilung bearbeiten: {data?.name}</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {company && isOnlyClient(user) && (
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label={t("Organisation")}
                        fullWidth
                        disabled={true}
                        value={company?.name}
                      />
                    </Grid>
                  )}
                  {locationsError ||
                    companiesError ||
                    (companiesError && (
                      <Grid item xs={12}>
                        {locationsError && (
                          <>
                            <Alert severity="info">
                              {t(
                                "Übergeordnete Orte konnten nicht geladen werden."
                              )}
                            </Alert>
                          </>
                        )}
                        {companiesError && (
                          <>
                            <Alert severity="info">
                              {t(
                                "Zugewiesene Organisationen konnten nicht geladen werden."
                              )}
                            </Alert>
                          </>
                        )}
                        {documentsError && !isOnlyClient(user) && (
                          <>
                            <Alert severity="info">
                              {t("Dokumente konnten nicht geladen werden.")}
                            </Alert>
                          </>
                        )}
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"name"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Name")}
                          fullWidth
                          {...field}
                          required
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {locations && (
                      <Controller
                        control={control}
                        name={"parent"}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            id="parent"
                            loadingText={"Lädt..."}
                            loading={locationsLoading}
                            disabled={!locations.length}
                            options={locations}
                            getOptionKey={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            value={
                              locations.find(
                                (location) => location.id === field.value
                              ) || null
                            }
                            onChange={(event, value) =>
                              field.onChange(value?.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label={t("Übergeordneter Ort")}
                                disabled={!!locationsError}
                                error={
                                  fieldState.isTouched && fieldState.invalid
                                }
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"comment"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Kommentar")}
                          fullWidth
                          multiline={true}
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  {!isOnlyClient(user) && (
                    <>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={"companies"}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              id="companies"
                              multiple={true}
                              options={companies}
                              getOptionKey={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              defaultValue={location.companies}
                              onChange={(event, value) =>
                                field.onChange(
                                  value.map((company) => company.id)
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label={t("Zugeordnete Organisationen")}
                                  error={
                                    fieldState.isTouched && fieldState.invalid
                                  }
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={"document"}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              id="document"
                              options={documents || []}
                              loading={documentsLoading}
                              getOptionKey={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              value={
                                documents.find(
                                  (document) => document.id === field.value
                                ) || null
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, value) =>
                                field.onChange(value?.id)
                              }
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label={t("Lagerplan")}
                                  error={
                                    fieldState.isTouched && fieldState.invalid
                                  }
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={"showUpload"}
                          render={({ field }) => (
                            <>
                              <Button
                                type="button"
                                onClick={() => {
                                  field.onChange(!showUpload);
                                }}
                              >
                                {showUpload ? (
                                  <>
                                    {" "}
                                    <Clear /> {t("Abbrechen")}
                                  </>
                                ) : (
                                  <>
                                    <Add /> {t("Neuen Lagerplan hochladen")}
                                  </>
                                )}
                              </Button>
                              <Checkbox
                                aria-hidden={true}
                                style={{ display: "none" }}
                                checked={showUpload}
                                onChange={field.onChange}
                                color="primary"
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                {showUpload && (
                  <Grid container spacing={0} sx={{ margin: "1em 0" }}>
                    <StyledHighlightedGrid item xs={12}>
                      <Typography variant={"h6"}>
                        {t("Dokument hinzufügen")}
                      </Typography>
                      <DocumentUpload
                        control={control}
                        formPrefix="documentUpload."
                      />
                    </StyledHighlightedGrid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  {!parent && !locationCompanies.length && (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        {t(
                          "Achtung: Orte, die keinen Organisationen oder anderen Orten zugewiesen sind, werden gelöscht."
                        )}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()} color="secondary">
              {t("Abbrechen")}
            </Button>
            <LoadingButton
              disabled={!isValid}
              type="submit"
              color="primary"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </DialogActions>
        </form>
      )}
    </PaperDialog>
  );
};

export default LocationEditDialog;
