import { useForm } from "react-hook-form";
import { ActionFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useActionValidationSchema } from "../validations/action/use-action-validation-schema";

export const useActionForm = (defaultValues: ActionFormValues) => {
  const validationSchema = useActionValidationSchema();

  return useForm<ActionFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
