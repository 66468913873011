import { Route, Switch, useRouteMatch } from "react-router-dom";
import StandardOverview from "./overview";
import { routes } from "../../lib/routes";
import CreateStandard from "./create";
import EditStandard from "./edit";
import StandardDetails from "./details";

const Standards = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <StandardOverview />
      </Route>
      <Route path={routes.standardCreate}>
        <CreateStandard />
      </Route>
      <Route path={routes.standardEdit(":standardId")}>
        <EditStandard />
      </Route>
      <Route path={routes.standard(":standardId")}>
        <StandardDetails />
      </Route>
    </Switch>
  );
};

export default Standards;
