import { Switch, useRouteMatch } from "react-router-dom";
import GuardedRoute from "../../components/guarded-route";
import { AppGrant } from "../../model";
import QueueTaskOverview from "./overview";

const QueueTasks = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <GuardedRoute exact path={path} grant={AppGrant.ITEMS}>
        <QueueTaskOverview />
      </GuardedRoute>
    </Switch>
  );
};

export default QueueTasks;
