import {
  Alert,
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AppGrant,
  Company,
  CompanyFilters,
  CompanyFormValues,
  CompanyGrant,
  CompanyType,
  Location,
} from "../../model";
import { StyledCardTitle, StyledFooter, StyledLogo } from "../globals";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { apiRoutes, getAbsoluteApiUrl, request } from "../../lib/api";
import { isGranted } from "../../lib/security";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import Guarded from "../guarded";
import { useApi } from "../../hooks/use-api";
import { Description, PictureAsPdf } from "@mui/icons-material";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import LoadingButton from "../loading-button";
import { generateCompanyMutationFormData } from "../../hooks/mutation/use-company-mutation";

export const CompanyFormFields: VoidFunctionComponent<{
  control: Control<CompanyFormValues>;
  watch: UseFormWatch<CompanyFormValues>;
  data?: Company;
  getValues?: UseFormGetValues<CompanyFormValues>;
}> = ({ control, watch, data, getValues }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const createDesignGranted = data
    ? isGranted(user, CompanyGrant.VIEW_DESIGN, data)
    : isGranted(user, AppGrant.COMPANY_CREATE_DESIGN);
  const fullCreateGranted = isGranted(user, AppGrant.COMPANY_CREATE_FULL);
  const { enqueueSnackbar } = useSnackbar();

  const customer = watch("customer");
  const supplier = watch("supplier");
  const importer = watch("importer");

  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<{}, Company>(
    apiRoutes.companies,
    "get",
    { companyOption: true },
    { enabled: fullCreateGranted }
  );

  const {
    data: companyParents,
    isLoading: companyParentsLoading,
    error: companyParentsError,
  } = useApi<Company[]>(
    apiRoutes.companyParents(data?.id as unknown as number),
    "get",
    undefined,
    { context: { companyOption: true } },
    undefined,
    { enabled: !!data && fullCreateGranted }
  );

  const {
    data: companiesManufacturer,
    isLoading: companiesManufacturerLoading,
    error: companiesManufacturerError,
  } = useUnlimitedPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    { types: [CompanyType.Manufacturer] },
    { companyOption: true },
    { enabled: fullCreateGranted }
  );
  const {
    data: companiesSupplier,
    isLoading: companiesSupplierLoading,
    error: companiesSupplierError,
  } = useUnlimitedPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    {
      types: [CompanyType.Supplier],
    },
    { companyOption: true },
    { enabled: fullCreateGranted }
  );
  const {
    data: companiesTestPartner,
    isLoading: companiesTestPartnerLoading,
    error: companiesTestPartnerError,
  } = useUnlimitedPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    {
      types: [CompanyType.TestPartner],
    },
    { companyOption: true },
    { enabled: fullCreateGranted }
  );

  const {
    data: locations,
    isLoading: locationsLoading,
    error: locationsError,
  } = useUnlimitedPaginationApi<{}, Location>(
    apiRoutes.locations,
    undefined,
    { locationOption: true },
    { enabled: fullCreateGranted }
  );

  const { mutateAsync: previewMutation, isLoading: previewIsLoading } =
    useMutation(
      async () => {
        if (!data) {
          return;
        }
        return await request<Company>(
          apiRoutes.companyReportPreview(data.id),
          "post",
          getValues ? generateCompanyMutationFormData(getValues()) : ""
        );
      },
      {
        onSuccess: async (res) => {
          if (!res || !data) {
            return;
          }
          window.open(
            getAbsoluteApiUrl(
              apiRoutes.companyReportPreview(data.id) + "?tmpData=true"
            ),
            "_blank"
          );
        },
        onError: (err: AxiosError) => {
          enqueueSnackbar(err.response?.data.message || "Error", {
            variant: "error",
          });
        },
      }
    );

  const error =
    companiesError ||
    companyParentsError ||
    locationsError ||
    companiesManufacturerError ||
    companiesSupplierError ||
    companiesTestPartnerError;
  if (error) {
    return (
      <HttpError
        error={[
          companiesError,
          companyParentsError,
          locationsError,
          companiesManufacturerError,
          companiesSupplierError,
          companiesTestPartnerError,
        ]}
        actions={
          <Button component={Link} to={routes.dashboard}>
            {t("Zurück zu Home")}
          </Button>
        }
      />
    );
  }

  return (
    <>
      <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Typ")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"customer"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="customer"
                        color="primary"
                      />
                    }
                    label={t("Kunde")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"supplier"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="supplier"
                        color="primary"
                      />
                    }
                    label={t("Lieferant")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"manufacturer"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="supplier"
                        color="primary"
                      />
                    }
                    label={t("Hersteller")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"partner"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="partner"
                        color="primary"
                      />
                    }
                    label={t("Vertriebspartner")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"distributor"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="distributor"
                        color="primary"
                      />
                    }
                    label={t("Distributeur")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"opinionLeader"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="opinionLeader"
                        color="primary"
                      />
                    }
                    label={t("Meinungsbildner")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"testPartner"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="testPartner"
                        color="primary"
                      />
                    }
                    label={t("Prüfpartner")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                control={control}
                name={"trainingCenter"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="trainingCenter"
                        color="primary"
                      />
                    }
                    label={t("Schulungszentrum")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Guarded>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Stammdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"name"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Name")}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"term"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Suchbegriff")}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
            <Grid item md={6}>
              {!data ? (
                <Controller
                  control={control}
                  name="parent"
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="parent"
                      options={companies}
                      loading={companiesLoading}
                      loadingText={t("Lädt Organisationen...")}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        companies.find(
                          (company) => company.id === field.value
                        ) || null
                      }
                      onChange={(event, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Übergeordnete Organisation")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name="parent"
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="parent"
                      options={companyParents || []}
                      loading={companyParentsLoading}
                      loadingText={t("Lädt Organisationen...")}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        (companyParents || []).find(
                          (company) => company.id === field.value
                        ) || null
                      }
                      onChange={(event, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Übergeordnete Organisation 1")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid>
          </Guarded>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"registerNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Firmenbuchnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"uidNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("UID-Nummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"phone"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Telefonnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"email"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"website"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Webseite")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {customer && (
        <>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Kunden Daten")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"customerNumber"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Kundennummer")}
                      fullWidth
                      {...field}
                      required
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"customerGroup"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Kundengruppe")}
                      fullWidth
                      {...field}
                      required={fullCreateGranted}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </>
      )}
      <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
        {supplier && (
          <>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Lieferanten Daten")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name={"supplierNumber"}
                    render={({ field, fieldState }) => (
                      <TextField
                        variant="standard"
                        label={t("Lieferantennummer")}
                        fullWidth
                        {...field}
                        required
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"importer"}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="importer"
                            color="primary"
                          />
                        }
                        label={t("Importeur")}
                      />
                    )}
                  />
                  <FormHelperText>
                    {t("Portfolio wird bei Änderung geleert.")}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"portfolio"}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        id="portfolio"
                        value={
                          field.value
                            .map((id) =>
                              companies.find((company) => company.id === id)
                            )
                            .filter((company) => !!company) as Company[]
                        }
                        multiple={true}
                        options={
                          importer ? companiesSupplier : companiesManufacturer
                        }
                        loading={
                          importer
                            ? companiesSupplierLoading
                            : companiesManufacturerLoading
                        }
                        loadingText={t("Lädt Organisationen...")}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        defaultValue={data?.portfolio}
                        onChange={(event, value) =>
                          field.onChange(value.map((company) => company.id))
                        }
                        getOptionDisabled={(option) => option.id === data?.id}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label={
                              t("Portfolio") +
                              " (" +
                              (importer ? "Hersteller" : "Lieferant") +
                              ")"
                            }
                            error={fieldState.isTouched && fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </>
        )}
      </Guarded>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Adresse")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"street"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Straße")}
                  fullWidth
                  {...field}
                  required={customer}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={"zipCode"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("PLZ")}
                  fullWidth
                  {...field}
                  required={customer}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={"city"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Ort")}
                  fullWidth
                  {...field}
                  required={customer}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"country"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Land")}
                  fullWidth
                  {...field}
                  required={customer}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"additionalAddressInformation"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Adresszusatz")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Sonstiges")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"comment"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Kommentar")}
                  fullWidth
                  {...field}
                  multiline={true}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
        <>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Zuordnung von Orten/Abteilungen")}
            </StyledCardTitle>
            <Controller
              control={control}
              name={"locations"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="locations"
                  multiple={true}
                  options={locations}
                  loading={locationsLoading}
                  loadingText={t("Lädt Orte/Abteilungen...")}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={locations.filter((location) => {
                    return location.id
                      ? field.value.includes(+location.id)
                      : [];
                  })}
                  onChange={(event, value) =>
                    field.onChange(value.map((company) => company.id))
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Orte/Abteilungen")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Zuordnung Prüfpartner")}
            </StyledCardTitle>
            <Controller
              control={control}
              name={"testPartners"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="testPartners"
                  multiple={true}
                  options={companiesTestPartner}
                  loading={companiesTestPartnerLoading}
                  loadingText={t("Lädt Organisationen...")}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={companiesTestPartner.filter((company) => {
                    return company.id ? field.value.includes(+company.id) : [];
                  })}
                  onChange={(_, value) =>
                    field.onChange(value.map((company) => company.id))
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Prüfpartner")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </CardContent>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Zuordnung Eigentümer")}
            </StyledCardTitle>
            <Controller
              control={control}
              name="owner"
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="owner"
                  options={companies}
                  loading={companiesLoading}
                  loadingText={t("Lädt Organisationen...")}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={
                    companies.find((company) => company.id === field.value) ||
                    null
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Eigentümer")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </CardContent>
          <Divider />
        </>
      </Guarded>
      {createDesignGranted && (
        <>
          <CardContent>
            <StyledCardTitle
              variant="h6"
              color="secondary"
              gutterBottom
              style={{ justifyContent: "space-between" }}
            >
              {t("Logos und Design")}
              {data && (
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={() => previewMutation()}
                  startIcon={<PictureAsPdf />}
                  loading={previewIsLoading}
                >
                  {t("Vorschau")}
                </LoadingButton>
              )}
            </StyledCardTitle>
            <Alert severity="info" style={{ marginBottom: "1em" }}>
              <Typography>{t("Datei Empfehlungen")}</Typography>
              {t("Dateiformate: PNG, JPG")}
              <br />
              {t("Max. Dateigröße pro Upload: 1MB")}
              <br />
              {t("Hintergrund: weiß, transparent")}
              <br />
              {t(
                "Stempel: geringere Deckkraft wegen Überlagerung von Unterschriften"
              )}
              <br />
              <b>
                {t(
                  "Vorschau kontrollieren, bei unscharfer Darstellung bitte eine größere Auflösung verwenden"
                )}
              </b>
              <br />
            </Alert>
            <Grid container>
              {data && (
                <Grid item xs={12}>
                  <Typography variant="body2">{t("Aktuelles Logo")}</Typography>
                  {data?.logo ? (
                    <StyledLogo
                      alt={t("Logo")}
                      src={data.logo + "&date=" + new Date().toISOString()}
                    />
                  ) : (
                    <Typography variant="caption">
                      {t("Kein Logo zugewiesen")}
                    </Typography>
                  )}
                </Grid>
              )}
              {data?.logo && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="deleteLogo"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="deleteLogo"
                            color="primary"
                          />
                        }
                        label={t("Logo löschen")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Controller
              control={control}
              name="logoUpload"
              render={({ field, fieldState }) => (
                <>
                  <input
                    hidden
                    {...field}
                    value=""
                    accept="image/*"
                    id="logoUpload"
                    type="file"
                    onChange={(event) => {
                      field.onChange(event.target.files![0]);
                    }}
                  />
                  <label htmlFor="logoUpload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<Description />}
                    >
                      {t("Logo hochladen")}
                    </Button>
                  </label>
                  {fieldState.error ? (
                    <FormHelperText error={true}>
                      {fieldState.error?.message}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {field.value?.name || t("Empfohlenes Verhältnis: 1:1")}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Protokolldesign")}
            </StyledCardTitle>
            <Grid container>
              {data && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {t("Aktueller Stempel")}
                  </Typography>
                  {data?.seal ? (
                    <StyledLogo
                      alt={t("Stempel")}
                      src={data.seal + "&date=" + new Date().toISOString()}
                    />
                  ) : (
                    <Typography variant="caption">
                      {t("Kein Stempel zugewiesen")}
                    </Typography>
                  )}
                </Grid>
              )}
              {data?.seal && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="deleteSeal"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="deleteSeal"
                            color="primary"
                          />
                        }
                        label={t("Stempel löschen")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Controller
              control={control}
              name="sealUpload"
              render={({ field, fieldState }) => (
                <>
                  <input
                    hidden
                    {...field}
                    value=""
                    accept="image/*"
                    id="sealUpload"
                    type="file"
                    onChange={(event) => {
                      field.onChange(event.target.files![0]);
                    }}
                  />
                  <label htmlFor="sealUpload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<Description />}
                    >
                      {t("Stempel hochladen")}
                    </Button>
                  </label>
                  {fieldState.error ? (
                    <FormHelperText error={true}>
                      {fieldState.error?.message}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {field.value?.name ||
                        t("Empfohlenes Verhältnis: 1:1, geringe Deckkraft")}
                    </FormHelperText>
                  )}
                </>
              )}
            />
            <Grid container style={{ marginTop: "2em" }}>
              {data && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {t("Aktueller Footer")}
                  </Typography>
                  {data?.footer ? (
                    <StyledFooter
                      alt={t("Footer")}
                      src={data.footer + "&date=" + new Date().toISOString()}
                    />
                  ) : (
                    <Typography variant="caption">
                      {t("Kein Footer zugewiesen")}
                    </Typography>
                  )}
                </Grid>
              )}
              {data?.footer && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="deleteFooter"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="deleteFooter"
                            color="primary"
                          />
                        }
                        label={t("Footer löschen")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Controller
              control={control}
              name="footerUpload"
              render={({ field, fieldState }) => (
                <>
                  <input
                    hidden
                    {...field}
                    value=""
                    accept="image/*"
                    id="footerUpload"
                    type="file"
                    onChange={(event) => {
                      field.onChange(event.target.files![0]);
                    }}
                  />
                  <label htmlFor="footerUpload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<Description />}
                    >
                      {t("Footer hochladen")}
                    </Button>
                  </label>
                  {fieldState.error ? (
                    <FormHelperText error={true}>
                      {fieldState.error?.message}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {field.value?.name || t("Empfohlenes Verhältnis: 6:1")}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </CardContent>
          <Divider />
        </>
      )}
    </>
  );
};
