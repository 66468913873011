import React, { VoidFunctionComponent } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Definition, StyledCardTitle } from "../globals";
import { Item } from "../../model";

const ItemImportInfo: VoidFunctionComponent<{ item: Item }> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Import Fallback Daten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Definition title={t("Produkt")} value={item.productName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Definition title={t("Hersteller")} value={item.manufacturerName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Definition title={t("Kunde")} value={item.customerName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Definition title={t("Ort")} value={item.locationName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Definition title={t("Adresse")} value={item.address} />
          </Grid>
          <Grid item xs={12} md={2}>
            <Definition title={t("PLZ")} value={item.zipCode} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Definition title={t("Ort")} value={item.city} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ItemImportInfo;
