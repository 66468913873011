import { Route, Switch, useRouteMatch } from "react-router-dom";
import GuardedRoute from "../../components/guarded-route";
import { routes } from "../../lib/routes";
import { Role } from "../../model";
import QualificationOverview from "./overview";
import EditQualification from "./edit";
import CreateQualification from "./create";
import QualificationDetails from "./details";

const Qualifications = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <QualificationOverview />
      </Route>
      <GuardedRoute path={routes.qualificationCreate} requiredRole={Role.QA}>
        <CreateQualification />
      </GuardedRoute>
      <GuardedRoute
        path={routes.qualificationEdit(":qualificationId")}
        requiredRole={Role.QA}
      >
        <EditQualification />
      </GuardedRoute>
      <GuardedRoute
        path={routes.qualification(":qualificationId")}
        requiredRole={Role.QA}
      >
        <QualificationDetails />
      </GuardedRoute>
    </Switch>
  );
};

export default Qualifications;
