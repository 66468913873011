import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container } from "@mui/material";
import React from "react";
import Header from "../../components/header";
import { CompanyFormValues, getCompanyFormValues } from "../../model";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "../../components/loading-button";
import { useCompanyValidationSchema } from "../../hooks/validations/companies/use-company-validation-schema";
import { CompanyFormFields } from "../../components/company-form-fields";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useCompanyCreateMutation } from "../../hooks/mutation/use-company-mutation";

const CreateCompany = () => {
  const { t } = useTranslation();
  useTitle(t("Neue Organisation"));
  const user = useCurrentUser();
  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Organisationen"), link: routes.companies },
    { label: t("Neu") },
  ];

  const validationSchema = useCompanyValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
    setValue,
    getValues,
  } = useForm<CompanyFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...getCompanyFormValues(),
      owner: user.person?.primaryCompany?.id || null,
      testPartners: user.person?.primaryCompany
        ? [user.person?.primaryCompany?.id]
        : [],
    },
  });

  watch((data, { name, type }) => {
    if (type !== "change") {
      return;
    }
    if (name === "importer" && (data.portfolio || []).length > 0) {
      setValue("portfolio", [], { shouldValidate: true });
    }
  });

  const { mutateAsync: createMutate } = useCompanyCreateMutation(setError);

  return (
    <Container maxWidth="md">
      <Header title={t("Organisationen")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => createMutate(values))}>
        <Card>
          <CompanyFormFields
            control={control}
            watch={watch}
            getValues={getValues}
          />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={isSubmitting || !isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateCompany;
