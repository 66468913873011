import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Company,
  DateFormat,
  Location,
  Person,
  UserFormValues,
} from "../../model";
import { StyledCardTitle } from "../globals";
import {
  createLocationOptions,
  locationsFilter,
} from "../../views/persons/edit";
import { Option } from "../order-processing/helper";
import { apiRoutes, request } from "../../lib/api";
import LocationCreateDialog from "../location-create-dialog";
import { useSnackbar } from "notistack";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import HttpError from "../http-error";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const UserPersonFormFields: VoidFunctionComponent<{
  control: Control<UserFormValues>;
  setValue: UseFormSetValue<UserFormValues>;
  getValues: UseFormGetValues<UserFormValues>;
  watch: UseFormWatch<UserFormValues>;
  person?: Person;
}> = ({ control, setValue, getValues, watch, person }) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState<Option[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [createLocationOpen, setCreateLocationOpen] = useState(false);
  const [currentName, setCurrentName] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const primaryCompany = watch("person.primaryCompany");
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useUnlimitedPaginationApi<{}, Company>(apiRoutes.companies);

  useEffect(() => {
    if (!primaryCompany) {
      setValue("person.locations", []);
      return setLocations([]);
    }
    setLocationLoading(true);
    request<Location[]>(
      apiRoutes.companyLocations(+primaryCompany),
      "get"
    ).then((res) => {
      setLocations(createLocationOptions(res.data));
      setLocationLoading(false);
      const locationValues = getValues("person.locations");
      const filteredLocations = locationValues.filter((location) =>
        res.data.find((l) => l.id === location)
      );
      setValue("person.locations", filteredLocations);
    });
  }, [primaryCompany, setValue, getValues]);

  const onLocationUpdate = (newLocation: Location) => {
    if (!primaryCompany) {
      return;
    }
    request<Location[]>(apiRoutes.companyLocations(primaryCompany), "get")
      .then((res) => {
        setLocations(createLocationOptions(res.data));
        const locations = getValues("person.locations");
        setValue("person.locations", [...locations, newLocation.id]);
      })
      .catch(() => {
        enqueueSnackbar(t("Orte konnten nicht geladen werden."), {
          variant: "error",
        });
      });
  };

  if (companiesError) {
    return (
      <HttpError
        error={[companiesError]}
        actions={
          <Button component={Link} to={routes.persons}>
            {t("Zurück zu Personen")}
          </Button>
        }
      />
    );
  }

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h5" color="secondary" gutterBottom>
          {t("Personendaten")}
        </StyledCardTitle>
        <small>
          {t(
            "Vorname und Nachname leer lassen, wenn keine Person angelegt/bearbeitet werden soll."
          )}
        </small>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Kontaktdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.firstName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Vorname")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.lastName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Nachname")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.titleBefore"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Voranstehender Titel")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.titleAfter"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Nachstehender Titel")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.email"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail Adresse")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.phone"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Telefonnummer")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.mobilePhone"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Mobiltelefon")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.externalCode"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Zuordnung")}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.birthdate"}
              render={({ field, fieldState }) => (
                <DatePicker
                  value={field.value ? new Date(field.value) : null}
                  format={DateFormat.Default}
                  onChange={field.onChange}
                  label={t("Geburtsdatum")}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: fieldState.isTouched && fieldState.invalid,
                      helperText: fieldState.error?.message,
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"person.comment"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Kommentar")}
                  fullWidth
                  multiline
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name="person.archived"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value || false}
                      onChange={field.onChange}
                      color="primary"
                    />
                  }
                  label={t("Archiviert")}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Zuordnung zu Organisationen")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"person.companies"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="companies"
                  multiple={true}
                  loading={companiesLoading}
                  loadingText={t("Lädt Organisationen...")}
                  options={companies}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  defaultValue={person?.companies}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) =>
                    field.onChange(value.map((company) => company.id))
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Organistationen")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"person.primaryCompany"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="primaryCompany"
                  loading={companiesLoading}
                  loadingText={t("Lädt Organisationen...")}
                  multiple={false}
                  options={companies}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  defaultValue={person?.primaryCompany}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Hauptorganisation")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"person.locations"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="locations"
                  noOptionsText={t(
                    "Es wurden keine Orte/Abteilungen zur Hauptorganisation gefunden"
                  )}
                  loading={locationLoading}
                  loadingText={t(
                    "Lädt Orte/Abteilungen für Hauptorganisation..."
                  )}
                  multiple={true}
                  options={locations}
                  getOptionKey={(option) => option.value || ""}
                  getOptionLabel={(option) => option.label}
                  value={locations.filter((location) => {
                    return location.value
                      ? field.value.includes(+location.value)
                      : [];
                  })}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, values) => {
                    const createValue = values.find((v) =>
                      v.value ? v.value.substr(0, 3) === "new" : false
                    );
                    if (createValue) {
                      setCurrentName(
                        createValue.value ? createValue.value.substr(3) : ""
                      );
                      return setCreateLocationOpen(true);
                    }
                    field.onChange(
                      values.map((option) =>
                        typeof option.value == "string"
                          ? +option.value
                          : option.value
                      )
                    );
                  }}
                  filterOptions={(options, params) => {
                    const filtered = locationsFilter(options, params);
                    if (params.inputValue !== "") {
                      filtered.push({
                        value: `new${params.inputValue}`,
                        label: t(`Ort "{{name}}" hinzufügen`, {
                          name: params.inputValue,
                        }),
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Freigeschaltene Orte/Abteilungen")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      {primaryCompany && (
        <LocationCreateDialog
          open={createLocationOpen}
          onClose={() => setCreateLocationOpen(false)}
          company={companies.find((c) => c.id === primaryCompany)}
          onLocationUpdate={onLocationUpdate}
          name={currentName}
        />
      )}
    </>
  );
};
