import { Role } from "../../model";
import { useTitle } from "../../hooks/use-title";
import { Link as RouterLink, Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Link as UILink,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import Header from "../../components/header";
import { apiRoutes, request, useQualificationDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Definition } from "../../components/globals";
import Guarded from "../../components/guarded";
import { ModifiedEntity } from "../../components/modified-entity";

const QualificationDetails = () => {
  const { t } = useTranslation();
  const { qualificationId } = useParams<{
    qualificationId: string | undefined;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useQualificationDetailsApi(
    qualificationId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.name || t("Qualifikationen Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.qualifications}>
            {t("Zurück zu Qualifikationen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikationen"), link: routes.qualifications },
    { label: data.name },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Qualifikation {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.qualification(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Qualifikation wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.qualifications);
      })
      .catch(() =>
        enqueueSnackbar(t("Qualifikation konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="lg">
      <Header
        title={t("Qualifikation")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.QA}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.qualificationEdit(data.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>
            </Guarded>{" "}
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Definition title={t("Name")} value={data.name} />
                </Grid>
                <Grid item md={6}>
                  <Definition
                    title={t("Typ")}
                    value={t(`qualification.type.${data.type}`)}
                  />
                </Grid>
                <Grid item md={6}>
                  <Definition
                    title={t("Auffrischungs Intervall")}
                    value={data.refreshInterval}
                  />
                </Grid>
                <Grid item md={6}>
                  <Definition
                    title={t("Tags")}
                    value={data.qualificationTags.map((tag) => (
                      <UILink
                        component={RouterLink}
                        to={routes.qualificationTag(tag.id)}
                        display={"block"}
                        key={tag.id}
                      >
                        {tag.name}
                      </UILink>
                    ))}
                  />
                </Grid>
                <Grid item md={6}>
                  <Definition
                    title={t("Voraussetzungen")}
                    value={data.conditions.map((condition) => (
                      <UILink
                        component={RouterLink}
                        to={routes.qualification(condition.id)}
                        display={"block"}
                        key={condition.id}
                      >
                        {condition.name}
                      </UILink>
                    ))}
                  />
                </Grid>
                <Grid item md={6}>
                  <Definition title={t("Notiz")} value={data.comment} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QualificationDetails;
