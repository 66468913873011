import { useUserValidation } from "../user/use-user-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useUserManagementValidationSchema = (passwordRequired = true) => {
  const userValidation = useUserValidation(passwordRequired);

  return useMemo(() => {
    return yup.object({
      username: userValidation.username,
      email: userValidation.email,
      plainPassword: userValidation.password,
      passwordRepeat: userValidation.passwordRepeat("plainPassword"),
    });
  }, [userValidation]);
};
