import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  TablePagination,
  Tabs,
  TextField,
} from "@mui/material";
import { AppGrant, Company, CompanyFilters, CompanyType } from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Guarded from "../../components/guarded";
import CompanyList from "../../components/company-list";
import { config } from "../../config";
import CreateCompanyDialog from "../../components/create-company-dialog";
import { useQueryClient } from "react-query";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyTester } from "../../lib/security";
import { useApi } from "../../hooks/use-api";
import {
  AccessStatus,
  BillingFilters,
  BillingPreview,
  TariffModel,
} from "model/billing";

export const StyledFilterFormControl = styled(FilterFormControl)`
  width: ${(props) => props.theme.width["1/5"]};
`;

const StyledSmallFilterFormControl = styled(StyledFilterFormControl)`
  flex-shrink: 2;
`;

const StyledFilterFormGroup = styled(FilterFormGroup)`
  flex-wrap: nowrap;
`;

export const StyledTab = styled.div`
  width: 250px;
  justify-content: center;
  max-width: none;
  display: flex;
  align-content: center;
  gap: 0.5em;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 7px;
`;

export const listCompanyQueryKey = "list-company";
export const countCompanyQueryKey = "count-company";

export enum CompanyOrigin {
  Owner = "owner",
  TestPartner = "testPartner",
  None = "",
}

const CompanyOverview = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  useTitle(t("Organisationen"));

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Organisationen") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [zipCode, setFilterZipCode] = useQueryState(
    "",
    "zipCode",
    queryStateOptions
  );
  const [inputZipCode, setInputZipCode] = useDebounceState(
    zipCode,
    setFilterZipCode
  );
  const [city, setFilterCity] = useQueryState("", "city", queryStateOptions);
  const [inputCity, setInputCity] = useDebounceState(city, setFilterCity);
  const [country, setFilterCountry] = useQueryState(
    "",
    "country",
    queryStateOptions
  );
  const [inputCountry, setInputCountry] = useDebounceState(
    country,
    setFilterCountry
  );
  const [types, setFilterTypes] = useQueryState(
    [],
    "companyTypes",
    queryStateOptions
  );
  const [origin, setFilterOrigin] = useQueryState(
    onlyTester ? CompanyOrigin.Owner : CompanyOrigin.None,
    "companyOrigin",
    queryStateOptions
  );
  const [accessStatus, setFilterAccessStatus] = useQueryState(
    "",
    "accessStatus",
    queryStateOptions
  );
  const [inputAccessStatus, setInputAccessStatus] = useDebounceState(
    accessStatus,
    setFilterAccessStatus
  );
  const [tariffModel, setFilterTariffModel] = useQueryState(
    "",
    "tariffModel",
    queryStateOptions
  );
  const [inputTariffModel, setInputTariffModel] = useDebounceState(
    tariffModel,
    setFilterTariffModel
  );
  const [dueDateMonth, setFilterDueDateMonth] = useQueryState(
    0,
    "dueDateMonth",
    queryStateOptions
  );
  const [inputDueDateMonth, setInputDueDateMonth] = useDebounceState(
    dueDateMonth,
    setFilterDueDateMonth
  );
  const [dueDateYear, setFilterDueDateYear] = useQueryState(
    "",
    "dueDateYear",
    queryStateOptions
  );

  const setFilterDueDateYearMemoized = useCallback(
    (value: string) => {
      if (value.length !== 4) {
        return;
      }
      setFilterDueDateYear(value);
    },
    [setFilterDueDateYear]
  );

  const [inputDueDateYear, setInputDueDateYear] = useDebounceState(
    dueDateYear,
    setFilterDueDateYearMemoized
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<CompanyFilters & BillingFilters, Company & BillingPreview>(
      {
        search,
        zipCode,
        city,
        country,
        types,
        origin,
        accessStatus,
        tariffModel,
        dueDateMonth,
        dueDateYear,
      },
      "name",
      "asc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    params,
    undefined,
    [listCompanyQueryKey, params]
  );

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useApi<{
    testPartner: number;
    owner: number;
    all: number;
  }>(apiRoutes.companyCount, "get", undefined, params, undefined, {
    queryKey: [countCompanyQueryKey, params],
  });

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterZipCode("");
    setFilterCountry("");
    setFilterCity("");
    setFilterTypes([]);
    setFilterAccessStatus("");
    setFilterTariffModel("");
    setFilterDueDateMonth(0);
    setFilterDueDateYear("");
  };

  const handleChange = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    if (value.includes("")) {
      setFilterTypes([]);
    } else {
      setFilterTypes(typeof value === "string" ? value.split(",") : value);
    }
  };

  const hasFilters =
    search !== "" ||
    zipCode !== "" ||
    city !== "" ||
    country !== "" ||
    types.length > 0 ||
    accessStatus !== "" ||
    tariffModel !== "" ||
    dueDateMonth !== 0 ||
    dueDateYear !== "";

  return (
    <StyledContainer data-test="companies-content">
      <Header
        title={t("Organisationen")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.companyCreate}
              >
                <Add /> {t("Neue Organisation")}
              </Button>
            </Guarded>
            {onlyTester && (
              <Guarded grant={AppGrant.COMPANY_CREATE}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateDialogOpen(true)}
                >
                  <Add /> {t("Neue Organisation")}
                </Button>
                {createDialogOpen && (
                  <CreateCompanyDialog
                    open={createDialogOpen}
                    onClose={() => setCreateDialogOpen(false)}
                    onUpdate={() =>
                      queryClient.invalidateQueries(listCompanyQueryKey)
                    }
                  />
                )}
              </Guarded>
            )}
          </>
        }
      />
      <Card>
        <CardContent>
          <form>
            <StyledFilterFormGroup row>
              <FilterFormControl variant="outlined" size="small">
                <InputLabel id="types">{t("Typen")}</InputLabel>
                <Select
                  labelId="types"
                  id="types"
                  multiple
                  value={types}
                  onChange={handleChange}
                  label={t("Typen")}
                >
                  <MenuItem sx={capitalizeStyle} value="">
                    <em>{t("Alle")}</em>
                  </MenuItem>
                  <MenuItem sx={capitalizeStyle} value={CompanyType.Customer}>
                    {t("Kunde")}
                  </MenuItem>
                  <MenuItem sx={capitalizeStyle} value={CompanyType.Supplier}>
                    {t("Lieferant")}
                  </MenuItem>
                  <MenuItem
                    sx={capitalizeStyle}
                    value={CompanyType.Manufacturer}
                  >
                    {t("Hersteller")}
                  </MenuItem>
                  <MenuItem sx={capitalizeStyle} value={CompanyType.Partner}>
                    {t("Vertriebspartner")}
                  </MenuItem>
                  <MenuItem
                    sx={capitalizeStyle}
                    value={CompanyType.Distributor}
                  >
                    {t("Distributeur")}
                  </MenuItem>
                  <MenuItem
                    sx={capitalizeStyle}
                    value={CompanyType.OpinionLeader}
                  >
                    {t("Meinungsbildner")}
                  </MenuItem>
                  <MenuItem
                    sx={capitalizeStyle}
                    value={CompanyType.TestPartner}
                  >
                    {t("Prüfpartner")}
                  </MenuItem>
                  <MenuItem sx={capitalizeStyle} value={CompanyType.Importer}>
                    {t("Importeur")}
                  </MenuItem>
                  <MenuItem
                    sx={capitalizeStyle}
                    value={CompanyType.TrainingCenter}
                  >
                    {t("Schulungszentrum")}
                  </MenuItem>
                </Select>
              </FilterFormControl>
              <StyledFilterFormControl>
                <TextField
                  label={t("Suche")}
                  variant="outlined"
                  placeholder={t("Alle Felder durchsuchen...")}
                  value={inputSearch}
                  onChange={(e) => setInputSearch(e.target.value)}
                  size="small"
                />
              </StyledFilterFormControl>
              <Guarded grant={AppGrant.COMPANY_CREATE_FULL}>
                <StyledFilterFormControl size="small" variant="outlined">
                  <InputLabel id="accessStatus">
                    {t("Zugriffsstatus")}
                  </InputLabel>
                  <Select
                    id="accessStatus"
                    labelId="accessStatus"
                    label={t("Zugriffsstatus")}
                    value={inputAccessStatus}
                    onChange={(e) =>
                      setInputAccessStatus(e.target.value as string)
                    }
                  >
                    <MenuItem sx={capitalizeStyle} value="">
                      <em>{t("Alle")}</em>
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={AccessStatus.None}>
                      {t("billing.accessStatus." + AccessStatus.None)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={AccessStatus.Basis}>
                      {t("billing.accessStatus." + AccessStatus.Basis)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={AccessStatus.Client}>
                      {t("billing.accessStatus." + AccessStatus.Client)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={AccessStatus.ClientReadOnly}
                    >
                      {t("billing.accessStatus." + AccessStatus.ClientReadOnly)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={AccessStatus.Enterprise}
                    >
                      {t("billing.accessStatus." + AccessStatus.Enterprise)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={AccessStatus.InHouse}>
                      {t("billing.accessStatus." + AccessStatus.InHouse)}
                    </MenuItem>
                  </Select>
                </StyledFilterFormControl>
                <StyledFilterFormControl size="small" variant="outlined">
                  <InputLabel id="tariffModel">{t("Tarif")}</InputLabel>
                  <Select
                    id="tariffModel"
                    labelId="tariffModel"
                    label={t("Tarif")}
                    value={inputTariffModel}
                    onChange={(e) =>
                      setInputTariffModel(e.target.value as string)
                    }
                  >
                    <MenuItem sx={capitalizeStyle} value="">
                      <em>{t("Alle")}</em>
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={TariffModel.None}>
                      {t("billing.tariffModel." + TariffModel.None)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={TariffModel.Basis}>
                      {t("billing.tariffModel." + TariffModel.Basis)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={TariffModel.BasisPercent}
                    >
                      {t("billing.tariffModel." + TariffModel.BasisPercent)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={TariffModel.Client}>
                      {t("billing.tariffModel." + TariffModel.Client)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={TariffModel.EnterpriseSmall}
                    >
                      {t("billing.tariffModel." + TariffModel.EnterpriseSmall)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={TariffModel.EnterpriseMedium}
                    >
                      {t("billing.tariffModel." + TariffModel.EnterpriseMedium)}
                    </MenuItem>
                    <MenuItem sx={capitalizeStyle} value={TariffModel.InHouse}>
                      {t("billing.tariffModel." + TariffModel.InHouse)}
                    </MenuItem>
                    <MenuItem
                      sx={capitalizeStyle}
                      value={TariffModel.InHousePercent}
                    >
                      {t("billing.tariffModel." + TariffModel.InHousePercent)}
                    </MenuItem>
                  </Select>
                </StyledFilterFormControl>
                <FilterFormControl size="small" variant="outlined">
                  <InputLabel id="dueDateMonth">
                    {t("Stichtag Monat")}
                  </InputLabel>
                  <Select
                    id="dueDateMonth"
                    labelId="dueDateMonth"
                    label={t("Stichtag Monat")}
                    value={inputDueDateMonth}
                    onChange={(e) =>
                      setInputDueDateMonth(e.target.value as number)
                    }
                  >
                    <MenuItem sx={capitalizeStyle} value={0}>
                      <em>{t("Alle")}</em>
                    </MenuItem>
                    {[...Array(12)].map((_, i) => (
                      <MenuItem sx={capitalizeStyle} key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FilterFormControl>
                <FilterFormControl size="small" variant="outlined">
                  <TextField
                    label={t("Stichtag Jahr")}
                    placeholder={t("zB: 2024")}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setInputDueDateYear(e.target.value)}
                    value={inputDueDateYear}
                  />
                </FilterFormControl>
              </Guarded>
              <StyledSmallFilterFormControl>
                <TextField
                  label={t("PLZ")}
                  variant="outlined"
                  placeholder={t("zB: 8010")}
                  value={inputZipCode}
                  onChange={(e) => setInputZipCode(e.target.value)}
                  size="small"
                />
              </StyledSmallFilterFormControl>
              <StyledFilterFormControl>
                <TextField
                  label={t("Ort")}
                  variant="outlined"
                  placeholder={t("zB: Wien, Graz, ...")}
                  value={inputCity}
                  onChange={(e) => setInputCity(e.target.value)}
                  size="small"
                />
              </StyledFilterFormControl>
              <StyledSmallFilterFormControl>
                <TextField
                  label={t("Land")}
                  variant="outlined"
                  placeholder={t("zB: AT")}
                  value={inputCountry}
                  onChange={(e) => setInputCountry(e.target.value)}
                  size="small"
                />
              </StyledSmallFilterFormControl>
              {hasFilters && (
                <IconButton size="small" onClick={resetFilter}>
                  <Clear />
                </IconButton>
              )}
            </StyledFilterFormGroup>
          </form>
        </CardContent>
        {onlyTester && (
          <Paper>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={origin}
              onChange={(event, newTab) => setFilterOrigin(newTab)}
            >
              <Tab
                label={
                  <StyledTab>
                    {t("Meine Kunden")}{" "}
                    {!countError && (
                      <Chip
                        size="small"
                        label={
                          countIsLoading ? (
                            <StyledCircularProgress size={16} />
                          ) : (
                            countData && countData.owner
                          )
                        }
                      />
                    )}
                  </StyledTab>
                }
                value={CompanyOrigin.Owner}
              />
              <Tab
                label={
                  <StyledTab>
                    {t("Freigegebene Kunden")}{" "}
                    {!countError && (
                      <Chip
                        size="small"
                        label={
                          countIsLoading ? (
                            <StyledCircularProgress size={16} />
                          ) : (
                            countData && countData.testPartner
                          )
                        }
                      />
                    )}
                  </StyledTab>
                }
                value={CompanyOrigin.TestPartner}
              />
              <Tab
                label={
                  <StyledTab>
                    {t("Alle")}{" "}
                    {!countError && (
                      <Chip
                        size="small"
                        label={
                          countIsLoading ? (
                            <StyledCircularProgress size={16} />
                          ) : (
                            countData && countData.all
                          )
                        }
                      />
                    )}
                  </StyledTab>
                }
                value={CompanyOrigin.None}
              />
            </Tabs>
          </Paper>
        )}
        <CompanyList
          companies={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default CompanyOverview;
