import React, { VoidFunctionComponent } from "react";
import { CardContent, List, Typography } from "@mui/material";
import { CheckProcessGroup } from "../../model";
import BoolCheckList from "./bool-check-list";
import TextCheckListItem from "./text-check-list-item";

const CheckGroupList: VoidFunctionComponent<{
  checkGroup: CheckProcessGroup;
}> = ({ checkGroup }) => {
  return (
    <CardContent key={checkGroup.id}>
      <Typography variant="overline" color="textSecondary" display={"block"}>
        {checkGroup.checkGroup.name}
      </Typography>
      <List dense={true}>
        {checkGroup.checkGroup.checkSteps.map((checkGroupStep) => {
          switch (checkGroupStep.checkStep.type) {
            case "bool":
              return (
                <BoolCheckList
                  checkStep={checkGroupStep.checkStep}
                  key={checkGroupStep.id}
                />
              );
            case "text":
            default:
              return (
                <TextCheckListItem
                  checkStep={checkGroupStep.checkStep}
                  key={checkGroupStep.id}
                />
              );
          }
        })}
      </List>
    </CardContent>
  );
};

export default CheckGroupList;
