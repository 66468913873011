import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getStandardFormValues,
  Standard,
  StandardFormValues,
} from "../../model";
import { apiRoutes, request, useStandardDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormSetError } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { useStandardValidationSchema } from "../../hooks/validations/standards/use-standard-validation-schema";
import StandardFormFields from "../../components/standard-form-fields";
import LoadingButton from "../../components/loading-button";

const EditStandard: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { standardId } = useParams<{ standardId: string | undefined }>();

  const [standard, setStandard] = useState<Standard | null>(null);
  const { isLoading, error, data } = useStandardDetailsApi(
    standardId as unknown as number
  );

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.number || t("Norm bearbeiten"));

  useEffect(() => {
    if (!data) {
      return;
    }

    setStandard(data);
  }, [data]);

  const validationSchema = useStandardValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<StandardFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getStandardFormValues(data),
  });

  useEffect(() => reset(getStandardFormValues(data)), [reset, data]);

  const showUpload = watch("showUpload", false);

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.standards}>
            {t("Zurück zu Normen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !standard) {
    return <LoadingContainer />;
  }

  const onSubmit = async (
    values: StandardFormValues,
    setError: UseFormSetError<StandardFormValues>
  ) => {
    if (showUpload && !values.documentUpload.documentUpload) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    if (!data) {
      return;
    }

    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        number: values.number,
        date: values.date,
        validUntil: values.validUntil,
        substitute: values.substitute,
        type: values.type,
        scope: values.scope,
        description: values.description,
        reviewedAt: values.reviewedAt,
        reviewedBy: values.reviewedBy,
        review: values.review,
        link: values.link,
        reviewInterval: values.reviewInterval,
        complete: values.complete,
        comment: values.comment,
        document: values.document,
        categories: values.categories,
        ...(values.showUpload
          ? {
              documentUpload: {
                name: values.documentUpload.name,
                type: values.documentUpload.type,
                comment: values.documentUpload.comment,
              },
            }
          : {}),
      })
    );

    await request<Standard>(apiRoutes.standard(standard.id), "post", formData)
      .then(() => {
        enqueueSnackbar(t("Norm wurde erfolgreich aktualisiert."), {
          variant: "success",
        });
        history.push(routes.standard(standard.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Normen"), link: routes.standards },
    { label: standard.number, link: routes.standard(standard.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container>
      <Header title={t("Norm")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <StandardFormFields control={control} watch={watch} data={standard} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditStandard;
