import { useTitle } from "../../hooks/use-title";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Link as UILink,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getQualificationTagFormValues,
  QualificationTag,
  QualificationTagFormValues,
  Role,
} from "../../model";
import {
  apiRoutes,
  request,
  useQualificationTagDetailsApi,
} from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { Definition, StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleHookFormErrors } from "../../helpers";
import LoadingButton from "../../components/loading-button";
import { useQualificationTagValidationSchema } from "../../hooks/validations/qualification-tags/use-qualification-tag-validation-schema";
import Guarded from "../../components/guarded";
import { Delete } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";

const EditQualificationTag = () => {
  const { t } = useTranslation();
  const { qualificationTagId } = useParams<{
    qualificationTagId: string | undefined;
  }>();
  const { isLoading, error, data } = useQualificationTagDetailsApi(
    qualificationTagId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.name || t("Qualifikationen-Tag bearbeiten"));

  const validationSchema = useQualificationTagValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<QualificationTagFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getQualificationTagFormValues(data),
  });

  const onSubmit = async (
    values: QualificationTagFormValues,
    setError: UseFormSetError<QualificationTagFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<QualificationTag>(
      apiRoutes.qualificationTag(data.id),
      "put",
      values
    )
      .then(() => {
        enqueueSnackbar(
          t("Qualifikationen-Tag wurde erfolgreich bearbeitet."),
          { variant: "success" }
        );
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  useEffect(() => reset(getQualificationTagFormValues(data)), [reset, data]);

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.qualificationTags}>
            {t("Zurück zu Qualifikationen-Tags")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikationen-Tags"), link: routes.qualificationTags },
    { label: data.name },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Qualifikationen-Tag {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.qualificationTag(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Qualifikationen-Tag wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.qualificationTags);
      })
      .catch(() =>
        enqueueSnackbar(
          t("Qualifikationen-Tag konnte nicht gelöscht werden."),
          { variant: "error" }
        )
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={t("Qualifikationen-Tag")}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>

            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
            <Card>
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Qualifikationen-Tag")}
                </StyledCardTitle>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"name"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Name")}
                          fullWidth
                          {...field}
                          required
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <LoadingButton
                  type="submit"
                  size="medium"
                  color="primary"
                  disabled={!isValid}
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t("Speichern")}
                </LoadingButton>
              </CardActions>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Zugewiesene Qualifikationen")}
              </StyledCardTitle>
              <Definition
                title={t("Qualifikationen")}
                value={
                  <>
                    {data.qualifications.map((qualification) => (
                      <UILink
                        component={RouterLink}
                        to={routes.qualification(qualification.id)}
                        display={"block"}
                        key={qualification.id}
                      >
                        {qualification.name}
                      </UILink>
                    ))}
                    {data.qualifications.length < 1 &&
                      t("Keiner Qualifikation zugewiesen.")}
                  </>
                }
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditQualificationTag;
