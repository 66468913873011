import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  AppGrant,
  Company,
  DateFormat,
  Order,
  OrderFormValues,
  OrderGrant,
  Person,
} from "../../model";
import {
  Autocomplete,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { StyledCardTitle } from "../globals";
import { isGranted, isOnlyTester } from "../../lib/security";
import OrderDeliveryDate from "../order-delivery-date";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useMutation, useQuery } from "react-query";
import { useSelectedCompany } from "../../hooks/use-selected-company";
import Guarded from "../guarded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const OrderFormFields: VoidFunctionComponent<{
  control: Control<OrderFormValues>;
  watch: UseFormWatch<OrderFormValues>;
  setValue: UseFormSetValue<OrderFormValues>;
  order?: Order;
  company?: Company;
}> = ({ control, watch, setValue, order, company }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const selectedCompany = useSelectedCompany();
  const [externalPersons, setExternalPersons] = useState<Person[]>([]);
  const { params } = useFilters<[], Company>([], "name", undefined, 0);
  const { data: customers, isLoading: customersLoading } = usePaginationApi<
    [],
    Company
  >(apiRoutes.companies, params);

  const { data: internalPersons, isLoading: internalPersonsLoading } = useQuery(
    "internalPersons",
    async () => {
      if (!user.person?.primaryCompany?.id) {
        return;
      }
      return await request<Person[]>(
        apiRoutes.companyPrimaryPersons(user.person?.primaryCompany?.id),
        "GET"
      );
    }
  );

  const customer = watch("customer");

  const { mutate: externalPersonMutation, isLoading: externalPersonsLoading } =
    useMutation(
      async () => {
        if (!customer) {
          return;
        }
        return await request<Person[]>(
          apiRoutes.companyPrimaryPersons(+customer),
          "get"
        );
      },
      {
        onSuccess: (res) => {
          if (!res) {
            return;
          }
          setExternalPersons(res.data);
        },
      }
    );

  useEffect(() => {
    if (isOnlyTester(user) && selectedCompany) {
      setValue("customer", selectedCompany.id);
    }
  }, [user, selectedCompany, setValue]);

  useEffect(() => {
    if (!customer) {
      setValue("externalContact", null);
      setExternalPersons([]);
      return;
    }
    externalPersonMutation();
  }, [customer, setValue, externalPersonMutation]);

  const deliveryDates = watch("deliveryDates");

  const fullUpdateGranted = order
    ? isGranted(user, OrderGrant.UPDATE_FULL, order)
    : isGranted(user, AppGrant.ORDER_CREATE);

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Auftragsdaten")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"orderNumber"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Auftragsnummer")}
                  fullWidth
                  {...field}
                  required
                  disabled={!fullUpdateGranted}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"customer"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="customer"
                  options={customers?.results || []}
                  noOptionsText={t("Es wurden keine Organisationen gefunden")}
                  loading={customersLoading}
                  loadingText={t("Lädt Organisationen...")}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={
                    customers?.results.find(
                      (customer) => customer.id === field.value
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={
                    !!company || !fullUpdateGranted || isOnlyTester(user)
                  }
                  onBlur={() => !field.value && field.onChange(null)}
                  onChange={(_, value) => field.onChange(value?.id || null)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Kunde")}
                      required
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"sign"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Projektname")}
                  fullWidth
                  {...field}
                  required
                  disabled={!fullUpdateGranted}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"date"}
              render={({ field, fieldState }) => (
                <DatePicker
                  name={field.name}
                  value={field.value ? new Date(field.value) : null}
                  format={DateFormat.Default}
                  onChange={field.onChange}
                  label={t("Auftragsdatum")}
                  disabled={!fullUpdateGranted}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: fieldState.isTouched && fieldState.invalid,
                      helperText: fieldState.error?.message,
                      required: true,
                      onBlur: () => !field.value && field.onChange(null),
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t("Ansprechpartner")}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"externalContact"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="externalContact"
                  options={externalPersons}
                  noOptionsText={t(
                    "Es wurden keine Personen zum Kunden gefunden"
                  )}
                  loading={externalPersonsLoading}
                  loadingText={t("Lädt Personen des Kunden...")}
                  value={
                    externalPersons.find(
                      (person) => person.id === field.value
                    ) || null
                  }
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.displayName + `(${option.primaryCompany?.name})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Auftraggeber")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={"internalContact"}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="internalContact"
                  options={internalPersons?.data || []}
                  noOptionsText={t("Es wurden keine Personen gefunden")}
                  loading={internalPersonsLoading}
                  loadingText={t("Lädt Personen...")}
                  value={
                    (internalPersons?.data || []).find(
                      (person) => person.id === field.value
                    ) || null
                  }
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) =>
                    option.displayName + `(${option.primaryCompany?.name})`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label={t("Ansprechpartner")}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Guarded grant={AppGrant.ORDER_MANAGE_DELIVERY_DATES}>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Liefertermine")}
          </StyledCardTitle>
          <OrderDeliveryDate control={control} deliveryDates={deliveryDates} />
        </CardContent>
      </Guarded>
    </>
  );
};
