import { useTitle } from "../../hooks/use-title";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { apiRoutes, request, useOrderDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { StyledCardTitle, StyledContainer } from "../../components/globals";
import { Controller, useForm } from "react-hook-form";
import {
  ApiError,
  getOrderReportFormValues,
  Nullable,
  Order,
  OrderReportFormValues,
  ReportTypes,
  Role,
} from "../../model";
import LoadingButton from "../../components/loading-button";
import axios, { AxiosError } from "axios";
import CheckReportList from "../../components/check-report-list";
import { Delete } from "@mui/icons-material";
import Guarded from "../../components/guarded";

const OrderReport = () => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState<Nullable<Order>>(null);
  const { isLoading, error, data } = useOrderDetailsApi(
    orderId as unknown as number
  );
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  useTitle(data?.orderNumber || t("Auftragsabwicklung Protokolle"));

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<OrderReportFormValues>({
    mode: "all",
    defaultValues: getOrderReportFormValues(),
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setOrder(data);
  }, [data]);

  const type = watch("type");

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={`/order`}>
            {t("Zurück zu Aufträgen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Auträge"), link: routes.orders },
    { label: data.orderNumber, link: routes.order(data.id) },
    { label: t("Protokoll Erstellung") },
  ];

  const onSubmit = async (values: OrderReportFormValues) => {
    setReportLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/${apiRoutes.orderReport(data.id)}`,
        values,
        {
          withCredentials: true,
          responseType: "blob",
        }
      )
      .then((res) => {
        if (!res.data) {
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([res.data as BlobPart], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = `${data.orderNumber}${
          values.type ? "_" + t(`checkReport.type.${values.type}`) : ""
        }.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("Protokolle Download wurde erfolgreich gestartet."), {
          variant: "success",
        });
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
      })
      .finally(() => {
        setReportLoading(false);
      });
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const confirm = window.confirm(
      t(`Wollen Sie alle PDF Protokolle wirklich löschen?`)
    );
    if (!confirm) {
      return;
    }
    await request<Order>(apiRoutes.orderReport(data.id), "delete")
      .then((res) => {
        setOrder(res.data);
        enqueueSnackbar(t("PDF Protokolle wurde erfolgreich gelöscht."), {
          variant: "success",
        });
      })
      .catch(() =>
        enqueueSnackbar(t("PDF Protokolle konnten nicht gelöscht werden."), {
          variant: "error",
        })
      )
      .finally(() => setDeleteLoading(false));
  };

  return (
    <StyledContainer>
      <Header
        title={t("Auftragsabwicklung")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <Guarded requiredRole={Role.SuperAdmin}>
            <LoadingButton
              loading={deleteLoading}
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <Delete /> {t("Alle PDFs löschen")}
            </LoadingButton>
          </Guarded>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit((values) => onSubmit(values))}>
            <Card>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CardContent>
                    <StyledCardTitle
                      variant="h6"
                      color="secondary"
                      gutterBottom
                    >
                      {t("PDF Protokoll Detaileinstellung")}
                    </StyledCardTitle>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name={"type"}
                          render={({ field }) => (
                            <RadioGroup {...field}>
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                value="person"
                                label={t("Verwender")}
                              />
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                value="location"
                                label={t("Abteilung")}
                              />
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                value="category"
                                label={t("Kategorie")}
                              />
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                value="single"
                                label={t("Einzelprotokolle")}
                              />
                            </RadioGroup>
                          )}
                        />
                        {type === ReportTypes.Single && (
                          <Typography variant="caption">
                            {t(
                              "Das Erstellen der Einzelprotokolle kann etwas länger dauern."
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name={"summary"}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={field.onChange}
                                  disabled={type === ReportTypes.Single}
                                  name="summary"
                                  color="primary"
                                />
                              }
                              label={t("Zusammenfassungen erstellen")}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <LoadingButton
                      type="submit"
                      size="medium"
                      color="secondary"
                      disabled={isSubmitting}
                      variant="contained"
                      loading={isSubmitting || reportLoading}
                    >
                      {t("Protokolle erstellen")}
                    </LoadingButton>
                  </CardActions>
                </Grid>
              </Grid>
            </Card>
          </form>
        </Grid>
        {order && (
          <Grid item xs={12} md={6}>
            <CheckReportList order={order} />
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default OrderReport;
