import React, { VoidFunctionComponent } from "react";
import { Check, DateFormat, Item } from "../../model";
import { Definition, textEllipsisStyles } from "../globals";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { FindInPage } from "@mui/icons-material";
import FormattedDate from "../formatted-date";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import ProductSelector from "./product-selector";
import { apiRoutes, getAbsoluteApiUrl } from "../../lib/api";
import styled from "styled-components";
import GuardedLink from "../guarded-link";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyTester } from "../../lib/security";

const StyledProductImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ItemDetails: VoidFunctionComponent<{
  item: Item;
  onItemUpdate: (item: Item) => void;
  check: Check;
}> = ({ item, onItemUpdate, check }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {item.product ? (
          <Definition
            title={t("Produkt")}
            value={
              item.product ? (
                <GuardedLink
                  disabled={onlyTester}
                  component={Link}
                  target="_blank"
                  to={routes.product(item.product.id)}
                >
                  {item.product?.displayName}
                </GuardedLink>
              ) : (
                "Kein Produkt zugeordnet"
              )
            }
          />
        ) : (
          <ProductSelector
            item={item}
            check={check}
            onItemUpdate={onItemUpdate}
          />
        )}
        {item.product && (
          <>
            {item.product.documents.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="overline" color="textSecondary">
                  {t("Dokumente")}
                </Typography>
                <List dense={true}>
                  {item.product.documents.map((document, index) => (
                    <ListItem style={{ paddingLeft: 0 }} key={index}>
                      <ListItemIcon>
                        <a
                          href={getAbsoluteApiUrl(
                            apiRoutes.documentFile(document.id)
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton aria-label={t("Ansehen")} size="small">
                            <FindInPage />
                          </IconButton>
                        </a>
                      </ListItemIcon>
                      <Tooltip
                        title={`${document.name} (${t(
                          `document.type.${document.type}`
                        )})`}
                      >
                        <ListItemText
                          primary={document.name}
                          secondary={t(`document.type.${document.type}`)}
                          primaryTypographyProps={{ style: textEllipsisStyles }}
                          secondaryTypographyProps={{
                            style: textEllipsisStyles,
                          }}
                        />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            {item.product.category.standards.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="overline" color="textSecondary">
                  {t("Normen")}
                </Typography>
                <List dense={true}>
                  {item.product.category.standards.map((standard, index) => (
                    <ListItem style={{ paddingLeft: 0 }} key={index}>
                      <ListItemIcon>
                        {!onlyTester && standard.document ? (
                          <a
                            href={getAbsoluteApiUrl(
                              apiRoutes.documentFile(standard.document.id)
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconButton
                              aria-label={t("Lagerplan ansehen")}
                              size="small"
                            >
                              <FindInPage />
                            </IconButton>
                          </a>
                        ) : (
                          <IconButton size="small" disabled>
                            <FindInPage />
                          </IconButton>
                        )}
                      </ListItemIcon>
                      <Tooltip
                        title={`${standard.number} (${t(
                          `standard.type.${standard.type}`
                        )} - ${standard.scope})`}
                      >
                        <ListItemText
                          primary={
                            <GuardedLink
                              component={Link}
                              to={routes.standard(standard.id)}
                              target="_blank"
                              disabled={onlyTester}
                            >
                              {standard.number}
                            </GuardedLink>
                          }
                          secondary={
                            <>
                              {t(`standard.type.${standard.type}`)} -{" "}
                              {standard.scope}
                            </>
                          }
                          primaryTypographyProps={{ style: textEllipsisStyles }}
                          secondaryTypographyProps={{
                            style: textEllipsisStyles,
                          }}
                        />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Definition
              title={t("Herstellungsjahr")}
              value={item.manufacturingYear}
            />
          </Grid>
          <Grid item xs={12}>
            <Definition
              title={t("Erster Gebrauch")}
              value={
                item.firstUse ? (
                  <FormattedDate
                    date={item.firstUse}
                    format={DateFormat.Default}
                  />
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Definition
              title={t("Lebensdauer (Monate)")}
              value={item.product?.lifetime}
            />
          </Grid>
          <Grid item xs={12}>
            <Definition
              title={t("Produktbild")}
              value={
                item.product?.image ? (
                  <StyledProductImage
                    alt={item.product?.model}
                    src={item.product?.image}
                  />
                ) : (
                  "-"
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemDetails;
