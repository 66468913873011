import React, { useState, VoidFunctionComponent } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Autocomplete, TextField } from "@mui/material";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { Check, Person } from "../../model";
import { FormValues } from "./index";
import { Option } from "./helper";
import { useTranslation } from "react-i18next";
import CreatePersonDialog from "../create-person-dialog";

const filter = createFilterOptions<Option>();
const createPersonOptions = (persons: Person[]): Option[] =>
  persons.map((person) => ({
    value: person.id.toString(),
    label: `${person.lastName} ${person.firstName}`,
  }));

const PersonSelector: VoidFunctionComponent<{
  check: Check;
  control: Control<FormValues>;
  persons: Person[];
  setValue: UseFormSetValue<FormValues>;
}> = ({ check, control, persons, setValue }) => {
  const { t } = useTranslation();
  const personOptions = createPersonOptions(persons);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [currentName, setCurrentName] = useState<string>("");

  return (
    <>
      <Controller
        control={control}
        name="person"
        render={({ field, fieldState }) => (
          <Autocomplete
            id="person"
            options={personOptions}
            disabled={!!check.currentItem}
            getOptionKey={(option) => option.value || ""}
            getOptionLabel={(option) => option.label}
            value={
              personOptions.find(
                (item) =>
                  item.value && field.value && +item.value === +field.value
              ) || null
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  value: `new${params.inputValue}`,
                  label: t(`Verwender "{{name}}" hinzufügen`, {
                    name: params.inputValue,
                  }),
                });
              }
              return filtered;
            }}
            onChange={(event, value) => {
              if (!value || !value.value) {
                field.onChange(null);
                return;
              }
              if (value.value.substr(0, 3) === "new") {
                setCurrentName(value.value.substr(3));
                return setCreateDialogOpen(true);
              }
              field.onChange(value.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Verwender")}
                error={fieldState.isTouched && fieldState.invalid}
                variant="outlined"
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      {createDialogOpen && (
        <CreatePersonDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          company={check.order.customer}
          onUpdate={(data) => setValue("person", data.id)}
          name={currentName}
          reduced={true}
        />
      )}
    </>
  );
};

export default PersonSelector;
