import React, {
  useEffect,
  useMemo,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, CheckStatus, ID } from "../../model";
import { Close } from "@mui/icons-material";
import ItemListView from "./item-list-view";
import SignatureView from "./signature-view";
import { GridData } from "../grid-data";
import { useMutation, useQueryClient } from "react-query";
import { apiRoutes, request } from "../../lib/api";
import { useApi } from "../../hooks/use-api";
import LoadingContainer from "../loading-container";

type DialogView = "item-list" | "signature";

const OrderCompletion: VoidFunctionComponent<{
  checkId: ID;
  open: boolean;
  onClose: () => void;
}> = ({ checkId, open, onClose }) => {
  const { t } = useTranslation();
  const [view, setView] = useState<DialogView>("item-list");
  const { data: check, isLoading } = useApi<Check>(apiRoutes.check(checkId));
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      return;
    }
    setView("item-list");
  }, [open]);

  const mutation = useMutation(
    async (signature: string) => {
      if (!check) {
        return;
      }
      await request(apiRoutes.checkComplete(check.id), "post", { signature });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.check(checkId));
        setView("item-list");
      },
    }
  );

  const renderedView = useMemo(() => {
    if (!check) {
      return;
    }
    switch (view) {
      case "item-list":
        return (
          <ItemListView
            check={check}
            onCancel={() => onClose()}
            showActions={check.status === CheckStatus.InProgress}
            onContinue={() => setView("signature")}
          />
        );
      case "signature":
        return (
          <SignatureView
            check={check}
            onCancel={() => setView("item-list")}
            onContinue={(signature) => mutation.mutateAsync(signature)}
            loading={mutation.isLoading}
          />
        );
    }
    return null;
  }, [view, mutation, check, onClose]);

  const handleClose = async () => {
    setLoading(true);
    await queryClient.invalidateQueries(apiRoutes.checks);
    onClose();
  };

  return (
    <>
      <Dialog open={open} maxWidth="xl" fullWidth={true}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {(check && check.status) === CheckStatus.InProgress
                ? t("Prüfung unterzeichnen")
                : t("Prüfung erfolgreich unterzeichnet")}
            </Grid>
            <Grid item>
              {view !== "signature" && (
                <IconButton onClick={handleClose} size="large">
                  <Close />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading || !check ? (
          <DialogContent>
            <LoadingContainer />
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item>
                  <GridData label={t("Auftragsnummer")}>
                    {check.order.orderNumber}
                  </GridData>
                </Grid>
                <Grid item>
                  <GridData label={t("Auftragsname")}>
                    {check.order.sign}
                  </GridData>
                </Grid>
                <Grid item>
                  <GridData label={t("Geprüfte Items")}>
                    {check.itemChecks.length}
                  </GridData>
                </Grid>
              </Grid>
            </DialogContent>
            {renderedView}
            {check.status === CheckStatus.Done && (
              <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                  {t("Schließen")}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default OrderCompletion;
