import React, { useEffect, VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {
  ApiError,
  CheckGroup,
  CheckGroupFormValues,
  CheckProcess,
  getCheckGroupFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { routes } from "../../lib/routes";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import CheckStepsFieldArray from "../check-steps-field-array";
import { StyledDialogContent } from "../globals";
import LoadingButton from "../loading-button";
import { useCheckGroupValidationSchema } from "../../hooks/validations/check-processes/use-check-group-validation-schema";

const CheckGroupEditDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  checkProcess: CheckProcess;
  checkGroup: CheckGroup;
  onCheckGroupUpdate: (checkGroup: CheckGroup) => void;
}> = ({ open, onClose, checkProcess, checkGroup, onCheckGroupUpdate }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useCheckGroupValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<CheckGroupFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getCheckGroupFormValues(checkGroup),
  });

  useEffect(() => {
    // TODO: timeout should not be required...
    setTimeout(() => reset(getCheckGroupFormValues(checkGroup)));
  }, [reset, checkGroup]);

  const onSubmit = async (
    values: CheckGroupFormValues,
    setError: UseFormSetError<CheckGroupFormValues>
  ) => {
    values.checkSteps = values.checkSteps
      ? values.checkSteps.map((step, index) => ({
          checkStep: step.checkStep,
          sortOrder: index,
        }))
      : [];
    await request<CheckGroup>(
      apiRoutes.checkGroup(checkGroup.id),
      "put",
      values
    )
      .then((res) => {
        enqueueSnackbar(t("Prüfkapitel wurde erfolgreich aktualisiert."), {
          variant: "success",
        });
        onCheckGroupUpdate(res.data);
        history.push(routes.checkProcess(checkProcess.id));
        onClose();
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <DialogTitle>{t("Neues Prüfkapitel")}</DialogTitle>
        <StyledDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mt={3}>
              <Controller
                control={control}
                name={`name` as const}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Titel")}
                    fullWidth
                    {...field}
                    required
                    autoComplete={"off"}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`description` as const}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Beschreibung")}
                    fullWidth
                    {...field}
                    multiline={true}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`comment` as const}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Interne Notiz")}
                    fullWidth
                    {...field}
                    multiline={true}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckStepsFieldArray control={control} />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="secondary">
            {t("Abbrechen")}
          </Button>
          <LoadingButton
            disabled={!isValid}
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CheckGroupEditDialog;
