import { Grant } from "../../model";
import { useCurrentUser } from "../../hooks/use-current-user";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { routes } from "../../lib/routes";
import { Guardable, hasRole, isGranted } from "../../lib/security";
import { config } from "../../config";

function GuardedRoute<TGrant extends Grant>({
  requiredRole,
  grant,
  subject,
  ...props
}: Guardable<TGrant> & RouteProps) {
  const location = useLocation();
  const user = useCurrentUser();

  if (requiredRole === undefined && grant === undefined) {
    if (config.env === "dev") {
      throw new Error("You must provide either a requiredRole or a grant");
    }
    return null;
  }

  if (!user) {
    return <Redirect to={routes.dashboard} />;
  }

  if (
    (requiredRole !== undefined && !hasRole(user, requiredRole)) ||
    (grant !== undefined && !isGranted(user, grant, subject))
  ) {
    return location.pathname === props.path ? (
      <Redirect to={routes.dashboard} />
    ) : null;
  }

  return <Route {...props} />;
}

export default GuardedRoute;
