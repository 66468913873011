import { useMemo } from "react";
import * as yup from "yup";
import { useItemDocumentationValidation } from "./use-item-documentation-validation";

export const useItemDocumentationValidationSchema = () => {
  const validation = useItemDocumentationValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
