import { Fab, Tooltip } from "@mui/material";
import React, { FunctionComponent, ReactChild } from "react";

export const IconFab: FunctionComponent<{
  title: string;
  handleClick: () => void;
  icon: ReactChild;
}> = ({ title, handleClick, icon }) => (
  <Tooltip title={title}>
    <Fab
      aria-label={title}
      color="secondary"
      size="small"
      onClick={handleClick}
    >
      {icon}
    </Fab>
  </Tooltip>
);
