import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  IconButton,
  LinearProgress,
  Link,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";
import Search from "../search";
import styled from "styled-components";
import Logo from "../logo";
import { Flare, NightsStay, OpenInNew } from "@mui/icons-material";
import AppContext from "../../context/app";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "react-query";
import { hasRole, isOnlyClient, isOnlyTester } from "lib/security";
import { useCurrentUser } from "hooks/use-current-user";
import { CurrentUser, Role } from "../../model";

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const StyledLogoContainer = styled(ButtonBase)`
  min-height: 63px;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const SUPPORT_PHONE_NUMBER = "+43 676 848 723 400";

const TopBar = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const fetching = useIsFetching();
  const user = useCurrentUser();
  const supportLink = getSupportLink(user);

  return (
    <>
      <StyledAppBar position="static" color={"inherit"}>
        <StyledToolbar>
          <div>
            <Search />
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ gap: ".5em" }}
          >
            <Link
              color="inherit"
              href={`tel:${SUPPORT_PHONE_NUMBER}`}
              style={{
                fontSize: "1.2em",
                textDecoration: "none",
              }}
            >
              {SUPPORT_PHONE_NUMBER}
            </Link>
            {supportLink && (
              <Button
                color="primary"
                href={supportLink}
                variant="contained"
                target="_blank"
                rel="noreferrer"
                data-test="support-button"
                startIcon={<OpenInNew />}
              >
                {t("Support")}
              </Button>
            )}
            {appContext.materialTheme === "light" ? (
              <IconButton
                aria-label={t("Dunkles Theme")}
                color={`inherit`}
                onClick={() => appContext.setMaterialTheme("dark")}
                size="large"
              >
                <NightsStay />
              </IconButton>
            ) : (
              <IconButton
                aria-label={t("Helles Theme")}
                color={`inherit`}
                onClick={() => appContext.setMaterialTheme("light")}
                size="large"
              >
                <Flare />
              </IconButton>
            )}
            <StyledLogoContainer>
              <Logo />
            </StyledLogoContainer>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      {fetching ? <LinearProgress /> : <div style={{ height: "4px" }} />}
    </>
  );
};

function getSupportLink(user: CurrentUser) {
  if (isOnlyClient(user)) {
    return "https://psa-portal.com/support-client/";
  }
  if (isOnlyTester(user)) {
    return "https://psa-portal.com/support-tester/";
  }
  if (hasRole(user, [Role.CEO, Role.QA, Role.Admin])) {
    return "https://psa-portal.com/support-admin/";
  }
  return null;
}

export default TopBar;
