import { Role } from "../../model";
import { useTitle } from "../../hooks/use-title";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link as UILink,
  Typography,
} from "@mui/material";
import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import Header from "../../components/header";
import { apiRoutes, request, useCategoryDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Definition, StyledCardTitle } from "../../components/globals";
import Guarded from "../../components/guarded";
import { ModifiedEntity } from "../../components/modified-entity";
import CategoryTree from "../../components/category-tree";
import StandardList from "../../components/standard-list";

const CategoryDetails = () => {
  const { t } = useTranslation();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useCategoryDetailsApi(
    categoryId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.name || t("Kategorie Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.categories}>
            {t("Zurück zu Kategorien")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Kategorien"), link: routes.categories },
    { label: data.name },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie Kategorie {{name}} wirklich löschen?`, { name: data.name })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.category(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Kategorie wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push(routes.categories);
      })
      .catch(() =>
        enqueueSnackbar(t("Kategorie konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="lg">
      <Header
        title={t("Kategorie")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.categoryEdit(data.id)}
              >
                <Edit /> {t("Bearbeiten")}
              </Button>{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>

            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Definition title={t("Name")} value={data.name} />
                </Grid>
                <Grid item md={6}>
                  <Definition
                    title={t("Produkte")}
                    value={
                      <UILink component={Link} to={routes.products(data.name)}>
                        {t("Zu den Produkten")}
                      </UILink>
                    }
                  />
                </Grid>

                <Grid item md={6}>
                  <Definition
                    title={t("Überkategorien")}
                    value={
                      data.parents.length ? (
                        <CategoryTree category={data} />
                      ) : (
                        <span>{t("Es ist keine Kategorie übergeordnet")}</span>
                      )
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="overline" color="textSecondary">
                    {t("Unterkategorien")}
                  </Typography>
                  {data.categories.map((category) => (
                    <UILink
                      component={Link}
                      to={routes.category(category.id)}
                      key={category.id}
                    >
                      <Typography>{category.name}</Typography>
                    </UILink>
                  ))}
                  {!data.categories.length && (
                    <Typography>
                      {t("Es ist keine Kategorie untergeordnet")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Zuordnung zu Normen")}
              </StyledCardTitle>
            </CardContent>
            {data.standards.length ? (
              <StandardList standards={data.standards} loading={isLoading} />
            ) : (
              <CardContent>
                <Typography variant="body1">
                  {t("Es wurden noch keine Normen zugeordnet")}
                </Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoryDetails;
