import React, { useState, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { StyledContainer, StyledLink } from "../../components/globals";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import ItemListFilterable from "../../components/item-list-filterable";
import { Button, Card } from "@mui/material";
import Guarded from "../../components/guarded";
import { AppGrant, Dict, ItemFilters } from "../../model";
import { Link } from "react-router-dom";
import { Add, GetApp, Publish } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { apiRoutes, request } from "../../lib/api";
import { FilterParams } from "../../hooks/use-filters";
import { QueueTaskStatusQueryKey } from "../../hooks/queries/use-queue-task-status-query";
import { QueueTasksQueryKey } from "../queue-tasks/overview";

const ItemsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  useTitle(t("Items"));
  const [filterParams, setFilterParams] =
    useState<FilterParams<ItemFilters, Dict>>();

  const handleExportClick = () => {
    const confirm = t(
      "Sobald der Export abgeschlossen ist erhalten Sie einen Link per E-Mail. Dies kann je nach Datenmenge zu längeren Wartezeiten führen. Erhalten Sie in den nächsten 24 Stunden keine E-Mail, wenden Sie sich bitte an Ihren Ansprechpartner."
    );
    if (!window.confirm(confirm)) {
      return;
    }
    exportItems();
  };

  const { mutate: exportItems } = useMutation(
    async () => {
      if (!filterParams) {
        return;
      }
      return await request<number>(apiRoutes.itemExport, "get", undefined, {
        filters: filterParams.filters,
        page: filterParams.page,
        pageSize: filterParams.pageSize,
        order: [[filterParams.orderBy, filterParams.order]],
        context: filterParams.context,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueueTaskStatusQueryKey]);
        await queryClient.invalidateQueries([QueueTasksQueryKey]);
      },
    }
  );

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Items") },
  ];
  return (
    <StyledContainer data-test="items-content">
      <Header
        title={t(`Items`)}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded grant={AppGrant.ITEMS}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleExportClick}
                startIcon={<GetApp />}
              >
                {t("Als Excel Exportieren")}
              </Button>{" "}
            </Guarded>
            <Guarded grant={AppGrant.ITEM_IMPORT}>
              <Button
                variant="contained"
                component={StyledLink}
                color="secondary"
                to={routes.itemImport}
                sx={{ margin: "0 .5em" }}
                startIcon={<Publish />}
              >
                {t("Items importieren")}
              </Button>{" "}
            </Guarded>
            <Guarded grant={AppGrant.ITEM_CREATE}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.itemCreate}
                startIcon={<Add />}
              >
                {t("Neues Item")}
              </Button>
            </Guarded>
          </>
        }
      />
      <Card>
        <ItemListFilterable onFilterParamsChange={setFilterParams} />
      </Card>
    </StyledContainer>
  );
};

export default ItemsOverview;
