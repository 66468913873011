import React, { FunctionComponent, VoidFunctionComponent } from "react";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";

const EntityLabel: VoidFunctionComponent<{
  entityId: number;
  label: string;
  caption: string;
  link: string;
}> = ({ entityId, label, caption, link }) => {
  return (
    <>
      <Typography
        variant="caption"
        style={{ display: "block" }}
        color="textSecondary"
      >
        {caption}
      </Typography>
      <Link component={RouterLink} to={link}>
        {label} (#{entityId})
      </Link>
    </>
  );
};

const LogEntityLink: FunctionComponent<{
  entityClass: string;
  entityId: number;
  label: string | null;
}> = ({ entityClass, entityId, label }) => {
  const { t } = useTranslation();
  const mapping: { [id: string]: [(id: number) => string, string] } = {
    "App\\Entity\\User": [routes.user, t("Benutzer")],
    "App\\Entity\\Category": [routes.category, t("Kategorie")],
    "App\\Entity\\Company": [routes.company, t("Kunde")],
    "App\\Entity\\Order": [routes.order, t("Auftrag")],
    "App\\Entity\\Standard": [routes.standard, t("Norm")],
    "App\\Entity\\Document": [routes.documentEdit, t("Dokument")],
    "App\\Entity\\Product": [routes.product, t("Produkte")],
    "App\\Entity\\Person": [routes.person, t("Personen")],
    "App\\Entity\\CheckProcess": [routes.checkProcess, t("Prüfprozess")],
    "App\\Entity\\CheckGroup": [() => routes.checkProcesses, t("Prüfkapitel")],
    "App\\Entity\\CheckStep": [routes.checkStep, t("Prüfschritt")],
    "App\\Entity\\Item": [routes.item, t("Item")],
    "App\\Entity\\ItemCheck": [() => routes.orders, t("Prüfurteil")],
    "App\\Entity\\Location": [() => routes.companies, t("Ort")],
    "App\\Entity\\OrderDeliveryDate": [() => routes.orders, t("Zustelldatum")],
    "App\\Entity\\Defect": [() => routes.defects, t("Mangel")],
    "App\\Entity\\Action": [() => routes.actions, t("Maßnahme")],
  };

  if (!mapping[entityClass]) {
    return (
      <>
        {entityClass}#{entityId}
      </>
    );
  }

  if (label) {
    return (
      <EntityLabel
        entityId={entityId}
        label={label}
        caption={mapping[entityClass][1]}
        link={mapping[entityClass][0](entityId)}
      />
    );
  }

  return (
    <Link component={RouterLink} to={mapping[entityClass][0](entityId)}>
      {mapping[entityClass][1]}#{entityId}
    </Link>
  );
};

export default LogEntityLink;
