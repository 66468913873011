import React, { VoidFunctionComponent } from "react";
import { Box, BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { green, red } from "@mui/material/colors";
import { Cancel, CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";

const DefectVerifiedLabel: VoidFunctionComponent<
  {
    verified?: boolean | null;
    corrected?: boolean;
    iconOnly?: boolean;
  } & BoxProps
> = ({ verified, corrected = false, iconOnly = false, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5em",
        marginBottom: iconOnly ? "0" : "0.5em",
        ...props,
      }}
    >
      {getIcon(verified)}
      {!iconOnly && t(getLabel(corrected, verified))}
    </Box>
  );
};
export default DefectVerifiedLabel;

function getIconColor(verified?: boolean | null) {
  switch (verified) {
    case true:
      return { color: green["500"] };
    case false:
      return { color: red["500"] };
    default:
      return { color: "grey" };
  }
}

function getLabel(corrected: boolean, verified?: boolean | null) {
  switch (verified) {
    case true:
      return "Verifiziert";
    case false:
      return "Nicht verifiziert";
  }
  return corrected ? "Mangel behoben" : "Offen";
}

function getIcon(verified?: boolean | null) {
  const style = getIconColor(verified);

  switch (verified) {
    case true:
      return <CheckCircle style={{ fontSize: "1.5em", ...style }} />;
    case false:
      return <Cancel style={{ fontSize: "1.5em", ...style }} />;
    default:
      return <RadioButtonUnchecked style={{ fontSize: "1.5em", ...style }} />;
  }
}
