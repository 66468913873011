import { Product, ProductOption as Option } from "../../model";
import { StyledVerifedIcon } from "../globals";

export function ProductOption({ product }: { product: Product | Option }) {
  return (
    <>
      {product.verified && <StyledVerifedIcon />}
      {product.displayName}
    </>
  );
}
