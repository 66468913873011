import { useMemo } from "react";
import * as yup from "yup";
import { useDocumentValidation } from "./use-document-validation";
import { ValidationMode } from "../index";

export const useDocumentValidationSchema = (mode: ValidationMode) => {
  const documentValidation = useDocumentValidation();

  return useMemo(() => {
    const { documentUpload, ...validation } = documentValidation;
    if (mode === ValidationMode.Create) {
      return yup.object({ ...validation, documentUpload });
    }
    return yup.object({ ...validation });
  }, [documentValidation, mode]);
};
