import {
  Blameable,
  BlameableUser,
  Company,
  DateTimeString,
  Grantable,
  ID,
  LocationPreview,
  Nullable,
  Person,
  Timestampable,
} from "./";

// Sync with OrderVoter in backend
export enum OrderGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  UPDATE_FULL = "update-full",
  DELETE_REPORT = "delete-report",
  COMPLETE = "complete",
  OPEN_CHECKS = "open-checks",
}

export interface OrderPreview {
  id: ID;
  orderNumber: string;
}

export interface Order
  extends OrderPreview,
    Timestampable,
    BlameableUser,
    Grantable<OrderGrant> {
  sign: string;
  customer: Company;
  date: DateTimeString;
  deliveryDates: OrderDeliveryDate[];
  externalContact: Nullable<Person>;
  internalContact: Nullable<Person>;
  complete: Nullable<boolean>;
  isCompletable: boolean;
  checkReports: CheckReport[];
  clientLocationViolations?: LocationPreview[];
}

export interface OrderDeliveryDate extends Timestampable, Blameable {
  id: ID;
  date: DateTimeString;
  dateTo: DateTimeString | null;
  badWeather: boolean;
}

export interface DeliveryDatesValues {
  isRange: boolean;
  date: DateTimeString | null;
  dateTo: DateTimeString | null;
  badWeather: boolean;
}

export interface OrderFormValues {
  orderNumber: string;
  sign: string;
  customer: ID | string;
  externalContact: ID | null;
  internalContact: ID | null;
  date: DateTimeString | null;
  deliveryDates: DeliveryDatesValues[];
}

export interface OrderFilters {
  search: string;
  customer: string;
  orderNumber: string;
  sign: string;
  to: Date | null;
  from: Date | null;
  emptyDeliveryDate: boolean | null;
  customerId: string | null;
}

export const getOrderFormValues = (order?: Order): OrderFormValues => ({
  orderNumber: order?.orderNumber || "",
  customer: order?.customer.id || "",
  sign: order?.sign || "",
  date: order?.date || "",
  externalContact: order?.externalContact?.id || null,
  internalContact: order?.internalContact?.id || null,
  deliveryDates:
    order?.deliveryDates?.map((date) => ({
      isRange: !!date.dateTo,
      date: date.date,
      dateTo: date.dateTo,
      badWeather: date.badWeather,
    })) || [],
});

// Sync with CheckReportVoter in backend
export enum CheckReportGrant {
  VIEW = "view",
}

export interface CheckReport extends Timestampable, Blameable {
  id: ID;
  name: string;
  type: string;
  order: Order;
  url: string;
}

export interface CheckReportFilters {
  search: string;
  type: string;
  order: ID;
}

export enum ReportTypes {
  Person = "person",
  Location = "location",
  Category = "category",
  Single = "single",
  PersonSummary = "person-summary",
  LocationSummary = "location-summary",
  CategorySummary = "category-summary",
}

export interface OrderReportFormValues {
  summary: boolean;
  type: string;
}

export const getOrderReportFormValues = (): OrderReportFormValues => ({
  summary: false,
  type: ReportTypes.Person,
});
