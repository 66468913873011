import { FunctionComponent, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLoginMutation } from "../../hooks/mutation/use-login-mutation";
import { useTranslation } from "react-i18next";
import { Alert, TextField } from "@mui/material";
import LoadingButton from "../../components/loading-button";
import styled from "styled-components";
import { LoginContext } from "./index";
import { Helmet } from "react-helmet";

interface LoginFormValues {
  username: string;
  password: string;
}

const StyledAlert = styled(Alert)`
  margin-bottom: 8px;
`;

const LoginForm: FunctionComponent = () => {
  const { email, setEmail } = useContext(LoginContext);
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors, isSubmitted },
  } = useForm<LoginFormValues>({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        username: yup.string().email().required(),
        password: yup.string().required(),
      })
    ),
    defaultValues: {
      username: email,
    },
  });
  const loginMutation = useLoginMutation();
  const { t } = useTranslation();

  const onSubmit = (values: LoginFormValues) => {
    loginMutation.mutate(values, {
      onSuccess: () => window.location.reload(),
    });
  };
  const showError =
    loginMutation.isError || (Object.keys(errors).length > 0 && isSubmitted);

  return (
    <>
      <Helmet>
        <title>{t("Login")}</title>
      </Helmet>
      <form onSubmit={handleSubmit((values) => onSubmit(values))}>
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("E-Mail Adresse")}
              autoFocus
              disabled={loginMutation.isLoading}
              value={value}
              ref={ref}
              onChange={(e) => {
                onChange(e.target.value);
                setEmail(e.target.value);
              }}
            />
          )}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("Passwort")}
          type="password"
          id="password"
          disabled={loginMutation.isLoading}
          {...register("password")}
        />
        {showError && (
          <StyledAlert severity={`error`}>
            {t("Login fehlgeschlagen")}
          </StyledAlert>
        )}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid}
          loading={loginMutation.isLoading}
        >
          {t("Log In")}
        </LoadingButton>
      </form>
    </>
  );
};

export default LoginForm;
