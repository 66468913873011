import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Check, Nullable, Order } from "../../model";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Alert, Autocomplete, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import { routes } from "../../lib/routes";
import { FormValues } from "./index";
import { Option } from "./helper";
import { apiRoutes } from "../../lib/api";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { useDebounceState } from "../../hooks/use-debounce-state";
import { config } from "../../config";

const filter = createFilterOptions<Option>();
const createOrderOptions = (orders: Order[]): Option[] =>
  orders
    .filter((order) => !order.complete)
    .map((order) => ({ value: order.id.toString(), label: order.orderNumber }));

const OrderSelector: VoidFunctionComponent<{
  check: Nullable<Check>;
  control: Control<FormValues>;
  watch: UseFormWatch<FormValues>;
  onOrderSelect: (order: Nullable<Order>) => void;
}> = ({ check, control, onOrderSelect, watch }) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [inputOrderNumber, setInputOrderNumber] = useDebounceState(
    orderNumber,
    setOrderNumber,
    500
  );
  const { params } = useFilters<{}, Order>(
    {
      orderNumber,
      complete: false,
    },
    "id",
    "desc",
    config.pageSize
  );
  const { data, isLoading, error } = usePaginationApi<{}, Order>(
    apiRoutes.orders,
    params
  );
  const { t } = useTranslation();
  const history = useHistory();
  const orderOptions = createOrderOptions(data?.results || []);
  const formOrder = watch("order");

  useEffect(() => {
    onOrderSelect(
      data?.results.find((o) => formOrder && o.id === +formOrder) || null
    );
  }, [formOrder, data?.results, onOrderSelect]);

  if (error) {
    return (
      <Alert severity="error">{t("Fehler beim Laden der Aufträge!")}</Alert>
    );
  }

  return (
    <Controller
      control={control}
      name="order"
      render={({ field, fieldState }) => (
        <Autocomplete
          id="order"
          loading={isLoading}
          options={orderOptions}
          disabled={!!check?.id}
          value={
            orderOptions.find(
              (order) =>
                order.value && field.value && +order.value === +field.value
            ) || null
          }
          getOptionKey={(option) => option.value || ""}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          inputValue={inputOrderNumber}
          onInputChange={(event, value) => setInputOrderNumber(value)}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);
            if (params.inputValue !== "") {
              filtered.push({
                value: `new${params.inputValue}`,
                label: t(`Auftrag "{{orderNumber}}" hinzufügen`, {
                  orderNumber: params.inputValue,
                }),
              });
            }
            return filtered;
          }}
          onChange={(event, value) => {
            if (!value || !value.value) {
              field.onChange(null);
              return;
            }
            if (value.value.substr(0, 3) === "new") {
              return history.push(routes.orderCreate, {
                orderNumber: value.value.substr(3),
              });
            }
            field.onChange(value.value);
            onOrderSelect(
              data?.results.find(
                (o) => value.value && +o.id === +value.value
              ) || null
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Auftrag")}
              required
              error={fieldState.isTouched && fieldState.invalid}
              variant="outlined"
              helperText={fieldState.error?.message}
            />
          )}
        />
      )}
    />
  );
};

export default OrderSelector;
