import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";
import { useCurrentUser } from "../../use-current-user";
import { isOnlyTester } from "../../../lib/security";

export const useOrderValidation = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const onlyTester = isOnlyTester(user);

  return useMemo(
    () => ({
      orderNumber: yup
        .string()
        .test(
          "orderNumber",
          "Auftragsnummer muss aus zwei Buchstaben und sieben Zahlen bestehen.",
          (value) =>
            // The order number for testers is not subject to any regulations
            onlyTester ? true : /^[A-Za-z]{2}[0-9]{7}$/.test(value ? value : "")
        )
        .required(t("Auftragsnummer darf nicht leer sein.")),
      sign: yup.string().required(t("Projektname darf nicht leer sein.")),
      customer: yup
        .number()
        .nullable()
        .required(t("Kunde darf nicht leer sein.")),
      date: yup.date().nullable().required(t("Datum darf nicht leer sein.")),
      showDeliveryDates: yup.boolean(),
      deliveryDates: yup.array().of(
        yup.object().shape({
          isRange: yup.boolean(),
          date: yup.date().nullable().required("Datum darf nicht leer sein."),
          dateTo: yup.mixed().when("isRange", {
            is: (isRange: boolean) => isRange,
            then: yup
              .date()
              .nullable()
              .required(t("Datum darf nicht leer sein.")),
          }),
        })
      ),
    }),
    [t, onlyTester]
  );
};
