import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DateFormat, HeadCell, Log } from "../../model";
import { Clear, Visibility } from "@mui/icons-material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  CompactTableCell,
  FilterFormControl,
  FilterFormGroup,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import FormattedDate from "../../components/formatted-date";
import LogEntityLink from "../../components/log-entity-link";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import { TableHeadCells } from "../../components/table-head-cells";
import { IconLink } from "../../components/icon-link";
import { config } from "../../config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid } from "date-fns";

interface Filters {
  search?: string;
  action?: string;
  from?: Date | null;
  to?: Date | null;
}

const LogsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Logs"));
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("home"), link: routes.dashboard },
    { label: t("Logs") },
  ];

  const headCells: HeadCell<Log>[] = [
    { id: "id", label: t("ID"), align: "center" },
    { id: "objectId", width: "30%", label: t("Subjekt") },
    { id: "action", label: t("Aktion") },
    { id: "loggedAt", width: "30%", label: t("Zeitpunkt") },
    { id: "username", width: "10%", label: t("Benutzer") },
    { id: "version", label: t("Version") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [action, setFilterAction] = useQueryState(
    "",
    "action",
    queryStateOptions
  );
  const [from, setFilterFromDate] = useQueryState<Date | null>(null, "from", {
    ...queryStateOptions,
    valueType: "Date",
  });
  const [to, setFilterToDate] = useQueryState<Date | null>(null, "to", {
    ...queryStateOptions,
    valueType: "Date",
  });

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<Filters, Log>(
      { search, action, from, to },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<Filters, Log>(
    apiRoutes.logs,
    params
  );

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterAction("");
    setFilterFromDate(null);
    setFilterToDate(null);
  };

  const hasFilters =
    search !== "" || action !== "" || from !== null || to !== null;

  return (
    <Container maxWidth="md" data-test="logs-content">
      <Header title={`Logs`} breadcrumbs={breadcrumbs} />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder="e.g. User#1 or admin"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="action">{t("Aktion")}</InputLabel>
              <Select
                labelId="action"
                id="action"
                value={action}
                onChange={(e) => setFilterAction(e.target.value as string)}
                label={t("Aktion")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="create">
                  {t("log.action.create")}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="update">
                  {t("log.action.update")}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value="remove">
                  {t("log.action.remove")}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl>
              <DatePicker
                label={t("Von")}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={from}
                format={DateFormat.Default}
                onChange={(date) => {
                  if (!isValid(date)) {
                    return;
                  }
                  setFilterFromDate(date);
                }}
                disableFuture
              />
            </FilterFormControl>
            <FilterFormControl>
              <DatePicker
                label={t("Bis")}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={to}
                format={DateFormat.Default}
                onChange={(date) => {
                  if (!isValid(date)) {
                    return;
                  }
                  setFilterToDate(date);
                }}
                disableFuture
              />
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCells
                  cells={headCells}
                  params={params}
                  sort={createSortHandler}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            {!isLoading && data ? (
              <TableBody>
                {data.results &&
                  data.results.map((log) => (
                    <TableRow key={log.id} hover={true}>
                      <CompactTableCell align="center">
                        {log.id}
                      </CompactTableCell>
                      <TableCell>
                        <LogEntityLink
                          entityClass={log.objectClass}
                          entityId={log.objectId}
                          label={log.label}
                        />
                      </TableCell>
                      <CompactTableCell>
                        {t(`log.action.${log.action}`)}
                      </CompactTableCell>
                      <TableCell>
                        <FormattedDate date={log.loggedAt} />
                      </TableCell>
                      <TableCell>{log.username}</TableCell>
                      <CompactTableCell>{log.version}</CompactTableCell>
                      <CompactTableCell>
                        <IconLink
                          to={routes.log(log.id)}
                          tooltip={t("Ansehen")}
                          icon={<Visibility />}
                        />
                      </CompactTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={100}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </Container>
  );
};

export default LogsOverview;
