import { FormikComputedProps, FormikState } from "formik";
import { Prompt } from "react-router";
import { FunctionComponent } from "react";

const PromptIfDirty: FunctionComponent<{
  formik: FormikState<unknown> & FormikComputedProps<unknown>;
}> = ({ formik }) => (
  <Prompt
    message="You have unsaved changes - are you sure to leave this page?"
    when={formik.dirty && formik.submitCount === 0}
  />
);

export default PromptIfDirty;
