import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as yup from "yup";
import { useCurrentUser } from "../../use-current-user";
import { isOnlyClient } from "../../../lib/security";

export const usePersonValidation = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  return useMemo(
    () => ({
      firstName: yup.string().required(t("Vorname darf nicht leer sein.")),
      lastName: yup.string().required(t("Nachname darf nicht leer sein.")),
      email: yup.string().email(t("Keine gültige E-Mail Adresse.")),
      titleBefore: yup.string(),
      titleAfter: yup.string(),
      phone: yup.string(),
      mobilePhone: yup.string(),
      externalCode: yup.string(),
      locations: yup
        .array()
        .min(
          isOnlyClient(user) && (user.person?.locations || []).length > 0
            ? 1
            : 0,
          t("Mindestens ein Ort muss ausgewählt werden.")
        ),
    }),
    [t, user]
  );
};
