import { Box, Button, Container, Grid } from "@mui/material";
import Header from "components/header";
import { apiRoutes, request } from "lib/api";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import { useSnackbar } from "notistack";
import LoadingButton from "components/loading-button";
import { useMutation } from "react-query";
import { useCurrentUser } from "hooks/use-current-user";
import { isInvalidUser, isOnlyUser } from "lib/security";
import { ReactNode } from "react";
import { CurrentUser } from "../../model";

const SUPPORT_PHONE_NUMBER = "+43 676 84 87 23 400";

const AccessDeniedInfo = () => {
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = useMutation(
    async () => await request(apiRoutes.sendSupportEmail(user.id)),
    {
      onError: () => {
        enqueueSnackbar(t("E-Mail konnte nicht versendet werden."), {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar(t("E-Mail wurde versendet."), { variant: "success" });
      },
    }
  );
  const { t } = useTranslation();

  const logout = () => {
    localStorage.clear();
    request(apiRoutes.logout).finally(() => window.location.reload());
  };

  return (
    <Container maxWidth="lg" data-test="access-denied-info">
      <Header title="Leider ist etwas schief gelaufen..." />
      <Box fontSize={18}>
        <Box fontWeight="bold" pb={2}>
          Entschuldigen Sie bitte die Unannehmlichkeiten, dass Ihr Vorhaben
          nicht auf Anhieb klappt:
        </Box>
        <Grid container spacing={1}>
          <Grid item>
            <ErrorIcon color="error" />
          </Grid>
          {getLabel(user)}
        </Grid>
        <Box py={2}>
          Bitte wenden Sie sich an das Support Team:{" "}
          <Box component="span" fontWeight="bold">
            {SUPPORT_PHONE_NUMBER}
          </Box>{" "}
          oder an Ihren{" "}
          <Box component="span" fontWeight="bold">
            Ansprechspartner
          </Box>
          .
        </Box>
        <Grid container spacing={2}>
          <Grid item>
            <LoadingButton
              color="primary"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => mutateAsync()}
              variant="contained"
            >
              {t("E-Mail an das Support Team")}
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={logout}>
              {t("Logout")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AccessDeniedInfo;

function getLabel(user: CurrentUser): ReactNode {
  if (isOnlyUser(user)) {
    return (
      <Grid item>
        Ihrem Benutzer fehlen{" "}
        <Box component="span" style={{ textDecoration: "underline" }}>
          notwendige
        </Box>{" "}
        Rollen-Berechtigungen.
      </Grid>
    );
  }
  if (isInvalidUser(user)) {
    return (
      <Grid item>
        Ihrem Benutzer wurde{" "}
        <Box component="span" style={{ textDecoration: "underline" }}>
          keine
        </Box>{" "}
        Person zugeteilt.
      </Grid>
    );
  }
  return (
    <Grid item>
      Ihrem Benutzer sind nicht genügend Berechtigungen zugeweisen.
    </Grid>
  );
}
