import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  Category,
  CheckProcess,
  CheckProcessState,
  Product,
  Role,
} from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useContext, useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  capitalizeStyle,
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { Link } from "react-router-dom";
import CheckProcessList from "../../components/check-process-list";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { StyledFilterFormControl } from "../products/overview";
import { ThemeContext } from "styled-components";
import { ProductOption } from "components/product-option";

export interface CheckProcessFilters {
  search: string;
  category: string;
  product: string;
  status: string;
}

const CheckProcessesOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Prozesse"));
  const { enqueueSnackbar } = useSnackbar();
  const theme = useContext(ThemeContext);

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Prozesse") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [category, setFilterCategory] = useQueryState(
    "",
    "category",
    queryStateOptions
  );
  const [inputCategory, setInputCategory] = useDebounceState(
    category,
    setFilterCategory
  );
  const [product, setFilterProduct] = useQueryState(
    "",
    "product",
    queryStateOptions
  );
  const [inputProduct, setInputProduct] = useDebounceState(
    product,
    setFilterProduct
  );
  const [status, setFilterStatus] = useQueryState(
    "",
    "status",
    queryStateOptions
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<CheckProcessFilters, CheckProcess>(
      { search, category, product, status },
      "id",
      "desc",
      10
    );

  const { data, isLoading, error } = usePaginationApi<
    CheckProcessFilters,
    CheckProcess
  >(apiRoutes.checkProcesses, params);
  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useUnlimitedPaginationApi<{}, Product>(apiRoutes.products, undefined, {
    productOption: true,
  });

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterCategory("");
    setFilterProduct("");
    setFilterStatus("");
  };

  const hasFilters =
    search !== "" || category !== "" || product !== "" || status !== "";

  return (
    <StyledContainer data-test="check-processes-content">
      <Header
        title={`Prozesse`}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.QA}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.checkProcessCreate}
            >
              <Add /> {t("Neuer Prozess")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("zB: Name, Beschreibung, ...")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <StyledFilterFormControl>
              <Autocomplete
                id="category"
                style={{ width: theme?.width.full }}
                onChange={(event, value) =>
                  setInputCategory(value ? value.name : "")
                }
                options={categories}
                loading={categoriesLoading}
                disabled={!!categoriesError}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) => option.name === inputCategory}
                value={
                  categories.find(
                    (category) => category.name === inputCategory
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Kategorie")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <Autocomplete
                id="product"
                style={{ width: theme?.width.full }}
                onChange={(event, value) =>
                  setInputProduct(value ? value.model : "")
                }
                options={products}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <ProductOption product={option} />
                    </li>
                  );
                }}
                loading={productsLoading}
                disabled={!!productsError}
                getOptionLabel={(option) => option.displayName}
                isOptionEqualToValue={(option) => option.model === inputProduct}
                value={
                  products.find((product) => product.model === inputProduct) ||
                  null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Produkt")}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </StyledFilterFormControl>
            <FilterFormControl variant="outlined" size="small">
              <InputLabel id="status">{t("Status")}</InputLabel>
              <Select
                labelId="status"
                id="status"
                value={status}
                onChange={(e) => setFilterStatus(e.target.value as string)}
                label={t("Status")}
              >
                <MenuItem sx={capitalizeStyle} value="">
                  <em>{t("Alle")}</em>
                </MenuItem>
                <MenuItem
                  sx={capitalizeStyle}
                  value={CheckProcessState.Approved}
                >
                  {t(`checkProcess.status.${CheckProcessState.Approved}`)}
                </MenuItem>
                <MenuItem sx={capitalizeStyle} value={CheckProcessState.Draft}>
                  {t(`checkProcess.status.${CheckProcessState.Draft}`)}
                </MenuItem>
                <MenuItem
                  sx={capitalizeStyle}
                  value={CheckProcessState.Inactive}
                >
                  {t(`checkProcess.status.${CheckProcessState.Inactive}`)}
                </MenuItem>
              </Select>
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <CheckProcessList
          checkProcesses={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default CheckProcessesOverview;
