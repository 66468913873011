import { useMutation } from "react-query";
import { apiRoutes, request } from "../../../lib/api";
import { Check } from "../../../model";
import { FormValues } from "../index";
import { useTranslation } from "react-i18next";
import { AxiosError, AxiosResponse } from "axios";

export function useStartCheckMutation() {
  return useMutation<AxiosResponse<Check>, AxiosError, FormValues>(
    async (values) => await request<Check>(apiRoutes.checks, "post", values)
  );
}

export function useCancelCheckMutation() {
  const { t } = useTranslation();

  return useMutation(async ({ checkId }: { checkId: number }) => {
    if (!window.confirm(t("Prüfung vorzeitig schließen?"))) {
      return;
    }
    return await request<Check>(apiRoutes.checkComplete(checkId), "post", {});
  });
}
