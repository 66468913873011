import { useTitle } from "../../hooks/use-title";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  DateFormat,
  getItemCheckFormValues,
  ItemCheck,
  ItemCheckFormValues,
  Person,
  Role,
} from "../../model";
import { apiRoutes, request, useItemCheckDetailsApi } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import { StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { hasRole } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import LoadingButton from "../../components/loading-button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const EditItemCheck: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { itemCheckId } = useParams<{ itemCheckId: string | undefined }>();
  const currentUser = useCurrentUser();

  const { isLoading, error, data } = useItemCheckDetailsApi(
    itemCheckId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: persons,
    error: personsError,
    isLoading: personsLoading,
  } = useUnlimitedPaginationApi<[], Person>(apiRoutes.persons);

  useTitle(data?.item?.serialNumber || t("Item Prüfung bearbeiten"));

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<ItemCheckFormValues>({
    mode: "all",
    defaultValues: getItemCheckFormValues(data),
  });

  useEffect(() => reset(getItemCheckFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: any,
    setError: UseFormSetError<ItemCheckFormValues>
  ) => {
    if (!data) {
      return;
    }

    await request<ItemCheck>(apiRoutes.itemCheck(data.id), "put", values)
      .then(() => {
        enqueueSnackbar(t("Item Prüfung wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.itemCheck(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  if (error || personsError) {
    return (
      <HttpError
        error={[error, personsError]}
        actions={
          <Button component={Link} to={routes.orders}>
            {t("Zurück zu Aufträgen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || personsLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Item Prüfung") },
    {
      label: data.item?.serialNumber
        ? data.item?.serialNumber
        : (data.id as unknown as string),
      link: routes.itemCheck(data.id),
    },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Item Prüfung")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prüfungs Details")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"checkedBy"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="checkedBy"
                      options={persons}
                      value={
                        persons.find((person) => person.id === field.value) ||
                        null
                      }
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Überprüfer")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"person"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="person"
                      options={persons}
                      value={
                        persons.find((person) => person.id === field.value) ||
                        null
                      }
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Verwender")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={"checkDate"}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value ? new Date(field.value) : null}
                      format={DateFormat.Default}
                      onChange={field.onChange}
                      label={t("Prüfdatum")}
                      disabled={!hasRole(currentUser, Role.Admin)}
                      slotProps={{
                        textField: {
                          error: fieldState.isTouched && fieldState.invalid,
                          helperText: fieldState.error?.message,
                          fullWidth: true,
                          required: true,
                          variant: "standard",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              disabled={!isValid}
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditItemCheck;
