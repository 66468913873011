import { useMutation } from "react-query";
import { apiRoutes, request } from "../../lib/api";

export const useResetPasswordMutation = () => {
  return useMutation(
    async (values: {
      plainPassword: string;
      passwordRepeat: string;
      token: string;
    }) => request(apiRoutes.resetPassword, "post", values)
  );
};
