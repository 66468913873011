import { createContext, Dispatch, SetStateAction } from "react";
import { CurrentUser } from "../model";

const AppContext = createContext<{
  materialTheme: "light" | "dark";
  setMaterialTheme: Dispatch<SetStateAction<"light" | "dark">>;
  locationTitle: string;
  setLocationTitle: Dispatch<SetStateAction<string>>;
  user?: CurrentUser;
  setUser: Dispatch<SetStateAction<CurrentUser>>;
  documentTitle: string;
}>({
  materialTheme: "light",
  setMaterialTheme: () => undefined,
  locationTitle: "",
  setLocationTitle: () => "",
  user: undefined,
  setUser: () => "",
  documentTitle: "",
});

export default AppContext;
