import { useMutation } from "react-query";
import { apiRoutes, request } from "../../lib/api";

export const useLoginMutation = () => {
  return useMutation(async (values: { username: string; password: string }) => {
    const data = await request(apiRoutes.login, "post", values);
    // we need to manually throw here, see axios interceptors
    if (!data) {
      throw new Error("Login failed");
    }
    return data;
  });
};
