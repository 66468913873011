import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useRef } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useCurrentUser } from "../../hooks/use-current-user";
import PromptIfDirty from "../../components/prompt-if-dirty";
import { apiRoutes, request } from "../../lib/api";
import { getErrorMessage, handleFormErrors } from "../../helpers";
import { useSnackbar } from "notistack";
import { CurrentUser } from "../../model";
import AppContext from "../../context/app";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useCredentialValidationSchema } from "../../hooks/validations/profile/use-credential-validation-schema";
import LoadingButton from "../../components/loading-button";

const StyledTextInput = styled(TextField)`
  width: 100%;
`;

interface FormValues {
  avatarUpload: any;
  fullName: string;
  email: string;
  deleteAvatar: boolean;
}

const GeneralProfileSettings = () => {
  const { setUser } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const avatarRef = useRef<any>();
  const { t } = useTranslation();
  const validationSchema = useCredentialValidationSchema();
  const formik = useFormik<FormValues>({
    initialValues: {
      avatarUpload: null,
      fullName: user.fullName ? user.fullName : "",
      email: user.email,
      deleteAvatar: false,
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const form = new FormData();
      form.append(
        "content",
        JSON.stringify({
          fullName: values.fullName,
          email: values.email,
          deleteAvatar: values.deleteAvatar,
        })
      );
      if (!values.deleteAvatar) {
        form.append("avatarUpload", values.avatarUpload);
      }
      request<CurrentUser>(apiRoutes.profile.me, "post", form)
        .then((res) => {
          enqueueSnackbar(t("Profil wurde erfolgreich aktualisiert."), {
            variant: "success",
          });
          setUser(res.data);
          removeFile();
        })
        .catch((err: AxiosError) => {
          enqueueSnackbar(getErrorMessage(err), { variant: "error" });
          handleFormErrors(err, actions);
        })
        .finally(() => formik.setSubmitting(false));
    },
  });

  const removeFile = () => {
    if (avatarRef.current) {
      avatarRef.current.value = "";
    }
  };

  const resetForm = () => {
    formik.resetForm();
    removeFile();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <PromptIfDirty formik={formik} />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledTextInput
                    id="username"
                    name="username"
                    label={t("Benutzername")}
                    value={user.username}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextInput
                    id="fullName"
                    name="fullName"
                    label={t("Name")}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={formik.errors.fullName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextInput
                    id="email"
                    name="email"
                    label={t("E-Mail")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined">
                <CardHeader subheader={t("Avatar")} />
                {user.avatar && (
                  <CardMedia
                    image={user.avatar}
                    style={{ paddingTop: "56.25%" }} // 16:9
                  />
                )}
                <CardContent>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    ref={avatarRef}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "avatarUpload",
                        e.currentTarget.files![0]
                      )
                    }
                  />
                </CardContent>
                {formik.errors.avatarUpload && (
                  <CardContent>
                    <Alert severity="error">{formik.errors.avatarUpload}</Alert>
                  </CardContent>
                )}
                {user.avatar && (
                  <CardActions>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="deleteAvatar"
                          checked={formik.values.deleteAvatar}
                          onChange={formik.handleChange}
                        />
                      }
                      label={t("Avatar Löschen")}
                    />
                  </CardActions>
                )}
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!formik.isValid}
            variant="contained"
            loading={formik.isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
          <Button
            type="reset"
            size="medium"
            onClick={resetForm}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            {t("Zurücksetzen")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default GeneralProfileSettings;
