import { AxiosError } from "axios";
import { handleHookFormErrors } from "helpers";
import { apiRoutes, request } from "lib/api";
import { Company } from "model";
import { Billing, BillingFormValues } from "model/billing";
import { useSnackbar } from "notistack";
import { UseFormSetError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export const useBillingUpdateMutation = (
  company: Company,
  setError: UseFormSetError<BillingFormValues>,
  onSuccess?: (data: Billing) => void
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (values: FormData) => {
      return await request<Billing>(
        apiRoutes.comapanyBilling(company.id),
        "post",
        values
      );
    },
    {
      onSuccess: async (res) => {
        enqueueSnackbar(t("Verrechnung wurde erfolgreich bearbeitet."), {
          variant: "success",
        });

        if (onSuccess) {
          onSuccess(res.data);
        }
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );
};
