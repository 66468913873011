import {
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Role, UserFormValues } from "../../model";
import { getSelectMenuItemStyles } from "../../helpers";
import { hasRole } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";

export const UserFormFields: VoidFunctionComponent<{
  control: Control<UserFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const theme = useTheme();

  const selectableRoles = Object.keys(Role).filter((role) =>
    hasRole(currentUser, Role[role as keyof typeof Role])
  );

  return (
    <>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"username"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Benutzername")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"fullName"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Name")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"email"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("E-Mail")}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"roles"}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel>{t("Rollen")}</InputLabel>
                  <Select
                    variant="standard"
                    fullWidth
                    {...field}
                    value={field.value}
                    multiple
                  >
                    {selectableRoles.map((role, key) => (
                      <MenuItem
                        key={key}
                        value={Role[role as keyof typeof Role]}
                        style={getSelectMenuItemStyles(
                          Role[role as keyof typeof Role],
                          field.value,
                          theme
                        )}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"enabled"}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label={t("Aktiviert")}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"plainPassword"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Passwort")}
                  type="password"
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={
                    fieldState.error?.message ||
                    t(
                      "Leer lassen, wenn das Passwort nicht geändert werden soll."
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"passwordRepeat"}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Passwort wiederholen")}
                  type="password"
                  fullWidth
                  required
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
