import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { getItemFormValues, ItemFormValues } from "../../model";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import ItemFormFields from "../../components/item-form-fields";
import { useItemCreateMutation, useItemForm } from "../../hooks";

const CreateItem: VoidFunctionComponent = () => {
  const { t } = useTranslation();

  useTitle(t("Neues Item"));

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
  } = useItemForm({
    ...getItemFormValues(),
    newProduct: {
      model: "",
      category: null,
      manufacturer: null,
      manufacturingYear: "",
    },
  });

  const createMutation = useItemCreateMutation(setError);
  const onSubmit = async (values: ItemFormValues) => {
    await createMutation.mutateAsync(values);
  };

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Items"), link: routes.items },
    { label: t("Neu") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Item")} breadcrumbs={breadcrumbs} />
      <Card>
        <ItemFormFields
          control={control}
          setValue={setValue}
          watch={watch}
          allowProductCreation={true}
        />
        <Divider />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default CreateItem;
