import { Company } from "../model";
import { useCurrentUser } from "./use-current-user";
import { isOnlyTester } from "../lib/security";

export const useSelectedCompany = (): Company | null => {
  const user = useCurrentUser();
  if (!isOnlyTester(user)) {
    return null;
  }
  return user.selectedCompany || user.person?.primaryCompany || null;
};
