import { useMemo } from "react";
import * as yup from "yup";
import { useUserValidation } from "../user/use-user-validation";

export const usePasswordValidationSchema = () => {
  const userValidation = useUserValidation();

  return useMemo(() => {
    return yup.object({
      currentPassword: userValidation.currentPassword,
      plainPassword: userValidation.password,
      passwordRepeat: userValidation.passwordRepeat("plainPassword"),
    });
  }, [userValidation]);
};
