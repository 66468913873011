import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ApiError,
  CheckReport,
  CheckReportFilters,
  DateFormat,
  HeadCell,
  Order,
  ReportTypes,
} from "../../model";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import {
  capitalizeStyle,
  CompactTableCell,
  FilterFormControl,
  FilterFormGroup,
} from "../globals";
import { useTranslation } from "react-i18next";
import { useFilters } from "../../hooks/use-filters";
import { TableNoResult } from "../table-no-result";
import FormattedDate from "../formatted-date";
import LoadingButton from "../loading-button";
import { Clear, GetApp } from "@mui/icons-material";
import { config } from "../../config";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  PaginationResponse,
  usePaginationApi,
} from "../../hooks/use-pagination-api";
import { apiRoutes, request } from "../../lib/api";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";

const CheckReportList: FunctionComponent<{
  order: Order;
}> = ({ order }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [checkReports, setCheckReports] = useState<CheckReport[]>([]);
  const [filtered, setFiltered] = useState<number>(0);

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [typeFilter, setFilterType] = useQueryState(
    "",
    "typeFilter",
    queryStateOptions
  );

  const hasFilters = search !== "" || typeFilter !== "";

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<CheckReportFilters, CheckReport>(
      {
        search,
        type: typeFilter,
        order: order.id,
      },
      "createdAt",
      "desc",
      config.pageSize
    );

  const { data } = usePaginationApi<CheckReportFilters, CheckReport>(
    apiRoutes.checkReports,
    params
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    setCheckReports(data.results);
    setFiltered(data.filtered);
  }, [data]);

  useEffect(() => {
    setDataLoading(true);
    request<PaginationResponse<CheckReport>>(
      apiRoutes.checkReports,
      "get",
      undefined,
      { ...params }
    ).then((res) => {
      setCheckReports(res.data.results);
      setFiltered(res.data.filtered);
      setDataLoading(false);
    });
    // Infinite loop if params in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const resetFilter = () => {
    setFilterSearch("");
    setFilterType("");
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/${apiRoutes.checkReportsExport}`,
        {
          search,
          type: typeFilter,
          order: order.id,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      )
      .then((res) => {
        if (!res.data) {
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([res.data as BlobPart], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = `${order.orderNumber}${
          typeFilter ? "_" + t(`checkReport.type.${typeFilter}`) : ""
        }${search ? "_" + search : ""}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        enqueueSnackbar(t("Protokolle Download wurde erfolgreich gestartet."), {
          variant: "success",
        });
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const headCells: HeadCell<CheckReport>[] = [
    { id: "type", label: t("Typ") },
    { id: "name", label: t("Protokoll Name") },
    { id: "createdAt", label: t("Ersellt am") },
  ];

  return (
    <Card>
      <CardHeader
        title={t("Protokolle")}
        action={
          <>
            <LoadingButton
              loading={downloadLoading}
              variant="contained"
              color="primary"
              onClick={handleDownload}
            >
              <GetApp />{" "}
              {hasFilters
                ? t("Ausgewählte herunterladen")
                : t("Alle herunterladen")}
            </LoadingButton>
          </>
        }
      />
      <CardContent>
        <FilterFormGroup row>
          <FilterFormControl>
            <TextField
              label={t("Suche")}
              variant="outlined"
              placeholder={t("zB: Name, Verwender, Kategorie,...")}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              size="small"
            />
          </FilterFormControl>
          <FilterFormControl variant="outlined" size="small">
            <InputLabel id="action">{t("Typ")}</InputLabel>
            <Select
              variant="standard"
              labelId="type"
              id="type"
              value={typeFilter}
              onChange={(e) => setFilterType(e.target.value as string)}
              label={t("Typ")}
            >
              <MenuItem sx={capitalizeStyle} value="">
                <em>{t("Alle")}</em>
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ReportTypes.Person}>
                {t("Verwender")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ReportTypes.Location}>
                {t("Abteilung")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ReportTypes.Category}>
                {t("Kategorie")}
              </MenuItem>
              <MenuItem sx={capitalizeStyle} value={ReportTypes.Single}>
                {t("Einzelprotokoll")}
              </MenuItem>
              <ListSubheader>{t("Zusammenfassungen")}</ListSubheader>
              <MenuItem sx={capitalizeStyle} value={ReportTypes.PersonSummary}>
                {t("Verwender Tabelle")}
              </MenuItem>
              <MenuItem
                sx={capitalizeStyle}
                value={ReportTypes.LocationSummary}
              >
                {t("Abteilung Tabelle")}
              </MenuItem>
              <MenuItem
                sx={capitalizeStyle}
                value={ReportTypes.CategorySummary}
              >
                {t("Kategorie Tabelle")}
              </MenuItem>
            </Select>
          </FilterFormControl>
          {hasFilters && (
            <IconButton size="small" onClick={resetFilter}>
              <Clear />
            </IconButton>
          )}
        </FilterFormGroup>
      </CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  width={headCell.width}
                  align={headCell.align}
                  padding={headCell.padding || "normal"}
                  sortDirection={
                    params && params.orderBy === headCell.id
                      ? params.order
                      : false
                  }
                >
                  {headCell.sortable !== false ? (
                    <TableSortLabel
                      active={params && params.orderBy === headCell.id}
                      direction={
                        params && params.orderBy === headCell.id
                          ? params.order
                          : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <>{headCell.label}</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!dataLoading ? (
            <>
              <TableBody>
                {checkReports.map((checkReport) => (
                  <TableRow key={checkReport.id} hover={true}>
                    <CompactTableCell>
                      {t(`checkReport.type.${checkReport.type}`)}
                    </CompactTableCell>
                    <TableCell>{checkReport.name}</TableCell>
                    <TableCell>
                      <FormattedDate
                        date={checkReport.createdAt}
                        format={DateFormat.Default}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {!checkReports.length && (
                <TableNoResult
                  text={t("Es wurden noch keine Protokolle erstellt.")}
                />
              )}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={100}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={config.pageSizes}
        component="div"
        count={filtered}
        rowsPerPage={params.pageSize}
        page={params.page}
        onPageChange={(_, page) => handleChangePage(page)}
        onRowsPerPageChange={(e) =>
          handleChangePageSize(parseInt(e.target.value, 10))
        }
      />
    </Card>
  );
};

export default CheckReportList;
