import { useMemo } from "react";
import * as yup from "yup";
import { useBillingValidation } from "./use-billing-validation";

export const useBillingValidationSchema = () => {
  const validation = useBillingValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
