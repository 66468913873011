import React, { VoidFunctionComponent } from "react";
import { Chip, ChipProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber } from "@mui/material/colors";
import { Item, ItemListPreview } from "../../model";

const DefectChip: VoidFunctionComponent<
  { item: ItemListPreview | Item; short?: boolean } & ChipProps
> = ({ item, short = false, ...props }) => {
  const { t } = useTranslation();

  if (!item.defects || item.defects.length < 1) {
    return null;
  }

  return (
    <Chip
      label={
        item.defects.filter((defect) => !defect.defectCorrected).length +
        "/" +
        item.defects.length +
        (!short ? (item.defects.length > 1 ? t(" Mängel") : t(" Mangel")) : " ")
      }
      style={{
        backgroundColor: amber[500],
        color: "black",
      }}
      size="small"
      {...props}
    />
  );
};

export default DefectChip;
