import React, { VoidFunctionComponent } from "react";
import { Chip, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { CheckStep } from "../../model";
import { useTranslation } from "react-i18next";

const BoolCheckListItem: VoidFunctionComponent<{
  checkStep: CheckStep;
  paddingLeft?: string;
}> = ({ checkStep, paddingLeft }) => {
  const { t } = useTranslation();

  return (
    <ListItem style={{ paddingLeft: paddingLeft || 0 }} key={checkStep.id}>
      <ListItemText
        primary={checkStep.title}
        secondary={checkStep.description || ""}
      />
      <ListItemIcon>
        {checkStep.value === true && (
          <Chip
            size="small"
            style={{ backgroundColor: green[500], color: "white" }}
            label={t("Ja")}
          />
        )}
        {checkStep.value === false && (
          <Chip
            size="small"
            style={{ backgroundColor: red[500], color: "white" }}
            label={t("Nein")}
          />
        )}
        {checkStep.value === null && (
          <Chip size="small" label={t("Nicht zutreffend")} />
        )}
      </ListItemIcon>
    </ListItem>
  );
};

export default BoolCheckListItem;
