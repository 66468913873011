import { useTitle } from "../../hooks/use-title";
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getQualificationFormValues,
  Qualification,
  QualificationFormValues,
  QualificationTag,
  QualificationType,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { StyledCardTitle } from "../../components/globals";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import LoadingButton from "../../components/loading-button";
import { useQualificationValidationSchema } from "../../hooks/validations/qualifications/use-qualification-validation-schema";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import LoadingContainer from "../../components/loading-container";

const CreateQualification = () => {
  const { t } = useTranslation();
  useTitle(t("Neue Qualifikation"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikation"), link: routes.qualificationTags },
    { label: t("Neu") },
  ];

  const {
    data: qualifications,
    isLoading: qualificationsLoading,
    error: qualificationsError,
  } = useUnlimitedPaginationApi<{}, Qualification>(apiRoutes.qualifications);
  const {
    data: tags,
    isLoading: tagsLoading,
    error: tagsError,
  } = useUnlimitedPaginationApi<{}, QualificationTag>(
    apiRoutes.qualificationTags
  );

  const validationSchema = useQualificationValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<QualificationFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getQualificationFormValues(),
  });

  useEffect(() => {
    qualificationsError?.response?.data.message &&
      enqueueSnackbar(qualificationsError?.response?.data.message, {
        variant: "error",
      });
    tagsError?.response?.data.message &&
      enqueueSnackbar(tagsError?.response?.data.message, { variant: "error" });
  }, [qualificationsError, tagsError, enqueueSnackbar]);

  if (qualificationsLoading || tagsLoading || !qualifications || !tags) {
    return <LoadingContainer />;
  }

  const onSubmit = async (
    values: QualificationFormValues,
    setError: UseFormSetError<QualificationFormValues>
  ) => {
    await request<Qualification>(apiRoutes.qualificationCreate, "post", values)
      .then((res) => {
        enqueueSnackbar(t("Qualifikation wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.qualification(res.data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Qualifikation")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Qualifikation")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"name"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Titel")}
                          fullWidth
                          {...field}
                          required
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"refreshInterval"}
                      render={({ field, fieldState }) => (
                        <TextField
                          variant="standard"
                          label={t("Auffrischungsintervall")}
                          fullWidth
                          {...field}
                          type="number"
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={
                            fieldState.error?.message || t("in Jahren")
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"type"}
                  render={({ field }) => (
                    <>
                      <FormLabel component="legend">{t("Typ")}</FormLabel>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.ManufacturingTraining}
                          label={t(
                            `qualification.type.${QualificationType.ManufacturingTraining}`
                          )}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.EmployeeTraining}
                          label={t(
                            `qualification.type.${QualificationType.EmployeeTraining}`
                          )}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={QualificationType.Instructions}
                          label={t(
                            `qualification.type.${QualificationType.Instructions}`
                          )}
                        />
                      </RadioGroup>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"conditions"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="conditions"
                      multiple={true}
                      options={qualifications}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Voraussetzungen")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={"qualificationTags"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="qualificationTags"
                      multiple={true}
                      options={tags}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) =>
                        field.onChange(
                          value.map((qualification) => qualification.id)
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={t("Tags")}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"comment"}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="standard"
                      label={t("Notiz")}
                      fullWidth
                      multiline
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateQualification;
