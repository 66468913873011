import { usePersonValidation } from "./use-person-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const usePersonValidationSchema = () => {
  const personValidation = usePersonValidation();

  return useMemo(() => {
    return yup.object({
      ...personValidation,
    });
  }, [personValidation]);
};
