import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  Category,
  getProductFormValues,
  ProductFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormSetError } from "react-hook-form";
import { useProductValidationSchema } from "../../hooks/validations/products/use-product-validation-schema";
import LoadingButton from "../../components/loading-button";
import ProductFormFields from "components/product-form-fields";

const CreateProduct: VoidFunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Produkte"), link: routes.products() },
    { label: t("Neu") },
  ];

  const validationSchema = useProductValidationSchema();
  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<ProductFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getProductFormValues(),
  });

  useTitle(t("Neues Produkt"));

  const onSubmit = async (
    values: ProductFormValues,
    setError: UseFormSetError<ProductFormValues>
  ) => {
    const formData = new FormData();
    let documentUploadsErrors = false;
    const documentUploads: any[] = !values.showUpload
      ? []
      : values.documentUploads.map((documentUpload, index) => {
          if (!documentUpload.documentUpload) {
            documentUploadsErrors = true;
          }
          formData.append(
            "documentUploadFile" + index,
            documentUpload.documentUpload
          );
          return {
            name: documentUpload.name,
            type: documentUpload.type,
            comment: documentUpload.comment,
          };
        });

    if (documentUploadsErrors) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    if (values.imageUpload) {
      formData.append("imageUploadFile", values.imageUpload);
    }

    formData.append(
      "content",
      JSON.stringify({
        model: values.model,
        manufacturingYear: values.manufacturingYear,
        category: values.category,
        manufacturer: values.manufacturer,
        articleNumber: values.articleNumber,
        shortDescription: values.shortDescription,
        description: values.description,
        reviewInterval: values.reviewInterval,
        lifetime: values.lifetime,
        documents: values.documents,
        ...(values.showUpload ? { documentUploads } : {}),
      })
    );

    await request<Category>(apiRoutes.productCreate, "post", formData)
      .then(() => {
        enqueueSnackbar(t("Produkt wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.products());
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Produkt")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <ProductFormFields control={control} watch={watch} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateProduct;
