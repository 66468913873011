import { FunctionComponent, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import { useRequestPasswordResetMutation } from "../../hooks/mutation/use-request-password-reset-mutation";
import { LoginContext } from "./index";
import { Helmet } from "react-helmet";

interface ForgotPasswordFormValues {
  username: string;
}

const ForgotPassword: FunctionComponent = () => {
  const { email, setEmail } = useContext(LoginContext);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ForgotPasswordFormValues>({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        username: yup.string().email().required(),
      })
    ),
    defaultValues: {
      username: email,
    },
  });
  const requestResetPasswordMutation = useRequestPasswordResetMutation();

  const onSubmit = (v: ForgotPasswordFormValues) => {
    requestResetPasswordMutation.mutate({ email: v.username });
  };

  if (requestResetPasswordMutation.isSuccess) {
    return (
      <Alert severity="success">
        {t(
          "Falls diese E-Mail Adresse im System gefunden wurde erhalten Sie eine E-Mail mit weiteren Anweisungen."
        )}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("Passwort vergessen")}</title>
      </Helmet>
      <form onSubmit={handleSubmit((values) => onSubmit(values))}>
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("E-Mail Adresse")}
              autoFocus
              disabled={requestResetPasswordMutation.isLoading}
              value={value}
              ref={ref}
              onChange={(e) => {
                onChange(e.target.value);
                setEmail(e.target.value);
              }}
            />
          )}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid}
          loading={requestResetPasswordMutation.isLoading}
        >
          {t("Passwort zurücksetzen")}
        </LoadingButton>
      </form>
    </>
  );
};

export default ForgotPassword;
