import React, { VoidFunctionComponent } from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber, green, red } from "@mui/material/colors";

const ItemCheckResult: VoidFunctionComponent<{ result: number | null }> = ({
  result,
}) => {
  const { t } = useTranslation();

  switch (result) {
    case 1:
      return (
        <Chip
          label={t("Freigegeben")}
          style={{ backgroundColor: green[500], color: "white" }}
          size={`small`}
        />
      );
    case 3:
      return (
        <Chip
          label={t("Gesperrt")}
          style={{ backgroundColor: amber[500], color: "black" }}
          size={`small`}
        />
      );
    case 5:
      return (
        <Chip
          label={t("Ausgeschieden")}
          style={{ backgroundColor: red[500], color: "white" }}
          size={`small`}
        />
      );
    default:
      return <Chip label={t("Ausständig")} size={`small`} />;
  }
};

export default ItemCheckResult;
