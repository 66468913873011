import React, { FunctionComponent } from "react";
import { HeadCell, Product, ProductFilters } from "../../model";
import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { routes } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { IconLink } from "../icon-link";
import { Edit, Visibility } from "@mui/icons-material";
import { TableNoResult } from "../table-no-result";
import { CompactTableCell, StyledVerifedIcon } from "../globals";
import styled from "styled-components";

export const StyledProductImage = styled.img`
  max-width: 50px;
`;

const ProductList: FunctionComponent<{
  products: Product[];
  loading?: boolean;
  params?: FilterParams<ProductFilters, Product>;
  createSortHandler?: SortHandler<Product>;
}> = ({ products, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<Product>[] = [
    { id: "image", label: "", sortable: false, width: "50px" },
    { id: "verified", label: "", sortable: false },
    { id: "model", label: t("Modell") },
    { id: "category", label: t("Kategorie") },
    { id: "manufacturer", label: t("Hersteller") },
    { id: "manufacturingYear", label: t("Herstellungsjahr") },
    { id: "lifetime", label: t("Lebensdauer") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id} hover={true}>
                  <TableCell style={{ padding: "0.5em" }}>
                    {product.image && (
                      <StyledProductImage
                        src={product.image}
                        alt={product.model}
                      />
                    )}
                  </TableCell>
                  <CompactTableCell style={{ padding: "0" }} align="center">
                    {product.verified && <StyledVerifedIcon />}
                  </CompactTableCell>
                  <TableCell style={{ maxWidth: "200px" }}>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {product.model}
                    </div>
                  </TableCell>
                  <TableCell style={{ maxWidth: "200px" }}>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to={routes.category(product.category.id)}
                      >
                        {product.category.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={routes.company(product.manufacturer.id)}
                    >
                      {product.manufacturer.name}
                    </Link>
                  </TableCell>
                  <TableCell>{product.manufacturingYear}</TableCell>
                  <TableCell>
                    {product.lifetime > 0 ? product.lifetime + " Monate" : ""}
                  </TableCell>
                  <TableCell align={"right"}>
                    <IconLink
                      to={routes.product(product.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <IconLink
                      to={routes.productEdit(product.id)}
                      tooltip={t("Bearbeiten")}
                      icon={<Edit />}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!products.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ProductList;
