import { useTitle } from "../../hooks/use-title";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import React from "react";
import Header from "../../components/header";
import {
  apiRoutes,
  getAbsoluteApiUrl,
  request,
  useProductDetailsApi,
} from "../../lib/api";
import HttpError from "../../components/http-error";
import { useSnackbar } from "notistack";
import LoadingContainer from "../../components/loading-container";
import { routes } from "../../lib/routes";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Definition, StyledCardTitle } from "../../components/globals";
import Guarded from "../../components/guarded";
import { Role } from "../../model";
import { Delete, Edit, FindInPage, VerifiedUser } from "@mui/icons-material";
import { ModifiedEntity } from "../../components/modified-entity";
import CategoryTree from "../../components/category-tree";
import DocumentList from "../../components/document-list";
import styled from "styled-components";
import { blue } from "@mui/material/colors";
import { useQueryClient } from "react-query";

const StyledProductImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const StyledVerifyButton = styled(Button)`
  background-color: ${() => blue[500]};

  &:hover {
    background-color: ${() => blue[700]};
  }
`;

export const StyledVerifiedButton = styled(Button)`
  border: 1px solid ${() => blue[500]};
  color: ${() => blue[500]};

  &:hover {
    border: 1px solid ${() => blue[700]};
    color: ${() => blue[700]};
  }
`;

const ProductDetails = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useProductDetailsApi(
    productId as unknown as number
  );
  const queryClient = useQueryClient();
  const history = useHistory();
  useTitle(data?.model || t("Produkt Details"));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={`/product`}>
            {t("Zurück zu Produkten")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Produkte"), link: routes.products() },
    { label: data.model },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie das Produkt {{model}} wirklich löschen?`, {
        model: data.model,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.product(data.id), "delete")
      .then(() => {
        enqueueSnackbar(t("Produkt wurde erfolgreich gelöscht."), {
          variant: "success",
        });
        history.push("/products");
      })
      .catch(() =>
        enqueueSnackbar(t("Produkt konnte nicht gelöscht werden."), {
          variant: "error",
        })
      );
  };

  const handleVerify = () => {
    const confirm = window.confirm(
      t(
        `Wollen Sie das Produkt {{model}} wirklich als {{action}} kennzeichnen?`,
        {
          action: data.verified ? "nicht verifiziert" : "verifiziert",
          model: data.model,
        }
      )
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.productVerify(data.id))
      .then(() => {
        enqueueSnackbar(
          t("Produkt wurde erfolgreich als {{status}} gekennzeichnet.", {
            status: data.verified ? "nicht verifiziert" : "verifiziert",
          }),
          {
            variant: "success",
          }
        );
        queryClient.invalidateQueries([apiRoutes.product(data.id)]);
      })
      .catch(() =>
        enqueueSnackbar(t("Produktstatus konnte nicht geändert werden."), {
          variant: "error",
        })
      );
  };

  return (
    <Container maxWidth="lg">
      <Header
        title={t("Produkt")}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              {data.verified ? (
                <StyledVerifyButton
                  variant="contained"
                  color="primary"
                  onClick={handleVerify}
                >
                  <VerifiedUser /> {t("Verifiziert")}
                </StyledVerifyButton>
              ) : (
                <StyledVerifiedButton
                  variant="outlined"
                  color="primary"
                  onClick={handleVerify}
                >
                  <VerifiedUser /> {t("Verifizieren")}
                </StyledVerifiedButton>
              )}
            </Guarded>{" "}
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={routes.productEdit(data.id)}
            >
              <Edit /> {t("Bearbeiten")}
            </Button>{" "}
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <Delete /> {t("Löschen")}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <Grid container>
          <Grid item md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Produktdaten")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Definition title={t("Model")} value={data.model} />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Hersteller")}
                        value={
                          <Link
                            component={RouterLink}
                            to={routes.company(data.manufacturer.id)}
                          >
                            {data.manufacturer.name}
                          </Link>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Kategorie")}
                        value={<CategoryTree category={data.category} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Definition
                        title={t("Herstellungsjahr")}
                        value={data.manufacturingYear}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Definition
                    title={t("Produktbild")}
                    value={
                      data.image ? (
                        <StyledProductImage alt={data.model} src={data.image} />
                      ) : (
                        "-"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          <Divider
            orientation={"vertical"}
            flexItem
            style={{ marginRight: "-2px" }}
          />
          <Grid item md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Details")}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Definition
                    title={t("Artikelnummer")}
                    value={data.articleNumber ? data.articleNumber : "-"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Definition
                    title={t("Prüfintervall")}
                    value={
                      data.reviewInterval
                        ? data.reviewInterval + " " + t("Monate")
                        : "-"
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Definition
                    title={t("Lebensdauer")}
                    value={
                      data.lifetime ? data.lifetime + " " + t("Monate") : "-"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Definition
                    title={t("Kurzbeschreibung")}
                    value={data.shortDescription ? data.shortDescription : "-"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Definition
                    title={t("Beschreibung")}
                    value={data.description ? data.description : "-"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12} md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t("Normen")}
              </StyledCardTitle>
              <List dense={true}>
                {data.category.standards.map((standard, index) => (
                  <ListItem style={{ paddingLeft: 0 }} key={index}>
                    <ListItemIcon>
                      {standard.document ? (
                        <a
                          href={getAbsoluteApiUrl(
                            apiRoutes.documentFile(standard.document.id)
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton aria-label={t("Ansehen")} size="small">
                            <FindInPage />
                          </IconButton>
                        </a>
                      ) : (
                        <IconButton size="small" disabled>
                          <FindInPage />
                        </IconButton>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Link
                          component={RouterLink}
                          to={routes.standard(standard.id)}
                        >
                          {standard.number}
                        </Link>
                      }
                      secondary={
                        <>
                          {t(`standard.type.${standard.type}`)} -{" "}
                          {standard.scope}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              {data.category.standards.length < 1 && (
                <Typography>
                  {t("Es ist keine Norm der Produkt-Kategorie zugewiesen.")}
                </Typography>
              )}
            </CardContent>
          </Grid>
          <Divider
            orientation={"vertical"}
            flexItem
            style={{ marginRight: "-2px" }}
          />
          <Grid item xs={12} md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t(`Zugewiesene Dokumente (${data.documents.length})`)}
              </StyledCardTitle>
            </CardContent>
            <DocumentList documents={data.documents} />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default ProductDetails;
