import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Description } from "@mui/icons-material";
import { ManualType, MappedManualTypes } from "../../model";

const DocumentUpload: FunctionComponent<{
  control: Control<any>;
  formPrefix?: string;
  allowedTypes?: ManualType[];
}> = ({ control, formPrefix = "", allowedTypes = undefined }) => {
  const { t } = useTranslation();
  const groupedManualTypes: Array<{
    id: number;
    group: string;
    name: ManualType;
  }> = [];
  Object.entries(MappedManualTypes).forEach(([key, value]) => {
    value.forEach((manualType) => {
      if (Array.isArray(allowedTypes) && !allowedTypes.includes(manualType)) {
        return;
      }
      groupedManualTypes.push({
        id: groupedManualTypes.length,
        group: key,
        name: manualType,
      });
    });
  });

  if (formPrefix && !/\.$/.test(formPrefix)) {
    formPrefix = formPrefix + ".";
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name={`${formPrefix}name` as const}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Name")}
                  fullWidth
                  required={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name={`${formPrefix}type` as const}
              render={({ field, fieldState }) => (
                <Autocomplete
                  disableClearable={true}
                  getOptionKey={(option) => option.name}
                  getOptionLabel={(option) => t(`document.type.${option.name}`)}
                  options={(groupedManualTypes || []).sort((a, b) => {
                    if (a.group && b.group) {
                      return -b.group.localeCompare(a.group);
                    }
                    return -1;
                  })}
                  groupBy={(option) =>
                    t(`document.group.${option.group}`) ||
                    t("standard.group.none")
                  }
                  value={
                    groupedManualTypes?.find(
                      (document) => document.name === field.value
                    ) || undefined
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onBlur={() => !field.value && field.onChange("")}
                  onChange={(event, value) => field.onChange(value?.name)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      required={true}
                      label={t("Typ")}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`${formPrefix}comment` as const}
              render={({ field, fieldState }) => (
                <TextField
                  variant="standard"
                  label={t("Kommentar")}
                  fullWidth
                  multiline
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`${formPrefix}documentUpload` as const}
              render={({ field, fieldState }) => (
                <>
                  <input
                    {...field}
                    value=""
                    accept="application/pdf"
                    id={`${formPrefix}file-upload` as const}
                    hidden
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.currentTarget.files![0]);
                    }}
                  />
                  <label htmlFor={`${formPrefix}file-upload` as const}>
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      onClick={() =>
                        !field.value &&
                        document
                          .querySelector(
                            `#${formPrefix.replace(/\./g, "\\.")}file-upload`
                          )
                          ?.addEventListener(
                            "cancel",
                            () => field.onChange(null),
                            {
                              once: true,
                            }
                          )
                      }
                    >
                      <Description /> {t("Datei auswählen")}
                    </Button>
                  </label>
                  <Box m={1} display="inline">
                    <Typography variant="caption">
                      {field.value?.name}
                    </Typography>
                  </Box>
                  {fieldState.error && (
                    <FormHelperText error={true}>
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentUpload;
