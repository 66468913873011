import { useQuery, useQueryClient } from "react-query";
import { AppGrant } from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { isGranted } from "../../lib/security";
import { useCurrentUser } from "../use-current-user";
import { QueueTaskProgressStatus } from "../../model/queue-task";
import { useRef } from "react";
import { QueueTasksQueryKey } from "../../views/queue-tasks/overview";

export const QueueTaskStatusQueryKey = "queueTaskStatusQueryKey";

export const useQueueTaskStatusQuery = () => {
  const user = useCurrentUser();
  const currentStatusRef = useRef<string>("");
  const queryClient = useQueryClient();

  return useQuery(
    QueueTaskStatusQueryKey,
    async () => {
      return await request<QueueTaskProgressStatus[]>(
        apiRoutes.queueTaskStatus,
        "GET"
      );
    },
    {
      onSuccess: async (res) => {
        const statusRef = JSON.stringify(res.data);
        if (currentStatusRef.current !== statusRef) {
          currentStatusRef.current = statusRef;
          await queryClient.invalidateQueries([QueueTasksQueryKey]);
        }
      },
      refetchOnWindowFocus: true,
      refetchInterval: (res) =>
        res?.data && res.data.length > 0 ? 5000 : 30000,
      enabled: isGranted(user, AppGrant.QUEUE_TASKS),
    }
  );
};
