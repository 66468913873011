import React, { VoidFunctionComponent } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Check,
  getItemFormValues,
  ID,
  ItemCheck,
  ItemFormValues,
  Location,
  Person,
} from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useItemValidationSchema } from "../../hooks/validations/items/use-item-validation-schema";
import { GridData } from "../grid-data";
import { Close } from "@mui/icons-material";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { useSnackbar } from "notistack";
import LoadingButton from "../loading-button";
import { useMutation, useQueryClient } from "react-query";

interface ItemEditFormValues
  extends Pick<ItemFormValues, "product" | "location" | "person"> {
  item: ID;
}

const ItemCheckEditDialog: VoidFunctionComponent<{
  itemCheck: ItemCheck;
  check: Check;
  open: boolean;
  onClose: () => void;
  persons: Person[];
  locations: Location[];
}> = ({ itemCheck, open, onClose, persons, locations, check }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useItemValidationSchema();
  const {
    setError,
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<ItemEditFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...getItemFormValues(itemCheck.item),
      item: itemCheck.item.id,
      location: itemCheck.location?.id,
      person: itemCheck.person?.id,
    },
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (values: ItemEditFormValues) =>
      await request<ItemCheck>(
        apiRoutes.itemCheckPartialUpdate(itemCheck.id),
        "put",
        values
      ),
    {
      onSuccess: async () => {
        enqueueSnackbar(t("Item wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        await queryClient.invalidateQueries(apiRoutes.check(check.id));
        await queryClient.invalidateQueries(apiRoutes.items);
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || "Error", {
          variant: "error",
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} fullWidth={true}>
      <form onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{t("Item bearbeiten")}</Grid>
            <Grid item>
              <IconButton onClick={onClose} size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <GridData label={t("Seriennummer")}>
                {itemCheck.item.serialNumber}
              </GridData>
            </Grid>
            <Grid item xs={12} md={6}>
              <GridData label={t("Systemnummer")}>
                {itemCheck.item.systemNumber}
              </GridData>
            </Grid>
            <Grid item xs={12} md={6}>
              <GridData label={t("Produkt")}>
                {itemCheck.item.product
                  ? itemCheck.item.product.model +
                    `(${itemCheck.item.product.manufacturer.name})`
                  : t("Achtung, kein Produkt!")}
              </GridData>
            </Grid>
            <Grid item xs={12} md={6}>
              <GridData label={t("Kunde")}>
                {itemCheck.item.customer?.name || itemCheck.item.customerName}
              </GridData>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"location"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="location"
                    options={locations}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) =>
                      `${option.name}${
                        option.parent ? ` (${option.parent.name})` : ""
                      }`
                    }
                    value={
                      locations.find(
                        (location) => location.id === field.value
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => field.onChange(value?.id)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Aktueller Ort/Abteilung")}
                        required
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={"person"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="person"
                    options={persons}
                    value={
                      persons.find((person) => person.id === field.value) ||
                      null
                    }
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => field.onChange(value?.id)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Aktueller Verwender")}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            size="medium"
            color="secondary"
            onClick={onClose}
          >
            {t("Abbrechen")}
          </Button>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
          >
            {t("Speichern")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ItemCheckEditDialog;
