import { AxiosError } from "axios";
import { QueryKey, useQuery } from "react-query";
import { Dict, Entity } from "../model";
import { FilterParams } from "./use-filters";
import { request } from "../lib/api";
import { UseQueryOptions } from "react-query/types/react/types";

export interface PaginationResponse<TEntity extends Entity> {
  total: number;
  filtered: number;
  results: TEntity[];
}

export const usePaginationApi = <
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
>(
  url: string,
  params: FilterParams<TFilters, TContext>,
  headers?: Dict,
  key?: unknown[],
  options?: UseQueryOptions<
    PaginationResponse<TEntity>,
    AxiosError<any>,
    PaginationResponse<TEntity>,
    QueryKey
  >
) => {
  const fetchData = async (params: FilterParams<TFilters, TContext>) => {
    const { data } = await request<PaginationResponse<TEntity>>(
      url,
      "get",
      undefined,
      {
        filters: params.filters,
        page: params.page,
        pageSize: params.pageSize,
        order: [[params.orderBy, params.order]],
        context: params.context,
      },
      headers
    );
    return data;
  };

  return useQuery<PaginationResponse<TEntity>, AxiosError>(
    key
      ? key
      : [
          url,
          params.page,
          params.pageSize,
          params.orderBy,
          params.order,
          params.filters,
          params.context,
        ],
    () => fetchData(params),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
