import React, { FunctionComponent, useEffect, useState } from "react";
import { Company, Person } from "../../model";
import { apiRoutes } from "../../lib/api";
import { useApi } from "../../hooks/use-api";
import HttpError from "../../components/http-error";
import PersonList from "../../components/person-list";

const Persons: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const [persons, setPersons] = useState<Person[]>([]);
  const { data, isLoading, error } = useApi<Person[]>(
    apiRoutes.companyPrimaryPersons(company.id)
  );

  useEffect(() => {
    setPersons(data || []);
  }, [data]);

  if (error) {
    return <HttpError error={error} />;
  }

  return <PersonList loading={isLoading} persons={persons} company={company} />;
};

export default Persons;
