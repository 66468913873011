import { useTitle } from "../../hooks/use-title";
import { Link, Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, FunctionComponent, useEffect, useState } from "react";
import Header from "../../components/header";
import { useLogDetailsApi } from "../../lib/api";
import HttpError from "../../components/http-error";
import FormattedDate from "../../components/formatted-date";
import LoadingContainer from "../../components/loading-container";
import { Log } from "../../model";
import LogEntityLink from "../../components/log-entity-link";
import { CompactTableCell } from "../../components/globals";
import { Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";

const LogDataValue: FunctionComponent<{ value: any }> = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <>
        [
        {value.map((v, i) => [
          i > 0 && ", ",
          <LogDataValue value={v} key={i} />,
        ])}
        ]
      </>
    );
  }
  if (value === null) {
    return <em>null</em>;
  }
  if (typeof value === "boolean") {
    return <em>{value ? "true" : "false"}</em>;
  }
  if (typeof value === "string") {
    return <>{value}</>;
  }
  if (typeof value === "object") {
    return <>{value.id}</>;
  }
  return <>{value}</>;
};

const LogsDetails: FC = () => {
  const { logId } = useParams<{ logId: string | undefined }>();
  const { isLoading, error, data } = useLogDetailsApi(
    logId as unknown as number
  );
  const [log, setLog] = useState<Log>();
  const { t } = useTranslation();
  useTitle(t("Log {{log}} Details", { log: logId }));

  useEffect(() => {
    setLog(data?.find((log) => logId && log.id === +logId));
  }, [data, logId]);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.logs}>
            Back to logs
          </Button>
        }
      />
    );
  }

  if (isLoading || !log) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Logs"), link: routes.logs },
    { label: `${log.id}` },
  ];

  return (
    <Container maxWidth="lg">
      <Header title={t("Logs")} breadcrumbs={detailBreadcrumbs} />
      <Grid container spacing={3} direction="row">
        <Grid item sx={{ flexGrow: 1 }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    display="block"
                  >
                    {t("Log")}
                  </Typography>
                  <Typography variant="body1" display="inline">
                    <LogEntityLink
                      entityClass={log.objectClass}
                      entityId={log.objectId}
                      label={log.label}
                    />{" "}
                  </Typography>
                  <Typography display="inline" color="textSecondary">
                    {t(`log.action.${log.action}d`)}{" "}
                    <strong>
                      <FormattedDate date={log.loggedAt} />
                    </strong>{" "}
                    {log.username && (
                      <>
                        {t("von")} <strong>{log.username}</strong>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="overline" color="textSecondary">
                    {t("Version")}
                  </Typography>
                  <Typography variant="body1">{log.version}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Feld")}</TableCell>
                    <TableCell>{t("Wert")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {log.data &&
                    Object.entries(log.data).map(([field, value]) => (
                      <TableRow key={field}>
                        <CompactTableCell variant="head">
                          {field}
                        </CompactTableCell>
                        <TableCell>
                          <LogDataValue value={value} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <CompactTableCell>{t("Action")}</CompactTableCell>
                    <TableCell>{t("Zeitpunkt")}</TableCell>
                    <TableCell>{t("Benutzer")}</TableCell>
                    <CompactTableCell>{t("Version")}</CompactTableCell>
                    <CompactTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data
                      .sort((a, b) => b.version - a.version)
                      .map((row) => (
                        <TableRow key={row.id} selected={row.id === log.id}>
                          <CompactTableCell>
                            {t(`log.action.${row.action}`)}
                          </CompactTableCell>
                          <TableCell>
                            <FormattedDate date={row.loggedAt} />
                          </TableCell>
                          <TableCell>{row.username}</TableCell>
                          <CompactTableCell>{row.version}</CompactTableCell>
                          <CompactTableCell>
                            <Link to={routes.log(row.id)}>
                              <IconButton aria-label="View" size="small">
                                <Visibility />
                              </IconButton>
                            </Link>
                          </CompactTableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LogsDetails;
