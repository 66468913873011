export const routes = {
  dashboard: "/",
  search: "/search",

  profile: "/profile",
  profilePassword: "/profile/password",

  users: "/users",
  user: (id: number | string) => `/users/${id}`,
  userEdit: (id: number | string) => `/users/${id}/edit`,
  userCreate: "/users/create",

  logs: "/logs",
  log: (id: number | string) => `/logs/${id}`,

  companies: "/companies",
  company: (id: number | string) => `/companies/${id}`,
  companyEdit: (id: number | string) => `/companies/${id}/edit`,
  companyCreate: "/companies/create",

  orders: "/orders",
  order: (id: number | string) => `/orders/${id}`,
  orderEdit: (id: number | string) => `/orders/${id}/edit`,
  orderCreate: "/orders/create",
  orderReport: (id: number | string) => `/orders/${id}/report`,

  categories: "/categories",
  category: (id: number | string) => `/categories/${id}`,
  categoryEdit: (id: number | string) => `/categories/${id}/edit`,
  categoryCreate: "/categories/create",

  documents: "/documents",
  documentEdit: (id: number | string) => `/documents/${id}/edit`,
  documentCreate: "/documents/create",

  standards: "/standards",
  standard: (id: number | string) => `/standards/${id}`,
  standardEdit: (id: number | string) => `/standards/${id}/edit`,
  standardUpdateSubstitute: (
    id: number | string,
    substituteId: number | string
  ) => `/standards/${id}/subsitute/${substituteId}`,
  standardCreate: "/standards/create",

  checkSteps: "/check-steps",
  checkStep: (id: number | string) => `/check-steps/${id}`,
  checkStepEdit: (id: number | string) => `/check-steps/${id}/edit`,
  checkStepCreate: "/check-steps/create",

  products: (category?: string) => {
    const url = new URL(window.location.origin + "/products");
    if (category) {
      url.searchParams.append("category", category);
    }
    return url.pathname + url.search;
  },
  product: (id: number | string) => `/products/${id}`,
  productEdit: (id: number | string) => `/products/${id}/edit`,
  productCreate: "/products/create",
  productImport: "/products/import",

  persons: "/persons",
  person: (id: number | string) => `/persons/${id}`,
  personEdit: (id: number | string) => `/persons/${id}/edit`,
  personCreate: "/persons/create",
  personQualificationCreate: (id: number | string) =>
    `/persons/${id}/qualification`,
  personQualification: (
    id: number | string,
    qualificationId: number | string
  ) => `/persons/${id}/qualification/${qualificationId}`,
  personQualificationEdit: (
    id: number | string,
    qualificationId: number | string
  ) => `/persons/${id}/qualification/${qualificationId}/edit`,

  items: "/items",
  item: (id: number | string) => `/items/${id}`,
  itemEdit: (id: number | string) => `/items/${id}/edit`,
  itemCreate: `/items/create`,
  itemImport: "/items/import",

  itemChecks: "/item-checks",
  itemCheck: (id: number | string) => `/item-checks/${id}`,
  itemCheckEdit: (id: number | string) => `/item-checks/${id}/edit`,

  checkProcesses: "/check-processes",
  checkProcess: (id: number | string) => `/check-processes/${id}`,
  checkProcessEdit: (id: number | string) => `/check-processes/${id}/edit`,
  checkProcessCreate: `/check-processes/create`,

  qualificationTags: "/qualification-tags",
  qualificationTag: (id: number | string) => `/qualification-tags/${id}`,
  qualificationTagCreate: "/qualification-tags/create",

  qualifications: "/qualifications",
  qualification: (id: number | string) => `/qualifications/${id}`,
  qualificationEdit: (id: number | string) => `/qualifications/${id}/edit`,
  qualificationCreate: "/qualifications/create",

  defects: "/defects",
  actions: "/actions",

  locations: "/locations",

  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",

  exports: "/exports",
};
