import { useTitle } from "../../hooks/use-title";
import { Button, Card, CardActions, Container, Divider } from "@mui/material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { DeliveryDatesValues, getOrderFormValues } from "../../model";
import { useOrderDetailsApi } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HttpError from "../../components/http-error";
import LoadingContainer from "../../components/loading-container";
import LoadingButton from "../../components/loading-button";
import { useOrderForm, useOrderUpdateMutation } from "../../hooks";
import { OrderFormFields } from "../../components/order-form-fields";

const EditOrder: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string | undefined }>();

  const { isLoading, error, data } = useOrderDetailsApi(
    orderId as unknown as number
  );

  useTitle(data?.orderNumber || t("Auftrag bearbeiten"));

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
  } = useOrderForm(getOrderFormValues(data));
  const { mutateAsync: updateMutation } = useOrderUpdateMutation(
    setError,
    data
  );

  useEffect(() => reset(getOrderFormValues(data)), [reset, data]);

  const onSubmit = async (values: any) => {
    if (!data) {
      return;
    }

    const deliveryDatesValues: DeliveryDatesValues[] = values.deliveryDates;
    if (deliveryDatesValues) {
      values.deliveryDates = deliveryDatesValues.map((date) => ({
        date: date.date,
        dateTo: date.isRange ? date.dateTo : null,
        badWeather: date.badWeather,
      }));
    }

    await updateMutation(values);
  };

  if (error) {
    return (
      <HttpError
        error={[error]}
        actions={
          <Button component={Link} to={routes.orders}>
            {t("Zurück zu Aufträgen")}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Aufträge"), link: routes.orders },
    {
      label: data.orderNumber,
      link: routes.order(data.id),
    },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Auftrag")} breadcrumbs={breadcrumbs} />
      <Card>
        <OrderFormFields
          control={control}
          watch={watch}
          setValue={setValue}
          order={data}
        />
        <Divider />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            disabled={!isValid}
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default EditOrder;
