import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { apiRoutes, request, useUserDetailsApi } from "../../lib/api";
import { useHistory } from "react-router";
import { useTitle } from "../../hooks/use-title";
import HttpError from "../../components/http-error";
import { Button, Card, CardActions, Container, Divider } from "@mui/material";
import LoadingContainer from "../../components/loading-container";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getUserFormValues,
  Person,
  UserFormValues,
} from "../../model";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import { useUserManagementValidationSchema } from "../../hooks/validations/user-management/use-user-management-validation-schema";
import { useForm, UseFormSetError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserPersonFormFields } from "../../components/user-person-form-fields";
import { UserFormFields } from "../../components/user-form-fields";

const EditUser: VoidFunctionComponent = () => {
  const { userId } = useParams<{ userId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useUserDetailsApi(
    userId as unknown as number
  );
  const history = useHistory();
  const { t } = useTranslation();
  const validationSchema = useUserManagementValidationSchema(false);
  useTitle(data?.username || t("Bearbeiten"));

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<UserFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getUserFormValues(data),
  });

  useEffect(() => reset(getUserFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: UserFormValues,
    setError: UseFormSetError<UserFormValues>
  ) => {
    if (!data) {
      return;
    }

    await request<Person>(apiRoutes.user(data.id), "put", values)
      .then(() => {
        enqueueSnackbar(t("Benutzer wurde erfolgreich bearbeitet."), {
          variant: "success",
        });
        history.push(routes.user(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.users}>
            Back to User overview
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const editBreadcrumbs = [
    { label: t("home"), link: routes.dashboard },
    { label: t("Benutzer"), link: routes.users },
    { label: data.username, link: routes.user(data.id) },
    { label: t("Bearbeiten") },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t("Benutzer")} breadcrumbs={editBreadcrumbs} />
      <Card>
        <UserFormFields control={control} />
        <Divider />
        <UserPersonFormFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          person={data.person}
        />
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit((values) => onSubmit(values, setError))}
          >
            {t("Speichern")}
          </LoadingButton>
        </CardActions>
      </Card>
    </Container>
  );
};

export default EditUser;
