import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

const StyledHeader = styled.header`
  margin-bottom: 2em;
`;

const StyledActions = styled.div`
  text-align: right;
`;

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

// Link proxy for breadcrumb links to React Router
// @ts-ignore
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const Header: FunctionComponent<{
  title: string;
  breadcrumbs?: Array<{
    label: string;
    link?: string;
  }>;
  actions?: ReactNode;
}> = ({ title, breadcrumbs = [], actions }) => (
  <StyledHeader>
    <Grid container justifyContent={`space-between`}>
      <div>
        <Typography variant="h2" component="h1" gutterBottom>
          {title}
        </Typography>
        <Breadcrumbs>
          {breadcrumbs &&
            breadcrumbs.map((link, key) =>
              link.link ? (
                <LinkRouter to={link.link} key={key}>
                  {link.label}
                </LinkRouter>
              ) : (
                <Typography color="textPrimary" key={key}>
                  {link.label}
                </Typography>
              )
            )}
        </Breadcrumbs>
      </div>
      <StyledActions>{actions}</StyledActions>
    </Grid>
  </StyledHeader>
);

export default Header;
