import { useRouteMatch } from "react-router-dom";
import GuardedRoute from "../../components/guarded-route";
import { Role } from "../../model";
import DefectOverview from "./overview";

const Defects = () => {
  const { path } = useRouteMatch();

  return (
    <GuardedRoute exact path={path} requiredRole={Role.Admin}>
      <DefectOverview />
    </GuardedRoute>
  );
};

export default Defects;
