import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import { Company, getCompanyFormValues } from "../../model";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/loading-button";
import PaperDialog from "../paper-dialog";
import { useCompanyForm } from "../../hooks/form/use-company-form";
import { useCurrentUser } from "../../hooks/use-current-user";
import { CompanyFormFields } from "../company-form-fields";
import { useCompanyCreateMutation } from "../../hooks/mutation/use-company-mutation";

const CreateCompanyDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  name?: string;
  onUpdate?: (data: Company) => void;
}> = ({ open, onClose, name = "", onUpdate }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    watch,
    getValues,
  } = useCompanyForm({
    ...getCompanyFormValues(),
    customer: true,
    name: name,
    term: name,
    owner: user.person?.primaryCompany?.id || null,
    testPartners: user.person?.primaryCompany
      ? [user.person?.primaryCompany?.id]
      : [],
  });

  const createMutation = useCompanyCreateMutation(setError, (data) => {
    reset(getCompanyFormValues());
    if (onUpdate) {
      onUpdate(data);
    }
    onClose();
  });

  return (
    <form
      onSubmit={handleSubmit((values) => createMutation.mutateAsync(values))}
    >
      <PaperDialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        autoFocus={false}
      >
        <DialogTitle>{t("Neue Organisation")}</DialogTitle>
        <DialogContent>
          <Card>
            <CompanyFormFields
              control={control}
              watch={watch}
              getValues={getValues}
            />
            <Divider />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="secondary">
            {t("Abbrechen")}
          </Button>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit((values) =>
              createMutation.mutateAsync(values)
            )}
          >
            {t("Speichern")}
          </LoadingButton>
        </DialogActions>
      </PaperDialog>
    </form>
  );
};

export default CreateCompanyDialog;
