import { useItemValidationSchema } from "../validations/items/use-item-validation-schema";
import { useForm } from "react-hook-form";
import { ItemFormValues } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";

export const useItemForm = (defaultValues: ItemFormValues) => {
  const validationSchema = useItemValidationSchema();

  return useForm<ItemFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
