import {
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";
import { Qualification, Role } from "../../model";
import { Add, Clear } from "@mui/icons-material";
import React, { useEffect, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import { FilterFormControl, FilterFormGroup } from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { Link } from "react-router-dom";
import { config } from "../../config";
import QualificationList from "../../components/qualification-list";

export interface QualificationFilters {
  search: string;
}

const QualificationOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Qualifikationen"));
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikationen") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [search, setFilterSearch] = useQueryState(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<QualificationFilters, Qualification>(
      { search },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    QualificationFilters,
    Qualification
  >(apiRoutes.qualifications, params);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  const resetFilter = () => setFilterSearch("");

  const hasFilters = search !== "";

  return (
    <Container data-test="qualifications-content">
      <Header
        title={`Qualifikationen`}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.QA}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.qualificationCreate}
            >
              <Add /> {t("Neue Qualifikation")}
            </Button>
          </Guarded>
        }
      />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                variant="outlined"
                placeholder={t("Name, Tag")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            {hasFilters && (
              <IconButton size="small" onClick={resetFilter}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        <QualificationList
          qualifications={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </Container>
  );
};

export default QualificationOverview;
