import { useProductValidation } from "./use-product-validation";
import { useMemo } from "react";
import * as yup from "yup";

export const useProductValidationSchema = () => {
  const productValidation = useProductValidation();

  return useMemo(() => {
    return yup.object({
      ...productValidation,
    });
  }, [productValidation]);
};
