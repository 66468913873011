import React, { FunctionComponent, useContext } from "react";
import { DateFormat, ItemCheck } from "../../model";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Definition, StyledCardTitle } from "../globals";
import { useTranslation } from "react-i18next";
import ItemCheckResult from "../item-check-result";
import FormattedDate from "../formatted-date";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../lib/routes";
import CheckGroupList from "../../views/item-checks/check-group-list";
import { ThemeContext } from "styled-components";
import { amber } from "@mui/material/colors";
import GuardedLink from "../guarded-link";
import { isOnlyClient, isOnlyTester } from "../../lib/security";
import { useCurrentUser } from "../../hooks/use-current-user";

export const ItemCheckDetailsDialog: FunctionComponent<{
  itemCheck: ItemCheck;
  open: boolean;
  onClose: () => void;
}> = ({ itemCheck, open, onClose }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: theme?.palette.grey["50"],
        },
      }}
    >
      <DialogTitle>{t("Item Prüfung")}</DialogTitle>
      <DialogContent>
        <ItemCheckDetails itemCheck={itemCheck} target="_blank" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {t("Schließen")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ItemCheckDetails: FunctionComponent<{
  itemCheck: ItemCheck;
  target?: string;
}> = ({ itemCheck, target = "" }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const onlyTester = isOnlyTester(user);
  const onlyClient = isOnlyClient(user);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prüfungs Details")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="overline"
                  color="textSecondary"
                  display={"block"}
                >
                  {t("Prüfurteil")}
                </Typography>
                <ItemCheckResult result={itemCheck.checkResult} />
                {itemCheck.hasDefect && (
                  <Chip
                    label={t("Mangelhaft")}
                    style={{
                      backgroundColor: amber[500],
                      color: "black",
                      marginLeft: "0.25em",
                    }}
                    size={`small`}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Definition
                  title={t("Prüfdatum")}
                  value={
                    <FormattedDate
                      date={itemCheck.checkDate}
                      format={DateFormat.Default}
                    />
                  }
                />
              </Grid>
              {itemCheck.checkedBy && (
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Prüfer")}
                    value={
                      <GuardedLink
                        disabled={onlyTester || onlyClient}
                        component={RouterLink}
                        to={routes.person(itemCheck.checkedBy.id)}
                        target={target}
                      >
                        {itemCheck.checkedBy.firstName}{" "}
                        {itemCheck.checkedBy.lastName}
                      </GuardedLink>
                    }
                  />
                </Grid>
              )}
              {itemCheck.person && (
                <Grid item xs={12} md={6}>
                  <Definition
                    title={t("Verwender")}
                    value={
                      <GuardedLink
                        disabled={onlyTester || onlyClient}
                        component={RouterLink}
                        to={routes.person(itemCheck.person.id)}
                        target={target}
                      >
                        {itemCheck.person.firstName} {itemCheck.person.lastName}
                      </GuardedLink>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Item Details")}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Definition
                  title={t("Item")}
                  value={
                    <GuardedLink
                      disabled={onlyTester}
                      component={RouterLink}
                      to={routes.item(itemCheck.item.id)}
                      target={target}
                    >
                      {itemCheck.item.serialNumber}
                    </GuardedLink>
                  }
                />
              </Grid>
              {itemCheck.item.product && (
                <>
                  <Grid item xs={12} md={6}>
                    <Definition
                      title={t("Produkt")}
                      value={
                        <GuardedLink
                          disabled={onlyTester || onlyClient}
                          component={RouterLink}
                          to={routes.product(itemCheck.item.product.id)}
                          target={target}
                        >
                          {itemCheck.item.product.model}
                        </GuardedLink>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Definition
                      title={t("Hersteller")}
                      value={
                        <GuardedLink
                          disabled={onlyTester || onlyClient}
                          component={RouterLink}
                          to={routes.company(
                            itemCheck.item.product.manufacturer.id
                          )}
                          target={target}
                        >
                          {itemCheck.item.product.manufacturer.name}
                        </GuardedLink>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Definition
                      title={t("Kategorie")}
                      value={
                        <GuardedLink
                          disabled={onlyTester || onlyClient}
                          component={RouterLink}
                          to={routes.category(
                            itemCheck.item.product.category.id
                          )}
                          target={target}
                        >
                          {itemCheck.item.product.category.name}
                        </GuardedLink>
                      }
                    />
                  </Grid>
                </>
              )}
              {itemCheck.order && (
                <>
                  <Grid item xs={12} md={6}>
                    <Definition
                      title={t("Auftrag")}
                      value={
                        <GuardedLink
                          disabled={onlyTester}
                          component={RouterLink}
                          to={routes.order(itemCheck.order.id)}
                          target={target}
                        >
                          {itemCheck.order.orderNumber}
                        </GuardedLink>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Definition
                      title={t("Kunde")}
                      value={
                        <GuardedLink
                          disabled={onlyTester || onlyClient}
                          component={RouterLink}
                          to={
                            itemCheck.order.customer
                              ? routes.company(itemCheck.order.customer.id)
                              : ""
                          }
                          target={target}
                        >
                          {itemCheck.order.customer?.name || "-"}
                        </GuardedLink>
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Divider />
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t("Prüfschritte im Detail")}
            </StyledCardTitle>
          </CardContent>
          {itemCheck.result &&
            itemCheck.result.length > 0 &&
            itemCheck.result.map((checkGroup) => (
              <CheckGroupList checkGroup={checkGroup} key={checkGroup.id} />
            ))}
          {!itemCheck.result?.length && (
            <CardContent>
              <Alert severity="info">
                {t(
                  "Ein importiertes Prüfergebnis hat keine Auflistung der Prüfschritte."
                )}
              </Alert>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ItemCheckDetails;
