import { ID, Person } from "../model";

export function isAllowedClientLocation(
  person?: Person | null,
  locationId?: ID | null
) {
  if (!person || !person.locations || !person.locations.length) {
    return true;
  }
  if (!locationId) {
    return false;
  }
  let isAllowed = false;
  (person.locations || []).forEach((personLocation) => {
    if (+locationId === personLocation.id) {
      isAllowed = true;
    }
  });
  return isAllowed;
}
