import React, { FunctionComponent, ReactElement } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

interface Props extends RouteComponentProps {
  to: string;
  exact?: boolean;
  icon: ReactElement;
  label: string;
  disabled?: boolean;
}

// See https://material-ui.com/guides/composition/#list
const ListItemLink: FunctionComponent<Props> = ({
  to,
  exact,
  icon,
  label,
  location,
  disabled,
}) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const active = exact
    ? location.pathname === to
    : `${location.pathname}/`.startsWith(`${to}/`);

  return (
    <li>
      <ListItem
        selected={active}
        button
        component={renderLink}
        dense={true}
        disabled={disabled}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} />
      </ListItem>
    </li>
  );
};

export default withRouter(ListItemLink);
