import { Route, Switch, useRouteMatch } from "react-router-dom";
import CompanyOverview from "./overview";
import { routes } from "../../lib/routes";
import CompanyDetails from "./details";
import CreateCompany from "./create";
import EditCompany from "./edit";
import { Role } from "../../model";
import GuardedRoute from "../../components/guarded-route";

const Customers = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CompanyOverview />
      </Route>
      <GuardedRoute path={routes.companyCreate} requiredRole={Role.Admin}>
        <CreateCompany />
      </GuardedRoute>
      <GuardedRoute
        path={routes.companyEdit(":companyId")}
        requiredRole={Role.Admin}
      >
        <EditCompany />
      </GuardedRoute>
      <GuardedRoute
        path={routes.company(":companyId")}
        requiredRole={Role.Admin}
      >
        <CompanyDetails />
      </GuardedRoute>
    </Switch>
  );
};

export default Customers;
