import { useMemo } from "react";
import * as yup from "yup";
import { useActionValidation } from "./use-action-validation";

export const useActionValidationSchema = () => {
  const validation = useActionValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
