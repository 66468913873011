import { Route, Switch, useRouteMatch } from "react-router-dom";
import LogsOverview from "./overview";
import LogsDetails from "./details";
import { routes } from "../../lib/routes";

const Logs = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <LogsOverview />
      </Route>
      <Route path={routes.log(":logId")}>
        <LogsDetails />
      </Route>
    </Switch>
  );
};

export default Logs;
