import { Fragment, VoidFunctionComponent } from "react";
import {
  CheckGroupStep,
  CheckProcessGroup,
  CheckStepCondition,
  CheckStepType,
  Nullable,
} from "../../model";
import {
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { green, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export const ChoiceButton = styled(Button)<{
  customcolor: string;
  selected: boolean;
}>`
  ${({ customcolor, selected }) =>
    selected &&
    `
    background-color: ${customcolor};
    color: #fff;

    &:hover {
      background-color: ${customcolor};
    }
  `};
`;

const CheckStep: VoidFunctionComponent<{
  title: string;
  type: CheckStepType;
  value: unknown;
  onChange: (value: unknown) => void;
  root?: boolean;
  description?: Nullable<string>;
}> = ({ title, value, onChange, root, type, description }) => {
  const { t } = useTranslation();

  return (
    <ListItem style={root ? { paddingLeft: 0 } : {}}>
      {type === CheckStepType.Bool ? (
        <>
          <ListItemText primary={title} secondary={description || ""} />
          <ListItemIcon>
            <ButtonGroup disableElevation>
              <ChoiceButton
                selected={value === true}
                customcolor={green[700]}
                onClick={() => onChange(value === true ? null : true)}
              >
                {t("Ja")}
              </ChoiceButton>
              <ChoiceButton
                customcolor={red[700]}
                selected={value === false}
                onClick={() => onChange(value === false ? null : false)}
              >
                {t("Nein")}
              </ChoiceButton>
            </ButtonGroup>
          </ListItemIcon>
        </>
      ) : (
        <ListItemText>
          <div>{title}</div>
          <TextField
            variant="standard"
            fullWidth
            multiline={true}
            placeholder="Freitext"
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            helperText={description || ""}
          />
        </ListItemText>
      )}
    </ListItem>
  );
};

const CheckGroup: VoidFunctionComponent<{
  checkProcessGroup: CheckProcessGroup;
  onUpdate: (
    checkProcessGroup: CheckProcessGroup,
    checkGroupStep: CheckGroupStep,
    value: unknown,
    condition?: CheckStepCondition
  ) => void;
}> = ({ checkProcessGroup, onUpdate }) => {
  return (
    <List style={{ width: "100%", paddingLeft: 0 }}>
      {checkProcessGroup.checkGroup.checkSteps.map((checkGroupStep, index) => (
        <Fragment key={index}>
          <CheckStep
            title={checkGroupStep.checkStep.title}
            type={checkGroupStep.checkStep.type}
            value={checkGroupStep.checkStep.value}
            onChange={(value) =>
              onUpdate(checkProcessGroup, checkGroupStep, value)
            }
            root={true}
            description={checkGroupStep.checkStep.description}
          />
          {checkGroupStep.checkStep.conditions &&
            checkGroupStep.checkStep.conditions.length > 0 && (
              <List>
                {checkGroupStep.checkStep.conditions
                  .filter(
                    (c) => c.condition.value === checkGroupStep.checkStep.value
                  )
                  .map((condition, index) => (
                    <CheckStep
                      title={condition.checkStep.title}
                      type={condition.checkStep.type}
                      value={condition.checkStep.value}
                      onChange={(value) =>
                        onUpdate(
                          checkProcessGroup,
                          checkGroupStep,
                          value,
                          condition
                        )
                      }
                      key={index}
                      description={condition.checkStep.description}
                    />
                  ))}
              </List>
            )}
        </Fragment>
      ))}
    </List>
  );
};

export default CheckGroup;
