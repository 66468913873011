import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import { routes } from "../../lib/routes";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Header from "../../components/header";
import { StyledCardTitle } from "../../components/globals";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { ApiError, Item } from "../../model";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { handleHookFormErrors } from "../../helpers";
import {
  AddOutlined,
  Description,
  ErrorOutline,
  Update,
} from "@mui/icons-material";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const StyledGrid = styled(Grid)`
  text-align: center;
`;

const StyledTitle = styled(Grid)`
  margin-top: 1em;
`;

interface FormValues {
  importFile: any;
}

interface ImportResult {
  errors: Array<{ message: string; row: number }>;
  items: Item[];
  created: Item[];
  updated: Item[];
}

const ImportItem = () => {
  const { t } = useTranslation();
  useTitle(t("Items importieren"));
  const { enqueueSnackbar } = useSnackbar();
  const [importResult, setImportResult] = useState<ImportResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Items"), link: routes.items },
    { label: t("Importieren") },
  ];

  const validationSchema = yup.object({
    importFile: yup.mixed().required(t("Modell darf nicht leer sein.")),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      importFile: "",
    },
  });

  const onSubmit = async (
    values: FormValues,
    setError: UseFormSetError<FormValues>
  ) => {
    if (!values.importFile) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", values.importFile);
    setImportResult(null);
    setLoading(true);
    await request<ImportResult>(apiRoutes.itemImport, "post", formData, null, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        setImportResult(res.data);
        enqueueSnackbar(t("Produkte-Import ist fertig."), {
          variant: "success",
        });
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container maxWidth="md">
      <Header title={t("Items importieren")} breadcrumbs={breadcrumbs} />
      <Grid container spacing={3} direction="row">
        <Grid item xs={12}>
          <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
            <Card>
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t("Datei hochladen")}
                </StyledCardTitle>
                <Grid container spacing={3} alignItems="center">
                  <Grid item md={6}>
                    <Typography>
                      {t("Die Tabelle muss folgende Spalten enthalten:")}
                    </Typography>
                    <ul>
                      <li>{t("Auftragsnummer")} *</li>
                      <li>{t("Seriennummer")} *</li>
                      <li>{t("Modell")} *</li>
                      <li>{t("Hersteller")} *</li>
                      <li>{t("Systemnummer")} </li>
                      <li>{t("Herstellungsjahr")} </li>
                      <li>{t("Kunde")} </li>
                      <li>{t("Standort")} </li>
                      <li>{t("Adresse")} </li>
                      <li>{t("PLZ")} </li>
                      <li>{t("Ort")} </li>
                      <li>{t("Erster Gebrauch")} </li>
                      <li>{t("Verwender")} </li>
                      <li>{t("Überprüfer Vorname")} </li>
                      <li>{t("Überprüfer Nachname")} </li>
                      <li>{t("Prüfdatum")} </li>
                      <li>{t("Prüfurteil")} </li>
                    </ul>
                    <Typography variant="caption">
                      {t("* Spalte muss ausgefüllt sein.")}
                    </Typography>
                  </Grid>
                  <StyledGrid item md={6}>
                    <Controller
                      control={control}
                      name={"importFile"}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            value=""
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            hidden
                            id="import-upload"
                            type="file"
                            onChange={(e) =>
                              field.onChange(e.currentTarget.files![0])
                            }
                          />
                          <label htmlFor="import-upload">
                            <Button
                              variant="contained"
                              component="span"
                              color="primary"
                            >
                              <Description /> {t("Datei auswählen")}
                            </Button>
                          </label>
                          <Typography variant="caption" display="block">
                            {field.value.name}
                          </Typography>
                        </>
                      )}
                    />
                  </StyledGrid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  type="submit"
                  size="medium"
                  color="primary"
                  disabled={!isValid || loading}
                >
                  {t("Importieren")}
                </Button>
                {loading && (
                  <CircularProgress color="secondary" size={"1.5em"} />
                )}
              </CardActions>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12}>
          <>
            {importResult && (
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t("Import Zusammenfassung")}
                  </StyledCardTitle>
                  <Typography>
                    {t(
                      "Es wurden {{created}} Items angelegt und {{updated}} aktualisiert.",
                      {
                        created: importResult?.created.length || 0,
                        updated: importResult?.updated.length || 0,
                      }
                    )}
                  </Typography>
                  {importResult?.errors.length > 0 && (
                    <>
                      <StyledTitle container alignItems="center" spacing={1}>
                        <Grid item>
                          <ErrorOutline style={{ fill: "red" }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {t("{{errors}} Fehler", {
                              errors: importResult?.errors.length,
                            })}
                          </Typography>
                        </Grid>
                      </StyledTitle>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("Zeile")}</TableCell>
                              <TableCell>{t("Fehler")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {importResult?.errors.map((error, index) => (
                              <TableRow key={index}>
                                <TableCell>{error.row}</TableCell>
                                <TableCell>{error.message}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    {importResult?.created.length > 0 && (
                      <Grid item md={6}>
                        <StyledTitle container alignItems="center" spacing={1}>
                          <Grid item>
                            <AddOutlined style={{ fill: "green" }} />
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">
                              {t("{{created}} Erstellt", {
                                created: importResult?.created.length,
                              })}
                            </Typography>
                          </Grid>
                        </StyledTitle>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("Seriennummer")}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importResult?.created.map((created, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Link
                                      component={RouterLink}
                                      to={routes.item(created.id)}
                                      target="_blank"
                                    >
                                      {created.serialNumber}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                    <Grid item md={6}>
                      <StyledTitle container alignItems="center" spacing={1}>
                        <Grid item>
                          <Update style={{ fill: "orange" }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {t("{{updated}} Aktualisiert", {
                              updated: importResult?.updated.length,
                            })}
                          </Typography>
                        </Grid>
                      </StyledTitle>
                      {importResult?.updated.length > 0 && (
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("Seriennummer")}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importResult?.updated.map((updated, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Link
                                      component={RouterLink}
                                      to={routes.item(updated.id)}
                                      target="_blank"
                                    >
                                      {updated.serialNumber}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImportItem;
