import React, { FunctionComponent, useContext } from "react";
import { Dialog, DialogProps } from "@mui/material";
import { ThemeContext } from "styled-components";
import { grey } from "@mui/material/colors";

const PaperDialog: FunctionComponent<
  {
    open: boolean;
    onClose: () => void;
  } & DialogProps
> = ({ open, onClose, children, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor:
            theme?.palette.mode === "light" ? grey["100"] : "#000",
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default PaperDialog;
