import { AppGrant, CurrentUser, Grant, Grantable, Role } from "../model";

export interface Guardable<TGrant extends Grant> {
  requiredRole?: Role | Role[];
  grant?: TGrant;
  subject?: Grantable<TGrant>;
}

export function isGranted<TGrant extends Grant>(
  user: CurrentUser,
  grant: TGrant,
  subject?: Grantable<TGrant>
): boolean {
  if (subject) {
    return subject.grants.includes(grant);
  }
  return user.appGrants.includes(grant as AppGrant);
}

export function hasRole(
  user: CurrentUser,
  requiredRole: Role | Role[]
): boolean {
  if (!Array.isArray(requiredRole)) {
    requiredRole = [requiredRole];
  }
  const userRoles = user.inheritedRoles || [];
  return requiredRole.some((role) => userRoles.includes(role));
}

export function isOnlyUser(user: CurrentUser): boolean {
  return (
    user.inheritedRoles.length === 1 && user.inheritedRoles.includes(Role.User)
  );
}

export function isInvalidUser(user: CurrentUser): boolean {
  return (isOnlyClient(user) || isOnlyTester(user)) && !user.person;
}

export function isOnlyClient(user: CurrentUser): boolean {
  return (
    user.inheritedRoles.length === 2 &&
    hasRole(user, Role.User) &&
    hasRole(user, Role.Client)
  );
}

export function isOnlyTester(user: CurrentUser): boolean {
  return (
    user.inheritedRoles.length === 2 &&
    hasRole(user, Role.User) &&
    hasRole(user, Role.Tester)
  );
}
