import React, { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import {
  Category,
  DateFormat,
  Document,
  ManualType,
  Role,
  Standard,
  StandardFormValues,
  StandardReview,
  StandardType,
  User,
} from "../../model";
import { useTranslation } from "react-i18next";
import { apiRoutes } from "lib/api";
import HttpError from "components/http-error";
import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { routes } from "lib/routes";
import { Link } from "react-router-dom";
import { StyledCardTitle, StyledHighlightedGrid } from "components/globals";
import { Add, Clear } from "@mui/icons-material";
import { useApi } from "../../hooks/use-api";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { hasRole } from "../../lib/security";
import { format as dateFormat, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { StandardSubstitute } from "../../views/standards/details";
import DocumentUpload from "../document-upload";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { useCurrentUser } from "../../hooks/use-current-user";

const StandardFormFields: FunctionComponent<{
  control: Control<StandardFormValues>;
  watch: UseFormWatch<StandardFormValues>;
  data?: Standard;
}> = ({ control, watch, data }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [standard, setStandard] = useState<Standard | null>(null);

  const {
    isLoading: availableSubstitutesLoading,
    error: availableSubstitutesError,
    data: availableSubstitutes,
  } = useApi<Standard[]>(
    apiRoutes.standardAvailableSubstitutes(data?.id as unknown as number),
    "get",
    undefined,
    undefined,
    undefined,
    {
      enabled: !!data,
    }
  );

  const { params: standardParams } = useFilters<{ type: string }, Standard>(
    { type: StandardType.Standard },
    "number",
    undefined,
    0
  );
  const {
    data: standards,
    isLoading: standardsLoading,
    error: standardsError,
  } = usePaginationApi<{ type: string }, Standard>(
    apiRoutes.standards,
    standardParams,
    undefined,
    undefined,
    {
      enabled: !data,
    }
  );

  const { error: duplicateStandardsError, data: duplicateStandards } = useApi<
    Standard[]
  >(
    apiRoutes.standardDuplicateNumberSubstitutes(data?.id as unknown as number),
    "get",
    undefined,
    undefined,
    undefined,
    {
      enabled: !!data,
    }
  );

  const {
    data: allCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useUnlimitedPaginationApi<{}, Category>(apiRoutes.categories);

  const { params: documentParams } = useFilters<{ type: string }, Document>(
    { type: ManualType.StandardPDF },
    "name",
    undefined,
    0
  );
  const {
    data: documents,
    isLoading: documentsLoading,
    error: documentsError,
  } = usePaginationApi<{ type: string }, Document>(
    apiRoutes.documents,
    documentParams
  );

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
  } = useApi<User[]>(apiRoutes.standardUsers);

  useEffect(() => {
    if (!data) {
      return;
    }

    setStandard(data);
  }, [data]);

  if (
    usersError ||
    categoriesError ||
    documentsError ||
    availableSubstitutesError ||
    duplicateStandardsError ||
    standardsError
  ) {
    return (
      <HttpError
        error={[
          usersError,
          categoriesError,
          documentsError,
          availableSubstitutesError,
          duplicateStandardsError,
          standardsError,
        ]}
        actions={
          <Button component={Link} to={routes.standards}>
            {t("Zurück zu Normen")}
          </Button>
        }
      />
    );
  }

  const showUpload = watch("showUpload", true);

  return (
    <Grid container>
      <Grid item md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Norm")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"number"}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Nummer")}
                    fullWidth
                    required
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"date"}
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value ? new Date(field.value) : null}
                    format={DateFormat.Default}
                    onChange={field.onChange}
                    label={t("Ausgabedatum")}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        error: fieldState.isTouched && fieldState.invalid,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"type"}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>{t("Typ")}</InputLabel>
                    <Select
                      variant="standard"
                      labelId="roles-label"
                      id="type"
                      fullWidth
                      {...field}
                      value={field.value}
                    >
                      {Object.values(StandardType).map((type) => (
                        <MenuItem value={type} key={type}>
                          {t(`standard.type.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"validUntil"}
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value ? new Date(field.value) : null}
                    format={DateFormat.Default}
                    onChange={field.onChange}
                    label={t("Gültig bis")}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        error: fieldState.isTouched && fieldState.invalid,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"scope"}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Geltungsbereich")}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"description"}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Beschreibung")}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Ersetzt durch die Norm")}
          </StyledCardTitle>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"substitute"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    disabled={!hasRole(currentUser, Role.User)}
                    id="substitute"
                    loading={availableSubstitutesLoading || standardsLoading}
                    options={(
                      availableSubstitutes ||
                      standards?.results ||
                      []
                    ).sort((a, b) => {
                      if (a.type && b.type) {
                        return -b.type.localeCompare(a.type);
                      }
                      return -1;
                    })}
                    groupBy={(option) =>
                      t(`standard.type.${option.type}`) ||
                      t("standard.type.none")
                    }
                    getOptionLabel={(option) =>
                      option.number +
                      (option.date
                        ? " (" +
                          dateFormat(
                            parseISO(option.date),
                            DateFormat.Default,
                            { locale: de }
                          ) +
                          ")"
                        : "")
                    }
                    value={
                      (availableSubstitutes || standards?.results)?.find(
                        (standard) => standard.id === field.value
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => field.onChange(value?.id)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Ersatznorm")}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          {duplicateStandards && standard && (
            <Grid container>
              {duplicateStandards.map((substitute) => (
                <Grid item xs={12}>
                  <StandardSubstitute
                    standard={standard}
                    substitute={substitute}
                    setStandard={setStandard}
                    key={substitute.id}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="body1" color="secondary" gutterBottom>
            {t("Sonstiges")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Controller
                control={control}
                name={"comment"}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Notiz")}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"reviewInterval"}
                defaultValue={12}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Prüfintervall")}
                    type="number"
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message || t("in Monaten")}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"complete"}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value || false}
                        onChange={field.onChange}
                        color="primary"
                      />
                    }
                    label={t("Vollständig")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
      <Divider
        orientation={"vertical"}
        flexItem
        style={{ marginRight: "-1px" }}
      />
      <Grid item md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Kategorien")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"categories"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="categories"
                    multiple={true}
                    loading={categoriesLoading}
                    options={allCategories || []}
                    defaultValue={data?.categories}
                    getOptionLabel={(option) =>
                      option.name +
                      (option.parents[0]
                        ? " (" + option.parents[0].name + ")"
                        : "")
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) =>
                      field.onChange(value.map((category) => category.id))
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Kategorien")}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Dateien")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"link"}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="standard"
                    label={t("Link")}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"document"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="document"
                    disabled={!hasRole(currentUser, Role.User)}
                    options={documents?.results || []}
                    loading={documentsLoading}
                    getOptionLabel={(option) => option.name}
                    value={
                      documents?.results.find(
                        (document) => document.id === field.value
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => field.onChange(value?.id)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("PDF")}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"showUpload"}
                render={({ field }) => (
                  <>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        field.onChange(!showUpload);
                      }}
                      sx={{ margin: "1em 0" }}
                    >
                      {showUpload ? (
                        <>
                          {" "}
                          <Clear /> {t("Abbrechen")}
                        </>
                      ) : (
                        <>
                          <Add /> {t("Neues Dokument hinzufügen1")}
                        </>
                      )}
                    </Button>
                    <Checkbox
                      aria-hidden={true}
                      style={{ display: "none" }}
                      checked={showUpload}
                      onChange={field.onChange}
                      color="primary"
                    />
                  </>
                )}
              />
            </Grid>
            {showUpload && (
              <StyledHighlightedGrid item xs={12}>
                <Typography variant={"h6"} gutterBottom={true}>
                  {t("Neues Dokument hinzufügen")}
                </Typography>
                <DocumentUpload
                  control={control}
                  formPrefix="documentUpload."
                />
              </StyledHighlightedGrid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t("Prüfung")}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"reviewedBy"}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="reviewedBy"
                    disabled={!hasRole(currentUser, Role.QA)}
                    options={users || []}
                    loading={usersLoading}
                    getOptionLabel={(option) =>
                      option.fullName || option.username
                    }
                    value={users?.find((data) => data.id === field.value)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => field.onChange(value?.id)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label={t("Prüfer")}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"reviewedAt"}
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value ? new Date(field.value) : null}
                    disabled={!hasRole(currentUser, Role.QA)}
                    format={DateFormat.Default}
                    onChange={field.onChange}
                    label={t("Prüfdatum")}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        error: fieldState.isTouched && fieldState.invalid,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                control={control}
                name={"review"}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="review-label">
                      {t("Prüfergebnis")}
                    </InputLabel>
                    <Select
                      variant="standard"
                      labelId="review-label"
                      label={t("Prüfergebnis")}
                      disabled={!hasRole(currentUser, Role.QA)}
                      id="roles"
                      fullWidth
                      {...field}
                      value={field.value}
                    >
                      {Object.values(StandardReview).map((review) => (
                        <MenuItem value={review} key={review}>
                          {t(`standard.review.${review}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default StandardFormFields;
