import {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";
import { Box, Container, Link, Typography } from "@mui/material";
import styled from "styled-components";
import GriehserLogo from "../../images/logo.png";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Route,
  Switch,
} from "react-router-dom";
import LoginForm from "./login-form";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import { useTranslation } from "react-i18next";
import { routes } from "../../lib/routes";
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material/styles";
import { MaterialLightTheme } from "../../styles/theme";

const StyledImg = styled.img`
  margin: 3em auto 1em;
  width: 100%;
  will-change: transform;
`;

const StyledDiv = styled.div`
  text-align: center;
`;

const client = new QueryClient();

// Used to share data between forms
export const LoginContext = createContext<{
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}>({
  email: "",
  setEmail: () => undefined,
});

const LoginFooterLink: FunctionComponent<{ label: string; to: string }> = ({
  label,
  to,
}) => {
  return (
    <Box textAlign="center" my={2}>
      <Typography variant="body1">
        <Link component={RouterLink} to={to}>
          {label}
        </Link>
      </Typography>
    </Box>
  );
};

const Login: FunctionComponent = () => {
  const theme = createTheme(adaptV4Theme(MaterialLightTheme));
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={client}>
        <LoginContext.Provider value={{ email, setEmail }}>
          <MaterialThemeProvider theme={theme}>
            <Container maxWidth={`xs`}>
              <StyledDiv>
                <StyledImg src={GriehserLogo} />
              </StyledDiv>
              <Router>
                <Switch>
                  <Route path={routes.forgotPassword}>
                    <ForgotPassword />
                    <LoginFooterLink label={t("Zurück zum Login")} to="/" />
                  </Route>
                  <Route path={routes.resetPassword}>
                    <ResetPassword />
                    <LoginFooterLink label={t("Zurück zum Login")} to="/" />
                  </Route>
                  <Route path="/">
                    <LoginForm />
                    <LoginFooterLink
                      label={t("Passwort vergessen")}
                      to={routes.forgotPassword}
                    />
                  </Route>
                </Switch>
              </Router>
            </Container>
          </MaterialThemeProvider>
        </LoginContext.Provider>
      </QueryClientProvider>
    </StyledEngineProvider>
  );
};

export default Login;
