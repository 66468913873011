import {
  Blameable,
  Commentable,
  Company,
  ID,
  Location,
  Timestampable,
} from "./index";

// Sync with DefectVoter in backend
export enum DefectGrant {
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export interface Defect
  extends DefectPreview,
    Timestampable,
    Blameable,
    Commentable {
  company: Company;
  location: Location | null;
}

export interface DefectPreview {
  id: ID;
  title: string;
}

export interface DefectFormValues {
  title: string;
  company: ID | null;
  location: ID | null;
}

export const getDefectFormValues = (data?: Defect): DefectFormValues => ({
  title: data?.title || "",
  company: data?.company.id || null,
  location: data?.location?.id || null,
});
