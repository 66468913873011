import React, { FunctionComponent } from "react";
import {
  DateFormat,
  HeadCell,
  Role,
  Standard,
  StandardReview,
  StandardType,
} from "../../model";
import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { routes } from "../../lib/routes";
import { CompactTableCell } from "../globals";
import Guarded from "../guarded";
import { useTranslation } from "react-i18next";
import { FilterParams, SortHandler } from "../../hooks/use-filters";
import { IconLink } from "../icon-link";
import { CategoryFilters } from "../../views/categories/overview";
import styled from "styled-components";
import FormattedDate from "../formatted-date";
import { green, grey, orange, red } from "@mui/material/colors";
import { TableNoResult } from "../table-no-result";

export const StandardReviewStyledChip: FunctionComponent<{
  standard: Standard;
}> = ({ standard }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {(standard.review === StandardReview.Valid && (
          <StyledChip
            label={t("Gültig")}
            style={{ backgroundColor: green[500], color: "white" }}
            size="small"
          />
        )) ||
          (standard.review === StandardReview.Invalid && (
            <StyledChip
              label={t("Ungültig")}
              style={{ backgroundColor: red[500], color: "white" }}
              size="small"
            />
          )) ||
          (standard.review === StandardReview.Irrelevant && (
            <StyledChip
              label={t("Nicht mehr relevant")}
              style={{ backgroundColor: grey[500], color: "white" }}
              size="small"
            />
          )) ||
          (standard.review === StandardReview.Required && (
            <StyledChip
              label={t("Erforderlich")}
              style={{ backgroundColor: orange[600], color: "white" }}
              size="small"
            />
          ))}
      </div>
    </>
  );
};

export const StandardTypeStyledChip: FunctionComponent<{
  standard: Standard;
}> = ({ standard }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {(standard.type === StandardType.Broschure && (
          <StyledChip
            color="default"
            variant={"outlined"}
            label={t(`standard.type.${standard.type}`)}
            size="small"
          />
        )) ||
          (standard.type === StandardType.Order && (
            <StyledChip
              color="default"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.Law && (
            <Chip
              color="primary"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.Leaflet && (
            <StyledChip
              color="secondary"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.Standard && (
            <StyledChip
              color="primary"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.Paragraph && (
            <StyledChip
              color="default"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.TechnicalRegulation && (
            <StyledChip
              color="default"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          )) ||
          (standard.type === StandardType.Act && (
            <StyledChip
              color="default"
              variant={"outlined"}
              label={t(`standard.type.${standard.type}`)}
              size="small"
            />
          ))}
      </div>
    </>
  );
};

const StyledChip = styled(Chip)`
  margin: 1px;
`;

const StandardList: FunctionComponent<{
  standards: Standard[];
  loading?: boolean;
  params?: FilterParams<CategoryFilters, Standard>;
  createSortHandler?: SortHandler<Standard>;
}> = ({ standards, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<Standard>[] = [
    { id: "number", label: t("Nummer") },
    { id: "type", label: t("Typ") },
    { id: "scope", label: t("Geltungsbereich") },
    { id: "reviewedAt", label: t("Prüfdatum") },
    { id: "review", label: t("Prüfergebnis") },
    { id: "complete", label: t("Vollständig") },
    { id: "createdAt", label: t("Erstellt") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {!loading ? (
          <>
            <TableBody>
              {standards.map((standard) => (
                <TableRow key={standard.id} hover={true}>
                  <CompactTableCell>{standard.number}</CompactTableCell>
                  <CompactTableCell>
                    <StandardTypeStyledChip standard={standard} />
                  </CompactTableCell>
                  <CompactTableCell>{standard.scope}</CompactTableCell>
                  <CompactTableCell>
                    {standard.reviewedAt && (
                      <FormattedDate
                        date={standard.reviewedAt}
                        format={DateFormat.Default}
                      />
                    )}
                  </CompactTableCell>
                  <CompactTableCell>
                    <StandardReviewStyledChip standard={standard} />
                  </CompactTableCell>
                  <CompactTableCell>
                    {standard.complete ? t("Vollständig") : t("Unvollständig")}
                  </CompactTableCell>
                  <CompactTableCell>
                    <FormattedDate
                      date={standard.createdAt}
                      format={DateFormat.Default}
                    />
                  </CompactTableCell>
                  <CompactTableCell align={"right"}>
                    <IconLink
                      to={routes.standard(standard.id)}
                      tooltip={t("Ansehen")}
                      icon={<Visibility />}
                    />
                    <Guarded requiredRole={Role.User}>
                      <IconLink
                        to={routes.standardEdit(standard.id)}
                        tooltip={t("Bearbeiten")}
                        icon={<Edit />}
                      />
                    </Guarded>
                  </CompactTableCell>
                </TableRow>
              ))}
            </TableBody>
            {!standards.length && <TableNoResult />}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default StandardList;
