import { useTitle } from "../../hooks/use-title";
import { Card, CardActions, Container, Divider } from "@mui/material";
import React, { VoidFunctionComponent } from "react";
import Header from "../../components/header";
import {
  ApiError,
  getStandardFormValues,
  Standard,
  StandardFormValues,
} from "../../model";
import { apiRoutes, request } from "../../lib/api";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { handleHookFormErrors } from "../../helpers";
import { routes } from "../../lib/routes";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormSetError } from "react-hook-form";
import LoadingButton from "../../components/loading-button";
import { useStandardValidationSchema } from "../../hooks/validations/standards/use-standard-validation-schema";
import StandardFormFields from "../../components/standard-form-fields";

const CreateStandard: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Neue Norm"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Normen"), link: routes.standards },
    { label: t("Neu") },
  ];

  const validationSchema = useStandardValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<StandardFormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: getStandardFormValues(),
  });

  const showUpload = watch("showUpload");

  const onSubmit = async (
    values: StandardFormValues,
    setError: UseFormSetError<StandardFormValues>
  ) => {
    if (showUpload && !values.documentUpload.documentUpload) {
      enqueueSnackbar(t("Bitte wählen Sie eine Datei aus."), {
        variant: "warning",
      });
      return;
    }

    const formData = new FormData();
    formData.append("documentUploadFile", values.documentUpload.documentUpload);
    formData.append(
      "content",
      JSON.stringify({
        number: values.number,
        date: values.date,
        validUntil: values.validUntil,
        substitute: values.substitute,
        type: values.type,
        scope: values.scope,
        description: values.description,
        reviewedAt: values.reviewedAt,
        reviewedBy: values.reviewedBy,
        review: values.review,
        link: values.link,
        reviewInterval: values.reviewInterval,
        complete: values.complete,
        comment: values.comment,
        document: values.document,
        categories: values.categories,
        ...(values.showUpload
          ? {
              documentUpload: {
                name: values.documentUpload.name,
                type: values.documentUpload.type,
                comment: values.documentUpload.comment,
              },
            }
          : {}),
      })
    );
    await request<Standard>(apiRoutes.standardCreate, "post", formData)
      .then(() => {
        enqueueSnackbar(t("Norm wurde erfolgreich erstellt."), {
          variant: "success",
        });
        history.push(routes.standards);
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: "error" });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container>
      <Header title={t("Norm")} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <StandardFormFields control={control} watch={watch} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t("Speichern")}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateStandard;
